// Day (DAY)
// Dia (DIA)
// Tabs (TAB)
// Tickets (T)
// Planning (P)
// SiteForm (SF)
// User Form (UF)
// Register (REG)
// User Form (UF)
// Light Tree (LT)
// Mail Check (MC)
// Global (GLOBAL)
// QR Entity (QRE)
// Excel Modal (EM)
// Form Render (FR)
// Vent Detail (VD)
// Event Reader (ER)
// Build Resume (BR)
// Annotation (ANNOT)
// Time Selector (TS)
// Grafana PopUp (GP)
// Formula Editor (FE)
// Gestion (G, GT, GU)
// Observations (OBST)
// Audio Recorder (AR)
// Benchmark graph (BG)
// Dashboard Ticket (DB)
// Covid Form Select (CFS)
// Air renewal Graph (ARG)
// Table Intervenants (TI)
// Notif (NOTIF, DIA_NOTIF)
// Covid From Question (CFQ)
// Schema - Canvas (SCH, CAN)
// Question Observations (QO)

export const DOC_TYPE_AUTO_FILE_TIP = "DOC_TYPE_AUTO_FILE_TIP";
export const REG_PROP_INVALID_SECTIONS = "REG_PROP_INVALID_SECTIONS";
export const FORM_REG_PROP_SIMPLE_SECTION = "FORM_REG_PROP_SIMPLE_SECTION";
export const FORM_REG_PROP_LIST_SECTION = "FORM_REG_PROP_LIST_SECTION";
export const FORM_REG_PROP_ITERATIVE_SECTION = "FORM_REG_PROP_ITERATIVE_SECTION";
export const FORM_REG_PROP_END_SECTION = "FORM_REG_PROP_END_SECTION";
export const FORM_REG_PROP_IMAGE = "FORM_REG_PROP_IMAGE";
export const FORM_REG_PROP_FILE = "FORM_REG_PROP_FILE";
export const FORM_REG_PROP_SIGNATURE = "FORM_REG_PROP_SIGNATURE";
export const FORM_REG_PROP_USER_SIGNATURE = "FORM_REG_PROP_USER_SIGNATURE";
export const FORM_REG_PROP_MULTI_SELECT = "FORM_REG_PROP_MULTI_SELECT";
export const FORM_REG_PROP_DURATION = "FORM_REG_PROP_DURATION";
export const STATE_SAVER_NAVBAR_SHORTCUT = "STATE_SAVER_NAVBAR_SHORTCUT";
export const MISSION_FORM_REFORMULATE = "MISSION_FORM_REFORMULATE";
export const MISSION_FORM_DATA_LANG = "MISSION_FORM_DATA_LANG";
export const EQUIP_TABLE_TOGGLE_SUB_EQUIPMENTS = "EQUIP_TABLE_TOGGLE_SUB_EQUIPMENTS";
export const TICKET_CLOSER_MODAL_TITLE = "TICKET_CLOSER_MODAL_TITLE";
export const TICKET_TABLE_CLOSE_TICKET = "TICKET_TABLE_CLOSE_TICKET";
export const REPORT_MAIL_BODY_SIGNOFF = "REPORT_MAIL_BODY_SIGNOFF";
export const MISSION_REPORT_MAIL_FORM_USER_COPY = "MISSION_REPORT_MAIL_FORM_USER_COPY";
export const GAMME_FORM_MISSING_TRANSLATIONS = "GAMME_FORM_MISSING_TRANSLATIONS";
export const SEND_REPORT_BODY_HEADER = "SEND_REPORT_BODY_HEADER";
export const SEND_REPORT_BODY_FILE = "SEND_REPORT_BODY_FILE";
export const SEND_REPORT_BODY_FILE_TEMPLATE = "SEND_REPORT_BODY_FILE_TEMPLATE";
export const SEND_REPORT_BODY_SIGNING = "SEND_REPORT_BODY_SIGNING";
export const SEND_REPORT_BODY_USER_TEMPLATE = "SEND_REPORT_BODY_USER_TEMPLATE";
export const GRID_BOOL_FILTER_SELECTED = "GRID_BOOL_FILTER_SELECTED";
export const GRID_BOOL_FILTER_NOT_SELECTED = "GRID_BOOL_FILTER_NOT_SELECTED";
export const EPRA_SURFACES_MISSING_BODY = "EPRA_SURFACES_MISSING_BODY";
export const EPRA_SURFACES_MISSING_TITLE = "EPRA_SURFACES_MISSING_TITLE";
export const EPRA_SURFACE_MISSING_FIX_NOW = "EPRA_SURFACE_MISSING_FIX_NOW";
export const EPRA_SURFACE_MISSING_FIX_LATER = "EPRA_SURFACE_MISSING_FIX_LATER";

export const ADD = "ADD";
export const ZIP = "ZIP";
export const DATE = "DATE";
export const MAIL = "MAIL";
export const NAME = "NAME";
export const NEXT = "NEXT";
export const SAVE = "SAVE";
export const TOWN = "TOWN";
export const TYPE = "TYPE";
export const YEAR = "YEAR";
export const BRAND = "BRAND";
export const FLOOR = "FLOOR";
export const FORMS = "FORMS";
export const GAMME = "GAMME";
export const LOCAL = "LOCAL";
export const LOGIN = "LOGIN";
export const MC_OK = "MC_OK";
export const OTHER = "OTHER";
export const PHONE = "PHONE";
export const PRICE = "PRICE";
export const SKILL = "SKILL";
export const STATE = "STATE";
export const ACTION = "ACTION";
export const DELETE = "DELETE";
export const EXPORT = "EXPORT";
export const GP_GAS = "GP_GAS";
export const IGNORE = "IGNORE";
export const IMPORT = "IMPORT";
export const LOGOUT = "LOGOUT";
export const M_A_HS = "M_A_HS";
export const NUMBER = "NUMBER";
export const QO_REM = "QO_REM";
export const REMOVE = "REMOVE";
export const SIGNIN = "SIGNIN";
export const STREET = "STREET";
export const T_OPEN = "T_OPEN";
export const TICKET = "TICKET";
export const UF_TAB = "UF_TAB";
export const UPDATE = "UPDATE";
export const APS_ROI = "APS_ROI";
export const ARG_MOY = "ARG_MOY";
export const ARG_VAL = "ARG_VAL";
export const COMPANY = "COMPANY";
export const CONFIRM = "CONFIRM";
export const COUNTRY = "COUNTRY";
export const DAY_DAY = "DAY_DAY";
export const ER_HOST = "ER_HOST";
export const GP_ELEC = "GP_ELEC";
export const GP_FUEL = "GP_FUEL";
export const GP_THCH = "GP_THCH";
export const GP_THFR = "GP_THFR";
export const LOADING = "LOADING";
export const MC_DONE = "MC_DONE";
export const NO_ITEM = "NO_ITEM";
export const NRJ_CO2 = "NRJ_CO2";
export const PCK_NRJ = "PCK_NRJ";
export const PCK_REG = "PCK_REG";
export const PROFILE = "PROFILE";
export const QO_NOTE = "QO_NOTE";
export const REF_TAB = "REF_TAB";
export const SUBJECT = "SUBJECT";
export const TICKETS = "TICKETS";
export const TREE_GRAPH = "graph";
export const PRED_NEW = "PRED_NEW";
export const BR_B_EXT = "BR_B_EXT";
export const BR_GP_GE = "BR_GP_GE";
export const BR_GP_GP = "BR_GP_GP";
export const BUILDING = "BUILDING";
export const CAN_SAVE = "CAN_SAVE";
export const CAN_UNDO = "CAN_UNDO";
export const CURATIVE = "CURATIVE";
export const DAY_DAYS = "DAY_DAYS";
export const DAY_HOUR = "DAY_HOUR";
export const DIA_LOCK = "DIA_LOCK";
export const DURATION = "DURATION";
export const EM_TITLE = "EM_TITLE";
export const ER_DATES = "ER_DATES";
export const G_ACTION = "G_ACTION";
export const GP_OTHER = "GP_OTHER";
export const GP_WATER = "GP_WATER";
export const LASTNAME = "LASTNAME";
export const NOTES_BY = "NOTES_BY";
export const OBST_PIC = "OBST_PIC";
export const OBST_REM = "OBST_REM";
export const PASSWORD = "PASSWORD";
export const PCK_MISC = "PCK_MISC";
export const PCK_TECH = "PCK_TECH";
export const PLANNING = "PLANNING";
export const PREVIOUS = "PREVIOUS";
export const REG_LATE = "REG_LATE";
export const RESOURCE = "RESOURCE";
export const SCHEDULE = "SCHEDULE";
export const T_CLOSED = "T_CLOSED";
export const TAB_TREE = "TAB_TREE";
export const TAB_USER = "TAB_USER";
export const TIMELIFE = "TIMELIFE";
export const VD_DEBIT = "VD_DEBIT";
export const VETUCITY = "VETUCITY";
export const TREE_HIDE = "treeHide";
export const TREE_SORT = "treeSort";
export const NRJ_WASTE = "NRJ_WASTE";
export const COMPASS_N = "COMPASS_N";
export const COMPASS_E = "COMPASS_E";
export const COMPASS_S = "COMPASS_S";
export const COMPASS_W = "COMPASS_W";
export const ADD_ALARM = "ADD_ALARM";
export const BR_B_AREA = "BR_B_AREA";
export const CAN_CLEAR = "CAN_CLEAR";
export const CREAT_NEW = "CREAT_NEW";
export const CRITICITY = "CRITICITY";
export const CRUMB_REG = "CRUMB_REG";
export const DATE_HOUR = "DATE_HOUR";
export const DAY_HOURS = "DAY_HOURS";
export const DIA_FOCUS = "DIA_FOCUS";
export const EVENT_END = "EVENT_END";
export const FIRSTNAME = "FIRSTNAME";
export const FREQUANCE = "FREQUANCE";
export const GLOBAL_BY = "GLOBAL_BY";
export const GLOBAL_NO = "GLOBAL_NO";
export const GLOBAL_TO = "GLOBAL_TO";
export const LT_SELECT = "LT_SELECT";
export const NO_TICKET = "NO_TICKET";
export const NOT_FOUND = "NOT_FOUND";
export const NRJ_MONEY = "NRJ_MONEY";
export const OPERATION = "OPERATION";
export const REFERENCE = "REFERENCE";
export const TAB_ADMIN = "TAB_ADMIN";
export const UF_RIGHTS = "UF_RIGHTS";
export const UNIT_YEAR = "UNIT_YEAR";
export const VD_CARACT = "VD_CARACT";
export const DIA_UNLOCK = "DIA_UNLOCK"
export const COMPASS_NE = "COMPASS_NE";
export const COMPASS_SE = "COMPASS_SE";
export const COMPASS_SW = "COMPASS_SW";
export const COMPASS_NW = "COMPASS_NW";
export const MAPPER_ROW = "MAPPER_ROW";
export const ACTION_REG = "ACTION_REG";
export const ADD_TICKET = "ADD_TICKET";
export const AFFECT_LAB = "AFFECT_LAB";
export const ALARM_TYPE = "ALARM_TYPE";
export const ANNOT_NAME = "ANNOT_NAME";
export const APS_BUDGET = "APS_BUDGET";
export const APS_IMPACT = "APS_IMPACT";
export const BR_B_PULSE = "BR_B_PULSE";
export const CAN_RELOAD = "CAN_RELOAD";
export const CPT_ALARMS = "CPT_ALARMS";
export const CREAT_USER = "CREAT_USER";
export const DAY_FRIDAY = "DAY_FRIDAY";
export const DAY_MINUTE = "DAY_MINUTE";
export const DAY_MONDAY = "DAY_MONDAY";
export const DAY_SUNDAY = "DAY_SUNDAY";
export const EQUIPMENTS = "EQUIPMENTS";
export const EVENT_NAME = "EVENT_NAME";
export const FORM_ERROR = "FORM_ERROR";
export const G_ENSEMBLE = "G_ENSEMBLE";
export const GLOBAL_ALL = "GLOBAL_ALL";
export const GLOBAL_END = "GLOBAL_END";
export const GLOBAL_FAV = "GLOBAL_FAV";
export const GLOBAL_NEW = "GLOBAL_NEW";
export const GLOBAL_YES = "GLOBAL_YES";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const MANAGEMENT = "MANAGEMENT";
export const MATRIX_TAB = "MATRIX_TAB";
export const NAV_SEARCH = "NAV_SEARCH";
export const OBST_CONST = "OBST_CONST";
export const OBST_EQUIP = "OBST_EQUIP";
export const OBST_FLOOR = "OBST_FLOOR";
export const OBST_PLACE = "OBST_PLACE";
export const P_END_DATE = "P_END_DATE";
export const PCK_ACTION = "PCK_ACTION";
export const PREVENTIVE = "PREVENTIVE";
export const QO_NEW_OBS = "QO_NEW_OBS";
export const SM_ADD_FAV = "SM_ADD_FAV";
export const SM_LOOK_IN = "SM_LOOK_IN";
export const TAB_CONFIG = "TAB_CONFIG";
export const TAB_WIZARD = "TAB_WIZARD";
export const TECHNICIEN = "TECHNICIEN";
export const VAL_NOT_ID = "VAL_NOT_ID";
export const VD_ADD_ROW = "VD_ADD_ROW";
export const VD_PULSION = "VD_PULSION";
export const TREE_EXPORT = "treeExport";
export const TREE_REVEAL = "treeReveal";
export const BAILS_TITLE = "BAILS_TITLE";
export const PCK_MISSION = "PCK_MISSION";
export const PRED_REPORT = "PRED_REPORT";
export const GLOBAL_TAGS = "GLOBAL_TAGS";
export const ADMIN_BOARD = "ADMIN_BOARD";
export const AFFECT_FOOD = "AFFECT_FOOD";
export const ALARM_NOTES = "ALARM_NOTES";
export const ALARM_TITLE = "ALARM_TITLE";
export const ALERT_ALARM = "ALERT_ALARM";
export const ALERT_VALUE = "ALERT_VALUE";
export const APS_IS_FOOD = "APS_IS_FOOD";
export const ARG_ET_PLUS = "ARG_ET_PLUS";
export const BR_FLOOR_NB = "BR_FLOOR_NB";
export const BRAND_MODEL = "BRAND_MODEL";
export const CFS_NO_DATA = "CFS_NO_DATA";
export const CREAT_GAMME = "CREAT_GAMME";
export const DATASET_SRC = "DATASET_SRC";
export const DATASET_TAG = "DATASET_TAG";
export const DAY_MINUTES = "DAY_MINUTES";
export const DAY_TUESDAY = "DAY_TUESDAY";
export const DESCRIPTION = "DESCRIPTION";
export const DIA_NO_DATA = "DIA_NO_DATA";
export const ER_PICTURES = "ER_PICTURES";
export const EVENT_CLOSE = "EVENT_CLOSE";
export const EVENT_START = "EVENT_START";
export const FORM_KICKED = "FORM_KICKED";
export const FORM_LOCKED = "FORM_LOCKED";
export const G_FREQUENCY = "G_FREQUENCY";
export const GLOBAL_DATA = "GLOBAL_DATA";
export const GLOBAL_EDIT = "GLOBAL_EDIT";
export const GLOBAL_FROM = "GLOBAL_FROM";
export const GLOBAL_HIDE = "GLOBAL_HIDE";
export const GLOBAL_HOME = "GLOBAL_HOME";
export const GLOBAL_LOAD = "GLOBAL_LOAD";
export const GLOBAL_NAME = "GLOBAL_NAME";
export const GLOBAL_NONE = "GLOBAL_NONE";
export const GLOBAL_QUIT = "GLOBAL_QUIT";
export const GLOBAL_SAVE = "GLOBAL_SAVE";
export const GLOBAL_SEND = "GLOBAL_SEND";
export const GLOBAL_SHOW = "GLOBAL_SHOW";
export const GLOBAL_TYPE = "GLOBAL_TYPE";
export const GLOBAL_WEEK = "GLOBAL_WEEK";
export const GLOBAL_ZONE = "GLOBAL_ZONE";
export const GP_ELEC_PPV = "GP_ELEC_PPV";
export const LT_NO_NODES = "LT_NO_NODES";
export const MAINTENANCE = "MAINTENANCE";
export const MC_OUTDATED = "MC_OUTDATED";
export const NEW_REM_DEF = "NEW_REM_DEF";
export const OBST_ACTION = "OBST_ACTION";
export const P_EMERGENCY = "P_EMERGENCY";
export const QO_EDIT_OBS = "QO_EDIT_OBS";
export const QRE_NEW_VAL = "QRE_NEW_VAL";
export const QRE_OLD_VAL = "QRE_OLD_VAL";
export const REG_MISSING = "REG_MISSING";
export const REG_SUCCESS = "REG_SUCCESS";
export const REM_CAT_REG = "REM_CAT_REG";
export const REM_COST_GT = "REM_COST_GT";
export const SCHEDULE_TO = "SCHEDULE_TO";
export const TAB_GENERAL = "TAB_GENERAL";
export const TECHNICIENS = "TECHNICIENS";
export const TICKET_LATE = "TICKET_LATE";
export const TICKET_OPEN = "TICKET_OPEN";
export const TREE_STRUCT = "TREE_STRUCT";
export const TS_GET_TAGS = "TS_GET_TAGS";
export const VD_COPY_ROW = "VD_COPY_ROW";
export const VD_EDIT_ROW = "VD_EDIT_ROW";
export const EPRA_PERIODS = "EPRA_PERIODS";
export const DATA_CORR_OG = "DATA_CORR_OG";
export const DATASET_TAGS = "DATASET_TAGS";
export const NAV_TAG_SITE = "NAV_TAG_SITE";
export const TOTAL_NO_VAT = "TOTAL_NO_VAT";
export const DATASET_FLOW = "DATASET_FLOW";
export const MATERIALS_PE = "MATERIALS_PE";
export const ACCESS_CRUMB = "ACCESS_CRUMB";
export const ACCESS_USERS = "ACCESS_USERS";
export const AFFECT_HOTEL = "AFFECT_HOTEL";
export const AFFECT_OTHER = "AFFECT_OTHER";
export const AFFECT_SPORT = "AFFECT_SPORT";
export const AG_DUPLICATE = "AG_DUPLICATE";
export const ALARM_ACTIVE = "ALARM_ACTIVE";
export const ALARM_PERIOD = "ALARM_PERIOD";
export const ALARM_STATUS = "ALARM_STATUS";
export const ALARM_T_FUNC = "ALARM_T_FUNC";
export const ANNOT_ORIGIN = "ANNOT_ORIGIN";
export const ANNOT_SAVING = "ANNOT_SAVING";
export const ANNOT_SUBMIT = "ANNOT_SUBMIT";
export const APS_BAIL_END = "APS_BAIL_END";
export const ARG_ET_MINUS = "ARG_ET_MINUS";
export const BAIL_MANAGER = "BAIL_MANAGER";
export const BR_GP_ADD_GE = "BR_GP_ADD_GE";
export const BR_GP_ADD_GP = "BR_GP_ADD_GP";
export const BUILD_LEVELS = "BUILD_LEVELS";
export const CFQ_QUOT_MAX = "CFQ_QUOT_MAX";
export const CFQ_QUOT_MIN = "CFQ_QUOT_MIN";
export const CREAT_TICKET = "CREAT_TICKET";
export const DATASET_DATA = "DATASET_DATA";
export const DATASET_FORM = "DATASET_FORM";
export const DATASET_NAME = "DATASET_NAME";
export const DATASET_PATH = "DATASET_PATH";
export const DATASET_TYPE = "DATASET_TYPE";
export const DATASET_UNIT = "DATASET_UNIT";
export const DATE_TOO_BIG = "DATE_TOO_BIG";
export const DAY_SATURDAY = "DAY_SATURDAY";
export const DAY_THURSDAY = "DAY_THURSDAY";
export const DIA_TIP_MENU = "DIA_TIP_MENU";
export const EMP_END_RENT = "EMP_END_RENT";
export const ER_CLOSE_ALL = "ER_CLOSE_ALL";
export const ER_CLOSED_BY = "ER_CLOSED_BY";
export const ER_SIGNATURE = "ER_SIGNATURE";
export const ERR_404_DESC = "ERR_404_DESC";
export const ERR_MOVE_LOC = "ERR_MOVE_LOC";
export const FORM_NUM_MAX = "FORM_NUM_MAX";
export const FORM_NUM_MIN = "FORM_NUM_MIN";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const G_ACTION_REG = "G_ACTION_REG";
export const G_CREATE_NEW = "G_CREATE_NEW";
export const G_FILE_LABEL = "G_FILE_LABEL";
export const G_PRICE_YEAR = "G_PRICE_YEAR";
export const GLOBAL_AFTER = "GLOBAL_AFTER";
export const GLOBAL_COLOR = "GLOBAL_COLOR";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const GLOBAL_EVENT = "GLOBAL_EVENT";
export const GLOBAL_FLOOR = "GLOBAL_FLOOR";
export const GLOBAL_LOCAL = "GLOBAL_LOCAL";
export const GLOBAL_MONTH = "GLOBAL_MONTH";
export const GLOBAL_NOTES = "GLOBAL_NOTES";
export const GLOBAL_OWNER = "GLOBAL_OWNER";
export const GLOBAL_REPLY = "GLOBAL_REPLY";
export const GLOBAL_RESET = "GLOBAL_RESET";
export const GLOBAL_ROLES = "GLOBAL_ROLES";
export const GLOBAL_SAVED = "GLOBAL_SAVED";
export const GLOBAL_START = "GLOBAL_START";
export const GLOBAL_TITLE = "GLOBAL_TITLE";
export const GLOBAL_TODAY = "GLOBAL_TODAY";
export const GLOBAL_TOTAL = "GLOBAL_TOTAL";
export const GLOBAL_VALUE = "GLOBAL_VALUE";
export const GP_GROUP_DAY = "GP_GROUP_DAY";
export const GP_GROUP_MIN = "GP_GROUP_MIN";
export const GP_PRICE_GAS = "GP_PRICE_GAS";
export const GT_SIGNED_BY = "GT_SIGNED_BY";
export const GU_LOAD_TYPE = "GU_LOAD_TYPE";
export const GU_SUPERIORS = "GU_SUPERIORS";
export const LANGUAGE_TAB = "LANGUAGE_TAB";
export const MATRIX_EQUAL = "MATRIX_EQUAL";
export const MC_COUNTDOWN = "MC_COUNTDOWN";
export const MISSION_LANG = "MISSION_LANG";
export const MISSION_TYPE = "MISSION_TYPE";
export const NAV_MISSIONS = "NAV_MISSIONS";
export const NRJ_HEAD_GAS = "NRJ_HEAD_GAS";
export const NRJ_NB_START = "NRJ_NB_START";
export const NRJ_PRESSURE = "NRJ_PRESSURE";
export const P_ADD_TICKET = "P_ADD_TICKET";
export const P_CLICK_SHOW = "P_CLICK_SHOW";
export const P_START_DATE = "P_START_DATE";
export const PROFILE_EDIT = "PROFILE_EDIT";
export const QO_ANOMALIES = "QO_ANOMALIES";
export const REG_LAST_DOC = "REG_LAST_DOC";
export const REG_PASSWORD = "REG_PASSWORD";
export const REM_COST_OFF = "REM_COST_OFF";
export const T_CREATED_BY = "T_CREATED_BY";
export const T_UNASSIGNED = "T_UNASSIGNED";
export const TAB_ADV_MODE = "TAB_ADV_MODE";
export const TAB_PLANNING = "TAB_PLANNING";
export const TAB_REG_DOCS = "TAB_REG_DOCS";
export const TI_TEL_LABEL = "TI_TEL_LABEL";
export const TREE_COPY_ID = "TREE_COPY_ID";
export const UF_BASE_INFO = "UF_BASE_INFO";
export const UF_HIERARCHY = "UF_HIERARCHY";
export const UF_ROLE_TABS = "UF_ROLE_TABS";
export const UF_TAB_EXTRA = "UF_TAB_EXTRA";
export const USER_GESTION = "USER_GESTION";
export const APS_EMISSIONS = "APS_EMISSIONS"
export const EPRA_FAV_NAME = "EPRA_FAV_NAME";
export const EPRA_FAV_SAVE = "EPRA_FAV_SAVE";
export const ACTIVE_4_PLUS = "ACTIVE_4_PLUS";
export const DATA_CORR_AGG = "DATA_CORR_AGG";
export const EQUIP_COM_LON = "EQUIP_COM_LON";
export const CERT_NB_FILES = "CERT_NB_FILES";
export const MATERIALS_TIN = "MATERIALS_TIN";
export const MATERIALS_SAP = "MATERIALS_SAP";
export const MATERIALS_ABS = "MATERIALS_ABS";
export const MATERIALS_APM = "MATERIALS_APM";
export const MATERIALS_PVC = "MATERIALS_PVC";
export const MATERIALS_AIR = "MATERIALS_AIR";
export const ACCESS_GROUPS = "ACCESS_GROUPS";
export const ADD_EQUIPMENT = "ADD_EQUIPMENT";
export const AFFECT_HEALTH = "AFFECT_HEALTH";
export const AFFECT_OFFICE = "AFFECT_OFFICE";
export const AFFECT_PUBLIC = "AFFECT_PUBLIC";
export const AFFECT_RETAIL = "AFFECT_RETAIL";
export const ALARM_T_GROUP = "ALARM_T_GROUP";
export const ALARM_T_VALUE = "ALARM_T_VALUE";
export const ALERT_TRIGGER = "ALERT_TRIGGER";
export const ANNOT_NO_PLAN = "ANNOT_NO_PLAN";
export const ANNOT_NO_SAVE = "ANNOT_NO_SAVE";
export const APS_CRITERIAS = "APS_CRITERIAS";
export const APS_IS_RETAIL = "APS_IS_RETAIL";
export const APS_PERIMETER = "APS_PERIMETER";
export const AR_NO_RECORDS = "AR_NO_RECORDS";
export const BR_B_NB_EQUIP = "BR_B_NB_EQUIP";
export const BR_ERROR_SITE = "BR_ERROR_SITE";
export const CFQ_OBS_LABEL = "CFQ_OBS_LABEL";
export const DAY_WEDNESDAY = "DAY_WEDNESDAY";
export const DIA_HIDE_SOLO = "DIA_HIDE_SOLO";
export const DIA_NB_COPIES = "DIA_NB_COPIES";
export const DIA_OBJ_TYPES = "DIA_OBJ_TYPES";
export const EMP_PANEL_REG = "EMP_PANEL_REG";
export const ER_CLOSED_THE = "ER_CLOSED_THE";
export const ER_NOTE_FRAME = "ER_NOTE_FRAME";
export const ERR_404_TITLE = "ERR_404_TITLE";
export const G_ERROR_FETCH = "G_ERROR_FETCH";
export const G_EXCEL_SHEET = "G_EXCEL_SHEET";
export const G_FILES_LABEL = "G_FILES_LABEL";
export const G_YEAR_SUFFIX = "G_YEAR_SUFFIX";
export const GLOBAL_BEFORE = "GLOBAL_BEFORE";
export const GLOBAL_CANCEL = "GLOBAL_CANCEL";
export const GLOBAL_COPIED = "GLOBAL_COPIED";
export const GLOBAL_DELETE = "GLOBAL_DELETE";
export const GLOBAL_ENERGY = "GLOBAL_ENERGY";
export const GLOBAL_HEIGHT = "GLOBAL_HEIGHT";
export const GLOBAL_IGNORE = "GLOBAL_IGNORE";
export const GLOBAL_MINUTE = "GLOBAL_MINUTE";
export const GLOBAL_NO_DOC = "GLOBAL_NO_DOC";
export const GLOBAL_RENAME = "GLOBAL_RENAME";
export const GLOBAL_RENTAL = "GLOBAL_RENTAL";
export const GLOBAL_SELECT = "GLOBAL_SELECT";
export const GLOBAL_STATUS = "GLOBAL_STATUS";
export const GLOBAL_SUBMIT = "GLOBAL_SUBMIT";
export const GLOBAL_UN_FAV = "GLOBAL_UN_FAV";
export const GP_GROUP_HOUR = "GP_GROUP_HOUR";
export const GP_GROUP_WEEK = "GP_GROUP_WEEK";
export const GP_GROUP_YEAR = "GP_GROUP_YEAR";
export const GP_PRICE_ELEC = "GP_PRICE_ELEC";
export const IMG_FAIL_LOAD = "IMG_FAIL_LOAD";
export const IMG_NO_IMAGES = "IMG_NO_IMAGES";
export const KPI_API_LABEL = "KPI_API_LABEL";
export const LOGIN_INVALID = "LOGIN_INVALID";
export const LOGIN_REG_NOW = "LOGIN_REG_NOW";
export const MISSION_ASSET = "MISSION_ASSET";
export const MISSION_TASKS = "MISSION_TASKS";
export const MISSION_TITLE = "MISSION_TITLE";
export const MISSION_USERS = "MISSION_USERS";
export const NRJ_HEAD_ELEC = "NRJ_HEAD_ELEC";
export const NRJ_HEAD_FUEL = "NRJ_HEAD_FUEL";
export const NRJ_HEAD_THCH = "NRJ_HEAD_THCH";
export const NRJ_HEAD_THFR = "NRJ_HEAD_THFR";
export const P_HIDE_TICKET = "P_HIDE_TICKET";
export const PANEL_ID_CARD = "PANEL_ID_CARD";
export const REG_AUTO_FILL = "REG_AUTO_FILL";
export const REG_FORM_FILE = "REG_FORM_FILE";
export const REG_FORM_NOTE = "REG_FORM_NOTE";
export const REG_IP_LOCKED = "REG_IP_LOCKED";
export const REG_LEGAL_REF = "REG_LEGAL_REF";
export const REG_MAIL_USED = "REG_MAIL_USED";
export const REG_TABLE_ALL = "REG_TABLE_ALL";
export const REM_CAT_OTHER = "REM_CAT_OTHER";
export const REM_COST_CONS = "REM_COST_CONS";
export const ROLE_FORM_TAB = "ROLE_FORM_TAB";
export const SCHEDULE_FROM = "SCHEDULE_FROM";
export const SUB_EQUIPMENT = "SUB_EQUIPMENT";
export const SUPPORT_FILES = "SUPPORT_FILES";
export const SUPPORT_TITLE = "SUPPORT_TITLE";
export const T_CREATED_THE = "T_CREATED_THE";
export const TAB_CONTRACTS = "TAB_CONTRACTS";
export const TAB_NRJ_TABLE = "TAB_NRJ_TABLE";
export const TAB_REMARQUES = "TAB_REMARQUES";
export const TAB_SITE_FORM = "TAB_SITE_FORM";
export const TI_FIRM_LABEL = "TI_FIRM_LABEL";
export const TI_MAIL_LABEL = "TI_MAIL_LABEL";
export const TI_NAME_LABEL = "TI_NAME_LABEL";
export const TI_ROLE_LABEL = "TI_ROLE_LABEL";
export const TICKET_CLOSED = "TICKET_CLOSED";
export const TS_LAST_2_DAY = "TS_LAST_2_DAY";
export const TS_LAST_5_MIN = "TS_LAST_5_MIN";
export const TS_LAST_7_DAY = "TS_LAST_7_DAY";
export const VD_DELETE_ROW = "VD_DELETE_ROW";
export const VD_EXTRACTION = "VD_EXTRACTION";
export const VD_OCCUPATION = "VD_OCCUPATION";
export const VD_PULSE_TREE = "VD_PULSE_TREE";
export const GLOBAL_UNKNOWN = "GLOBAL_UNKNOWN";
export const EPRA_FAV_USERS = "EPRA_FAV_USERS";
export const AUTO_TRANSLATE = "AUTO_TRANSLATE";
export const EQUIP_COM_LORA = "EQUIP_COM_LORA";
export const CRIT_LEVEL_MID = "CRIT_LEVEL_MID";
export const CRIT_LEVEL_LOW = "CRIT_LEVEL_LOW";
export const NRJ_DASH_PATHS = "NRJ_DASH_PATHS";
export const NRJ_DASH_TOOLS = "NRJ_DASH_TOOLS";
export const CERT_FORM_FILE = "CERT_FORM_FILE";
export const CERT_FORM_DATE = "CERT_FORM_DATE";
export const CERT_EDITED_BY = "CERT_EDITED_BY";
export const TAB_CAPEX_PLAN = "TAB_CAPEX_PLAN";
export const MATERIALS_IRON = "MATERIALS_IRON";
export const MATERIALS_ZINC = "MATERIALS_ZINC";
export const MATERIALS_GOLD = "MATERIALS_GOLD";
export const MATERIALS_LEAD = "MATERIALS_LEAD";
export const MATERIALS_ONYX = "MATERIALS_ONYX";
export const MATERIALS_LIME = "MATERIALS_LIME";
export const MATERIALS_TUFA = "MATERIALS_TUFA";
export const MATERIALS_CLAY = "MATERIALS_CLAY";
export const MATERIALS_SOIL = "MATERIALS_SOIL";
export const MATERIALS_MICA = "MATERIALS_MICA";
export const MATERIALS_SAND = "MATERIALS_SAND";
export const MATERIALS_COIR = "MATERIALS_COIR";
export const MATERIALS_JUTE = "MATERIALS_JUTE";
export const MATERIALS_PULP = "MATERIALS_PULP";
export const MATERIALS_CORK = "MATERIALS_CORK";
export const MATERIALS_WOOL = "MATERIALS_WOOL";
export const MATERIALS_SILK = "MATERIALS_SILK";
export const MATERIALS_ACID = "MATERIALS_ACID";
export const MATERIALS_NEON = "MATERIALS_NEON";
export const GRID_FILTER_OR = "GRID_FILTER_OR";
export const TABLE_NEW_NOTE = "TABLE_NEW_NOTE";
export const ACCESS_NB_SITE = "ACCESS_NB_SITE";
export const ADD_EQUIP_GAME = "ADD_EQUIP_GAME";
export const AFFECT_AIRPORT = "AFFECT_AIRPORT";
export const ALERT_END_DATE = "ALERT_END_DATE";
export const APS_CO2_RATION = "APS_CO2_RATION";
export const APS_NOT_EXISTS = "APS_NOT_EXISTS";
export const APS_PRIORITIES = 'APS_PRIORITIES';
export const APS_PROP_ERROR = "APS_PROP_ERROR";
export const BR_GP_LOCATION = "BR_GP_LOCATION";
export const BUILD_BUY_DATE = "BUILD_BUY_DATE";
export const CFS_MAIN_TITLE = "CFS_MAIN_TITLE";
export const CONTRACT_PRICE = "CONTRACT_PRICE";
export const CPT_ALARM_LIST = "CPT_ALARM_LIST";
export const CREAT_BUILDING = "CREAT_BUILDING";
export const DATASET_ORIGIN = "DATASET_ORIGIN";
export const DIA_LINK_TYPES = "DIA_LINK_TYPES";
export const DIA_REVEAL_ALL = "DIA_REVEAL_ALL";
export const DIA_SORT_TITLE = "DIA_SORT_TITLE";
export const DO_MAINTENANCE = "DO_MAINTENANCE";
export const ER_EQUIP_FRAME = "ER_EQUIP_FRAME";
export const ERR_FAILED_LOC = "ERR_FAILED_LOC";
export const ERR_IMG_UPLOAD = "ERR_IMG_UPLOAD";
export const FAILURE_DELETE = "FAILURE_DELETE";
export const G_DID_YOU_MEAN = "G_DID_YOU_MEAN";
export const GLOBAL_ACTIONS = "GLOBAL_ACTIONS";
export const GLOBAL_ADDRESS = "GLOBAL_ADDRESS";
export const GLOBAL_AGENDAS = "GLOBAL_AGENDAS";
export const GLOBAL_CONFIRM = "GLOBAL_CONFIRM";
export const GLOBAL_ELEMENT = "GLOBAL_ELEMENT";
export const GLOBAL_HISTORY = "GLOBAL_HISTORY";
export const GLOBAL_LOADING = "GLOBAL_LOADING";
export const GLOBAL_NEW_FAV = "GLOBAL_NEW_FAV";
export const GLOBAL_PICTURE = "GLOBAL_PICTURE";
export const GLOBAL_REFRESH = "GLOBAL_REFRESH";
export const GLOBAL_REGIONS = "GLOBAL_REGIONS";
export const GLOBAL_REMARKS = "GLOBAL_REMARKS";
export const GLOBAL_SUBJECT = "GLOBAL_SUBJECT";
export const GLOBAL_SUPPORT = "GLOBAL_SUPPORT";
export const GLOBAL_SURFACE = "GLOBAL_SURFACE";
export const GP_GROUP_MONTH = "GP_GROUP_MONTH";
export const GP_PRICE_WATER = "GP_PRICE_WATER";
export const GT_SIGNED_DATE = "GT_SIGNED_DATE";
export const KPI_SAVE_LABEL = "KPI_SAVE_LABEL";
export const MATRIX_CONTAIN = "MATRIX_CONTAIN";
export const NOTE_NEW_REPLY = "NOTE_NEW_REPLY";
export const NRJ_HEAD_OTHER = "NRJ_HEAD_OTHER";
export const NRJ_HEAD_WATER = "NRJ_HEAD_WATER";
export const NRJ_WORK_HOURS = "NRJ_WORK_HOURS";
export const P_TICKETS_LATE = "P_TICKETS_LATE";
export const P_UNDO_FILTERS = "P_UNDO_FILTERS";
export const PANEL_PLANNING = "PANEL_PLANNING";
export const PARKING_EXISTS = "PARKING_EXISTS";
export const REG_FORM_NOTES = "REG_FORM_NOTES";
export const REG_MAIL_EXIST = "REG_MAIL_EXIST";
export const REG_MAIL_LABEL = "REG_MAIL_LABEL";
export const REG_NAME_LABEL = "REG_NAME_LABEL";
export const ROLE_FORM_DESC = "ROLE_FORM_DESC";
export const ROLE_FORM_NAME = "ROLE_FORM_NAME";
export const ROLE_FORM_PAGE = "ROLE_FORM_PAGE";
export const SF_NO_PARKINGS = "SF_NO_PARKINGS";
export const SM_DEFAULT_FAV = "SM_DEFAULT_FAV";
export const SUCCESS_DELETE = "SUCCESS_DELETE";
export const SUPPORT_IS_BUG = "SUPPORT_IS_BUG";
export const TAB_REG_WIZARD = "TAB_REG_WIZARD";
export const TS_LAST_1_HOUR = "TS_LAST_1_HOUR";
export const TS_LAST_1_YEAR = "TS_LAST_1_YEAR";
export const TS_LAST_15_MIN = "TS_LAST_15_MIN";
export const TS_LAST_2_YEAR = "TS_LAST_2_YEAR";
export const TS_LAST_3_HOUR = "TS_LAST_3_HOUR";
export const TS_LAST_30_DAY = "TS_LAST_30_DAY";
export const TS_LAST_30_MIN = "TS_LAST_30_MIN";
export const TS_LAST_5_YEAR = "TS_LAST_5_YEAR";
export const TS_LAST_6_HOUR = "TS_LAST_6_HOUR";
export const TS_LAST_90_DAY = "TS_LAST_90_DAY";
export const TS_MENU_BUTTON = "TS_MENU_BUTTON";
export const VAL_NOT_UNIQUE = "VAL_NOT_UNIQUE";
export const VD_AIR_QUALITY = "VD_AIR_QUALITY";
export const VD_STRUCT_TREE = "VD_STRUCT_TREE";
export const VD_SURFACE_NET = "VD_SURFACE_NET";
export const GAMME_FORM_NAME = "GAMME_FORM_NAME";
export const EPRA_FAV_DELETE = "EPRA_FAV_DELETE";
export const OPTION_CODE_REI = "OPTION_CODE_REI";
export const EPRA_FAV_CANCEL = "EPRA_FAV_CANCEL";
export const EPRA_FAV_SELECT = "EPRA_FAV_SELECT";
export const EPRA_FAV_PUBLIC = "EPRA_FAV_PUBLIC";
export const DATASET_COUNTRY = "DATASET_COUNTRY";
export const EPRA_CODE_LABEL = "EPRA_CODE_LABEL";
export const EPRA_PERIOD_END = "EPRA_PERIOD_END";
export const CERT_OPEN_FILES = "CERT_OPEN_FILES";
export const DATA_CORR_TITLE = "DATA_CORR_TITLE";
export const EQUIP_COM_M_BUS = "EQUIP_COM_M_BUS";
export const EQUIP_COM_RS232 = "EQUIP_COM_RS232";
export const EQUIP_COM_J_BUS = "EQUIP_COM_J_BUS";
export const CRIT_LEVEL_HIGH = "CRIT_LEVEL_HIGH";
export const CRIT_LEVEL_NONE = "CRIT_LEVEL_NONE";
export const ACTIVE_EDL_TECH = "ACTIVE_EDL_TECH";
export const ACTIVE_EDL_ARCH = "ACTIVE_EDL_ARCH";
export const TAB_MISSION_LOG = "TAB_MISSION_LOG";
export const GLOBAL_COMMENTS = "GLOBAL_COMMENTS";
export const CERT_FORM_VALUE = "CERT_FORM_VALUE";
export const CERT_EDITED_THE = "CERT_EDITED_THE";
export const CERT_EDITED_FOR = "CERT_EDITED_FOR";
export const TICKET_TYPE_REG = "TICKET_TYPE_REG";
export const MATERIALS_BORON = "MATERIALS_BORON";
export const MATERIALS_ARGON = "MATERIALS_ARGON";
export const MATERIALS_XENON = "MATERIALS_XENON";
export const MATERIALS_CHALK = "MATERIALS_CHALK";
export const MATERIALS_SHALE = "MATERIALS_SHALE";
export const MATERIALS_SLATE = "MATERIALS_SLATE";
export const MATERIALS_BRICK = "MATERIALS_BRICK";
export const MATERIALS_GLASS = "MATERIALS_GLASS";
export const MATERIALS_BRASS = "MATERIALS_BRASS";
export const MATERIALS_ZAMAK = "MATERIALS_ZAMAK";
export const MATERIALS_MAZAC = "MATERIALS_MAZAC";
export const MATERIALS_TERNE = "MATERIALS_TERNE";
export const MATERIALS_LINEN = "MATERIALS_LINEN";
export const MATERIALS_REEDS = "MATERIALS_REEDS";
export const MATERIALS_STRAW = "MATERIALS_STRAW";
export const MATERIALS_PAPER = "MATERIALS_PAPER";
export const MATERIALS_GRASS = "MATERIALS_GRASS";
export const MATERIALS_MAPLE = "MATERIALS_MAPLE";
export const MATERIALS_ACIDS = "MATERIALS_ACIDS";
export const MATERIALS_BASES = "MATERIALS_BASES";
export const MATERIALS_SALTS = "MATERIALS_SALTS";
export const MATERIALS_EPOXY = "MATERIALS_EPOXY";
export const MATERIALS_PVC_U = "MATERIALS_PVC_U";
export const MATERIALS_VINYL = "MATERIALS_VINYL";
export const MATERIALS_WAXES = "MATERIALS_WAXES";
export const MATERIALS_GASES = "MATERIALS_GASES";
export const REMOVE_FILE_MSG = "REMOVE_FILE_MSG";
export const COUNTRY_CODE_AF = "COUNTRY_CODE_AF";
export const COUNTRY_CODE_AX = "COUNTRY_CODE_AX";
export const COUNTRY_CODE_AL = "COUNTRY_CODE_AL";
export const COUNTRY_CODE_DZ = "COUNTRY_CODE_DZ";
export const COUNTRY_CODE_AS = "COUNTRY_CODE_AS";
export const COUNTRY_CODE_AD = "COUNTRY_CODE_AD";
export const COUNTRY_CODE_AO = "COUNTRY_CODE_AO";
export const COUNTRY_CODE_AI = "COUNTRY_CODE_AI";
export const COUNTRY_CODE_AQ = "COUNTRY_CODE_AQ";
export const COUNTRY_CODE_AG = "COUNTRY_CODE_AG";
export const COUNTRY_CODE_AR = "COUNTRY_CODE_AR";
export const COUNTRY_CODE_AM = "COUNTRY_CODE_AM";
export const COUNTRY_CODE_AW = "COUNTRY_CODE_AW";
export const COUNTRY_CODE_AU = "COUNTRY_CODE_AU";
export const COUNTRY_CODE_AT = "COUNTRY_CODE_AT";
export const COUNTRY_CODE_AZ = "COUNTRY_CODE_AZ";
export const COUNTRY_CODE_BS = "COUNTRY_CODE_BS";
export const COUNTRY_CODE_BH = "COUNTRY_CODE_BH";
export const COUNTRY_CODE_BD = "COUNTRY_CODE_BD";
export const COUNTRY_CODE_BB = "COUNTRY_CODE_BB";
export const COUNTRY_CODE_BY = "COUNTRY_CODE_BY";
export const COUNTRY_CODE_BE = "COUNTRY_CODE_BE";
export const COUNTRY_CODE_BZ = "COUNTRY_CODE_BZ";
export const COUNTRY_CODE_BJ = "COUNTRY_CODE_BJ";
export const COUNTRY_CODE_BM = "COUNTRY_CODE_BM";
export const COUNTRY_CODE_BT = "COUNTRY_CODE_BT";
export const COUNTRY_CODE_BO = "COUNTRY_CODE_BO";
export const COUNTRY_CODE_BQ = "COUNTRY_CODE_BQ";
export const COUNTRY_CODE_BA = "COUNTRY_CODE_BA";
export const COUNTRY_CODE_BW = "COUNTRY_CODE_BW";
export const COUNTRY_CODE_BV = "COUNTRY_CODE_BV";
export const COUNTRY_CODE_BR = "COUNTRY_CODE_BR";
export const COUNTRY_CODE_IO = "COUNTRY_CODE_IO";
export const COUNTRY_CODE_BN = "COUNTRY_CODE_BN";
export const COUNTRY_CODE_BG = "COUNTRY_CODE_BG";
export const COUNTRY_CODE_BF = "COUNTRY_CODE_BF";
export const COUNTRY_CODE_BI = "COUNTRY_CODE_BI";
export const COUNTRY_CODE_KH = "COUNTRY_CODE_KH";
export const COUNTRY_CODE_CM = "COUNTRY_CODE_CM";
export const COUNTRY_CODE_CA = "COUNTRY_CODE_CA";
export const COUNTRY_CODE_CV = "COUNTRY_CODE_CV";
export const COUNTRY_CODE_KY = "COUNTRY_CODE_KY";
export const COUNTRY_CODE_CF = "COUNTRY_CODE_CF";
export const COUNTRY_CODE_TD = "COUNTRY_CODE_TD";
export const COUNTRY_CODE_CL = "COUNTRY_CODE_CL";
export const COUNTRY_CODE_CN = "COUNTRY_CODE_CN";
export const COUNTRY_CODE_CX = "COUNTRY_CODE_CX";
export const COUNTRY_CODE_CC = "COUNTRY_CODE_CC";
export const COUNTRY_CODE_CO = "COUNTRY_CODE_CO";
export const COUNTRY_CODE_KM = "COUNTRY_CODE_KM";
export const COUNTRY_CODE_CG = "COUNTRY_CODE_CG";
export const COUNTRY_CODE_CD = "COUNTRY_CODE_CD";
export const COUNTRY_CODE_CK = "COUNTRY_CODE_CK";
export const COUNTRY_CODE_CR = "COUNTRY_CODE_CR";
export const COUNTRY_CODE_CI = "COUNTRY_CODE_CI";
export const COUNTRY_CODE_HR = "COUNTRY_CODE_HR";
export const COUNTRY_CODE_CU = "COUNTRY_CODE_CU";
export const COUNTRY_CODE_CW = "COUNTRY_CODE_CW";
export const COUNTRY_CODE_CY = "COUNTRY_CODE_CY";
export const COUNTRY_CODE_CZ = "COUNTRY_CODE_CZ";
export const COUNTRY_CODE_DK = "COUNTRY_CODE_DK";
export const COUNTRY_CODE_DJ = "COUNTRY_CODE_DJ";
export const COUNTRY_CODE_DM = "COUNTRY_CODE_DM";
export const COUNTRY_CODE_DO = "COUNTRY_CODE_DO";
export const COUNTRY_CODE_EC = "COUNTRY_CODE_EC";
export const COUNTRY_CODE_EG = "COUNTRY_CODE_EG";
export const COUNTRY_CODE_SV = "COUNTRY_CODE_SV";
export const COUNTRY_CODE_GQ = "COUNTRY_CODE_GQ";
export const COUNTRY_CODE_ER = "COUNTRY_CODE_ER";
export const COUNTRY_CODE_EE = "COUNTRY_CODE_EE";
export const COUNTRY_CODE_ET = "COUNTRY_CODE_ET";
export const COUNTRY_CODE_FK = "COUNTRY_CODE_FK";
export const COUNTRY_CODE_FO = "COUNTRY_CODE_FO";
export const COUNTRY_CODE_FJ = "COUNTRY_CODE_FJ";
export const COUNTRY_CODE_FI = "COUNTRY_CODE_FI";
export const COUNTRY_CODE_FR = "COUNTRY_CODE_FR";
export const COUNTRY_CODE_GF = "COUNTRY_CODE_GF";
export const COUNTRY_CODE_PF = "COUNTRY_CODE_PF";
export const COUNTRY_CODE_TF = "COUNTRY_CODE_TF";
export const COUNTRY_CODE_GA = "COUNTRY_CODE_GA";
export const COUNTRY_CODE_GM = "COUNTRY_CODE_GM";
export const COUNTRY_CODE_GE = "COUNTRY_CODE_GE";
export const COUNTRY_CODE_DE = "COUNTRY_CODE_DE";
export const COUNTRY_CODE_GH = "COUNTRY_CODE_GH";
export const COUNTRY_CODE_GI = "COUNTRY_CODE_GI";
export const COUNTRY_CODE_GR = "COUNTRY_CODE_GR";
export const COUNTRY_CODE_GL = "COUNTRY_CODE_GL";
export const COUNTRY_CODE_GD = "COUNTRY_CODE_GD";
export const COUNTRY_CODE_GP = "COUNTRY_CODE_GP";
export const COUNTRY_CODE_GU = "COUNTRY_CODE_GU";
export const COUNTRY_CODE_GT = "COUNTRY_CODE_GT";
export const COUNTRY_CODE_GG = "COUNTRY_CODE_GG";
export const COUNTRY_CODE_GN = "COUNTRY_CODE_GN";
export const COUNTRY_CODE_GW = "COUNTRY_CODE_GW";
export const COUNTRY_CODE_GY = "COUNTRY_CODE_GY";
export const COUNTRY_CODE_HT = "COUNTRY_CODE_HT";
export const COUNTRY_CODE_HM = "COUNTRY_CODE_HM";
export const COUNTRY_CODE_VA = "COUNTRY_CODE_VA";
export const COUNTRY_CODE_HN = "COUNTRY_CODE_HN";
export const COUNTRY_CODE_HK = "COUNTRY_CODE_HK";
export const COUNTRY_CODE_HU = "COUNTRY_CODE_HU";
export const COUNTRY_CODE_IS = "COUNTRY_CODE_IS";
export const COUNTRY_CODE_IN = "COUNTRY_CODE_IN";
export const COUNTRY_CODE_ID = "COUNTRY_CODE_ID";
export const COUNTRY_CODE_IR = "COUNTRY_CODE_IR";
export const COUNTRY_CODE_IQ = "COUNTRY_CODE_IQ";
export const COUNTRY_CODE_IE = "COUNTRY_CODE_IE";
export const COUNTRY_CODE_IM = "COUNTRY_CODE_IM";
export const COUNTRY_CODE_IL = "COUNTRY_CODE_IL";
export const COUNTRY_CODE_IT = "COUNTRY_CODE_IT";
export const COUNTRY_CODE_JM = "COUNTRY_CODE_JM";
export const COUNTRY_CODE_JP = "COUNTRY_CODE_JP";
export const COUNTRY_CODE_JE = "COUNTRY_CODE_JE";
export const COUNTRY_CODE_JO = "COUNTRY_CODE_JO";
export const COUNTRY_CODE_KZ = "COUNTRY_CODE_KZ";
export const COUNTRY_CODE_KE = "COUNTRY_CODE_KE";
export const COUNTRY_CODE_KI = "COUNTRY_CODE_KI";
export const COUNTRY_CODE_KP = "COUNTRY_CODE_KP";
export const COUNTRY_CODE_KR = "COUNTRY_CODE_KR";
export const COUNTRY_CODE_KW = "COUNTRY_CODE_KW";
export const COUNTRY_CODE_KG = "COUNTRY_CODE_KG";
export const COUNTRY_CODE_LA = "COUNTRY_CODE_LA";
export const COUNTRY_CODE_LV = "COUNTRY_CODE_LV";
export const COUNTRY_CODE_LB = "COUNTRY_CODE_LB";
export const COUNTRY_CODE_LS = "COUNTRY_CODE_LS";
export const COUNTRY_CODE_LR = "COUNTRY_CODE_LR";
export const COUNTRY_CODE_LY = "COUNTRY_CODE_LY";
export const COUNTRY_CODE_LI = "COUNTRY_CODE_LI";
export const COUNTRY_CODE_LT = "COUNTRY_CODE_LT";
export const COUNTRY_CODE_LU = "COUNTRY_CODE_LU";
export const COUNTRY_CODE_MO = "COUNTRY_CODE_MO";
export const COUNTRY_CODE_MK = "COUNTRY_CODE_MK";
export const COUNTRY_CODE_MG = "COUNTRY_CODE_MG";
export const COUNTRY_CODE_MW = "COUNTRY_CODE_MW";
export const COUNTRY_CODE_MY = "COUNTRY_CODE_MY";
export const COUNTRY_CODE_MV = "COUNTRY_CODE_MV";
export const COUNTRY_CODE_ML = "COUNTRY_CODE_ML";
export const COUNTRY_CODE_MT = "COUNTRY_CODE_MT";
export const COUNTRY_CODE_MH = "COUNTRY_CODE_MH";
export const COUNTRY_CODE_MQ = "COUNTRY_CODE_MQ";
export const COUNTRY_CODE_MR = "COUNTRY_CODE_MR";
export const COUNTRY_CODE_MU = "COUNTRY_CODE_MU";
export const COUNTRY_CODE_YT = "COUNTRY_CODE_YT";
export const COUNTRY_CODE_MX = "COUNTRY_CODE_MX";
export const COUNTRY_CODE_FM = "COUNTRY_CODE_FM";
export const COUNTRY_CODE_MD = "COUNTRY_CODE_MD";
export const COUNTRY_CODE_MC = "COUNTRY_CODE_MC";
export const COUNTRY_CODE_MN = "COUNTRY_CODE_MN";
export const COUNTRY_CODE_ME = "COUNTRY_CODE_ME";
export const COUNTRY_CODE_MS = "COUNTRY_CODE_MS";
export const COUNTRY_CODE_MA = "COUNTRY_CODE_MA";
export const COUNTRY_CODE_MZ = "COUNTRY_CODE_MZ";
export const COUNTRY_CODE_MM = "COUNTRY_CODE_MM";
export const COUNTRY_CODE_NA = "COUNTRY_CODE_NA";
export const COUNTRY_CODE_NR = "COUNTRY_CODE_NR";
export const COUNTRY_CODE_NP = "COUNTRY_CODE_NP";
export const COUNTRY_CODE_NL = "COUNTRY_CODE_NL";
export const COUNTRY_CODE_NC = "COUNTRY_CODE_NC";
export const COUNTRY_CODE_NZ = "COUNTRY_CODE_NZ";
export const COUNTRY_CODE_NI = "COUNTRY_CODE_NI";
export const COUNTRY_CODE_NE = "COUNTRY_CODE_NE";
export const COUNTRY_CODE_NG = "COUNTRY_CODE_NG";
export const COUNTRY_CODE_NU = "COUNTRY_CODE_NU";
export const COUNTRY_CODE_NF = "COUNTRY_CODE_NF";
export const COUNTRY_CODE_MP = "COUNTRY_CODE_MP";
export const COUNTRY_CODE_NO = "COUNTRY_CODE_NO";
export const COUNTRY_CODE_OM = "COUNTRY_CODE_OM";
export const COUNTRY_CODE_PK = "COUNTRY_CODE_PK";
export const COUNTRY_CODE_PW = "COUNTRY_CODE_PW";
export const COUNTRY_CODE_PS = "COUNTRY_CODE_PS";
export const COUNTRY_CODE_PA = "COUNTRY_CODE_PA";
export const COUNTRY_CODE_PG = "COUNTRY_CODE_PG";
export const COUNTRY_CODE_PY = "COUNTRY_CODE_PY";
export const COUNTRY_CODE_PE = "COUNTRY_CODE_PE";
export const COUNTRY_CODE_PH = "COUNTRY_CODE_PH";
export const COUNTRY_CODE_PN = "COUNTRY_CODE_PN";
export const COUNTRY_CODE_PL = "COUNTRY_CODE_PL";
export const COUNTRY_CODE_PT = "COUNTRY_CODE_PT";
export const COUNTRY_CODE_PR = "COUNTRY_CODE_PR";
export const COUNTRY_CODE_QA = "COUNTRY_CODE_QA";
export const COUNTRY_CODE_RE = "COUNTRY_CODE_RE";
export const COUNTRY_CODE_RO = "COUNTRY_CODE_RO";
export const COUNTRY_CODE_RU = "COUNTRY_CODE_RU";
export const COUNTRY_CODE_RW = "COUNTRY_CODE_RW";
export const COUNTRY_CODE_BL = "COUNTRY_CODE_BL";
export const COUNTRY_CODE_SH = "COUNTRY_CODE_SH";
export const COUNTRY_CODE_KN = "COUNTRY_CODE_KN";
export const COUNTRY_CODE_LC = "COUNTRY_CODE_LC";
export const COUNTRY_CODE_MF = "COUNTRY_CODE_MF";
export const COUNTRY_CODE_PM = "COUNTRY_CODE_PM";
export const COUNTRY_CODE_VC = "COUNTRY_CODE_VC";
export const COUNTRY_CODE_WS = "COUNTRY_CODE_WS";
export const COUNTRY_CODE_SM = "COUNTRY_CODE_SM";
export const COUNTRY_CODE_ST = "COUNTRY_CODE_ST";
export const COUNTRY_CODE_SA = "COUNTRY_CODE_SA";
export const COUNTRY_CODE_SN = "COUNTRY_CODE_SN";
export const COUNTRY_CODE_RS = "COUNTRY_CODE_RS";
export const COUNTRY_CODE_SC = "COUNTRY_CODE_SC";
export const COUNTRY_CODE_SL = "COUNTRY_CODE_SL";
export const COUNTRY_CODE_SG = "COUNTRY_CODE_SG";
export const COUNTRY_CODE_SX = "COUNTRY_CODE_SX";
export const COUNTRY_CODE_SK = "COUNTRY_CODE_SK";
export const COUNTRY_CODE_SI = "COUNTRY_CODE_SI";
export const COUNTRY_CODE_SB = "COUNTRY_CODE_SB";
export const COUNTRY_CODE_SO = "COUNTRY_CODE_SO";
export const COUNTRY_CODE_ZA = "COUNTRY_CODE_ZA";
export const COUNTRY_CODE_GS = "COUNTRY_CODE_GS";
export const COUNTRY_CODE_SS = "COUNTRY_CODE_SS";
export const COUNTRY_CODE_ES = "COUNTRY_CODE_ES";
export const COUNTRY_CODE_LK = "COUNTRY_CODE_LK";
export const COUNTRY_CODE_SD = "COUNTRY_CODE_SD";
export const COUNTRY_CODE_SR = "COUNTRY_CODE_SR";
export const COUNTRY_CODE_SJ = "COUNTRY_CODE_SJ";
export const COUNTRY_CODE_SZ = "COUNTRY_CODE_SZ";
export const COUNTRY_CODE_SE = "COUNTRY_CODE_SE";
export const COUNTRY_CODE_CH = "COUNTRY_CODE_CH";
export const COUNTRY_CODE_SY = "COUNTRY_CODE_SY";
export const COUNTRY_CODE_TW = "COUNTRY_CODE_TW";
export const COUNTRY_CODE_TJ = "COUNTRY_CODE_TJ";
export const COUNTRY_CODE_TZ = "COUNTRY_CODE_TZ";
export const COUNTRY_CODE_TH = "COUNTRY_CODE_TH";
export const COUNTRY_CODE_TL = "COUNTRY_CODE_TL";
export const COUNTRY_CODE_TG = "COUNTRY_CODE_TG";
export const COUNTRY_CODE_TK = "COUNTRY_CODE_TK";
export const COUNTRY_CODE_TO = "COUNTRY_CODE_TO";
export const COUNTRY_CODE_TT = "COUNTRY_CODE_TT";
export const COUNTRY_CODE_TN = "COUNTRY_CODE_TN";
export const COUNTRY_CODE_TR = "COUNTRY_CODE_TR";
export const COUNTRY_CODE_TM = "COUNTRY_CODE_TM";
export const COUNTRY_CODE_TC = "COUNTRY_CODE_TC";
export const COUNTRY_CODE_TV = "COUNTRY_CODE_TV";
export const COUNTRY_CODE_UG = "COUNTRY_CODE_UG";
export const COUNTRY_CODE_UA = "COUNTRY_CODE_UA";
export const COUNTRY_CODE_AE = "COUNTRY_CODE_AE";
export const COUNTRY_CODE_GB = "COUNTRY_CODE_GB";
export const COUNTRY_CODE_US = "COUNTRY_CODE_US";
export const COUNTRY_CODE_UM = "COUNTRY_CODE_UM";
export const COUNTRY_CODE_UY = "COUNTRY_CODE_UY";
export const COUNTRY_CODE_UZ = "COUNTRY_CODE_UZ";
export const COUNTRY_CODE_VU = "COUNTRY_CODE_VU";
export const COUNTRY_CODE_VE = "COUNTRY_CODE_VE";
export const COUNTRY_CODE_VN = "COUNTRY_CODE_VN";
export const COUNTRY_CODE_VG = "COUNTRY_CODE_VG";
export const COUNTRY_CODE_VI = "COUNTRY_CODE_VI";
export const COUNTRY_CODE_WF = "COUNTRY_CODE_WF";
export const COUNTRY_CODE_EH = "COUNTRY_CODE_EH";
export const COUNTRY_CODE_YE = "COUNTRY_CODE_YE";
export const COUNTRY_CODE_ZM = "COUNTRY_CODE_ZM";
export const COUNTRY_CODE_ZW = "COUNTRY_CODE_ZW";
export const MAIL_NAME_INFOS = "MAIL_NAME_INFOS";
export const REM_LINKED_ELEM = "REM_LINKED_ELEM";
export const GRID_FILTER_AND = "GRID_FILTER_AND";
export const TAB_NOTES_TABLE = "TAB_NOTES_TABLE";
export const PRED_CONFIG_GEN = "PRED_CONFIG_GEN";
export const PRED_CONFIG_SUN = "PRED_CONFIG_SUN";
export const AFFECT_INDUSTRY = "AFFECT_INDUSTRY";
export const AG_QUICK_INSERT = "AG_QUICK_INSERT";
export const ALARM_END_RANGE = "ALARM_END_RANGE";
export const ALARM_GROUP_AGG = "ALARM_GROUP_AGG";
export const ANNOT_FORM_TYPE = "ANNOT_FORM_TYPE";
export const ANNOT_NAME_USED = "ANNOT_NAME_USED";
export const APS_CRUMB_TITLE = 'APS_CRUMB_TITLE';
export const APS_IS_NOT_FOOD = "APS_IS_NOT_FOOD";
export const APS_METEO_FETCH = "APS_METEO_FETCH";
export const APS_YEAR_PLURAL = "APS_YEAR_PLURAL";
export const AR_YOUR_RECORDS = "AR_YOUR_RECORDS";
export const AUTH_LINK_TITLE = "AUTH_LINK_TITLE";
export const BR_B_EXT_BOUCHE = "BR_B_EXT_BOUCHE";
export const BR_B_HOPPER_EXT = "BR_B_HOPPER_EXT";
export const BR_CREATE_BUILD = "BR_CREATE_BUILD";
export const BUILD_EDIT_ICON = "BUILD_EDIT_ICON";
export const BUILD_EDIT_MISC = "BUILD_EDIT_MISC";
export const CFQ_AFFECTATION = "CFQ_AFFECTATION";
export const CONTRACT_NOTICE = "CONTRACT_NOTICE";
export const DATA_ENTRY_NOTE = "DATA_ENTRY_NOTE";
export const DATA_ENTRY_TIME = "DATA_ENTRY_TIME";
export const DATASET_AGG_AVG = "DATASET_AGG_AVG";
export const DATASET_AGG_MAX = "DATASET_AGG_MAX";
export const DATASET_AGG_MIN = "DATASET_AGG_MIN";
export const DATASET_AGG_SUM = "DATASET_AGG_SUM";
export const DATASET_ELEMENT = "DATASET_ELEMENT";
export const DATASET_FLOW_IN = "DATASET_FLOW_IN";
export const DATASET_IS_HEAD = "DATASET_IS_HEAD";
export const DATASET_STATION = "DATASET_STATION";
export const DIA_DELETE_LINK = "DIA_DELETE_LINK";
export const DIA_FILTER_TYPE = "DIA_FILTER_TYPE";
export const DIA_JUST_SEARCH = "DIA_JUST_SEARCH";
export const DIA_NAME_SEARCH = "DIA_NAME_SEARCH";
export const DIA_ORIENTATION = "DIA_ORIENTATION";
export const DIA_SEARCH_PATH = "DIA_SEARCH_PATH";
export const DIA_TOGGLE_DRAG = "DIA_TOGGLE_DRAG";
export const ER_DELETE_EVENT = "ER_DELETE_EVENT";
export const ER_PARTICIPANTS = "ER_PARTICIPANTS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORM_MAIN_LABEL = "FORM_MAIN_LABEL";
export const FORM_MAX_TIME_W = "FORM_MAX_TIME_W";
export const FORM_MAX_X_CHAR = "FORM_MAX_X_CHAR";
export const FORM_MIN_TIME_W = "FORM_MIN_TIME_W";
export const FORM_MIN_X_CHAR = "FORM_MIN_X_CHAR";
export const FORM_REGEX_MISS = "FORM_REGEX_MISS";
export const G_CANCEL_IMPORT = "G_CANCEL_IMPORT";
export const G_ERROR_CONTENT = "G_ERROR_CONTENT";
export const GLOBAL_ADD_NOTE = "GLOBAL_ADD_NOTE";
export const GLOBAL_CATEGORY = "GLOBAL_CATEGORY";
export const GLOBAL_DOCUMENT = "GLOBAL_DOCUMENT";
export const GLOBAL_DOWNLOAD = "GLOBAL_DOWNLOAD";
export const GLOBAL_ELEMENTS = "GLOBAL_ELEMENTS";
export const GLOBAL_EMP_NAME = "GLOBAL_EMP_NAME";
export const GLOBAL_FULL_LOC = "GLOBAL_FULL_LOC";
export const GLOBAL_LATITUDE = "GLOBAL_LATITUDE";
export const GLOBAL_LOCATION = "GLOBAL_LOCATION";
export const GLOBAL_ORDER_BY = "GLOBAL_ORDER_BY";
export const GLOBAL_QUANTITY = "GLOBAL_QUANTITY";
export const GLOBAL_RESTROOM = "GLOBAL_RESTROOM";
export const GLOBAL_SAME_LOC = "GLOBAL_SAME_LOC";
export const GT_CLOSE_TICKET = "GT_CLOSE_TICKET";
export const LT_INVALID_ROOT = "LT_INVALID_ROOT";
export const MATRIX_ADD_PROP = "MATRIX_ADD_PROP";
export const MISSION_GENERAL = "MISSION_GENERAL";
export const NOTIF_CLIPBOARD = "NOTIF_CLIPBOARD";
export const NRJ_TEMPERATURE = "NRJ_TEMPERATURE";
export const P_CLOSE_TICKETS = "P_CLOSE_TICKETS";
export const P_REVEAL_TICKET = "P_REVEAL_TICKET";
export const PANEL_NRJ_TABLE = "PANEL_NRJ_TABLE";
export const PANEL_TREE_MAIN = "PANEL_TREE_MAIN";
export const PARASITE_VALUES = "PARASITE_VALUES";
export const REG_CONFORM_DOC = "REG_CONFORM_DOC";
export const REG_FIX_COLUMNS = "REG_FIX_COLUMNS";
export const REG_FORM_PERIOD = "REG_FORM_PERIOD";
export const REG_MAKE_ACTIVE = "REG_MAKE_ACTIVE";
export const REG_MARK_ALL_AS = "REG_MARK_ALL_AS";
export const REG_TABLE_TITLE = "REG_TABLE_TITLE";
export const REM_CAT_ENV_NRJ = "REM_CAT_ENV_NRJ";
export const REM_DEF_COST_HC = "REM_DEF_COST_HC";
export const REM_STATUS_MEMO = "REM_STATUS_MEMO";
export const REM_STATUS_OPEN = "REM_STATUS_OPEN";
export const REM_X_REMARQUES = "REM_X_REMARQUES";
export const RIGHT_EDIT_SITE = "RIGHT_EDIT_SITE";
export const RIGHT_MOVE_SITE = "RIGHT_MOVE_SITE";
export const ROLE_FORM_RIGHT = "ROLE_FORM_RIGHT";
export const SCHEDULE_FRIDAY = "SCHEDULE_FRIDAY";
export const SCHEDULE_MONDAY = "SCHEDULE_MONDAY";
export const SCHEDULE_SUNDAY = "SCHEDULE_SUNDAY";
export const SELECT_EXISTING = "SELECT_EXISTING";
export const SM_ASK_FAV_NAME = "SM_ASK_FAV_NAME";
export const STORE_EQUIPMENT = "STORE_EQUIPMENT";
export const SUPPORT_CAT_BUG = "SUPPORT_CAT_BUG";
export const SUPPORT_SUCCESS = "SUPPORT_SUCCESS";
export const T_SAVE_AND_NEXT = "T_SAVE_AND_NEXT";
export const TAB_EQUIP_STORE = "TAB_EQUIP_STORE";
export const TAB_EQUIP_TABLE = "TAB_EQUIP_TABLE";
export const TAB_GAMME_EQUIP = "TAB_GAMME_EQUIP";
export const TAB_SITE_WIZARD = "TAB_SITE_WIZARD";
export const TAB_USERS_TABLE = "TAB_USERS_TABLE";
export const TECHNICIAN_RESP = "TECHNICIAN_RESP";
export const TICKET_ASSIGNED = "TICKET_ASSIGNED";
export const TRAD_TAB_IMPORT = "TRAD_TAB_IMPORT";
export const TS_LAST_12_HOUR = "TS_LAST_12_HOUR";
export const TS_LAST_15_DAYS = "TS_LAST_15_DAYS";
export const TS_LAST_24_HOUR = "TS_LAST_24_HOUR";
export const TS_LAST_6_MONTH = "TS_LAST_6_MONTH";
export const UF_DEFAULT_NODE = "UF_DEFAULT_NODE";
export const UF_TECH_PROFILE = "UF_TECH_PROFILE";
export const VAL_IS_REQUIRED = "VAL_IS_REQUIRED";
export const VD_SURFACE_BRUT = "VD_SURFACE_BRUT";
export const ADD_OPTION_TITLE = "ADD_OPTION_TITLE";
export const EPRA_FAV_PRIVATE = "EPRA_FAV_PRIVATE";
export const EPRA_VALUE_LABEL = "EPRA_VALUE_LABEL";
export const NO_CERTIFY_EMPTY = "NO_CERTIFY_EMPTY";
export const BUBBLE_FORM_SIZE = "BUBBLE_FORM_SIZE";
export const TAXO_MISSION_EPC = "TAXO_MISSION_EPC";
export const BUBBLE_FORM_PAGE = "BUBBLE_FORM_PAGE";
export const DATA_CORR_TAG_ID = "DATA_CORR_TAG_ID";
export const DATA_CORR_FACTOR = "DATA_CORR_FACTOR";
export const NAV_TAG_BUILDING = "NAV_TAG_BUILDING";
export const EQUIP_COM_WM_BUS = "EQUIP_COM_WM_BUS";
export const EQUIP_COM_ZIGBEE = "EQUIP_COM_ZIGBEE";
export const EQUIP_COM_SERIAL = "EQUIP_COM_SERIAL";
export const RED_FLAG_CAT_REG = "RED_FLAG_CAT_REG";
export const RED_FLAG_CAT_HOT = "RED_FLAG_CAT_HOT";
export const RED_FLAG_CAT_AIR = "RED_FLAG_CAT_AIR";
export const MISSION_RED_FLAG = "MISSION_RED_FLAG";
export const CAPEX_ORIGIN_REG = "CAPEX_ORIGIN_REG";
export const CAPEX_TABLE_TYPE = "CAPEX_TABLE_TYPE";
export const NRJ_DASH_SOURCES = "NRJ_DASH_SOURCES";
export const DIA_EXPAND_NODES = "DIA_EXPAND_NODES";
export const CAPEX_TABLE_COST = "CAPEX_TABLE_COST";
export const CAPEX_TABLE_TAGS = "CAPEX_TABLE_TAGS";
export const CAPEX_TABLE_YEAR = "CAPEX_TABLE_YEAR";
export const MATERIALS_METALS = "MATERIALS_METALS";
export const MATERIALS_NICKEL = "MATERIALS_NICKEL";
export const MATERIALS_COPPER = "MATERIALS_COPPER";
export const MATERIALS_SILVER = "MATERIALS_SILVER";
export const MATERIALS_IODINE = "MATERIALS_IODINE";
export const MATERIALS_CARBON = "MATERIALS_CARBON";
export const MATERIALS_OXYGEN = "MATERIALS_OXYGEN";
export const MATERIALS_SULFUR = "MATERIALS_SULFUR";
export const MATERIALS_QUARTZ = "MATERIALS_QUARTZ";
export const MATERIALS_BARITE = "MATERIALS_BARITE";
export const MATERIALS_MARBLE = "MATERIALS_MARBLE";
export const MATERIALS_GYPSUM = "MATERIALS_GYPSUM";
export const MATERIALS_CEMENT = "MATERIALS_CEMENT";
export const MATERIALS_CERMET = "MATERIALS_CERMET";
export const MATERIALS_BRONZE = "MATERIALS_BRONZE";
export const MATERIALS_SOLDER = "MATERIALS_SOLDER";
export const MATERIALS_FIBERS = "MATERIALS_FIBERS";
export const MATERIALS_COTTON = "MATERIALS_COTTON";
export const MATERIALS_BAMBOO = "MATERIALS_BAMBOO";
export const MATERIALS_TIMBER = "MATERIALS_TIMBER";
export const MATERIALS_CHERRY = "MATERIALS_CHERRY";
export const MATERIALS_TALLOW = "MATERIALS_TALLOW";
export const MATERIALS_ALKALI = "MATERIALS_ALKALI";
export const MATERIALS_HELIUM = "MATERIALS_HELIUM";
export const MATERIALS_BUTANE = "MATERIALS_BUTANE";
export const MATERIALS_ARSINE = "MATERIALS_ARSINE";
export const TAG_MAPPER_TITLE = "TAG_MAPPER_TITLE";
export const PRED_OPEN_NUMBER = "PRED_OPEN_NUMBER";
export const DATASET_SRC_PRED = "DATASET_SRC_PRED";
export const PRED_QUALITY_KPI = "PRED_QUALITY_KPI";
export const PRED_QUALITY_GEN = "PRED_QUALITY_GEN";
export const ACCESS_CHECK_OUT = "ACCESS_CHECK_OUT";
export const AFFECT_COMMUNITY = "AFFECT_COMMUNITY";
export const AFFECT_EDUCATION = "AFFECT_EDUCATION";
export const ALARM_NO_DATASET = "ALARM_NO_DATASET";
export const ALARM_START_DATE = "ALARM_START_DATE";
export const ALERT_BEGIN_DATE = "ALERT_BEGIN_DATE";
export const APS_ERR_PCT_OVER = "APS_ERR_PCT_OVER";
export const APS_MISSING_PROP = "APS_MISSING_PROP";
export const AR_DELETE_RECORD = "AR_DELETE_RECORD";
export const BR_B_AUTO_CREATE = "BR_B_AUTO_CREATE";
export const BR_B_RECALCULATE = "BR_B_RECALCULATE";
export const BR_B_VIRTUAL_EXT = "BR_B_VIRTUAL_EXT";
export const BR_BUILDING_NAME = "BR_BUILDING_NAME";
export const BR_HEAD_ENTITIES = "BR_HEAD_ENTITIES";
export const BUILD_CONST_DATE = "BUILD_CONST_DATE";
export const BUILD_EDIT_COLOR = "BUILD_EDIT_COLOR";
export const BUILD_EDIT_LABEL = "BUILD_EDIT_LABEL";
export const BUILD_TIME_MARKS = "BUILD_TIME_MARKS";
export const CELL_IS_RENTABLE = "CELL_IS_RENTABLE";
export const CONTRACT_ADD_NEW = "CONTRACT_ADD_NEW";
export const CONTRACT_EXT_REF = "CONTRACT_EXT_REF";
export const CONTRACT_INT_REF = "CONTRACT_INT_REF";
export const DATA_ENTRY_VALUE = "DATA_ENTRY_VALUE";
export const DATASET_AGG_LAST = "DATASET_AGG_LAST";
export const DATASET_COL_TIME = "DATASET_COL_TIME";
export const DATASET_FLOW_OUT = "DATASET_FLOW_OUT";
export const DATASET_FORMULAS = "DATASET_FORMULAS";
export const DATASET_LINK_ADD = "DATASET_LINK_ADD";
export const DATASET_SRC_AUTO = "DATASET_SRC_AUTO";
export const DIA_CONFIG_SCALE = "DIA_CONFIG_SCALE";
export const DIA_CONFIG_TITLE = "DIA_CONFIG_TITLE";
export const DIA_COUNTER_PLAN = "DIA_COUNTER_PLAN";
export const DIA_DESCEND_FROM = "DIA_DESCEND_FROM";
export const DIA_DOWNLOAD_PDF = "DIA_DOWNLOAD_PDF";
export const DIA_HIDE_LINEAGE = "DIA_HIDE_LINEAGE";
export const DIA_NOTIF_NO_TAG = "DIA_NOTIF_NO_TAG";
export const DIA_REINITIALIZE = "DIA_REINITIALIZE";
export const FAIL_DATA_IMPORT = "FAIL_DATA_IMPORT";
export const FAV_ERROR_CREATE = "FAV_ERROR_CREATE";
export const FAV_ERROR_DELETE = "FAV_ERROR_DELETE";
export const FR_NO_FORM_FOUND = "FR_NO_FORM_FOUND";
export const G_ENTITY_CONTROL = "G_ENTITY_CONTROL";
export const G_EQUIPMENT_TYPE = "G_EQUIPMENT_TYPE";
export const G_FULL_REFERENCE = "G_FULL_REFERENCE";
export const GENERAL_USERNAME = "GENERAL_USERNAME";
export const GLOBAL_AFTER_PIC = "GLOBAL_AFTER_PIC";
export const GLOBAL_CALCULATE = "GLOBAL_CALCULATE";
export const GLOBAL_FREQUENCY = "GLOBAL_FREQUENCY";
export const GLOBAL_LONGITUDE = "GLOBAL_LONGITUDE";
export const GLOBAL_NAME_PLAN = "GLOBAL_NAME_PLAN";
export const GLOBAL_NEW_EQUIP = "GLOBAL_NEW_EQUIP";
export const GLOBAL_NUM_SUP_0 = 'GLOBAL_NUM_SUP_0';
export const GLOBAL_PERIMETER = "GLOBAL_PERIMETER";
export const GLOBAL_PORTFOLIO = "GLOBAL_PORTFOLIO";
export const GLOBAL_SHOW_MORE = "GLOBAL_SHOW_MORE";
export const GLOBAL_SUB_OWNER = "GLOBAL_SUB_OWNER";
export const GLOBAL_VISUALIZE = "GLOBAL_VISUALIZE";
export const GP_GROUP_QUARTER = "GP_GROUP_QUARTER";
export const KPI_API_LIMIT_OK = "KPI_API_LIMIT_OK";
export const KPI_API_NB_EQUIP = "KPI_API_NB_EQUIP";
export const MISSION_CDM_DATA = "MISSION_CDM_DATA";
export const MISSION_DEADLINE = "MISSION_DEADLINE";
export const MISSION_PROV_REF = "MISSION_PROV_REF";
export const MISSION_TASK_REG = "MISSION_TASK_REG";
export const MISSION_VISIT_TO = "MISSION_VISIT_TO";
export const MODAL_TIME_LABEL = "MODAL_TIME_LABEL";
export const MODAL_TIME_TITLE = "MODAL_TIME_TITLE";
export const NAV_EMPTY_RECENT = "NAV_EMPTY_RECENT";
export const NOTES_EDITED_THE = "NOTES_EDITED_THE";
export const NRJ_CONDUCTIVITY = "NRJ_CONDUCTIVITY";
export const P_TICKETS_URGENT = "P_TICKETS_URGENT";
export const PANEL_USER_TABLE = "PANEL_USER_TABLE";
export const PROFILE_SETTINGS = "PROFILE_SETTINGS";
export const PWD_CHANGE_TITLE = "PWD_CHANGE_TITLE";
export const QUIT_NO_SAVE_MSG = "QUIT_NO_SAVE_MSG";
export const REG_CONFIRMATION = "REG_CONFIRMATION";
export const REG_DATA_FILLING = "REG_DATA_FILLING";
export const REG_DEFAULT_FREQ = "REG_DEFAULT_FREQ";
export const REG_EQUIP_NO_CAT = "REG_EQUIP_NO_CAT";
export const REG_FORM_CONFORM = "REG_FORM_CONFORM";
export const REG_INVALID_DESC = "REG_INVALID_DESC";
export const REG_INVALID_ELEM = "REG_INVALID_ELEM";
export const REG_INVALID_NAME = "REG_INVALID_NAME";
export const REG_MAIL_INVALID = "REG_MAIL_INVALID";
export const REG_MISSING_TERM = "REG_MISSING_TERM";
export const REG_NEXT_CONTROL = "REG_NEXT_CONTROL";
export const REG_NO_FREQUENCY = "REG_NO_FREQUENCY";
export const REG_PRESENCE_DOC = "REG_PRESENCE_DOC";
export const REM_CAT_SECURITY = "REM_CAT_SECURITY";
export const REM_COST_MINI_GT = "REM_COST_MINI_GT";
export const RIGHT_EDIT_EQUIP = "RIGHT_EDIT_EQUIP";
export const RIGHT_MOVE_EQUIP = "RIGHT_MOVE_EQUIP";
export const SCHEDULE_HOLIDAY = "SCHEDULE_HOLIDAY";
export const SCHEDULE_OVERLAP = "SCHEDULE_OVERLAP";
export const SCHEDULE_TUESDAY = "SCHEDULE_TUESDAY";
export const STATE_SAVER_LOAD = "STATE_SAVER_LOAD";
export const SUPPORT_FILE_TIP = "SUPPORT_FILE_TIP";
export const T_SAVE_AND_CLOSE = "T_SAVE_AND_CLOSE";
export const TAB_ACTION_TABLE = "TAB_ACTION_TABLE";
export const TAB_COVID_WIZARD = "TAB_COVID_WIZARD";
export const TAB_ENTITY_TABLE = "TAB_ENTITY_TABLE";
export const TAB_EVENT_READER = "TAB_EVENT_READER";
export const TAB_TRANSLATIONS = "TAB_TRANSLATIONS";
export const TS_ABSOLUTE_TIME = "TS_ABSOLUTE_TIME";
export const TS_RELATIVE_TIME = "TS_RELATIVE_TIME";
export const VD_DEFAULT_TITLE = "VD_DEFAULT_TITLE";
export const VD_NORM_EXIGENCE = "VD_NORM_EXIGENCE";
export const VD_OCCUPATION_TH = "VD_OCCUPATION_TH";
export const APS_ENERGY_SAVING = "APS_ENERGY_SAVING"
export const REG_NO_FORMULA = "REG_INVALID_FORMULA";
export const GAMME_FORM_PARENT = "GAMME_FORM_PARENT";
export const OPTION_CODE_SMOKE = "OPTION_CODE_SMOKE";
export const TAB_ENV_DASHBOARD = "TAB_ENV_DASHBOARD";
export const TAB_OPTIONS_TABLE = "TAB_OPTIONS_TABLE";
export const EPRA_FAV_NO_VIEWS = "EPRA_FAV_NO_VIEWS";
export const EPRA_FAV_NEW_VIEW = "EPRA_FAV_NEW_VIEW";
export const EPRA_CAT_EDIT_TIP = "EPRA_CAT_EDIT_TIP";
export const EPRA_PERIOD_START = "EPRA_PERIOD_START";
export const NOT_CERTIFIED_YET = "NOT_CERTIFIED_YET";
export const AUTO_CORRECT_DATA = "AUTO_CORRECT_DATA";
export const MISSION_TASK_CRVA = "MISSION_TASK_CRVA";
export const DATA_CORR_COMMENT = "DATA_CORR_COMMENT";
export const TICKET_TYPE_GROUP = "TICKET_TYPE_GROUP";
export const EQUIP_COM_4_20_MA = "EQUIP_COM_4_20_MA";
export const MISSION_TYPES_REG = "MISSION_TYPES_REG";
export const RED_FLAG_CAT_COLD = "RED_FLAG_CAT_COLD";
export const RED_FLAG_CAT_MOOD = "RED_FLAG_CAT_MOOD";
export const RED_FLAG_CAT_FIRE = "RED_FLAG_CAT_FIRE";
export const RED_FLAG_CAT_ELEC = "RED_FLAG_CAT_ELEC";
export const NRJ_DASH_ENERGIES = "NRJ_DASH_ENERGIES";
export const NRJ_DASH_DATASETS = "NRJ_DASH_DATASETS";
export const CERT_HISTORY_DATE = "CERT_HISTORY_DATE";
export const CERT_HISTORY_USER = "CERT_HISTORY_USER";
export const DATA_EXPORT_TITLE = "DATA_EXPORT_TITLE";
export const CAPEX_TABLE_TITLE = "CAPEX_TABLE_TITLE";
export const CAPEX_TABLE_CAPEX = "CAPEX_TABLE_CAPEX";
export const CAPEX_TABLE_OWNER = "CAPEX_TABLE_OWNER";
export const MATERIALS_NIOBIUM = "MATERIALS_NIOBIUM";
export const MATERIALS_CADMIUM = "MATERIALS_CADMIUM";
export const MATERIALS_BISMUTH = "MATERIALS_BISMUTH";
export const MATERIALS_SILICON = "MATERIALS_SILICON";
export const MATERIALS_ARSENIC = "MATERIALS_ARSENIC";
export const MATERIALS_BROMINE = "MATERIALS_BROMINE";
export const MATERIALS_KRYPTON = "MATERIALS_KRYPTON";
export const MATERIALS_GRANITE = "MATERIALS_GRANITE";
export const MATERIALS_CERAMIC = "MATERIALS_CERAMIC";
export const MATERIALS_FLY_ASH = "MATERIALS_FLY_ASH";
export const MATERIALS_PERLITE = "MATERIALS_PERLITE";
export const MATERIALS_REDWOOD = "MATERIALS_REDWOOD";
export const MATERIALS_RED_OAK = "MATERIALS_RED_OAK";
export const MATERIALS_LEATHER = "MATERIALS_LEATHER";
export const MATERIALS_BEESWAX = "MATERIALS_BEESWAX";
export const MATERIALS_RUBBERS = "MATERIALS_RUBBERS";
export const MATERIALS_ASPHALT = "MATERIALS_ASPHALT";
export const MATERIALS_LIQUIDS = "MATERIALS_LIQUIDS";
export const MATERIALS_AMMONIA = "MATERIALS_AMMONIA";
export const MATERIALS_PROPANE = "MATERIALS_PROPANE";
export const MATERIALS_METHANE = "MATERIALS_METHANE";
export const MATERIALS_PE_DESC = "MATERIALS_PE_DESC";
export const NRJ_MISSION_CRUMB = "NRJ_MISSION_CRUMB";
export const REMOVE_FILE_TITLE = "REMOVE_FILE_TITLE";
export const MISSION_TYPES_NRJ = "MISSION_TYPES_NRJ";
export const MISSION_COPY_FROM = "MISSION_COPY_FROM";
export const GRID_FILTER_OP_EQ = "GRID_FILTER_OP_EQ";
export const GRID_FILTER_OP_LT = "GRID_FILTER_OP_LT";
export const GRID_FILTER_OP_GT = "GRID_FILTER_OP_GT";
export const PRED_OPEN_CONS_ON = "PRED_OPEN_CONS_ON";
export const AFFECT_RETAIL_DIY = "AFFECT_RETAIL_DIY";
export const AFFECT_SPORT_POOL = "AFFECT_SPORT_POOL";
export const PRED_UPDATE_MODEL = "PRED_UPDATE_MODEL";
export const PRED_MODEL_REPORT = "PRED_MODEL_REPORT";
export const GLOBAL_ZIP_EXPORT = "GLOBAL_ZIP_EXPORT";
export const GLOBAL_CREATED_BY = "GLOBAL_CREATED_BY";
export const PANEL_NOTES_TABLE = "PANEL_NOTES_TABLE";
export const PRED_CONFIG_TITLE = "PRED_CONFIG_TITLE";
export const ACCESS_CACHE_INFO = "ACCESS_CACHE_INFO";
export const ACCESS_USER_GROUP = "ACCESS_USER_GROUP";
export const ACCESS_USER_ROLES = "ACCESS_USER_ROLES";
export const ACCESS_USERS_TREE = "ACCESS_USERS_TREE";
export const ALARM_PICK_ORIGIN = "ALARM_PICK_ORIGIN";
export const ANNOT_ADD_MEASURE = "ANNOT_ADD_MEASURE";
export const APS_BUILD_LOC_POT = "APS_BUILD_LOC_POT";
export const APS_ENERGY_GAINED = "APS_ENERGY_GAINED";
export const APS_EURO_PER_YEAR = "APS_EURO_PER_YEAR";
export const APS_IS_NOT_RETAIL = "APS_IS_NOT_RETAIL";
export const APS_REFACTURATION = "APS_REFACTURATION";
export const APS_WRONG_FORMULA = "APS_WRONG_FORMULA";
export const AR_MENU_RECORDING = "AR_MENU_RECORDING";
export const AR_SAVE_RECORDING = "AR_SAVE_RECORDING";
export const AR_VOICE_RECORDER = "AR_VOICE_RECORDER";
export const AUTH_LINK_CLOSURE = "AUTH_LINK_CLOSURE";
export const AUTH_LINK_SUBJECT = "AUTH_LINK_SUBJECT";
export const AUTH_SIGNIN_TITLE = "AUTH_SIGNIN_TITLE";
export const BR_B_HOPPER_INPUT = "BR_B_HOPPER_INPUT";
export const BR_B_HOPPER_PULSE = "BR_B_HOPPER_PULSE";
export const BR_B_PULSE_BOUCHE = "BR_B_PULSE_BOUCHE";
export const BR_FLOOR_START_AT = "BR_FLOOR_START_AT";
export const BUILD_EDIT_VISUAL = "BUILD_EDIT_VISUAL";
export const BUILD_PERMIT_DATE = "BUILD_PERMIT_DATE";
export const BUILD_REPAIR_DATE = "BUILD_REPAIR_DATE";
export const BUILD_ROOD_HEIGHT = "BUILD_ROOD_HEIGHT";
export const CELLS_REPORT_TILE = "CELLS_REPORT_TILE";
export const CFS_DOWNLOAD_WORD = "CFS_DOWNLOAD_WORD";
export const CFS_FAILED_DELETE = "CFS_FAILED_DELETE";
export const CONTRACT_CATEGORY = "CONTRACT_CATEGORY";
export const CONTRACT_RECEIVER = "CONTRACT_RECEIVER";
export const CREAT_MAINTENANCE = "CREAT_MAINTENANCE";
export const DATASET_AGG_COUNT = "DATASET_AGG_COUNT";
export const DATASET_AGG_FIRST = "DATASET_AGG_FIRST";
export const DATASET_COL_VALUE = "DATASET_COL_VALUE";
export const DATASET_CUMULATED = "DATASET_CUMULATED";
export const DATASET_LINK_TYPE = "DATASET_LINK_TYPE";
export const DIA_APPLY_CHANGES = "DIA_APPLY_CHANGES";
export const DIA_CONFIG_VISUAL = "DIA_CONFIG_VISUAL";
export const DIA_HIDE_SOLO_TIP = "DIA_HIDE_SOLO_TIP";
export const DIA_LINK_CODE_EXT = "DIA_LINK_CODE_EXT";
export const DIA_LINK_TO_WHICH = "DIA_LINK_TO_WHICH";
export const DIA_NOTIF_SAME_ID = "DIA_NOTIF_SAME_ID";
export const DIA_TECH_DEPLASSE = "DIA_TECH_DEPLASSE";
export const DIA_TEMPLATE_PICK = "DIA_TEMPLATE_PICK";
export const DIA_TOGGLE_HIDDEN = "DIA_TOGGLE_HIDDEN";
export const EMP_PANEL_DISPLAY = "EMP_PANEL_DISPLAY";
export const EQUIP_GO_TO_SHEET = "EQUIP_GO_TO_SHEET";
export const EQUIP_TAB_FORM_ID = "EQUIP_TAB_FORM_ID";
export const ERR_CLOSED_CIRCLE = "ERR_CLOSED_CIRCLE";
export const ERROR_LOAD_ACCESS = "ERROR_LOAD_ACCESS";
export const FAILURE_CRITICITY = "FAILURE_CRITICITY";
export const FE_NO_ENERGY_TYPE = "FE_NO_ENERGY_TYPE";
export const FORM_ERR_LOAD_SUB = "FORM_ERR_LOAD_SUB";
export const FORM_FOUND_NO_SUB = "FORM_FOUND_NO_SUB";
export const FORM_FREQ_INVALID = "FORM_FREQ_INVALID";
export const FORM_SUFFIX_YEARS = "FORM_SUFFIX_YEARS";
export const G_ERROR_FILE_TYPE = "G_ERROR_FILE_TYPE";
export const G_FUNCTIONAL_UNIT = "G_FUNCTIONAL_UNIT";
export const G_NO_PARENT_EQUIP = "G_NO_PARENT_EQUIP";
export const G_VISUALIZE_TITLE = "G_VISUALIZE_TITLE";
export const GAMME_MAINTENANCE = "GAMME_MAINTENANCE";
export const GLOBAL_CALCULATED = "GLOBAL_CALCULATED";
export const GLOBAL_CLICK_HERE = "GLOBAL_CLICK_HERE";
export const GLOBAL_COLOR_DARK = "GLOBAL_COLOR_DARK";
export const GLOBAL_EDITED_THE = "GLOBAL_EDITED_THE";
export const GLOBAL_LABEL_BAIL = "GLOBAL_LABEL_BAIL";
export const GLOBAL_LABEL_SITE = "GLOBAL_LABEL_SITE";
export const GLOBAL_NEW_CLIENT = "GLOBAL_NEW_CLIENT";
export const GLOBAL_NO_CONTEXT = "GLOBAL_NO_CONTEXT";
export const GLOBAL_PROP_LABEL = "GLOBAL_PROP_LABEL";
export const GLOBAL_SURFACE_AA = "GLOBAL_SURFACE_AA";
export const GLOBAL_SURFACE_CA = "GLOBAL_SURFACE_CA";
export const GLOBAL_SURFACE_LA = "GLOBAL_SURFACE_LA";
export const GLOBAL_SURFACE_PA = "GLOBAL_SURFACE_PA";
export const GLOBAL_SURFACE_TA = "GLOBAL_SURFACE_TA";
export const GLOBAL_TIME_CHECK = "GLOBAL_TIME_CHECK";
export const GLOBAL_TIME_GROUP = "GLOBAL_TIME_GROUP";
export const GP_BUILD_TAB_COST = "GP_BUILD_TAB_COST";
export const HOME_RELOAD_TITLE = "HOME_RELOAD_TITLE";
export const LOGIN_TEMP_NO_LOG = "LOGIN_TEMP_NO_LOG";
export const MATRIX_IMPORTANCE = "MATRIX_IMPORTANCE";
export const MATRIX_LABEL_PROP = "MATRIX_LABEL_PROP";
export const MATRIX_VALUE_FORM = "MATRIX_VALUE_FORM";
export const MISSION_FREQUENCY = "MISSION_FREQUENCY";
export const MISSION_TYPES_CDM = "MISSION_TYPES_CDM";
export const MISSION_TYPES_EDL = "MISSION_TYPES_EDL";
export const NAV_ADD_PORTFOLIO = "NAV_ADD_PORTFOLIO";
export const NOTE_HIDE_REPLIES = "NOTE_HIDE_REPLIES";
export const NOTES_CREATED_THE = "NOTES_CREATED_THE";
export const NRJ_HEAD_ELEC_PPV = "NRJ_HEAD_ELEC_PPV";
export const P_ASSIGNED_TICKET = "P_ASSIGNED_TICKET";
export const P_TICKET_DURATION = "P_TICKET_DURATION";
export const PANEL_EQUIP_TABLE = "PANEL_EQUIP_TABLE";
export const PANEL_ID_CARD_NRJ = "PANEL_ID_CARD_NRJ";
export const REG_FORM_VALIDITY = "REG_FORM_VALIDITY";
export const REG_MAKE_INACTIVE = "REG_MAKE_INACTIVE";
export const REG_SURNAME_LABEL = "REG_SURNAME_LABEL";
export const REM_CAT_ENV_OTHER = "REM_CAT_ENV_OTHER";
export const REM_DEF_COST_MINI = "REM_DEF_COST_MINI";
export const REM_STATUS_CLOSED = "REM_STATUS_CLOSED";
export const RIGHT_BE_READ_REG = "RIGHT_BE_READ_REG";
export const RIGHT_CREATE_SITE = "RIGHT_CREATE_SITE";
export const RIGHT_DELETE_SITE = "RIGHT_DELETE_SITE";
export const ROLE_FORM_PACKAGE = "ROLE_FORM_PACKAGE";
export const SCHEDULE_NO_EQUAL = "SCHEDULE_NO_EQUAL";
export const SCHEDULE_SATURDAY = "SCHEDULE_SATURDAY";
export const SCHEDULE_THURSDAY = "SCHEDULE_THURSDAY";
export const SUPPORT_CAT_OTHER = "SUPPORT_CAT_OTHER";
export const TAB_BUILD_ID_CARD = "TAB_BUILD_ID_CARD";
export const TAB_ENTITY_EDITOR = "TAB_ENTITY_EDITOR";
export const TAB_REG_DOC_TABLE = "TAB_REG_DOC_TABLE";
export const TI_PRESENCE_LABEL = "TI_PRESENCE_LABEL";
export const TICKET_ATTRIBUTED = "TICKET_ATTRIBUTED";
export const TREE_TIME_OUT_MSG = "TREE_TIME_OUT_MSG";
export const UF_TECH_COMPANIES = "UF_TECH_COMPANIES";
export const VD_DEBIT_PER_PERS = "VD_DEBIT_PER_PERS";
export const VD_SWITCH_ROW_EXT = "VD_SWITCH_ROW_EXT";
export const DASH_ENV_AGG_LABEL = "DASH_ENV_AGG_LABEL";
export const DASH_ENV_AGG_BUILD = "DASH_ENV_AGG_BUILD";
export const DASH_ENV_AGG_SITES = "DASH_ENV_AGG_SITES";
export const SUPERSET_LOAD_DATA = "SUPERSET_LOAD_DATA";
export const MATERIALS_CONCRETE = "MATERIALS_CONCRETE";
export const RENT_FORM_BAIL_FOR = "RENT_FORM_BAIL_FOR";
export const RENT_FORM_NEW_BAIL = "RENT_FORM_NEW_BAIL";
export const REG_TABLE_ALL_DOCS = "REG_TABLE_ALL_DOCS";
export const REG_TABLE_REG_DOCS = "REG_TABLE_REG_DOCS";
export const OPTIONS_TABLE_CODE = "OPTIONS_TABLE_CODE";
export const EPRA_ADD_CAT_TITLE = "EPRA_ADD_CAT_TITLE";
export const MISSION_TYPES_EPRA = "MISSION_TYPES_EPRA";
export const MISSION_TYPE_RSC01 = "MISSION_TYPE_RSC01";
export const FORMULA_HELPER_TIP = "FORMULA_HELPER_TIP";
export const CERT_OPEN_DOCUMENT = "CERT_OPEN_DOCUMENT";
export const TAXO_MISSION_PROOF = "TAXO_MISSION_PROOF";
export const BUBBLE_TABLE_BOARD = "BUBBLE_TABLE_BOARD";
export const DATA_CORR_TAG_NAME = "DATA_CORR_TAG_NAME";
export const NRJ_REPORT_API_ECS = "NRJ_REPORT_API_ECS";
export const RED_FLAG_TOTAL_VAT = "RED_FLAG_TOTAL_VAT";
export const ALLOW_REG_MISSIONS = "ALLOW_REG_MISSIONS";
export const RED_FLAG_MAIN_REMS = "RED_FLAG_MAIN_REMS";
export const CERT_HISTORY_VALUE = "CERT_HISTORY_VALUE";
export const CERT_CERTIFIED_THE = "CERT_CERTIFIED_THE";
export const CERTIF_EMPTY_VALUE = "CERTIF_EMPTY_VALUE";
export const REG_PROP_DUPLICATE = "REG_PROP_DUPLICATE";
export const FORM_REG_PROP_NAME = "FORM_REG_PROP_NAME";
export const FORM_REG_PROP_UNIT = "FORM_REG_PROP_UNIT";
export const EDL_EXCEL_COL_CRIT = "EDL_EXCEL_COL_CRIT";
export const CAPEX_TABLE_ORIGIN = "CAPEX_TABLE_ORIGIN";
export const MATERIALS_SCANDIUM = "MATERIALS_SCANDIUM";
export const MATERIALS_TITANIUM = "MATERIALS_TITANIUM";
export const MATERIALS_VANADIUM = "MATERIALS_VANADIUM";
export const MATERIALS_CHROMIUM = "MATERIALS_CHROMIUM";
export const MATERIALS_ALUMINUM = "MATERIALS_ALUMINUM";
export const MATERIALS_ANTIMONY = "MATERIALS_ANTIMONY";
export const MATERIALS_POLONIUM = "MATERIALS_POLONIUM";
export const MATERIALS_HALOGENS = "MATERIALS_HALOGENS";
export const MATERIALS_FLUORINE = "MATERIALS_FLUORINE";
export const MATERIALS_CHLORINE = "MATERIALS_CHLORINE";
export const MATERIALS_NEON_GAS = "MATERIALS_NEON_GAS";
export const MATERIALS_NITROGEN = "MATERIALS_NITROGEN";
export const MATERIALS_SELENIUM = "MATERIALS_SELENIUM";
export const MATERIALS_GRAPHITE = "MATERIALS_GRAPHITE";
export const MATERIALS_SILICATE = "MATERIALS_SILICATE";
export const MATERIALS_ASBESTOS = "MATERIALS_ASBESTOS";
export const MATERIALS_TEXTILES = "MATERIALS_TEXTILES";
export const MATERIALS_PLASTICS = "MATERIALS_PLASTICS";
export const MATERIALS_PHENOLIC = "MATERIALS_PHENOLIC";
export const MATERIALS_NEOPRENE = "MATERIALS_NEOPRENE";
export const MATERIALS_SILICONE = "MATERIALS_SILICONE";
export const MATERIALS_ASPHALTS = "MATERIALS_ASPHALTS";
export const MATERIALS_PARAFFIN = "MATERIALS_PARAFFIN";
export const MATERIALS_ALCOHOLS = "MATERIALS_ALCOHOLS";
export const MATERIALS_ETHYLENE = "MATERIALS_ETHYLENE";
export const MATERIALS_HYDROGEN = "MATERIALS_HYDROGEN";
export const MATERIALS_ISOBUTAN = "MATERIALS_ISOBUTAN";
export const MATERIALS_TIN_DESC = "MATERIALS_TIN_DESC";
export const MATERIALS_SAP_DESC = "MATERIALS_SAP_DESC";
export const MATERIALS_ABS_DESC = "MATERIALS_ABS_DESC";
export const MATERIALS_APM_DESC = "MATERIALS_APM_DESC";
export const MATERIALS_PVC_DESC = "MATERIALS_PVC_DESC";
export const MATERIALS_AIR_DESC = "MATERIALS_AIR_DESC";
export const GRID_FILTER_OP_LTE = "GRID_FILTER_OP_LTE";
export const GRID_FILTER_OP_GTE = "GRID_FILTER_OP_GTE";
export const PRED_OPEN_AVG_OPEN = "PRED_OPEN_AVG_OPEN";
export const PRED_OPEN_CONS_OFF = "PRED_OPEN_CONS_OFF";
export const AFFECT_RETAIL_MAIL = "AFFECT_RETAIL_MAIL";
export const AFFECT_PUBLIC_JAIL = "AFFECT_PUBLIC_JAIL";
export const AFFECT_SPORT_MIXTE = "AFFECT_SPORT_MIXTE";
export const PRED_QUALITY_TITLE = "PRED_QUALITY_TITLE";
export const KPI_NEXT_EXEC_DATE = "KPI_NEXT_EXEC_DATE";
export const KPI_REPORT_NO_DATA = "KPI_REPORT_NO_DATA";
export const EQUIP_GAMME_LABELS = "EQUIP_GAMME_LABELS";
export const ACCESS_NB_BUILDING = "ACCESS_NB_BUILDING";
export const ACCESS_SUM_SURFACE = "ACCESS_SUM_SURFACE";
export const ACCESS_USERS_TITLE = "ACCESS_USERS_TITLE";
export const ACTION_GHOST_VALUE = "ACTION_GHOST_VALUE";
export const ACTION_MAINTENANCE = "ACTION_MAINTENANCE";
export const ADD_SCHEDULE_RANGE = "ADD_SCHEDULE_RANGE";
export const AFFECT_RESIDENTIAL = "AFFECT_RESIDENTIAL";
export const ALARM_DATA_INCLUDE = "ALARM_DATA_INCLUDE";
export const ALARM_GROUP_PERIOD = "ALARM_GROUP_PERIOD";
export const ALARM_PICK_DATASET = "ALARM_PICK_DATASET";
export const ALERT_TRIGGER_DATE = "ALERT_TRIGGER_DATE";
export const ANNOT_DEFAULT_PLAN = "ANNOT_DEFAULT_PLAN";
export const ANNOT_EDIT_MEASURE = "ANNOT_EDIT_MEASURE";
export const APS_BAIL_YEAR_LEFT = "APS_BAIL_YEAR_LEFT";
export const APS_DISCRIMINATION = "APS_DISCRIMINATION";
export const APS_PCT_ATTRIBUTES = 'APS_PCT_ATTRIBUTES';
export const AR_START_RECORDING = "AR_START_RECORDING";
export const BR_B_DEFAULT_EQUIP = "BR_B_DEFAULT_EQUIP";
export const BR_B_GP_ASSOCIATED = "BR_B_GP_ASSOCIATED";
export const BR_B_HOPPER_OUTPUT = "BR_B_HOPPER_OUTPUT";
export const BR_B_VIRTUAL_PULSE = "BR_B_VIRTUAL_PULSE";
export const BR_GP_DISTRIBUTION = "BR_GP_DISTRIBUTION";
export const BR_TAB_LABEL_GP_GE = "BR_TAB_LABEL_GP_GE";
export const BUILD_DALLE_HEIGHT = "BUILD_DALLE_HEIGHT";
export const BUILD_EDIT_GENERAL = "BUILD_EDIT_GENERAL";
export const BUILD_EDIT_NO_ROOT = "BUILD_EDIT_NO_ROOT";
export const BUILD_EDIT_PICTURE = "BUILD_EDIT_PICTURE";
export const BUILD_NORM_SURFACE = "BUILD_NORM_SURFACE";
export const CDM_DATA_NO_CONFIG = "CDM_DATA_NO_CONFIG";
export const CFS_FORM_LOCKED_BY = "CFS_FORM_LOCKED_BY";
export const CFS_OPTIONAL_LABEL = "CFS_OPTIONAL_LABEL";
export const DATA_ENTRY_PICTURE = "DATA_ENTRY_PICTURE";
export const DATASET_AGG_FACTOR = "DATASET_AGG_FACTOR";
export const DATASET_FLOW_ALERT = "DATASET_FLOW_ALERT";
export const DATASET_LINK_INPUT = "DATASET_LINK_INPUT";
export const DATASET_NORMALIZED = "DATASET_NORMALIZED";
export const DATASET_SRC_MANUAL = "DATASET_SRC_MANUAL";
export const DATASET_START_DATE = "DATASET_START_DATE";
export const DIA_CONFIRM_BUTTON = "DIA_CONFIRM_BUTTON";
export const DIA_NO_DESCENDANTS = "DIA_NO_DESCENDANTS";
export const DIA_NOTIF_ADD_LINK = "DIA_NOTIF_ADD_LINK";
export const DIA_TOO_MANY_NODES = "DIA_TOO_MANY_NODES";
export const EMP_NUM_FLOOR_ONLY = "EMP_NUM_FLOOR_ONLY";
export const EQUIP_ADD_SAME_CAT = "EQUIP_ADD_SAME_CAT";
export const EQUIP_TAB_FORM_REG = "EQUIP_TAB_FORM_REG";
export const ERR_404_CONTACT_US = "ERR_404_CONTACT_US";
export const FE_NO_FORMULA_NAME = "FE_NO_FORMULA_NAME";
export const FILE_FAILED_UPLOAD = "FILE_FAILED_UPLOAD";
export const FORM_ERR_LOAD_FORM = "FORM_ERR_LOAD_FORM";
export const FORM_FIELD_INVALID = "FORM_FIELD_INVALID";
export const FORM_FOUND_NO_FORM = "FORM_FOUND_NO_FORM";
export const G_EXCEL_LINE_INFOS = "G_EXCEL_LINE_INFOS";
export const GLOBAL_AFFECTATION = "GLOBAL_AFFECTATION";
export const GLOBAL_BULK_INSERT = "GLOBAL_BULK_INSERT";
export const GLOBAL_COLOR_CLEAR = "GLOBAL_COLOR_CLEAR";
export const GLOBAL_ERROR_MIN_1 = "GLOBAL_ERROR_MIN_1";
export const GLOBAL_FAILED_LOAD = "GLOBAL_FAILED_LOAD";
export const GLOBAL_FILE_UPLOAD = "GLOBAL_FILE_UPLOAD";
export const GLOBAL_LABEL_BUILD = "GLOBAL_LABEL_BUILD";
export const GLOBAL_LABEL_CELLS = "GLOBAL_LABEL_CELLS";
export const GLOBAL_PICK_PARENT = "GLOBAL_PICK_PARENT";
export const GLOBAL_SURFACE_BHS = "GLOBAL_SURFACE_BHS";
export const GLOBAL_SURFACE_GFA = "GLOBAL_SURFACE_GFA";
export const GLOBAL_SURFACE_IFA = "GLOBAL_SURFACE_IFA";
export const GLOBAL_SURFACE_NFA = "GLOBAL_SURFACE_NFA";
export const GLOBAL_SURFACE_NRA = "GLOBAL_SURFACE_NRA";
export const GLOBAL_SURFACE_UAA = "GLOBAL_SURFACE_UAA";
export const GLOBAL_SURFACE_UCA = "GLOBAL_SURFACE_UCA";
export const GLOBAL_SURFACE_UPA = "GLOBAL_SURFACE_UPA";
export const GLOBAL_SURFACE_UTA = "GLOBAL_SURFACE_UTA";
export const GLOBAL_UPLOAD_PLAN = "GLOBAL_UPLOAD_PLAN";
export const GLOBAL_VALID_UNTIL = "GLOBAL_VALID_UNTIL";
export const GRAPH_TICKETS_LATE = "GRAPH_TICKETS_LATE";
export const IMPORT_AUTO_CREATE = "IMPORT_AUTO_CREATE";
export const KPI_API_EVAL_LIMIT = "KPI_API_EVAL_LIMIT";
export const KPI_API_TIPS_LABEL = "KPI_API_TIPS_LABEL";
export const KPI_SAVE_FREQUENCY = "KPI_SAVE_FREQUENCY";
export const LAYOUT_UNAVAILABLE = "LAYOUT_UNAVAILABLE";
export const LOGIN_REG_QUESTION = "LOGIN_REG_QUESTION";
export const MAGIC_CREATE_FLOOR = "MAGIC_CREATE_FLOOR";
export const MATRIX_IS_RESOURCE = "MATRIX_IS_RESOURCE";
export const MATRIX_PROP_EXISTS = "MATRIX_PROP_EXISTS";
export const MATRIX_REMOVE_COND = "MATRIX_REMOVE_COND";
export const MISSION_CLIENT_REF = "MISSION_CLIENT_REF";
export const MISSION_INTERV_TEL = "MISSION_INTERV_TEL";
export const MISSION_INVITATION = "MISSION_INVITATION";
export const MISSION_TASK_VISIT = "MISSION_TASK_VISIT";
export const MISSION_VISIT_FROM = "MISSION_VISIT_FROM";
export const NAV_EDIT_PORTFOLIO = "NAV_EDIT_PORTFOLIO";
export const NAV_SEARCH_MISSION = "NAV_SEARCH_MISSION";
export const NOTIF_SAVE_SUCCESS = "NOTIF_SAVE_SUCCESS";
export const P_TICKETS_END_SOON = "P_TICKETS_END_SOON";
export const PANEL_ACTION_TABLE = "PANEL_ACTION_TABLE";
export const PANEL_BUILD_ID_REG = "PANEL_BUILD_ID_REG";
export const PANEL_ID_CARD_TECH = "PANEL_ID_CARD_TECH";
export const QUIT_NO_SAVE_TITLE = "QUIT_NO_SAVE_TITLE";
export const REG_BANNER_WARNING = "REG_BANNER_WARNING";
export const REG_EDIT_PASSWORDS = "REG_EDIT_PASSWORDS";
export const REG_EMPTY_PASSWORD = "REG_EMPTY_PASSWORD";
export const REG_INVALID_ACTION = "REG_INVALID_ACTION";
export const REG_WRONG_RESOURCE = "REG_WRONG_RESOURCE";
export const REG_X_PROP_MISSING = "REG_X_PROP_MISSING";
export const RIGHT_BE_WRITE_REG = "RIGHT_BE_WRITE_REG";
export const RIGHT_CREATE_EQUIP = "RIGHT_CREATE_EQUIP";
export const RIGHT_DELETE_EQUIP = "RIGHT_DELETE_EQUIP";
export const RIGHT_READ_NRJ_TAG = "RIGHT_READ_NRJ_TAG";
export const RIGHT_WRITE_EVENTS = "RIGHT_WRITE_EVENTS";
export const RIGHT_WRITE_OTHERS = "RIGHT_WRITE_OTHERS";
export const SCH_FILE_TOO_LARGE = "SCH_FILE_TOO_LARGE";
export const SCH_UNNAMED_SCHEMA = "SCH_UNNAMED_SCHEMA";
export const SCHEDULE_WEDNESDAY = "SCHEDULE_WEDNESDAY";
export const SITE_PLURAL_SINGLE = "SITE_PLURAL_SINGLE";
export const STATE_SAVER_UPDATE = "STATE_SAVER_UPDATE";
export const T_SELECT_EQUIPMENT = "T_SELECT_EQUIPMENT";
export const TAB_CONTRACT_TABLE = "TAB_CONTRACT_TABLE";
export const TAB_DASHBOARD_GMAO = "TAB_DASHBOARD_GMAO";
export const TECHNIQUE_DEPLASSE = "TECHNIQUE_DEPLASSE";
export const TREE_ENTITY_PARENT = "TREE_ENTITY_PARENT";
export const VAL_ALREADY_EXISTS = "VAL_ALREADY_EXISTS";
export const VD_EXTRACTION_TREE = "VD_EXTRACTION_TREE";
export const VD_REMARQUES_LABEL = "VD_REMARQUES_LABEL";
export const VD_RESTROOM_NUMBER = "VD_RESTROOM_NUMBER";
export const GLOBAL_LABEL_CLIENT = "GLOBAL_LABEL_CLIENT"
export const SUPERSET_ERROR_DATA = "SUPERSET_ERROR_DATA";
export const MISSION_CDM_SCAN_QR = "MISSION_CDM_SCAN_QR";
export const DATASET_PRESET_THCH = "DATASET_PRESET_THCH";
export const DATASET_PRESET_THFR = "DATASET_PRESET_THFR";
export const OPTION_CODE_WINDOWS = "OPTION_CODE_WINDOWS";
export const OPTIONS_TABLE_LABEL = "OPTIONS_TABLE_LABEL";
export const OPTIONS_TABLE_VALUE = "OPTIONS_TABLE_VALUE";
export const EPRA_COVERAGE_LABEL = "EPRA_COVERAGE_LABEL";
export const DATASET_AFFECTATION = "DATASET_AFFECTATION";
export const EPRA_CATEGORY_LABEL = "EPRA_CATEGORY_LABEL";
export const ALLOW_EPRA_MISSIONS = "ALLOW_EPRA_MISSIONS";
export const ACCESS_GROUP_ADMINS = "ACCESS_GROUP_ADMINS";
export const ZIP_FILE_GENERATION = "ZIP_FILE_GENERATION";
export const CERT_PROOF_DOCUMENT = "CERT_PROOF_DOCUMENT";
export const BUBBLE_FORM_MESSAGE = "BUBBLE_FORM_MESSAGE";
export const BUBBLE_FORM_VARIANT = "BUBBLE_FORM_VARIANT";
export const TICKET_CREATE_GROUP = "TICKET_CREATE_GROUP";
export const NRJ_REPORT_API_COST = "NRJ_REPORT_API_COST";
export const NRJ_REPORT_API_ABBR = "NRJ_REPORT_API_ABBR";
export const MISSION_BILLED_DATE = "MISSION_BILLED_DATE";
export const REG_PROP_NO_OPTIONS = "REG_PROP_NO_OPTIONS";
export const EQUIP_COM_MODBUS_IP = "EQUIP_COM_MODBUS_IP";
export const EQUIP_COM_BACNET_IP = "EQUIP_COM_BACNET_IP";
export const EQUIP_COM_IMPULSION = "EQUIP_COM_IMPULSION";
export const EDIT_APPLICABLE_REG = "EDIT_APPLICABLE_REG";
export const CATEGORIES_RED_FLAG = "CATEGORIES_RED_FLAG";
export const RED_FLAG_CAT_HOISTS = "RED_FLAG_CAT_HOISTS";
export const DOOR_OPENING_SIMPLE = "DOOR_OPENING_SIMPLE";
export const DOOR_OPENING_DOUBLE = "DOOR_OPENING_DOUBLE";
export const TABLE_REMS_MOVE_REM = "TABLE_REMS_MOVE_REM";
export const CAPEX_TABLE_ELEMENT = "CAPEX_TABLE_ELEMENT";
export const MATERIALS_POTASSIUM = "MATERIALS_POTASSIUM";
export const MATERIALS_NEODYMIUM = "MATERIALS_NEODYMIUM";
export const MATERIALS_ACTINOIDS = "MATERIALS_ACTINOIDS";
export const MATERIALS_MANGANESE = "MATERIALS_MANGANESE";
export const MATERIALS_GERMANIUM = "MATERIALS_GERMANIUM";
export const MATERIALS_TELLURIUM = "MATERIALS_TELLURIUM";
export const MATERIALS_LIMESTONE = "MATERIALS_LIMESTONE";
export const MATERIALS_QUICKLIME = "MATERIALS_QUICKLIME";
export const MATERIALS_SANDSTONE = "MATERIALS_SANDSTONE";
export const MATERIALS_PERICLASE = "MATERIALS_PERICLASE";
export const MATERIALS_QUARTZITE = "MATERIALS_QUARTZITE";
export const MATERIALS_FIRE_CLAY = "MATERIALS_FIRE_CLAY";
export const MATERIALS_BENTONITE = "MATERIALS_BENTONITE";
export const MATERIALS_PORCELAIN = "MATERIALS_PORCELAIN";
export const MATERIALS_CAST_IRON = "MATERIALS_CAST_IRON";
export const MATERIALS_CELLULOSE = "MATERIALS_CELLULOSE";
export const MATERIALS_WHITE_OAK = "MATERIALS_WHITE_OAK";
export const MATERIALS_HORSEHAIR = "MATERIALS_HORSEHAIR";
export const MATERIALS_POLYAMIDE = "MATERIALS_POLYAMIDE";
export const MATERIALS_POLYESTER = "MATERIALS_POLYESTER";
export const MATERIALS_COAL_TARS = "MATERIALS_COAL_TARS";
export const MATERIALS_SEA_WATER = "MATERIALS_SEA_WATER";
export const MATERIALS_ACETYLENE = "MATERIALS_ACETYLENE";
export const MATERIALS_IRON_DESC = "MATERIALS_IRON_DESC";
export const MATERIALS_ZINC_DESC = "MATERIALS_ZINC_DESC";
export const MATERIALS_GOLD_DESC = "MATERIALS_GOLD_DESC";
export const MATERIALS_LEAD_DESC = "MATERIALS_LEAD_DESC";
export const MATERIALS_NEON_DESC = "MATERIALS_NEON_DESC";
export const MATERIALS_ONYX_DESC = "MATERIALS_ONYX_DESC";
export const MATERIALS_LIME_DESC = "MATERIALS_LIME_DESC";
export const MATERIALS_TUFA_DESC = "MATERIALS_TUFA_DESC";
export const MATERIALS_CLAY_DESC = "MATERIALS_CLAY_DESC";
export const MATERIALS_SOIL_DESC = "MATERIALS_SOIL_DESC";
export const MATERIALS_MICA_DESC = "MATERIALS_MICA_DESC";
export const MATERIALS_SAND_DESC = "MATERIALS_SAND_DESC";
export const MATERIALS_COIR_DESC = "MATERIALS_COIR_DESC";
export const MATERIALS_JUTE_DESC = "MATERIALS_JUTE_DESC";
export const MATERIALS_PULP_DESC = "MATERIALS_PULP_DESC";
export const MATERIALS_CORK_DESC = "MATERIALS_CORK_DESC";
export const MATERIALS_WOOL_DESC = "MATERIALS_WOOL_DESC";
export const MATERIALS_SILK_DESC = "MATERIALS_SILK_DESC";
export const MATERIALS_ACID_DESC = "MATERIALS_ACID_DESC";
export const ALARM_PRESET_CUSTOM = "ALARM_PRESET_CUSTOM";
export const GRID_FILTER_UNIFIER = "GRID_FILTER_UNIFIER";
export const PRED_OPEN_DAY_LABEL = "PRED_OPEN_DAY_LABEL";
export const AFFECT_RETAIL_BOOKS = "AFFECT_RETAIL_BOOKS";
export const AFFECT_RETAIL_SHOES = "AFFECT_RETAIL_SHOES";
export const PRED_TRAINING_MODEL = "PRED_TRAINING_MODEL";
export const MAILING_ALARMS_FREQ = "MAILING_ALARMS_FREQ";
export const MAILING_ALARM_TITLE = "MAILING_ALARM_TITLE";
export const PRED_CONFIG_WEEKDAY = "PRED_CONFIG_WEEKDAY";
export const PRED_CONFIG_DATASET = "PRED_CONFIG_DATASET";
export const TABLE_EMP_MOVE_ELEM = "TABLE_EMP_MOVE_ELEM";
export const GLOBAL_EQUIP_IMPORT = "GLOBAL_EQUIP_IMPORT";
export const AG_LOC_DESC_WARNING = "AG_LOC_DESC_WARNING";
export const AG_TABLE_STATE_SIDE = "AG_TABLE_STATE_SIDE";
export const ALERT_LINK_DUPE_MSG = "ALERT_LINK_DUPE_MSG";
export const ALERT_STATUS_URGENT = "ALERT_STATUS_URGENT";
export const ANNOT_DRAW_SELECTOR = "ANNOT_DRAW_SELECTOR";
export const ANNOT_REQUIRED_NAME = "ANNOT_REQUIRED_NAME";
export const APS_ENERGY_PRODUCED = "APS_ENERGY_PRODUCED";
export const APS_EQUIP_YEAR_LEFT = "APS_EQUIP_YEAR_LEFT";
export const APS_SHOW_VIEW_TABLE = "APS_SHOW_VIEW_TABLE";
export const AR_CANCEL_RECORDING = "AR_CANCEL_RECORDING";
export const AUTH_OVERSIGHT_MAIN = "AUTH_OVERSIGHT_MAIN";
export const AUTH_OVERSIGHT_TALK = "AUTH_OVERSIGHT_TALK";
export const AUTH_SIGNIN_CLOSURE = "AUTH_SIGNIN_CLOSURE";
export const AUTH_SIGNIN_SUBJECT = "AUTH_SIGNIN_SUBJECT";
export const BG_Y_AXIS_MAX_LABEL = "BG_Y_AXIS_MAX_LABEL";
export const BG_Y_AXIS_MIN_LABEL = "BG_Y_AXIS_MIN_LABEL";
export const BR_B_ADD_HOPPER_EXT = "BR_B_ADD_HOPPER_EXT";
export const BR_ERROR_BUILD_NAME = "BR_ERROR_BUILD_NAME";
export const BR_ERROR_ENTERPRISE = "BR_ERROR_ENTERPRISE";
export const BR_FLOOR_REF_FORMAT = "BR_FLOOR_REF_FORMAT";
export const BR_TAB_LABEL_BOUCHE = "BR_TAB_LABEL_BOUCHE";
export const BR_TAB_LABEL_FLOORS = "BR_TAB_LABEL_FLOORS";
export const CFQ_ERROR_FORM_LOAD = "CFQ_ERROR_FORM_LOAD";
export const CFQ_QUOT_UNASSIGNED = "CFQ_QUOT_UNASSIGNED";
export const CFQ_WORD_GENERATION = "CFQ_WORD_GENERATION";
export const CONTRACT_INDEXATION = "CONTRACT_INDEXATION";
export const COVID_FORM_TAB_NAME = "COVID_FORM_TAB_NAME";
export const CREAT_SUB_EQUIPMENT = "CREAT_SUB_EQUIPMENT";
export const DATASET_ADD_FORMULA = "DATASET_ADD_FORMULA";
export const DATASET_AGGREGATION = "DATASET_AGGREGATION";
export const DATASET_COL_NEW_VAL = "DATASET_COL_NEW_VAL";
export const DATASET_COL_OLD_VAL = "DATASET_COL_OLD_VAL";
export const DATASET_CREATE_DATA = "DATASET_CREATE_DATA";
export const DATASET_IMPORT_DATA = "DATASET_IMPORT_DATA";
export const DATASET_LINK_OUTPUT = "DATASET_LINK_OUTPUT";
export const DATASET_LINK_REMOVE = "DATASET_LINK_REMOVE";
export const DIA_EDIT_MODE_QUEUE = "DIA_EDIT_MODE_QUEUE";
export const DIA_SHOW_TAG_VALUES = "DIA_SHOW_TAG_VALUES";
export const ELEM_BASE_CRITICITY = "ELEM_BASE_CRITICITY";
export const EMP_TABLE_N_CREATED = "EMP_TABLE_N_CREATED";
export const EQUIP_TAB_FORM_MISC = "EQUIP_TAB_FORM_MISC";
export const EQUIP_TAB_FORM_PERF = "EQUIP_TAB_FORM_PERF";
export const ER_EVENT_NOT_EXISTS = "ER_EVENT_NOT_EXISTS";
export const ERR_404_RETURN_HOME = "ERR_404_RETURN_HOME";
export const FE_NO_TAG_AUTOMATIC = "FE_NO_TAG_AUTOMATIC";
export const FORM_DELETE_CONFIRM = "FORM_DELETE_CONFIRM";
export const G_EXPLOITATION_UNIT = "G_EXPLOITATION_UNIT";
export const GLOBAL_ADD_LOCATION = "GLOBAL_ADD_LOCATION";
export const GLOBAL_ARCHITECTURE = "GLOBAL_ARCHITECTURE";
export const GLOBAL_CREATED_DATE = "GLOBAL_CREATED_DATE";
export const GLOBAL_ERROR_DELETE = "GLOBAL_ERROR_DELETE";
export const GLOBAL_ERROR_UPDATE = "GLOBAL_ERROR_UPDATE";
export const GLOBAL_FLOOR_NUMBER = "GLOBAL_FLOOR_NUMBER";
export const GLOBAL_LABEL_ENTITY = "GLOBAL_LABEL_ENTITY";
export const GLOBAL_LABEL_PARCEL = "GLOBAL_LABEL_PARCEL";
export const GLOBAL_LABEL_RENTER = "GLOBAL_LABEL_RENTER";
export const GLOBAL_MATRIX_LABEL = "GLOBAL_MATRIX_LABEL";
export const GLOBAL_NEW_LOCATION = "GLOBAL_NEW_LOCATION";
export const GLOBAL_NO_SELECTION = "GLOBAL_NO_SELECTION";
export const GLOBAL_NO_X_CONTEXT = "GLOBAL_NO_X_CONTEXT";
export const GLOBAL_SCAN_QR_CODE = "GLOBAL_SCAN_QR_CODE";
export const GLOBAL_SURFACE_HEAT = "GLOBAL_SURFACE_HEAT";
export const GLOBAL_SURFACE_RENT = "GLOBAL_SURFACE_RENT";
export const GP_BUILD_TAB_ENERGY = "GP_BUILD_TAB_ENERGY";
export const GP_BUILD_TAB_ENTITY = "GP_BUILD_TAB_ENTITY";
export const GP_BUILD_TAB_GLOBAL = "GP_BUILD_TAB_GLOBAL";
export const ID_CARD_PUBLIC_LINK = "ID_CARD_PUBLIC_LINK";
export const ID_CARD_TICKET_WORD = "ID_CARD_TICKET_WORD";
export const KPI_API_CRIT_SPREAD = "KPI_API_CRIT_SPREAD";
export const KPI_API_END_LIFE_TH = "KPI_API_END_LIFE_TH";
export const LT_LIGHT_TREE_TITLE = "LT_LIGHT_TREE_TITLE";
export const MATRIX_ADD_RESOURCE = "MATRIX_ADD_RESOURCE";
export const MATRIX_ADD_SELECTOR = "MATRIX_ADD_SELECTOR";
export const MATRIX_IS_FORM_PROP = 'MATRIX_IS_FORM_PROP';
export const MATRIX_PROP_CHECKED = "MATRIX_PROP_CHECKED";
export const MISSION_INTERV_MAIL = "MISSION_INTERV_MAIL";
export const MISSION_INTERV_NAME = "MISSION_INTERV_NAME";
export const MISSION_INTERV_ROLE = "MISSION_INTERV_ROLE";
export const MISSION_INVITE_SENT = "MISSION_INVITE_SENT";
export const MISSION_REPORT_DATE = "MISSION_REPORT_DATE";
export const MISSION_REPORT_FILE = "MISSION_REPORT_FILE";
export const MISSION_REPORT_NAME = "MISSION_REPORT_NAME";
export const MISSION_REPORT_NOTE = "MISSION_REPORT_NOTE";
export const MISSION_REPORT_SEND = "MISSION_REPORT_SEND";
export const MISSION_REPORT_SENT = "MISSION_REPORT_SENT";
export const MISSION_TASK_REPORT = "MISSION_TASK_REPORT";
export const NAV_RECENT_MISSIONS = "NAV_RECENT_MISSIONS";
export const NAV_SELECTION_SAVED = "NAV_SELECTION_SAVED";
export const NOTE_SHOW_X_REPLIES = "NOTE_SHOW_X_REPLIES";
export const P_RESERVED_DURATION = "P_RESERVED_DURATION";
export const P_UNASSIGNED_TICKET = "P_UNASSIGNED_TICKET";
export const PANEL_REG_DOC_TABLE = "PANEL_REG_DOC_TABLE";
export const PARKING_IN_CAPACITY = "PARKING_IN_CAPACITY";
export const PARKING_UG_CAPACITY = "PARKING_UG_CAPACITY";
export const PORTFOLIO_LAST_YEAR = "PORTFOLIO_LAST_YEAR";
export const PWD_CHANGE_MAIL_MSG = "PWD_CHANGE_MAIL_MSG";
export const REG_CLAIMED_ACTIONS = "REG_CLAIMED_ACTIONS";
export const REG_FORM_CONTROLLER = "REG_FORM_CONTROLLER";
export const REG_FORM_INFRACTION = "REG_FORM_INFRACTION";
export const REG_FORM_PERIODICAL = "REG_FORM_PERIODICAL";
export const REG_GAMME_UNPRECISE = "REG_GAMME_UNPRECISE";
export const REG_INVALID_FORMULA = "REG_INVALID_FORMULA";
export const REG_INVALID_SUBJECT = "REG_INVALID_SUBJECT";
export const REG_NO_MISSING_PROP = "REG_NO_MISSING_PROP";
export const REG_WRONG_OMNICLASS = "REG_WRONG_OMNICLASS";
export const REM_DEF_STATUS_MEMO = "REM_DEF_STATUS_MEMO";
export const REM_DEF_STATUS_OPEN = "REM_DEF_STATUS_OPEN";
export const REM_EQUIP_CRITICITY = "REM_EQUIP_CRITICITY";
export const REPORT_ERRORS_STILL = "REPORT_ERRORS_STILL";
export const RIGHT_EDIT_BUILDING = "RIGHT_EDIT_BUILDING";
export const RIGHT_MOVE_BUILDING = "RIGHT_MOVE_BUILDING";
export const RIGHT_TICKET_REPORT = "RIGHT_TICKET_REPORT";
export const RIGHT_WRITE_NRJ_TAG = "RIGHT_WRITE_NRJ_TAG";
export const SUPPORT_CAT_REQUEST = "SUPPORT_CAT_REQUEST";
export const SUPPORT_DESCRIPTION = "SUPPORT_DESCRIPTION";
export const TAB_BUILDING_EDITOR = "TAB_BUILDING_EDITOR";
export const TAB_REMARQUES_TABLE = "TAB_REMARQUES_TABLE";
export const TEMPLATE_NEW_ITEM_M = "TEMPLATE_NEW_ITEM_M";
export const TIME_FORMATTER_DESC = "TIME_FORMATTER_DESC";
export const TREE_TIME_OUT_TITLE = "TREE_TIME_OUT_TITLE";
export const TS_CONFIRM_ABSOLUTE = "TS_CONFIRM_ABSOLUTE";
export const UPDATE_FORCE_CHANGE = "UPDATE_FORCE_CHANGE";
export const VD_REMARQUES_TITLES = "VD_REMARQUES_TITLES";
export const VD_SWITCH_ROW_PULSE = "VD_SWITCH_ROW_PULSE";
export const VD_TOOLTIP_TOT_NORM = "VD_TOOLTIP_TOT_NORM";
export const WARNING_NO_SUB_ELEM = "WARNING_NO_SUB_ELEM";
export const FAIL_DATA_IMPORT_PCS = "FAIL_DATA_IMPORT_PCS"
export const FORM_REG_STATUS_CODE = "FORM_REG_STATUS_CODE";
export const FORM_REG_STATUS_NAME = "FORM_REG_STATUS_NAME";
export const GAMME_FORM_OMNICLASS = "GAMME_FORM_OMNICLASS";
export const TABLE_GAMME_REASSIGN = "TABLE_GAMME_REASSIGN";
export const DASH_ENV_FILTER_TAGS = "DASH_ENV_FILTER_TAGS";
export const OPTION_CODE_EMP_TYPE = "OPTION_CODE_EMP_TYPE";
export const ALLOW_RSC01_MISSIONS = "ALLOW_RSC01_MISSIONS";
export const TAXO_MISSION_NO_FUEL = "TAXO_MISSION_NO_FUEL";
export const BUBBLE_FORM_EXP_DATE = "BUBBLE_FORM_EXP_DATE";
export const BUBBLE_FORM_DURATION = "BUBBLE_FORM_DURATION";
export const DATA_CORR_SELECT_ROW = "DATA_CORR_SELECT_ROW";
export const DATA_CORR_CORRECTION = "DATA_CORR_CORRECTION";
export const DATA_CORR_STATION_ID = "DATA_CORR_STATION_ID";
export const TICKET_PARENT_ACTION = "TICKET_PARENT_ACTION";
export const NRJ_REPORT_API_INTRO = "NRJ_REPORT_API_INTRO";
export const NRJ_REPORT_API_TAB_1 = "NRJ_REPORT_API_TAB_1";
export const NRJ_REPORT_API_TAB_2 = "NRJ_REPORT_API_TAB_2";
export const NRJ_REPORT_API_TAB_3 = "NRJ_REPORT_API_TAB_3";
export const NRJ_REPORT_API_TAB_4 = "NRJ_REPORT_API_TAB_4";
export const NRJ_REPORT_API_TAB_5 = "NRJ_REPORT_API_TAB_5";
export const NRJ_REPORT_API_TAB_6 = "NRJ_REPORT_API_TAB_6";
export const NRJ_REPORT_API_TAB_7 = "NRJ_REPORT_API_TAB_7";
export const NRJ_REPORT_API_TAB_8 = "NRJ_REPORT_API_TAB_8";
export const NRJ_REPORT_API_BY_EU = "NRJ_REPORT_API_BY_EU";
export const NRJ_REPORT_API_TAB_9 = "NRJ_REPORT_API_TAB_9";
export const JS_NRJ_GLOBAL_OTHERS = "JS_NRJ_GLOBAL_OTHERS";
export const GAMME_TABLE_LIFESPAN = "GAMME_TABLE_LIFESPAN";
export const EQUIP_COM_MODBUS_485 = "EQUIP_COM_MODBUS_485";
export const EQUIP_COM_BACNET_485 = "EQUIP_COM_BACNET_485";
export const ACTIVE_MAT_INVENTORY = "ACTIVE_MAT_INVENTORY";
export const RED_FLAG_GEN_COMMENT = "RED_FLAG_GEN_COMMENT";
export const GLOBAL_DATE_IN_RANGE = "GLOBAL_DATE_IN_RANGE";
export const NRJ_DASH_NO_DATASETS = "NRJ_DASH_NO_DATASETS";
export const CERT_HISTORY_MISSION = "CERT_HISTORY_MISSION";
export const EDL_EXCEL_COL_NUMBER = "EDL_EXCEL_COL_NUMBER";
export const EDL_EXCEL_COL_VALUES = "EDL_EXCEL_COL_VALUES";
export const EDL_EXCEL_COL_IMPACT = "EDL_EXCEL_COL_IMPACT";
export const EDL_EXCEL_COL_YEAR_2 = "EDL_EXCEL_COL_YEAR_2";
export const EDL_EXCEL_COL_YEAR_5 = "EDL_EXCEL_COL_YEAR_5";
export const MATERIALS_MOLYBDENUM = "MATERIALS_MOLYBDENUM";
export const MATERIALS_METALLOIDS = "MATERIALS_METALLOIDS";
export const MATERIALS_NON_METALS = "MATERIALS_NON_METALS";
export const MATERIALS_HELIUM_GAS = "MATERIALS_HELIUM_GAS";
export const MATERIALS_PHOSPHORUS = "MATERIALS_PHOSPHORUS";
export const MATERIALS_TRAVERTINE = "MATERIALS_TRAVERTINE";
export const MATERIALS_TERRACOTTA = "MATERIALS_TERRACOTTA";
export const MATERIALS_RAIN_WATER = "MATERIALS_RAIN_WATER";
export const MATERIALS_LUBRICANTS = "MATERIALS_LUBRICANTS";
export const MATERIALS_OXYGEN_GAS = "MATERIALS_OXYGEN_GAS";
export const MATERIALS_BORON_DESC = "MATERIALS_BORON_DESC";
export const MATERIALS_ARGON_DESC = "MATERIALS_ARGON_DESC";
export const MATERIALS_XENON_DESC = "MATERIALS_XENON_DESC";
export const MATERIALS_CHALK_DESC = "MATERIALS_CHALK_DESC";
export const MATERIALS_SHALE_DESC = "MATERIALS_SHALE_DESC";
export const MATERIALS_SLATE_DESC = "MATERIALS_SLATE_DESC";
export const MATERIALS_BRICK_DESC = "MATERIALS_BRICK_DESC";
export const MATERIALS_GLASS_DESC = "MATERIALS_GLASS_DESC";
export const MATERIALS_BRASS_DESC = "MATERIALS_BRASS_DESC";
export const MATERIALS_ZAMAK_DESC = "MATERIALS_ZAMAK_DESC";
export const MATERIALS_MAZAC_DESC = "MATERIALS_MAZAC_DESC";
export const MATERIALS_TERNE_DESC = "MATERIALS_TERNE_DESC";
export const MATERIALS_LINEN_DESC = "MATERIALS_LINEN_DESC";
export const MATERIALS_REEDS_DESC = "MATERIALS_REEDS_DESC";
export const MATERIALS_STRAW_DESC = "MATERIALS_STRAW_DESC";
export const MATERIALS_PAPER_DESC = "MATERIALS_PAPER_DESC";
export const MATERIALS_GRASS_DESC = "MATERIALS_GRASS_DESC";
export const MATERIALS_MAPLE_DESC = "MATERIALS_MAPLE_DESC";
export const MATERIALS_ACIDS_DESC = "MATERIALS_ACIDS_DESC";
export const MATERIALS_BASES_DESC = "MATERIALS_BASES_DESC";
export const MATERIALS_SALTS_DESC = "MATERIALS_SALTS_DESC";
export const MATERIALS_EPOXY_DESC = "MATERIALS_EPOXY_DESC";
export const MATERIALS_PVC_U_DESC = "MATERIALS_PVC_U_DESC";
export const MATERIALS_VINYL_DESC = "MATERIALS_VINYL_DESC";
export const MATERIALS_WAXES_DESC = "MATERIALS_WAXES_DESC";
export const MATERIALS_GASES_DESC = "MATERIALS_GASES_DESC";
export const NRJ_MISSION_CONS_GEN = "NRJ_MISSION_CONS_GEN";
export const NRJ_MISSION_TAB_CONS = "NRJ_MISSION_TAB_CONS";
export const DIA_EDIT_MODE_IN_USE = "DIA_EDIT_MODE_IN_USE";
export const TAG_MAPPER_RESET_ROW = "TAG_MAPPER_RESET_ROW";
export const GRID_FILTER_OP_RANGE = "GRID_FILTER_OP_RANGE";
export const GRID_FILTER_OP_BLANK = "GRID_FILTER_OP_BLANK";
export const PRED_OPEN_EARLY_OPEN = "PRED_OPEN_EARLY_OPEN";
export const PRED_OPEN_LATE_CLOSE = "PRED_OPEN_LATE_CLOSE";
export const AFFECT_RETAIL_FROZEN = "AFFECT_RETAIL_FROZEN";
export const AFFECT_RETAIL_LIQUOR = "AFFECT_RETAIL_LIQUOR";
export const AFFECT_RETAIL_MARKET = "AFFECT_RETAIL_MARKET";
export const AFFECT_PUBLIC_POLICE = "AFFECT_PUBLIC_POLICE";
export const AFFECT_SPORT_FITNESS = "AFFECT_SPORT_FITNESS";
export const PRED_VALIDATE_UPDATE = "PRED_VALIDATE_UPDATE";
export const PRED_PRESET_NAME_MID = "PRED_PRESET_NAME_MID";
export const PRED_PRESET_DESC_MID = "PRED_PRESET_DESC_MID";
export const PRED_PRESET_NAME_LOW = "PRED_PRESET_NAME_LOW";
export const PRED_PRESET_DESC_LOW = "PRED_PRESET_DESC_LOW";
export const MAILING_ALARMS_ALARM = "MAILING_ALARMS_ALARM";
export const MAILING_ALARMS_USERS = "MAILING_ALARMS_USERS";
export const PRED_QUALITY_EXP_VAR = "PRED_QUALITY_EXP_VAR";
export const PRED_QUALITY_PERIODS = "PRED_QUALITY_PERIODS";
export const PRED_CONFIG_HOUR_MIN = "PRED_CONFIG_HOUR_MIN";
export const PRED_CONFIG_HOLIDAYS = "PRED_CONFIG_HOLIDAYS";
export const PRED_CONFIG_TEMP_EXT = "PRED_CONFIG_TEMP_EXT";
export const PRED_CONFIG_VAR_MISC = "PRED_CONFIG_VAR_MISC";
export const PRED_CONFIG_TH_USAGE = "PRED_CONFIG_TH_USAGE";
export const PRED_CONFIG_VAR_TIME = "PRED_CONFIG_VAR_TIME";
export const ACCESS_USER_IS_ADMIN = "ACCESS_USER_IS_ADMIN";
export const ACCESS_USER_NEW_ROLE = "ACCESS_USER_NEW_ROLE";
export const ACCESS_USERS_PROFILE = "ACCESS_USERS_PROFILE";
export const ACTION_REG_LAST_DATE = "ACTION_REG_LAST_DATE";
export const ACTION_SHOW_SPECIFIC = "ACTION_SHOW_SPECIFIC";
export const ADD_EQUIP_GAMME_VOID = "ADD_EQUIP_GAMME_VOID";
export const AFFECT_ENTERTAINMENT = "AFFECT_ENTERTAINMENT";
export const AG_TABLE_FILTER_SIDE = "AG_TABLE_FILTER_SIDE";
export const ALARM_TYPE_THRESHOLD = "ALARM_TYPE_THRESHOLD";
export const ALERT_STATUS_INVALID = "ALERT_STATUS_INVALID";
export const ANNOT_POINT_SELECTOR = "ANNOT_POINT_SELECTOR";
export const APS_METEO_FETCH_WAIT = "APS_METEO_FETCH_WAIT";
export const APS_NOT_EXISTS_TITLE = "APS_NOT_EXISTS_TITLE";
export const AUTH_OVERSIGHT_TIMER = "AUTH_OVERSIGHT_TIMER";
export const AUTH_OVERSIGHT_TITLE = "AUTH_OVERSIGHT_TITLE";
export const BR_FLOOR_NAME_FORMAT = "BR_FLOOR_NAME_FORMAT";
export const BR_FLOOR_NB_RESTROOM = "BR_FLOOR_NB_RESTROOM";
export const BR_GP_DELETE_MESSAGE = "BR_GP_DELETE_MESSAGE";
export const BR_TAB_LABEL_DETAILS = "BR_TAB_LABEL_DETAILS";
export const BR_TAB_LABEL_GENERAL = "BR_TAB_LABEL_GENERAL";
export const BR_TAB_LABEL_HOPPERS = "BR_TAB_LABEL_HOPPERS";
export const BUILD_EDIT_ELEM_TECH = "BUILD_EDIT_ELEM_TECH";
export const BUILD_ON_FIELD_LEVEL = "BUILD_ON_FIELD_LEVEL";
export const BUILD_SURFACE_OTHERS = "BUILD_SURFACE_OTHERS";
export const CDM_DATA_DEFAULT_CAT = "CDM_DATA_DEFAULT_CAT";
export const CELL_IS_NOT_RENTABLE = "CELL_IS_NOT_RENTABLE";
export const CFQ_TITLE_SAVE_NOTIF = "CFQ_TITLE_SAVE_NOTIF";
export const CONTRACT_FACTURATION = "CONTRACT_FACTURATION";
export const CONTRACT_OBLIGATIONS = "CONTRACT_OBLIGATIONS";
export const DATA_ENTRY_ADD_TITLE = "DATA_ENTRY_ADD_TITLE";
export const DATASET_LINK_INPUT_D = "DATASET_LINK_INPUT_D";
export const DIA_CONFIG_BASE_LINK = "DIA_CONFIG_BASE_LINK";
export const DIA_CONFIG_HIDE_LINK = "DIA_CONFIG_HIDE_LINK";
export const DIA_GROUP_LINK_TYPES = "DIA_GROUP_LINK_TYPES";
export const DIA_LINK_CODE_HIDDEN = "DIA_LINK_CODE_HIDDEN";
export const DIA_NOTIF_KICKED_OUT = "DIA_NOTIF_KICKED_OUT";
export const DIA_ZOOM_PLUS_BUTTON = "DIA_ZOOM_PLUS_BUTTON";
export const EQUIP_PANEL_PERF_CAL = "EQUIP_PANEL_PERF_CAL";
export const EQUIP_PANEL_PERF_GEN = "EQUIP_PANEL_PERF_GEN";
export const EQUIP_PANEL_PHYS_EXT = "EQUIP_PANEL_PHYS_EXT";
export const EQUIP_PANEL_PHYS_GEN = "EQUIP_PANEL_PHYS_GEN";
export const EQUIP_STORE_CATEGORY = "EQUIP_STORE_CATEGORY";
export const EQUIP_TAB_FORM_STATE = "EQUIP_TAB_FORM_STATE";
export const FORM_GAMME_NOT_EQUIP = "FORM_GAMME_NOT_EQUIP";
export const FR_TAB_NO_COMPONENTS = "FR_TAB_NO_COMPONENTS";
export const G_ERROR_CONTENT_HELP = "G_ERROR_CONTENT_HELP";
export const G_ERROR_EMPTY_SELECT = "G_ERROR_EMPTY_SELECT";
export const G_TECHNIQUE_DEPLASSE = "G_TECHNIQUE_DEPLASSE";
export const GLOBAL_DELET_N_ITEMS = "GLOBAL_DELET_N_ITEMS";
export const GLOBAL_ELEMENT_LABEL = "GLOBAL_ELEMENT_LABEL";
export const GLOBAL_FAIL_GEN_FILE = "GLOBAL_FAIL_GEN_FILE";
export const GLOBAL_GAMME_UNKNOWN = "GLOBAL_GAMME_UNKNOWN";
export const GLOBAL_LABEL_CONTACT = "GLOBAL_LABEL_CONTACT";
export const GLOBAL_LABEL_PARKING = "GLOBAL_LABEL_PARKING";
export const GLOBAL_ONLY_SELECTED = "GLOBAL_ONLY_SELECTED";
export const GLOBAL_QUICK_INSERTS = "GLOBAL_QUICK_INSERTS";
export const GLOBAL_REGION_SINGLE = "GLOBAL_REGION_SINGLE";
export const GLOBAL_VALUE_INVALID = "GLOBAL_VALUE_INVALID";
export const GP_BUILD_TAB_HEATMAP = "GP_BUILD_TAB_HEATMAP";
export const GRAPH_TICKETS_CLOSED = "GRAPH_TICKETS_CLOSED";
export const ID_CARD_HIDE_OPTIONS = "ID_CARD_HIDE_OPTIONS";
export const ID_CARD_SELECT_BUILD = "ID_CARD_SELECT_BUILD";
export const ID_CARD_SHOW_OPTIONS = "ID_CARD_SHOW_OPTIONS";
export const KPI_API_END_LIFE_NOW = "KPI_API_END_LIFE_NOW";
export const KPI_API_NB_REM_LABEL = "KPI_API_NB_REM_LABEL";
export const KPI_API_REM_CRIT_LOW = "KPI_API_REM_CRIT_LOW";
export const KPI_API_REM_CRIT_MID = "KPI_API_REM_CRIT_MID";
export const MATRIX_ADD_CONDITION = "MATRIX_ADD_CONDITION";
export const MATRIX_CONDITION_NUM = "MATRIX_CONDITION_NUM";
export const MATRIX_SELECT_SCREEN = "MATRIX_SELECT_SCREEN";
export const MISSION_INTERV_OTHER = "MISSION_INTERV_OTHER";
export const MISSION_INTERV_TITLE = "MISSION_INTERV_TITLE";
export const MISSION_TYPE_EDL_REG = "MISSION_TYPE_EDL_REG";
export const NAV_CHANGE_SELECTION = "NAV_CHANGE_SELECTION";
export const NAV_CREATE_SELECTION = "NAV_CREATE_SELECTION";
export const NAV_EMPTY_PORTFOLIOS = "NAV_EMPTY_PORTFOLIOS";
export const NAV_RECENT_SELECTION = "NAV_RECENT_SELECTION";
export const NAV_TABLE_SELECT_ALL = "NAV_TABLE_SELECT_ALL";
export const NAVIGATOR_LOAD_HEAVY = "NAVIGATOR_LOAD_HEAVY";
export const NOTIF_NOTHING_IMPORT = "NOTIF_NOTHING_IMPORT";
export const PANEL_CONTRACT_TABLE = "PANEL_CONTRACT_TABLE";
export const PARKING_OUT_CAPACITY = "PARKING_OUT_CAPACITY";
export const PARKING_UG_NB_LEVELS = "PARKING_UG_NB_LEVELS";
export const PLACEHOLDER_NOTE_MSG = "PLACEHOLDER_NOTE_MSG";
export const PWD_CHANGE_MAIL_SENT = "PWD_CHANGE_MAIL_SENT";
export const REG_FAILED_CONDITION = "REG_FAILED_CONDITION";
export const REG_INVALID_RESOURCE = "REG_INVALID_RESOURCE";
export const REG_LOAD_RECALCULATE = "REG_LOAD_RECALCULATE";
export const REG_VALIDITY_EXPIRED = "REG_VALIDITY_EXPIRED";
export const REM_DEF_COST_MINI_GT = "REM_DEF_COST_MINI_GT";
export const REM_DEF_STATUS_CLOSE = "REM_DEF_STATUS_CLOSE";
export const REM_TABLE_ADD_ACTION = "REM_TABLE_ADD_ACTION";
export const REPORT_FINAL_VERSION = "REPORT_FINAL_VERSION";
export const ROLE_FORM_IS_GENERIC = "ROLE_FORM_IS_GENERIC";
export const SCH_SAVE_BEFORE_QUIT = "SCH_SAVE_BEFORE_QUIT";
export const T_REQUIRED_EQUIPMENT = "T_REQUIRED_EQUIPMENT";
export const TAB_ACTION_DASHBOARD = "TAB_ACTION_DASHBOARD";
export const TAB_ACTION_SIMULATOR = "TAB_ACTION_SIMULATOR";
export const TAB_ALERTS_TRIGGERED = "TAB_ALERTS_TRIGGERED";
export const TIME_FORMATTER_TITLE = "TIME_FORMATTER_TITLE";
export const TIME_FORMATTER_VALID = "TIME_FORMATTER_VALID";
export const UPDATE_VALUE_UNFRESH = "UPDATE_VALUE_UNFRESH";
export const VD_CALCULATE_DEFAULT = "VD_CALCULATE_DEFAULT";
export const APS_ENERGY_PRODUCTION = "APS_ENERGY_PRODUCTION"
export const FORM_REG_STATUS_COLOR = "FORM_REG_STATUS_COLOR";
export const DATA_ENTRY_TABLE_NAME = "DATA_ENTRY_TABLE_NAME";
export const DASH_ENV_OPTION_LABEL = "DASH_ENV_OPTION_LABEL";
export const SUPERSET_DASH_JUMP_TO = "SUPERSET_DASH_JUMP_TO";
export const OPTION_CODE_MATERIALS = "OPTION_CODE_MATERIALS";
export const OPTION_CODE_APP_PAGES = "OPTION_CODE_APP_PAGES";
export const DIA_BUILDING_CREATION = "DIA_BUILDING_CREATION";
export const OPTIONS_TABLE_VAR_REF = "OPTIONS_TABLE_VAR_REF";
export const EQUIP_PANEL_TYPE_MONO = "EQUIP_PANEL_TYPE_MONO";
export const EQUIP_PANEL_TYPE_POLY = "EQUIP_PANEL_TYPE_POLY";
export const PRED_PRESET_DESC_COLD = "PRED_PRESET_DESC_COLD";
export const PRED_PRESET_NAME_COLD = "PRED_PRESET_NAME_COLD";
export const NRJ_DASH_EMPLACEMENTS = "NRJ_DASH_EMPLACEMENTS";
export const EPRA_DATASET_SELECTED = "EPRA_DATASET_SELECTED";
export const EQUIP_QR_CODE_UNKNOWN = "EQUIP_QR_CODE_UNKNOWN";
export const TAXO_MISSION_NZEB_TIP = "TAXO_MISSION_NZEB_TIP";
export const TAXO_MISSION_NZEB_NEW = "TAXO_MISSION_NZEB_NEW";
export const EU_TAXONOMY_EPC_PROOF = "EU_TAXONOMY_EPC_PROOF";
export const ACTION_CONTROL_ENTITY = "ACTION_CONTROL_ENTITY";
export const DATA_CORR_SELECT_ROWS = "DATA_CORR_SELECT_ROWS";
export const NRJ_REPORT_API_LIGHTS = "NRJ_REPORT_API_LIGHTS";
export const NRJ_REPORT_API_TAB_11 = "NRJ_REPORT_API_TAB_11";
export const NRJ_REPORT_API_TAB_12 = "NRJ_REPORT_API_TAB_12";
export const NRJ_REPORT_API_TAB_13 = "NRJ_REPORT_API_TAB_13";
export const NRJ_REPORT_API_NO_VAT = "NRJ_REPORT_API_NO_VAT";
export const JS_NRJ_COST_LABEL_TXT = "JS_NRJ_COST_LABEL_TXT";
export const RED_FLAG_CAT_AUTO_REG = "RED_FLAG_CAT_AUTO_REG";
export const RED_FLAG_CAT_SANITARY = "RED_FLAG_CAT_SANITARY";
export const NRJ_DASH_SUB_MENU_BAR = "NRJ_DASH_SUB_MENU_BAR";
export const NRJ_DASH_DATASETS_TIP = "NRJ_DASH_DATASETS_TIP";
export const CERT_FORM_CERTIFY_FOR = "CERT_FORM_CERTIFY_FOR";
export const CERT_FORM_DESCRIPTION = "CERT_FORM_DESCRIPTION";
export const CERT_CLICK_TO_CERTIFY = "CERT_CLICK_TO_CERTIFY";
export const REG_PROP_NAME_INVALID = "REG_PROP_NAME_INVALID";
export const REG_PROP_TYPE_INVALID = "REG_PROP_TYPE_INVALID";
export const EDL_EXCEL_COL_VETUSTY = "EDL_EXCEL_COL_VETUSTY";
export const EDL_EXCEL_COL_YEAR_10 = "EDL_EXCEL_COL_YEAR_10";
export const CAPEX_ORIGIN_FROM_REM = "CAPEX_ORIGIN_FROM_REM";
export const CAPEX_ORIGIN_FROM_REG = "CAPEX_ORIGIN_FROM_REG";
export const CAPEX_ORIGIN_AUTO_REG = "CAPEX_ORIGIN_AUTO_REG";
export const MATERIALS_LANTHANOIDS = "MATERIALS_LANTHANOIDS";
export const MATERIALS_NOBLE_GASES = "MATERIALS_NOBLE_GASES";
export const MATERIALS_SLAKED_LIME = "MATERIALS_SLAKED_LIME";
export const MATERIALS_VERMICULITE = "MATERIALS_VERMICULITE";
export const MATERIALS_EARTHENWARE = "MATERIALS_EARTHENWARE";
export const MATERIALS_FIRED_SHALE = "MATERIALS_FIRED_SHALE";
export const MATERIALS_ROCK_FIBERS = "MATERIALS_ROCK_FIBERS";
export const MATERIALS_ZINC_ALLOYS = "MATERIALS_ZINC_ALLOYS";
export const MATERIALS_LEAD_ALLOYS = "MATERIALS_LEAD_ALLOYS";
export const MATERIALS_POLYSTYRENE = "MATERIALS_POLYSTYRENE";
export const MATERIALS_POLYSULFIDE = "MATERIALS_POLYSULFIDE";
export const MATERIALS_WATER_VAPOR = "MATERIALS_WATER_VAPOR";
export const MATERIALS_TOXIC_GASES = "MATERIALS_TOXIC_GASES";
export const MATERIALS_REFRIGERANT = "MATERIALS_REFRIGERANT";
export const MATERIALS_METALS_DESC = "MATERIALS_METALS_DESC";
export const MATERIALS_NICKEL_DESC = "MATERIALS_NICKEL_DESC";
export const MATERIALS_COPPER_DESC = "MATERIALS_COPPER_DESC";
export const MATERIALS_SILVER_DESC = "MATERIALS_SILVER_DESC";
export const MATERIALS_IODINE_DESC = "MATERIALS_IODINE_DESC";
export const MATERIALS_HELIUM_DESC = "MATERIALS_HELIUM_DESC";
export const MATERIALS_CARBON_DESC = "MATERIALS_CARBON_DESC";
export const MATERIALS_OXYGEN_DESC = "MATERIALS_OXYGEN_DESC";
export const MATERIALS_SULFUR_DESC = "MATERIALS_SULFUR_DESC";
export const MATERIALS_QUARTZ_DESC = "MATERIALS_QUARTZ_DESC";
export const MATERIALS_BARITE_DESC = "MATERIALS_BARITE_DESC";
export const MATERIALS_MARBLE_DESC = "MATERIALS_MARBLE_DESC";
export const MATERIALS_GYPSUM_DESC = "MATERIALS_GYPSUM_DESC";
export const MATERIALS_CEMENT_DESC = "MATERIALS_CEMENT_DESC";
export const MATERIALS_CERMET_DESC = "MATERIALS_CERMET_DESC";
export const MATERIALS_BRONZE_DESC = "MATERIALS_BRONZE_DESC";
export const MATERIALS_SOLDER_DESC = "MATERIALS_SOLDER_DESC";
export const MATERIALS_FIBERS_DESC = "MATERIALS_FIBERS_DESC";
export const MATERIALS_COTTON_DESC = "MATERIALS_COTTON_DESC";
export const MATERIALS_BAMBOO_DESC = "MATERIALS_BAMBOO_DESC";
export const MATERIALS_TIMBER_DESC = "MATERIALS_TIMBER_DESC";
export const MATERIALS_CHERRY_DESC = "MATERIALS_CHERRY_DESC";
export const MATERIALS_TALLOW_DESC = "MATERIALS_TALLOW_DESC";
export const MATERIALS_ALKALI_DESC = "MATERIALS_ALKALI_DESC";
export const MATERIALS_BUTANE_DESC = "MATERIALS_BUTANE_DESC";
export const MATERIALS_ARSINE_DESC = "MATERIALS_ARSINE_DESC";
export const ACCESS_USER_ACTIVATED = "ACCESS_USER_ACTIVATED";
export const GRID_FILTER_OP_NOT_EQ = "GRID_FILTER_OP_NOT_EQ";
export const PRED_OPEN_HOURS_COUNT = "PRED_OPEN_HOURS_COUNT";
export const PRED_QUALITY_OPENINGS = "PRED_QUALITY_OPENINGS";
export const AFFECT_RETAIL_CLOTHES = "AFFECT_RETAIL_CLOTHES";
export const AFFECT_RETAIL_BUTCHER = "AFFECT_RETAIL_BUTCHER";
export const AFFECT_PUBLIC_LIBRARY = "AFFECT_PUBLIC_LIBRARY";
export const AFFECT_SPORT_ICE_RINK = "AFFECT_SPORT_ICE_RINK";
export const PRED_NEW_FROM_DATASET = "PRED_NEW_FROM_DATASET";
export const PRED_PRESET_NAME_HIGH = "PRED_PRESET_NAME_HIGH";
export const PRED_PRESET_DESC_HIGH = "PRED_PRESET_DESC_HIGH";
export const RIGHT_WRITE_OWN_NOTES = "RIGHT_WRITE_OWN_NOTES";
export const PRED_THERMAL_ANALYSIS = "PRED_THERMAL_ANALYSIS";
export const ALARMS_MAILING_CREATE = "ALARMS_MAILING_CREATE";
export const PRED_QUALITY_VALIDATE = "PRED_QUALITY_VALIDATE";
export const PRED_VALIDATION_TITLE = "PRED_VALIDATION_TITLE";
export const CDM_REPORT_EXT_ANNEXE = "CDM_REPORT_EXT_ANNEXE";
export const ADDRESS_PLURAL_SINGLE = "ADDRESS_PLURAL_SINGLE";
export const ACCESS_ROLES_NO_ROLES = "ACCESS_ROLES_NO_ROLES";
export const ACCESS_USER_NO_SELECT = "ACCESS_USER_NO_SELECT";
export const ACCESS_USER_SELF_TREE = "ACCESS_USER_SELF_TREE";
export const ACCESS_USERS_TREE_ALL = "ACCESS_USERS_TREE_ALL";
export const ACTION_SHOW_ALL_ELEMS = "ACTION_SHOW_ALL_ELEMS";
export const AG_LOC_DELETE_CONFIRM = "AG_LOC_DELETE_CONFIRM";
export const AG_TABLE_COLUMNS_SIDE = "AG_TABLE_COLUMNS_SIDE";
export const ALARM_ASSIGN_DATASETS = "ALARM_ASSIGN_DATASETS";
export const ALERT_LINK_DUPE_TITLE = "ALERT_LINK_DUPE_TITLE";
export const ALERT_STATUS_TO_WATCH = "ALERT_STATUS_TO_WATCH";
export const ANNOT_POLYGON_ACTIONS = "ANNOT_POLYGON_ACTIONS";
export const APS_METEO_FETCH_ERROR = "APS_METEO_FETCH_ERROR";
export const APS_REPLACE_EMERGENCY = "APS_REPLACE_EMERGENCY";
export const APS_SHOW_ERROR_BANNER = "APS_SHOW_ERROR_BANNER";
export const AUTH_LINK_EXPLANATION = "AUTH_LINK_EXPLANATION";
export const AUTH_LINK_FOLLOW_LINK = "AUTH_LINK_FOLLOW_LINK";
export const AUTH_NEW_USER_SUCCESS = "AUTH_NEW_USER_SUCCESS";
export const BR_B_ADD_HOPPER_PULSE = "BR_B_ADD_HOPPER_PULSE";
export const BR_ERROR_LOCATION_REF = "BR_ERROR_LOCATION_REF";
export const BR_FLOOR_DEFAULT_AREA = "BR_FLOOR_DEFAULT_AREA";
export const BR_FLOOR_DEFAULT_ICON = "BR_FLOOR_DEFAULT_ICON";
export const BR_FLOOR_HAS_RESTROOM = "BR_FLOOR_HAS_RESTROOM";
export const BR_FLOOR_REINITIALIZE = "BR_FLOOR_REINITIALIZE";
export const BUILD_OFF_FIELD_LEVEL = "BUILD_OFF_FIELD_LEVEL";
export const BUILD_PERMIT_END_DATE = "BUILD_PERMIT_END_DATE";
export const CDM_MISSION_REG_PANEL = "CDM_MISSION_REG_PANEL";
export const CDM_MISSION_REM_PANEL = "CDM_MISSION_REM_PANEL";
export const CFS_FORM_FILLED_WRONG = "CFS_FORM_FILLED_WRONG";
export const CONTRACT_RECONDUCTION = "CONTRACT_RECONDUCTION";
export const DATA_CHARTS_VALUE_MSG = "DATA_CHARTS_VALUE_MSG";
export const DATASET_COL_EDIT_DATE = "DATASET_COL_EDIT_DATE";
export const DATASET_COL_EDIT_USER = "DATASET_COL_EDIT_USER";
export const DATASET_LINK_OUTPUT_D = "DATASET_LINK_OUTPUT_D";
export const DIA_CONFIG_ANNOT_LINK = "DIA_CONFIG_ANNOT_LINK";
export const DIA_NOTIF_ROOT_OUTPUT = "DIA_NOTIF_ROOT_OUTPUT";
export const DIA_NOTIF_TREE_LOCKED = "DIA_NOTIF_TREE_LOCKED";
export const DIA_WARN_NO_LINK_PAIR = "DIA_WARN_NO_LINK_PAIR";
export const DIA_ZOOM_MINUS_BUTTON = "DIA_ZOOM_MINUS_BUTTON";
export const EMP_TAB_DEFAULT_BUILD = "EMP_TAB_DEFAULT_BUILD";
export const EQUIP_PANEL_PHYS_HEAT = "EQUIP_PANEL_PHYS_HEAT";
export const EQUIP_PANEL_PHYS_VASE = "EQUIP_PANEL_PHYS_VASE";
export const EQUIP_STORE_OMNICLASS = "EQUIP_STORE_OMNICLASS";
export const EQUIP_STORE_TABLE_NEW = "EQUIP_STORE_TABLE_NEW";
export const ER_CLOSE_TICKET_FRAME = "ER_CLOSE_TICKET_FRAME";
export const ER_NO_TICKET_SELECTED = "ER_NO_TICKET_SELECTED";
export const FAV_ERROR_SET_DEFAULT = "FAV_ERROR_SET_DEFAULT";
export const FORM_FIELD_NOT_UNIQUE = "FORM_FIELD_NOT_UNIQUE";
export const GLOBAL_CONFIRM_DELETE = "GLOBAL_CONFIRM_DELETE";
export const GLOBAL_CONFIRM_PARAMS = "GLOBAL_CONFIRM_PARAMS";
export const GLOBAL_CONSTRUCT_DATE = "GLOBAL_CONSTRUCT_DATE";
export const GLOBAL_ERROR_OCCURRED = "GLOBAL_ERROR_OCCURRED";
export const GLOBAL_FIND_NO_PARENT = "GLOBAL_FIND_NO_PARENT";
export const GLOBAL_HAVE_NOT_RIGHT = "GLOBAL_HAVE_NOT_RIGHT";
export const GLOBAL_LABEL_ENSEIGNE = "GLOBAL_LABEL_ENSEIGNE";
export const GLOBAL_NO_EQUIP_GAMME = "GLOBAL_NO_EQUIP_GAMME";
export const GLOBAL_REQUIRED_FIELD = "GLOBAL_REQUIRED_FIELD";
export const GLOBAL_RESOURCE_LABEL = "GLOBAL_RESOURCE_LABEL";
export const GLOBAL_SAVE_AND_QUITE = "GLOBAL_SAVE_AND_QUITE";
export const GLOBAL_SERVICE_WORKER = "GLOBAL_SERVICE_WORKER";
export const GLOBAL_SHOW_MINI_TREE = "GLOBAL_SHOW_MINI_TREE";
export const GLOBAL_SUCCESS_UPDATE = "GLOBAL_SUCCESS_UPDATE";
export const GLOBAL_SURFACE_RENTED = "GLOBAL_SURFACE_RENTED";
export const GLOBAL_VAL_LOWER_THAN = "GLOBAL_VAL_LOWER_THAN";
export const GRAPH_TICKETS_HISTORY = "GRAPH_TICKETS_HISTORY";
export const GRAPH_TICKETS_IN_TIME = "GRAPH_TICKETS_IN_TIME";
export const GRAPH_TICKETS_NB_OPEN = "GRAPH_TICKETS_NB_OPEN";
export const GU_SUPERIORS_REQUIRED = "GU_SUPERIORS_REQUIRED";
export const IMPORT_EQUIP_EMP_INFO = "IMPORT_EQUIP_EMP_INFO";
export const KPI_API_COMMENT_LABEL = "KPI_API_COMMENT_LABEL";
export const KPI_API_EQUIP_VETUSTY = "KPI_API_EQUIP_VETUSTY";
export const KPI_API_LIMIT_CRIT_OK = "KPI_API_LIMIT_CRIT_OK";
export const KPI_API_REM_CRIT_HIGH = "KPI_API_REM_CRIT_HIGH";
export const KPI_API_REM_CRIT_NONE = "KPI_API_REM_CRIT_NONE";
export const KPI_API_VETUSTY_LEVEL = "KPI_API_VETUSTY_LEVEL";
export const MISSION_CDM_NO_ACTIVE = "MISSION_CDM_NO_ACTIVE";
export const MISSION_CDM_REMARQUES = "MISSION_CDM_REMARQUES";
export const MISSION_INVITE_DENIED = "MISSION_INVITE_DENIED";
export const MISSION_VERSION_TITLE = "MISSION_VERSION_TITLE";
export const NAV_EMPTY_SUB_CONTEXT = "NAV_EMPTY_SUB_CONTEXT";
export const NAV_TABLE_ITEM_SELECT = "NAV_TABLE_ITEM_SELECT";
export const NAVIGATOR_ADD_MISSION = "NAVIGATOR_ADD_MISSION";
export const NOTIF_CLIPBOARD_TITLE = "NOTIF_CLIPBOARD_TITLE";
export const PANEL_BUILDING_EDITOR = "PANEL_BUILDING_EDITOR";
export const PANEL_REMARQUES_TABLE = "PANEL_REMARQUES_TABLE";
export const POP_UP_FORM_PANEL_KPI = "POP_UP_FORM_PANEL_KPI";
export const PWD_CHANGE_MAIL_TITLE = "PWD_CHANGE_MAIL_TITLE";
export const REG_ATTEMPT_REMAINING = "REG_ATTEMPT_REMAINING";
export const REG_DELETE_ACTIVE_NOW = "REG_DELETE_ACTIVE_NOW";
export const REG_POTENTIAL_ACTIONS = "REG_POTENTIAL_ACTIONS";
export const REG_TABLE_MOST_RECENT = "REG_TABLE_MOST_RECENT";
export const REM_CAT_COMFORT_NOISE = "REM_CAT_COMFORT_NOISE";
export const REM_CAT_COMFORT_OTHER = "REM_CAT_COMFORT_OTHER";
export const REM_CAT_ENV_POLLUTION = "REM_CAT_ENV_POLLUTION";
export const REM_DELAY_TIP_CONTENT = "REM_DELAY_TIP_CONTENT";
export const RIGHT_ACCESS_MISSIONS = "RIGHT_ACCESS_MISSIONS";
export const RIGHT_CREATE_BUILDING = "RIGHT_CREATE_BUILDING";
export const RIGHT_DELETE_BUILDING = "RIGHT_DELETE_BUILDING";
export const ROLE_FORM_AFFECTATION = "ROLE_FORM_AFFECTATION";
export const STATE_SAVER_NO_ORIGIN = "STATE_SAVER_NO_ORIGIN";
export const STATE_SAVER_NO_STATES = "STATE_SAVER_NO_STATES";
export const SUPPORT_FEEDBACK_INFO = "SUPPORT_FEEDBACK_INFO";
export const SUPPORT_FEEDBACK_TYPE = "SUPPORT_FEEDBACK_TYPE";
export const TAB_EMPLACEMENT_TABLE = "TAB_EMPLACEMENT_TABLE";
export const TAB_EQUIP_GAMME_TABLE = "TAB_EQUIP_GAMME_TABLE";
export const TAB_PORTFOLIO_ID_CARD = "TAB_PORTFOLIO_ID_CARD";
export const TAB_STORE_EQUIP_TABLE = "TAB_STORE_EQUIP_TABLE";
export const TABLE_EQUIP_MOVE_ELEM = "TABLE_EQUIP_MOVE_ELEM";
export const TABLES_INSERTS_FAILED = "TABLES_INSERTS_FAILED";
export const TIME_FORMATTER_FORMAT = "TIME_FORMATTER_FORMAT";
export const TREE_CONFIG_HIDE_SUBS = "TREE_CONFIG_HIDE_SUBS";
export const VD_EXTRACTION_QUALITY = "VD_EXTRACTION_QUALITY";
export const VD_MEASURED_SAN_DEBIT = "VD_MEASURED_SAN_DEBIT";
export const TABLE_GAMME_CREATE_NEW = "TABLE_GAMME_CREATE_NEW";
export const DASH_ENV_AGG_LOC_CELLS = "DASH_ENV_AGG_LOC_CELLS";
export const DATA_ENTRY_TABLE_ISSUE = "DATA_ENTRY_TABLE_ISSUE";
export const NAV_TABLE_UNSELECT_ALL = "NAV_TABLE_UNSELECT_ALL";
export const DATASET_PRESET_FILLING = "DATASET_PRESET_FILLING";
export const DASH_ENV_FILTER_ENERGY = "DASH_ENV_FILTER_ENERGY";
export const DASH_ENV_FILTER_AFFECT = "DASH_ENV_FILTER_AFFECT";
export const ACCESS_GROUP_ONE_ADMIN = "ACCESS_GROUP_ONE_ADMIN";
export const OPTION_CODE_PANEL_TYPE = "OPTION_CODE_PANEL_TYPE";
export const OPTION_CODE_REM_STATUS = "OPTION_CODE_REM_STATUS";
export const OPTION_CODE_FILTRATION = "OPTION_CODE_FILTRATION";
export const OPTIONS_TABLE_LABEL_DB = "OPTIONS_TABLE_LABEL_DB";
export const PRED_CONFIG_TIME_GROUP = "PRED_CONFIG_TIME_GROUP";
export const EPRA_DATASET_TO_SELECT = "EPRA_DATASET_TO_SELECT";
export const EPRA_DATASET_SELECTION = "EPRA_DATASET_SELECTION";
export const EPRA_FAIL_CAT_CREATION = "EPRA_FAIL_CAT_CREATION";
export const REG_CUSTOM_PROP_LOCKED = "REG_CUSTOM_PROP_LOCKED";
export const EU_TAXONOMY_NZEB_PROOF = "EU_TAXONOMY_NZEB_PROOF";
export const EU_TAXONOMY_CRVA_PROOF = "EU_TAXONOMY_CRVA_PROOF";
export const TAXO_MISSION_POWER_SUM = "TAXO_MISSION_POWER_SUM";
export const BUBBLE_FORM_TITLE_PROP = "BUBBLE_FORM_TITLE_PROP";
export const FILE_UPLOAD_WRONG_TYPE = "FILE_UPLOAD_WRONG_TYPE";
export const DATA_CORR_STATION_NAME = "DATA_CORR_STATION_NAME";
export const NRJ_REPORT_API_PCS_GAS = "NRJ_REPORT_API_PCS_GAS";
export const NRJ_REPORT_API_COOLING = "NRJ_REPORT_API_COOLING";
export const NRJ_REPORT_API_HEATING = "NRJ_REPORT_API_HEATING";
export const NRJ_REPORT_API_SUMMARY = "NRJ_REPORT_API_SUMMARY";
export const FORM_REG_PROP_UNIT_TIP = "FORM_REG_PROP_UNIT_TIP";
export const GAMME_TABLE_DEFINITION = "GAMME_TABLE_DEFINITION";
export const MISSION_TYPES_RED_FLAG = "MISSION_TYPES_RED_FLAG";
export const CERT_HISTORY_DATA_DATE = "CERT_HISTORY_DATA_DATE";
export const REG_PROP_LABEL_INVALID = "REG_PROP_LABEL_INVALID";
export const FORM_REG_PROP_PROPNAME = "FORM_REG_PROP_PROPNAME";
export const FORM_REG_PROP_REQUIRED = "FORM_REG_PROP_REQUIRED";
export const DATASET_LATEST_FORMULA = "DATASET_LATEST_FORMULA";
export const DATA_EXPORT_FORMATTING = "DATA_EXPORT_FORMATTING";
export const CAPEX_TABLE_START_DATE = "CAPEX_TABLE_START_DATE";
export const CAPEX_TABLE_TIME_RANGE = "CAPEX_TABLE_TIME_RANGE";
export const CAPEX_TABLE_GROUP_YEAR = "CAPEX_TABLE_GROUP_YEAR";
export const MATERIALS_UNIT_INVALID = "MATERIALS_UNIT_INVALID";
export const MATERIALS_HYDROGEN_GAS = "MATERIALS_HYDROGEN_GAS";
export const MATERIALS_MARBLE_STONE = "MATERIALS_MARBLE_STONE";
export const MATERIALS_CARBON_FIBER = "MATERIALS_CARBON_FIBER";
export const MATERIALS_CARBON_STEEL = "MATERIALS_CARBON_STEEL";
export const MATERIALS_WROUGHT_IRON = "MATERIALS_WROUGHT_IRON";
export const MATERIALS_DUCTILE_IRON = "MATERIALS_DUCTILE_IRON";
export const MATERIALS_FOOD_OR_FUEL = "MATERIALS_FOOD_OR_FUEL";
export const MATERIALS_POLYBUTYLENE = "MATERIALS_POLYBUTYLENE";
export const MATERIALS_POLYURETHANE = "MATERIALS_POLYURETHANE";
export const MATERIALS_BUTYL_RUBBER = "MATERIALS_BUTYL_RUBBER";
export const MATERIALS_CARBON_BLACK = "MATERIALS_CARBON_BLACK";
export const MATERIALS_GROUND_WATER = "MATERIALS_GROUND_WATER";
export const MATERIALS_NIOBIUM_DESC = "MATERIALS_NIOBIUM_DESC";
export const MATERIALS_CADMIUM_DESC = "MATERIALS_CADMIUM_DESC";
export const MATERIALS_BISMUTH_DESC = "MATERIALS_BISMUTH_DESC";
export const MATERIALS_SILICON_DESC = "MATERIALS_SILICON_DESC";
export const MATERIALS_ARSENIC_DESC = "MATERIALS_ARSENIC_DESC";
export const MATERIALS_BROMINE_DESC = "MATERIALS_BROMINE_DESC";
export const MATERIALS_KRYPTON_DESC = "MATERIALS_KRYPTON_DESC";
export const MATERIALS_GRANITE_DESC = "MATERIALS_GRANITE_DESC";
export const MATERIALS_CERAMIC_DESC = "MATERIALS_CERAMIC_DESC";
export const MATERIALS_FLY_ASH_DESC = "MATERIALS_FLY_ASH_DESC";
export const MATERIALS_PERLITE_DESC = "MATERIALS_PERLITE_DESC";
export const MATERIALS_REDWOOD_DESC = "MATERIALS_REDWOOD_DESC";
export const MATERIALS_RED_OAK_DESC = "MATERIALS_RED_OAK_DESC";
export const MATERIALS_LEATHER_DESC = "MATERIALS_LEATHER_DESC";
export const MATERIALS_BEESWAX_DESC = "MATERIALS_BEESWAX_DESC";
export const MATERIALS_RUBBERS_DESC = "MATERIALS_RUBBERS_DESC";
export const MATERIALS_ASPHALT_DESC = "MATERIALS_ASPHALT_DESC";
export const MATERIALS_LIQUIDS_DESC = "MATERIALS_LIQUIDS_DESC";
export const MATERIALS_AMMONIA_DESC = "MATERIALS_AMMONIA_DESC";
export const MATERIALS_PROPANE_DESC = "MATERIALS_PROPANE_DESC";
export const MATERIALS_METHANE_DESC = "MATERIALS_METHANE_DESC";
export const NRJ_MISSION_CONS_RANGE = "NRJ_MISSION_CONS_RANGE";
export const NRJ_MISSION_CONS_CRREM = "NRJ_MISSION_CONS_CRREM";
export const NRJ_MISSION_CONS_WATER = "NRJ_MISSION_CONS_WATER";
export const PRED_PRESET_NAME_DELTA = "PRED_PRESET_NAME_DELTA";
export const PRED_PRESET_DESC_DELTA = "PRED_PRESET_DESC_DELTA";
export const DATA_INSERT_DUPLICATES = "DATA_INSERT_DUPLICATES";
export const PARKING_SURFACES_TOTAL = "PARKING_SURFACES_TOTAL";
export const CDM_DATA_DATASET_DELTA = "CDM_DATA_DATASET_DELTA";
export const CDM_DATA_DATASET_INDEX = "CDM_DATA_DATASET_INDEX";
export const MISSION_USER_ADD_LABEL = "MISSION_USER_ADD_LABEL";
export const MISSION_COPY_FROM_DESC = "MISSION_COPY_FROM_DESC";
export const ALARM_PRESET_DATA_MISS = "ALARM_PRESET_DATA_MISS";
export const TAG_MAPPER_IMPORT_TYPE = "TAG_MAPPER_IMPORT_TYPE";
export const TAG_MAPPER_PATH_SELECT = "TAG_MAPPER_PATH_SELECT";
export const RIGHT_READ_OTHER_NOTES = "RIGHT_READ_OTHER_NOTES";
export const PRED_PERIOD_OVERLAP_TO = "PRED_PERIOD_OVERLAP_TO";
export const PRED_QUALITY_PARAM_MIN = "PRED_QUALITY_PARAM_MIN";
export const PRED_QUALITY_PARAM_MAX = "PRED_QUALITY_PARAM_MAX";
export const PRED_QUALITY_NO_PERIOD = "PRED_QUALITY_NO_PERIOD";
export const KPI_API_REM_CRIT_MID_R = "KPI_API_REM_CRIT_MID_R";
export const KPI_API_REM_CRIT_LOW_R = "KPI_API_REM_CRIT_LOW_R";
export const GLOBAL_STATUS_REG_LATE = "GLOBAL_STATUS_REG_LATE";
export const GLOBAL_MOVE_N_ELEMENTS = "GLOBAL_MOVE_N_ELEMENTS";
export const TAB_CDM_MISSION_WIZARD = "TAB_CDM_MISSION_WIZARD";
export const ACCESS_USER_KICK_GROUP = "ACCESS_USER_KICK_GROUP";
export const ALARM_CONSIDERED_RANGE = "ALARM_CONSIDERED_RANGE";
export const ALARM_EDIT_NOT_ALLOWED = "ALARM_EDIT_NOT_ALLOWED";
export const ALARM_SCHEDULE_PRESETS = "ALARM_SCHEDULE_PRESETS";
export const ALARM_START_DATE_ERROR = "ALARM_START_DATE_ERROR";
export const ALERT_MAINTENANCE_MODE = "ALERT_MAINTENANCE_MODE";
export const ALERT_STATUS_CORRECTED = "ALERT_STATUS_CORRECTED";
export const ALERT_STATUS_IN_ACTION = "ALERT_STATUS_IN_ACTION";
export const ANNOT_COMPLETE_POLYGON = "ANNOT_COMPLETE_POLYGON";
export const ANNOT_ERROR_LOAD_PLANS = "ANNOT_ERROR_LOAD_PLANS";
export const ANNOT_POLYGON_SELECTOR = "ANNOT_POLYGON_SELECTOR";
export const APS_REFACTURATION_PROD = "APS_REFACTURATION_PROD";
export const AUTH_SIGNIN_IGNORE_MSG = "AUTH_SIGNIN_IGNORE_MSG";
export const BG_DEBIT_LABEL_TOOLTIP = "BG_DEBIT_LABEL_TOOLTIP";
export const BR_ERROR_BUILD_DETAILS = "BR_ERROR_BUILD_DETAILS";
export const BR_ERROR_GP_GE_DISTRIB = "BR_ERROR_GP_GE_DISTRIB";
export const BR_ERROR_LOCATION_NAME = "BR_ERROR_LOCATION_NAME";
export const BR_ERROR_LOCATION_TYPE = "BR_ERROR_LOCATION_TYPE";
export const BR_FLOOR_DEFAULT_COLOR = "BR_FLOOR_DEFAULT_COLOR";
export const BUILD_EDIT_LOCAL_FLOOR = "BUILD_EDIT_LOCAL_FLOOR";
export const BUILD_FAKE_ROOF_HEIGHT = "BUILD_FAKE_ROOF_HEIGHT";
export const BUILD_PROTECTED_VOLUME = "BUILD_PROTECTED_VOLUME";
export const BUILDING_PLURAL_SINGLE = "BUILDING_PLURAL_SINGLE";
export const CDM_MISSION_DATA_CRUMB = "CDM_MISSION_DATA_CRUMB";
export const CDM_MISSION_DATA_PANEL = "CDM_MISSION_DATA_PANEL";
export const CONTRACT_ADD_SAME_REFS = "CONTRACT_ADD_SAME_REFS";
export const DATASET_NORMALIZED_HOT = "DATASET_NORMALIZED_HOT";
export const DATASET_PANEL_TAB_DATA = "DATASET_PANEL_TAB_DATA";
export const DATASET_SRC_CALCULATED = "DATASET_SRC_CALCULATED";
export const DIA_CHOOSE_DELETE_LINK = "DIA_CHOOSE_DELETE_LINK";
export const DIA_CONFIG_ORIENTATION = "DIA_CONFIG_ORIENTATION";
export const DIA_LINK_CODE_HERITAGE = "DIA_LINK_CODE_HERITAGE";
export const DIA_NOTIF_CHANGES_MADE = "DIA_NOTIF_CHANGES_MADE";
export const DIA_NOTIF_NO_TAG_TITLE = "DIA_NOTIF_NO_TAG_TITLE";
export const EQUIP_PANEL_PERF_THERM = "EQUIP_PANEL_PERF_THERM";
export const EQUIP_PANEL_PHYS_DOORS = "EQUIP_PANEL_PHYS_DOORS";
export const EQUIP_PANEL_PHYS_OTHER = "EQUIP_PANEL_PHYS_OTHER";
export const EQUIP_PANEL_PHYS_PANEL = "EQUIP_PANEL_PHYS_PANEL";
export const EQUIP_STORE_IS_GENERIC = "EQUIP_STORE_IS_GENERIC";
export const EQUIP_STORE_TABLE_EDIT = "EQUIP_STORE_TABLE_EDIT";
export const FR_NO_SUBMISSION_FOUND = "FR_NO_SUBMISSION_FOUND";
export const GLOBAL_FILE_READ_ERROR = "GLOBAL_FILE_READ_ERROR";
export const GLOBAL_GENERATING_FILE = "GLOBAL_GENERATING_FILE";
export const GLOBAL_LABEL_EQUIPMENT = "GLOBAL_LABEL_EQUIPMENT";
export const GLOBAL_NO_EMPLACEMENTS = "GLOBAL_NO_EMPLACEMENTS";
export const GP_GROUP_SINGLE_MINUTE = "GP_GROUP_SINGLE_MINUTE";
export const GRAPH_TICKETS_ASSIGNED = "GRAPH_TICKETS_ASSIGNED";
export const GRAPH_TICKETS_OVERVIEW = "GRAPH_TICKETS_OVERVIEW";
export const ID_CARD_PUBLIC_LINK_OK = "ID_CARD_PUBLIC_LINK_OK";
export const IMPORT_EQUIP_EMP_TITLE = "IMPORT_EQUIP_EMP_TITLE";
export const KPI_API_LIMIT_CRIT_LOW = "KPI_API_LIMIT_CRIT_LOW";
export const KPI_API_LIMIT_CRIT_MID = "KPI_API_LIMIT_CRIT_MID";
export const LINK_ERROR_SAME_IN_OUT = "LINK_ERROR_SAME_IN_OUT";
export const LT_INVALID_LINK_FORMAT = "LT_INVALID_LINK_FORMAT";
export const MATRIX_DELETE_SELECTOR = 'MATRIX_DELETE_SELECTOR';
export const MATRIX_NO_PROP_DEFINED = "MATRIX_NO_PROP_DEFINED";
export const MISSION_CDM_CONTROLLED = "MISSION_CDM_CONTROLLED";
export const MISSION_CDM_PROPERTIES = "MISSION_CDM_PROPERTIES";
export const MISSION_INTERV_COMPANY = "MISSION_INTERV_COMPANY";
export const MISSION_INTERV_MANAGER = "MISSION_INTERV_MANAGER";
export const MISSION_INTERV_PRESENT = "MISSION_INTERV_PRESENT";
export const MISSION_INVITE_TO_SEND = "MISSION_INVITE_TO_SEND";
export const MISSION_REPORT_SENDING = "MISSION_REPORT_SENDING";
export const NAV_NO_CURRENT_CONTEXT = "NAV_NO_CURRENT_CONTEXT";
export const NOTIF_EDIT_SUPER_ADMIN = "NOTIF_EDIT_SUPER_ADMIN";
export const PANEL_NRJ_TABLE_ALARMS = "PANEL_NRJ_TABLE_ALARMS";
export const POP_UP_FORM_PROP_PANEL = "POP_UP_FORM_PROP_PANEL";
export const PWD_CHANGE_FOLLOW_LINK = "PWD_CHANGE_FOLLOW_LINK";
export const REG_ACTION_APPLICABLES = "REG_ACTION_APPLICABLES";
export const REG_AUTO_SAVE_ELEMENTS = "REG_AUTO_SAVE_ELEMENTS";
export const REG_PASSWORD_DIFFERENT = "REG_PASSWORD_DIFFERENT";
export const REM_BUTTON_FILTER_MEMO = "REM_BUTTON_FILTER_MEMO";
export const REM_BUTTON_FILTER_NONE = "REM_BUTTON_FILTER_NONE";
export const REM_BUTTON_FILTER_OPEN = "REM_BUTTON_FILTER_OPEN";
export const REM_TABLE_SHOW_ACTIONS = "REM_TABLE_SHOW_ACTIONS";
export const REPORT_UNSAVED_MISSION = "REPORT_UNSAVED_MISSION";
export const RIGHT_EDIT_EMPLACEMENT = "RIGHT_EDIT_EMPLACEMENT";
export const RIGHT_MOVE_EMPLACEMENT = "RIGHT_MOVE_EMPLACEMENT";
export const RIGHT_WRITE_REG_ACTION = "RIGHT_WRITE_REG_ACTION";
export const SCHEDULE_FROM_LOWER_TO = "SCHEDULE_FROM_LOWER_TO";
export const SF_PARKING_LIGHT_LABEL = "SF_PARKING_LIGHT_LABEL";
export const STATE_SAVER_NEW_IS_GEN = "STATE_SAVER_NEW_IS_GEN";
export const TAB_ACTION_EVENT_TABLE = "TAB_ACTION_EVENT_TABLE";
export const TIME_FORMATTER_INVALID = "TIME_FORMATTER_INVALID";
export const TREE_CONFIG_HIDE_LINKS = "TREE_CONFIG_HIDE_LINKS";
export const TREE_CREATE_DESCENDANT = "TREE_CREATE_DESCENDANT";
export const VD_ASTERISK_OCCUPATION = "VD_ASTERISK_OCCUPATION";
export const VD_CONFIRM_DELETE_VENT = "VD_CONFIRM_DELETE_VENT";
export const DATASET_PRESET_GAS_CONS = "DATASET_PRESET_GAS_CONS";
export const DATASET_PRESET_NB_START = "DATASET_PRESET_NB_START";
export const DATASET_PRESET_END_TEMP = "DATASET_PRESET_END_TEMP";
export const LINK_SUGGEST_NO_KWH_GAS = "LINK_SUGGEST_NO_KWH_GAS";
export const DASH_ENV_FILTER_COUNTRY = "DASH_ENV_FILTER_COUNTRY";
export const DASH_ENV_CONFIG_FILTERS = "DASH_ENV_CONFIG_FILTERS";
export const DASH_ENV_PROPERTY_LABEL = "DASH_ENV_PROPERTY_LABEL";
export const GAMME_FORM_COMP_LOADING = "GAMME_FORM_COMP_LOADING";
export const SUPERSET_DASH_CO2_TITLE = "SUPERSET_DASH_CO2_TITLE";
export const OPTION_CODE_ELEC_PHASES = "OPTION_CODE_ELEC_PHASES";
export const OPTION_CODE_REFRIGERANT = "OPTION_CODE_REFRIGERANT";
export const OPTION_CODE_ORIENTATION = "OPTION_CODE_ORIENTATION";
export const REFRIGERANT_BASED_ON_KG = "REFRIGERANT_BASED_ON_KG";
export const EPRA_EMPLACEMENTS_LABEL = "EPRA_EMPLACEMENTS_LABEL";
export const DATA_CHARTS_EDIT_PERIOD = "DATA_CHARTS_EDIT_PERIOD";
export const EPRA_DATASET_SELECT_ALL = "EPRA_DATASET_SELECT_ALL";
export const EQUIP_QR_SCAN_NO_ACCESS = "EQUIP_QR_SCAN_NO_ACCESS";
export const QR_EQUIP_SCAN_SHOW_PROP = "QR_EQUIP_SCAN_SHOW_PROP";
export const QR_EQUIP_SCAN_SHOW_DATA = "QR_EQUIP_SCAN_SHOW_DATA";
export const CERT_PROOF_DOCUMENT_ADD = "CERT_PROOF_DOCUMENT_ADD";
export const SUPPORT_CAT_ENERGY_PLAN = "SUPPORT_CAT_ENERGY_PLAN";
export const TAXO_MISSION_HEAT_POWER = "TAXO_MISSION_HEAT_POWER";
export const BUBBLE_FORM_TEST_RENDER = "BUBBLE_FORM_TEST_RENDER";
export const BUBBLE_FORM_POPUP_TITLE = "BUBBLE_FORM_POPUP_TITLE";
export const TICKET_TYPE_DOC_RENEWAL = "TICKET_TYPE_DOC_RENEWAL";
export const NRJ_REPORT_API_COMB_PCS = "NRJ_REPORT_API_COMB_PCS";
export const NRJ_REPORT_API_GAS_CONS = "NRJ_REPORT_API_GAS_CONS";
export const NRJ_REPORT_API_CO2_AREA = "NRJ_REPORT_API_CO2_AREA";
export const NRJ_REPORT_API_MISC_OBJ = "NRJ_REPORT_API_MISC_OBJ";
export const NRJ_REPORT_API_LAW_JUNE = "NRJ_REPORT_API_LAW_JUNE";
export const NRJ_REPORT_API_NRJ_SIGN = "NRJ_REPORT_API_NRJ_SIGN";
export const FORM_REG_PROP_BOOL_TYPE = "FORM_REG_PROP_BOOL_TYPE";
export const NRJ_REPORT_API_CONS_KWH = "NRJ_REPORT_API_CONS_KWH";
export const NRJ_REPORT_API_COST_GAZ = "NRJ_REPORT_API_COST_GAZ";
export const RED_FLAG_PICTURE_ANNEXE = "RED_FLAG_PICTURE_ANNEXE";
export const RED_FLAG_DETAIL_PER_CAT = "RED_FLAG_DETAIL_PER_CAT";
export const CAPEX_TOTAL_WITH_CHARGE = "CAPEX_TOTAL_WITH_CHARGE";
export const ALLOW_RED_FLAG_MISSIONS = "ALLOW_RED_FLAG_MISSIONS";
export const NRJ_DASH_SUB_MENU_INDEX = "NRJ_DASH_SUB_MENU_INDEX";
export const DATASET_FLOW_PRODUCTION = "DATASET_FLOW_PRODUCTION";
export const DOOR_OPENING_TELESCOPIC = "DOOR_OPENING_TELESCOPIC";
export const CERT_CLICK_TO_RECERTIFY = "CERT_CLICK_TO_RECERTIFY";
export const FORM_REG_PROP_DATE_TYPE = "FORM_REG_PROP_DATE_TYPE";
export const FORM_REG_PROP_DATA_TYPE = "FORM_REG_PROP_DATA_TYPE";
export const FORM_REG_PROP_DASHBOARD = "FORM_REG_PROP_DASHBOARD";
export const NRJ_REPORT_FAILED_CHECK = "NRJ_REPORT_FAILED_CHECK";
export const EDL_EXCEL_COL_FAIL_CRIT = "EDL_EXCEL_COL_FAIL_CRIT";
export const CAPEX_REPLACEMENT_TITLE = "CAPEX_REPLACEMENT_TITLE";
export const CAPEX_TABLE_DESCRIPTION = "CAPEX_TABLE_DESCRIPTION";
export const CAPEX_TABLE_GROUP_RANGE = "CAPEX_TABLE_GROUP_RANGE";
export const TICKET_TYPE_MAINTENANCE = "TICKET_TYPE_MAINTENANCE";
export const TICKET_TYPE_REPLACEMENT = "TICKET_TYPE_REPLACEMENT";
export const MATERIALS_ALKALI_METALS = "MATERIALS_ALKALI_METALS";
export const MATERIALS_IGNEOUS_ROCKS = "MATERIALS_IGNEOUS_ROCKS";
export const MATERIALS_HYDRATED_LIME = "MATERIALS_HYDRATED_LIME";
export const MATERIALS_EXPANDED_CLAY = "MATERIALS_EXPANDED_CLAY";
export const MATERIALS_EXPANDED_MICA = "MATERIALS_EXPANDED_MICA";
export const MATERIALS_COPPER_ALLOYS = "MATERIALS_COPPER_ALLOYS";
export const MATERIALS_SOUTHERN_PINE = "MATERIALS_SOUTHERN_PINE";
export const MATERIALS_HAIR_AND_SKIN = "MATERIALS_HAIR_AND_SKIN";
export const MATERIALS_POLYCARBONATE = "MATERIALS_POLYCARBONATE";
export const MATERIALS_POLYPROPYLENE = "MATERIALS_POLYPROPYLENE";
export const MATERIALS_OTHER_LIQUIDS = "MATERIALS_OTHER_LIQUIDS";
export const MATERIALS_SCANDIUM_DESC = "MATERIALS_SCANDIUM_DESC";
export const MATERIALS_TITANIUM_DESC = "MATERIALS_TITANIUM_DESC";
export const MATERIALS_VANADIUM_DESC = "MATERIALS_VANADIUM_DESC";
export const MATERIALS_CHROMIUM_DESC = "MATERIALS_CHROMIUM_DESC";
export const MATERIALS_ALUMINUM_DESC = "MATERIALS_ALUMINUM_DESC";
export const MATERIALS_ANTIMONY_DESC = "MATERIALS_ANTIMONY_DESC";
export const MATERIALS_POLONIUM_DESC = "MATERIALS_POLONIUM_DESC";
export const MATERIALS_HALOGENS_DESC = "MATERIALS_HALOGENS_DESC";
export const MATERIALS_FLUORINE_DESC = "MATERIALS_FLUORINE_DESC";
export const MATERIALS_CHLORINE_DESC = "MATERIALS_CHLORINE_DESC";
export const MATERIALS_HYDROGEN_DESC = "MATERIALS_HYDROGEN_DESC";
export const MATERIALS_NITROGEN_DESC = "MATERIALS_NITROGEN_DESC";
export const MATERIALS_SELENIUM_DESC = "MATERIALS_SELENIUM_DESC";
export const MATERIALS_GRAPHITE_DESC = "MATERIALS_GRAPHITE_DESC";
export const MATERIALS_SILICATE_DESC = "MATERIALS_SILICATE_DESC";
export const MATERIALS_ASBESTOS_DESC = "MATERIALS_ASBESTOS_DESC";
export const MATERIALS_TEXTILES_DESC = "MATERIALS_TEXTILES_DESC";
export const MATERIALS_PLASTICS_DESC = "MATERIALS_PLASTICS_DESC";
export const MATERIALS_PHENOLIC_DESC = "MATERIALS_PHENOLIC_DESC";
export const MATERIALS_NEOPRENE_DESC = "MATERIALS_NEOPRENE_DESC";
export const MATERIALS_SILICONE_DESC = "MATERIALS_SILICONE_DESC";
export const MATERIALS_ASPHALTS_DESC = "MATERIALS_ASPHALTS_DESC";
export const MATERIALS_PARAFFIN_DESC = "MATERIALS_PARAFFIN_DESC";
export const MATERIALS_ALCOHOLS_DESC = "MATERIALS_ALCOHOLS_DESC";
export const MATERIALS_NEON_GAS_DESC = "MATERIALS_NEON_GAS_DESC";
export const MATERIALS_ETHYLENE_DESC = "MATERIALS_ETHYLENE_DESC";
export const MATERIALS_ISOBUTAN_DESC = "MATERIALS_ISOBUTAN_DESC";
export const TABLE_DATASET_MOVE_ELEM = "TABLE_DATASET_MOVE_ELEM";
export const NRJ_MISSION_PCT_INVALID = "NRJ_MISSION_PCT_INVALID";
export const NRJ_MISSION_TAB_GENERAL = "NRJ_MISSION_TAB_GENERAL";
export const DATE_FORMATTER_BASE_STR = "DATE_FORMATTER_BASE_STR";
export const DATE_FORMATTER_UTC_TIME = "DATE_FORMATTER_UTC_TIME";
export const ACCESS_GROUP_AM_I_ADMIN = "ACCESS_GROUP_AM_I_ADMIN";
export const MISSION_USER_CREATE_NEW = "MISSION_USER_CREATE_NEW";
export const ALARM_PRESET_NEG_VALUES = "ALARM_PRESET_NEG_VALUES";
export const TABLE_STATE_MSG_DEFAULT = "TABLE_STATE_MSG_DEFAULT";
export const PRED_CONFIG_NO_FEATURES = "PRED_CONFIG_NO_FEATURES";
export const AFFECT_COMMUNITY_CHURCH = "AFFECT_COMMUNITY_CHURCH";
export const AFFECT_COMMUNITY_CENTER = "AFFECT_COMMUNITY_CENTER";
export const AFFECT_RETAIL_BIG_STORE = "AFFECT_RETAIL_BIG_STORE";
export const AFFECT_RETAIL_WAREHOUSE = "AFFECT_RETAIL_WAREHOUSE";
export const AFFECT_PUBLIC_FIREHOUSE = "AFFECT_PUBLIC_FIREHOUSE";
export const AFFECT_PUBLIC_TOWN_HALL = "AFFECT_PUBLIC_TOWN_HALL";
export const CDM_REPORT_LAST_CONTROL = "CDM_REPORT_LAST_CONTROL";
export const MISSION_USERS_READ_ONLY = "MISSION_USERS_READ_ONLY";
export const RIGHT_WRITE_OTHER_NOTES = "RIGHT_WRITE_OTHER_NOTES";
export const PRED_NO_HOLIDAY_COUNTRY = "PRED_NO_HOLIDAY_COUNTRY";
export const PRED_CONFIG_VAR_WEATHER = "PRED_CONFIG_VAR_WEATHER";
export const KPI_API_REM_CRIT_HIGH_R = "KPI_API_REM_CRIT_HIGH_R";
export const KPI_API_REM_CRIT_NONE_R = "KPI_API_REM_CRIT_NONE_R";
export const MISSION_CDM_REMS_RESUME = "MISSION_CDM_REMS_RESUME";
export const IMPORT_EQUIP_EMPTY_FILE = "IMPORT_EQUIP_EMPTY_FILE";
export const ACCESS_USER_PERMISSIONS = "ACCESS_USER_PERMISSIONS";
export const ACTION_SHOW_ALL_ACTIONS = "ACTION_SHOW_ALL_ACTIONS";
export const AUTH_LINK_VALIDITY_TIME = "AUTH_LINK_VALIDITY_TIME";
export const AUTH_NEW_USER_MAIL_DUPE = "AUTH_NEW_USER_MAIL_DUPE";
export const AUTH_SIGNIN_ASK_OVERSEE = "AUTH_SIGNIN_ASK_OVERSEE";
export const AUTH_SIGNIN_CONFIRM_PWD = "AUTH_SIGNIN_CONFIRM_PWD";
export const AUTH_SIGNIN_PWD_CONFIRM = "AUTH_SIGNIN_PWD_CONFIRM";
export const AUTO_FILL_REG_VIEW_HIDE = "AUTO_FILL_REG_VIEW_HIDE";
export const AUTO_FILL_REG_VIEW_SHOW = "AUTO_FILL_REG_VIEW_SHOW";
export const BR_ERROR_DEFAULT_BOUCHE = "BR_ERROR_DEFAULT_BOUCHE";
export const BR_FLOOR_FORMAT_TOOLTIP = "BR_FLOOR_FORMAT_TOOLTIP";
export const BR_NOTIF_ERROR_CREATION = "BR_NOTIF_ERROR_CREATION";
export const BUILD_EDIT_CREATE_FIRST = "BUILD_EDIT_CREATE_FIRST";
export const BUILD_EDIT_INVALID_ROOT = "BUILD_EDIT_INVALID_ROOT";
export const BUILD_FAKE_FLOOR_HEIGHT = "BUILD_FAKE_FLOOR_HEIGHT";
export const CDM_MISSION_EQUIP_CRUMB = "CDM_MISSION_EQUIP_CRUMB";
export const CDM_MISSION_EQUIP_PANEL = "CDM_MISSION_EQUIP_PANEL";
export const CFQ_SAVED_MESSAGE_NOTIF = "CFQ_SAVED_MESSAGE_NOTIF";
export const CFS_FORM_ALREADY_EXISTS = "CFS_FORM_ALREADY_EXISTS";
export const CONTRACT_EDIT_PERIMETER = "CONTRACT_EDIT_PERIMETER";
export const DATA_CHARTS_EDIT_DELETE = "DATA_CHARTS_EDIT_DELETE";
export const DATA_CHARTS_VALUE_TITLE = "DATA_CHARTS_VALUE_TITLE";
export const DATA_ENTRY_ASK_NOTE_MSG = "DATA_ENTRY_ASK_NOTE_MSG";
export const DATASET_ADD_ENTRY_QUICK = "DATASET_ADD_ENTRY_QUICK";
export const DATASET_COL_DELETE_DATE = "DATASET_COL_DELETE_DATE";
export const DATASET_COL_DELETE_USER = "DATASET_COL_DELETE_USER";
export const DATASET_ERROR_NO_ORIGIN = "DATASET_ERROR_NO_ORIGIN";
export const DATASET_NORMALIZED_COLD = "DATASET_NORMALIZED_COLD";
export const DATASET_PANEL_TAB_LINKS = "DATASET_PANEL_TAB_LINKS";
export const DB_TICKETS_CURRENT_TIME = "DB_TICKETS_CURRENT_TIME";
export const DIA_EDIT_MODE_BUTTON_ON = "DIA_EDIT_MODE_BUTTON_ON";
export const DIA_NOTIF_NO_INFO_GRAPH = "DIA_NOTIF_NO_INFO_GRAPH";
export const DIA_NOTIF_SAME_ID_TITLE = "DIA_NOTIF_SAME_ID_TITLE";
export const DIA_NOTIF_TOO_MUCH_DATA = "DIA_NOTIF_TOO_MUCH_DATA";
export const EMP_PANEL_SURFACES_NORM = "EMP_PANEL_SURFACES_NORM";
export const EMP_TABLE_DELETE_SINGLE = "EMP_TABLE_DELETE_SINGLE";
export const EQUIP_PANEL_PERF_RESIST = "EQUIP_PANEL_PERF_RESIST";
export const EQUIP_PANEL_PHYS_WINDOW = "EQUIP_PANEL_PHYS_WINDOW";
export const EQUIP_PANEL_SERVICE_AIR = "EQUIP_PANEL_SERVICE_AIR";
export const EQUIP_PANEL_SERVICE_GAS = "EQUIP_PANEL_SERVICE_GAS";
export const EQUIP_TAB_FORM_PHYSICAL = "EQUIP_TAB_FORM_PHYSICAL";
export const EQUIP_TAB_FORM_SERVICES = "EQUIP_TAB_FORM_SERVICES";
export const FR_INVALID_FORM_SOURCES = "FR_INVALID_FORM_SOURCES";
export const G_ERROR_SCHEDULE_FORMAT = "G_ERROR_SCHEDULE_FORMAT";
export const G_NO_SIMILAR_DATA_FOUND = "G_NO_SIMILAR_DATA_FOUND";
export const G_NO_SUB_EQUIP_SELECTED = "G_NO_SUB_EQUIP_SELECTED";
export const GLOBAL_CONTEXT_ELEMENTS = "GLOBAL_CONTEXT_ELEMENTS";
export const GLOBAL_LABEL_ENTERPRISE = "GLOBAL_LABEL_ENTERPRISE";
export const GLOBAL_NO_BUILD_CONTEXT = "GLOBAL_NO_BUILD_CONTEXT";
export const GLOBAL_PROPERTIES_LABEL = "GLOBAL_PROPERTIES_LABEL";
export const GLOBAL_REMOVE_PORTFOLIO = "GLOBAL_REMOVE_PORTFOLIO";
export const GLOBAL_SAVE_BEFORE_QUIT = "GLOBAL_SAVE_BEFORE_QUIT";
export const GLOBAL_WEATHER_STATIONS = "GLOBAL_WEATHER_STATIONS";
export const GRAPH_TICKETS_NB_CLOSED = "GRAPH_TICKETS_NB_CLOSED";
export const GRAPH_TICKETS_OPEN_USER = "GRAPH_TICKETS_OPEN_USER";
export const KPI_API_EQUIP_SPREAD_OK = "KPI_API_EQUIP_SPREAD_OK";
export const KPI_API_LIMIT_CRIT_HIGH = "KPI_API_LIMIT_CRIT_HIGH";
export const KPI_API_MAIN_COMMENT_OK = "KPI_API_MAIN_COMMENT_OK";
export const LINK_ERROR_PAIR_INVALID = "LINK_ERROR_PAIR_INVALID";
export const MISSION_CDM_CONTROL_ALL = "MISSION_CDM_CONTROL_ALL";
export const MISSION_INTERV_FACILITY = "MISSION_INTERV_FACILITY";
export const MISSION_INTERV_PROPERTY = "MISSION_INTERV_PROPERTY";
export const MISSION_INVITE_ACCEPTED = "MISSION_INVITE_ACCEPTED";
export const MISSION_REPORT_GENERATE = "MISSION_REPORT_GENERATE";
export const NAV_TABLE_ITEM_SELECTED = "NAV_TABLE_ITEM_SELECTED";
export const PANEL_EMPLACEMENT_TABLE = "PANEL_EMPLACEMENT_TABLE";
export const PANEL_STORE_EQUIP_TABLE = "PANEL_STORE_EQUIP_TABLE";
export const REG_CELL_NOT_OCCUPATION = "REG_CELL_NOT_OCCUPATION";
export const REG_DEFAULT_PERIODICITY = "REG_DEFAULT_PERIODICITY";
export const REG_FORM_REGLEMENTATION = "REG_FORM_REGLEMENTATION";
export const REM_DEF_COST_CONSUMABLE = "REM_DEF_COST_CONSUMABLE";
export const REM_FORM_PANEL_DROP_REM = "REM_FORM_PANEL_DROP_REM";
export const RIGHT_READ_OTHER_EVENTS = "RIGHT_READ_OTHER_EVENTS";
export const RIGHT_WRITE_EQUIP_STORE = "RIGHT_WRITE_EQUIP_STORE";
export const RIGHT_WRITE_OWN_REG_DOC = "RIGHT_WRITE_OWN_REG_DOC";
export const RIGHT_WRITE_REG_ACTIONS = "RIGHT_WRITE_REG_ACTIONS";
export const SCHEDULE_TO_HIGHER_FROM = "SCHEDULE_TO_HIGHER_FROM";
export const SUPPORT_ERROR_MAX_FILES = "SUPPORT_ERROR_MAX_FILES";
export const TAB_CHECK_MANUAL_RECORD = "TAB_CHECK_MANUAL_RECORD";
export const TEMPLATE_NEW_ITEM_NEW_F = "TEMPLATE_NEW_ITEM_NEW_F";
export const TIME_FORMATTER_TIMEZONE = "TIME_FORMATTER_TIMEZONE";
export const VD_CONFIRM_DEFAULT_DATA = "VD_CONFIRM_DEFAULT_DATA";
export const FORM_REG_STATUS_CODE_TIP = "FORM_REG_STATUS_CODE_TIP";
export const TABLE_GAMME_EDIT_CURRENT = "TABLE_GAMME_EDIT_CURRENT";
export const DATA_ENTRY_TABLE_PROBLEM = "DATA_ENTRY_TABLE_PROBLEM";
export const DATASET_PRESET_ELEC_CONS = "DATASET_PRESET_ELEC_CONS";
export const DATASET_PRESET_CYCLE_AVG = "DATASET_PRESET_CYCLE_AVG";
export const DASH_ENV_CONFIG_GROUPING = "DASH_ENV_CONFIG_GROUPING";
export const SUPERSET_DASH_ELEC_TITLE = "SUPERSET_DASH_ELEC_TITLE";
export const SUPERSET_LOAD_CONNECTING = "SUPERSET_LOAD_CONNECTING";
export const NRJ_DASH_SUB_PANEL_TABLE = "NRJ_DASH_SUB_PANEL_TABLE";
export const OPTION_CODE_TICKET_TYPES = "OPTION_CODE_TICKET_TYPES";
export const OPTION_CODE_AFFECTATIONS = "OPTION_CODE_AFFECTATIONS";
export const EQUIP_WINDOW_TYPE_SIMPLE = "EQUIP_WINDOW_TYPE_SIMPLE";
export const EQUIP_WINDOW_TYPE_DOUBLE = "EQUIP_WINDOW_TYPE_DOUBLE";
export const RSC01_TAB_MISSION_CHOICE = "RSC01_TAB_MISSION_CHOICE";
export const DATA_CORRECT_EDIT_PARAMS = "DATA_CORRECT_EDIT_PARAMS";
export const DATA_CORRECT_UPPER_BOUND = "DATA_CORRECT_UPPER_BOUND";
export const DATA_CORRECT_FIRST_VALUE = "DATA_CORRECT_FIRST_VALUE";
export const MISSION_TYPE_EU_TAXONOMY = "MISSION_TYPE_EU_TAXONOMY";
export const BUBBLE_FORM_ANY_PAGE_OPT = "BUBBLE_FORM_ANY_PAGE_OPT";
export const BUBBLE_FORM_DURATION_TIP = "BUBBLE_FORM_DURATION_TIP";
export const DATA_CORR_REJECT_CHANGES = "DATA_CORR_REJECT_CHANGES";
export const DATA_CORR_ACCEPT_CHANGES = "DATA_CORR_ACCEPT_CHANGES";
export const NRJ_REPORT_API_AREA_CONS = "NRJ_REPORT_API_AREA_CONS";
export const NRJ_REPORT_API_FUEL_CONS = "NRJ_REPORT_API_FUEL_CONS";
export const NRJ_REPORT_API_CONS_AREA = "NRJ_REPORT_API_CONS_AREA";
export const NRJ_REPORT_API_DATA_NORM = "NRJ_REPORT_API_DATA_NORM";
export const GAMME_TABLE_IS_EQUIPMENT = "GAMME_TABLE_IS_EQUIPMENT";
export const NRJ_REPORT_API_CONS_COMB = "NRJ_REPORT_API_CONS_COMB";
export const NRJ_REPORT_API_CONS_ELEC = "NRJ_REPORT_API_CONS_ELEC";
export const NRJ_REPORT_API_COST_FUEL = "NRJ_REPORT_API_COST_FUEL";
export const NRJ_REPORT_API_COST_ELEC = "NRJ_REPORT_API_COST_ELEC";
export const RED_FLAG_INCLUDE_REG_DOC = "RED_FLAG_INCLUDE_REG_DOC";
export const RED_FLAG_REM_AUTO_ASSIGN = "RED_FLAG_REM_AUTO_ASSIGN";
export const GAMME_DEACTIVATION_TITLE = "GAMME_DEACTIVATION_TITLE";
export const GAMME_DEACTIVATION_LABEL = "GAMME_DEACTIVATION_LABEL";
export const CAPEX_ORIGIN_MAINTENANCE = "CAPEX_ORIGIN_MAINTENANCE";
export const CAPEX_ORIGIN_REPLACEMENT = "CAPEX_ORIGIN_REPLACEMENT";
export const DATASET_FLOW_CONSUMPTION = "DATASET_FLOW_CONSUMPTION";
export const CERT_HISTORY_DESCRIPTION = "CERT_HISTORY_DESCRIPTION";
export const CERT_HISTORY_FILE_PROOFS = "CERT_HISTORY_FILE_PROOFS";
export const FORM_REG_PROP_TRANSLATOR = "FORM_REG_PROP_TRANSLATOR";
export const RIGHT_ALLOW_CDM_MISSIONS = "RIGHT_ALLOW_CDM_MISSIONS";
export const RIGHT_ALLOW_EDL_MISSIONS = "RIGHT_ALLOW_EDL_MISSIONS";
export const RIGHT_ALLOW_NRJ_MISSIONS = "RIGHT_ALLOW_NRJ_MISSIONS";
export const CAPEX_ORIGIN_END_LIFE_TH = "CAPEX_ORIGIN_END_LIFE_TH";
export const MATERIALS_EXPANDED_SHALE = "MATERIALS_EXPANDED_SHALE";
export const MATERIALS_VITREOUS_CHINA = "MATERIALS_VITREOUS_CHINA";
export const MATERIALS_UNREFINED_SALT = "MATERIALS_UNREFINED_SALT";
export const MATERIALS_FERROUS_ALLOYS = "MATERIALS_FERROUS_ALLOYS";
export const MATERIALS_MALLEABLE_IRON = "MATERIALS_MALLEABLE_IRON";
export const MATERIALS_NATURAL_RUBBER = "MATERIALS_NATURAL_RUBBER";
export const MATERIALS_BARK_STEM_ROOT = "MATERIALS_BARK_STEM_ROOT";
export const MATERIALS_COAL_TAR_PITCH = "MATERIALS_COAL_TAR_PITCH";
export const MATERIALS_NORMAL_LIQUIDS = "MATERIALS_NORMAL_LIQUIDS";
export const MATERIALS_CARBON_DIOXIDE = "MATERIALS_CARBON_DIOXIDE";
export const MATERIALS_ETHYL_CHLORIDE = "MATERIALS_ETHYL_CHLORIDE";
export const MATERIALS_POTASSIUM_DESC = "MATERIALS_POTASSIUM_DESC";
export const MATERIALS_NEODYMIUM_DESC = "MATERIALS_NEODYMIUM_DESC";
export const MATERIALS_ACTINOIDS_DESC = "MATERIALS_ACTINOIDS_DESC";
export const MATERIALS_MANGANESE_DESC = "MATERIALS_MANGANESE_DESC";
export const MATERIALS_GERMANIUM_DESC = "MATERIALS_GERMANIUM_DESC";
export const MATERIALS_TELLURIUM_DESC = "MATERIALS_TELLURIUM_DESC";
export const MATERIALS_LIMESTONE_DESC = "MATERIALS_LIMESTONE_DESC";
export const MATERIALS_QUICKLIME_DESC = "MATERIALS_QUICKLIME_DESC";
export const MATERIALS_SANDSTONE_DESC = "MATERIALS_SANDSTONE_DESC";
export const MATERIALS_PERICLASE_DESC = "MATERIALS_PERICLASE_DESC";
export const MATERIALS_QUARTZITE_DESC = "MATERIALS_QUARTZITE_DESC";
export const MATERIALS_FIRE_CLAY_DESC = "MATERIALS_FIRE_CLAY_DESC";
export const MATERIALS_BENTONITE_DESC = "MATERIALS_BENTONITE_DESC";
export const MATERIALS_PORCELAIN_DESC = "MATERIALS_PORCELAIN_DESC";
export const MATERIALS_CAST_IRON_DESC = "MATERIALS_CAST_IRON_DESC";
export const MATERIALS_CELLULOSE_DESC = "MATERIALS_CELLULOSE_DESC";
export const MATERIALS_WHITE_OAK_DESC = "MATERIALS_WHITE_OAK_DESC";
export const MATERIALS_HORSEHAIR_DESC = "MATERIALS_HORSEHAIR_DESC";
export const MATERIALS_POLYAMIDE_DESC = "MATERIALS_POLYAMIDE_DESC";
export const MATERIALS_POLYESTER_DESC = "MATERIALS_POLYESTER_DESC";
export const MATERIALS_COAL_TARS_DESC = "MATERIALS_COAL_TARS_DESC";
export const MATERIALS_SEA_WATER_DESC = "MATERIALS_SEA_WATER_DESC";
export const MATERIALS_ACETYLENE_DESC = "MATERIALS_ACETYLENE_DESC";
export const NRJ_MISSION_CONS_HAS_ECS = "NRJ_MISSION_CONS_HAS_ECS";
export const NRJ_MISSION_CONS_COS_PHI = "NRJ_MISSION_CONS_COS_PHI";
export const NRJ_MISSION_TAB_DATASETS = "NRJ_MISSION_TAB_DATASETS";
export const MISSION_USERS_INPUT_MAIL = "MISSION_USERS_INPUT_MAIL";
export const ALARM_PRESET_NULL_VALUES = "ALARM_PRESET_NULL_VALUES";
export const TAG_MAPPER_MANUAL_IMPORT = "TAG_MAPPER_MANUAL_IMPORT";
export const GRID_FILTER_OP_NOT_BLANK = "GRID_FILTER_OP_NOT_BLANK";
export const AFFECT_COMMUNITY_HOSPICE = "AFFECT_COMMUNITY_HOSPICE";
export const AFFECT_COMMUNITY_SHELTER = "AFFECT_COMMUNITY_SHELTER";
export const AFFECT_COMMUNITY_STORAGE = "AFFECT_COMMUNITY_STORAGE";
export const AFFECT_EDUCATION_PRIMARY = "AFFECT_EDUCATION_PRIMARY";
export const AFFECT_PUBLIC_AMBULANCES = "AFFECT_PUBLIC_AMBULANCES";
export const PRED_PRESET_NAME_THERMAL = "PRED_PRESET_NAME_THERMAL";
export const PRED_PRESET_DESC_THERMAL = "PRED_PRESET_DESC_THERMAL";
export const PRED_PERIOD_OVERLAP_FROM = "PRED_PERIOD_OVERLAP_FROM";
export const ALARMS_MAILING_MY_ALARMS = "ALARMS_MAILING_MY_ALARMS";
export const MAILING_ALARMS_NEXT_EXEC = "MAILING_ALARMS_NEXT_EXEC";
export const PRED_QUALITY_TYPE_NORMAL = "PRED_QUALITY_TYPE_NORMAL";
export const PRED_CONFIG_TH_USAGE_HOT = "PRED_CONFIG_TH_USAGE_HOT";
export const CDM_MISSION_COLUMN_LABEL = "CDM_MISSION_COLUMN_LABEL";
export const CDM_REPORT_EXT_REG_CHECK = "CDM_REPORT_EXT_REG_CHECK";
export const CDM_REPORT_EXT_CRIT_REMS = "CDM_REPORT_EXT_CRIT_REMS";
export const ACCESS_USER_USERS_GROUPS = "ACCESS_USER_USERS_GROUPS";
export const ACTION_FAILED_LOAD_VALUE = "ACTION_FAILED_LOAD_VALUE";
export const AG_LOC_NONE_DESC_WARNING = "AG_LOC_NONE_DESC_WARNING";
export const ALERT_STATUS_TO_EVALUATE = "ALERT_STATUS_TO_EVALUATE";
export const ANNOT_RECTANGLE_SELECTOR = "ANNOT_RECTANGLE_SELECTOR";
export const BAIL_MANAGER_CREATE_BAIL = "BAIL_MANAGER_CREATE_BAIL";
export const BAIL_MANAGER_REMOVE_BAIL = "BAIL_MANAGER_REMOVE_BAIL";
export const BR_ERROR_GP_GE_UNLOCATED = "BR_ERROR_GP_GE_UNLOCATED";
export const BUILD_EDIT_INVALID_BUILD = "BUILD_EDIT_INVALID_BUILD";
export const BUILD_ID_CARD_XML_EXPORT = "BUILD_ID_CARD_XML_EXPORT";
export const CFS_RESUME_EXISTING_FORM = "CFS_RESUME_EXISTING_FORM";
export const CONTRACT_INDEXATION_DATE = "CONTRACT_INDEXATION_DATE";
export const DASHBOARD_MODAL_DATASETS = "DASHBOARD_MODAL_DATASETS";
export const DATASET_EQUIVALENT_TITLE = "DATASET_EQUIVALENT_TITLE";
export const DATASET_ERROR_DATE_START = "DATASET_ERROR_DATE_START";
export const DATASET_ERROR_NO_DATASET = "DATASET_ERROR_NO_DATASET";
export const DATASET_LINK_ADD_SAME_IN = "DATASET_LINK_ADD_SAME_IN";
export const DATASET_NORMALIZED_FALSE = "DATASET_NORMALIZED_FALSE";
export const DIA_EDIT_MODE_BUTTON_OFF = "DIA_EDIT_MODE_BUTTON_OFF";
export const DIA_NOTIF_ADD_LINK_TITLE = "DIA_NOTIF_ADD_LINK_TITLE";
export const DIA_NOTIF_FAKE_NODE_LINK = "DIA_NOTIF_FAKE_NODE_LINK";
export const DIA_NOTIF_NO_OTHER_INPUT = "DIA_NOTIF_NO_OTHER_INPUT";
export const DIA_NOTIF_OFFLINE_ENTITY = "DIA_NOTIF_OFFLINE_ENTITY";
export const EMP_PANEL_SURFACES_OTHER = "EMP_PANEL_SURFACES_OTHER";
export const EQUIP_PANEL_SERVICE_ELEC = "EQUIP_PANEL_SERVICE_ELEC";
export const EQUIP_PANEL_SERVICE_FUEL = "EQUIP_PANEL_SERVICE_FUEL";
export const EQUIP_PANEL_SERVICE_LEAK = "EQUIP_PANEL_SERVICE_LEAK";
export const EQUIP_PANEL_SERVICE_MISC = "EQUIP_PANEL_SERVICE_MISC";
export const EQUIP_STORE_TABLE_DELETE = "EQUIP_STORE_TABLE_DELETE";
export const ER_TICKET_DELETE_WARNING = "ER_TICKET_DELETE_WARNING";
export const GLOBAL_ACTION_IMPOSSIBLE = "GLOBAL_ACTION_IMPOSSIBLE";
export const GLOBAL_CDM_MISSION_TITLE = "GLOBAL_CDM_MISSION_TITLE";
export const GLOBAL_ERR_NOT_CONNECTED = "GLOBAL_ERR_NOT_CONNECTED";
export const GLOBAL_LABEL_EMPLACEMENT = "GLOBAL_LABEL_EMPLACEMENT";
export const GLOBAL_NO_DATA_AVAILABLE = "GLOBAL_NO_DATA_AVAILABLE";
export const GLOBAL_NO_OPTIONS_SELECT = "GLOBAL_NO_OPTIONS_SELECT";
export const GLOBAL_TITLE_TIME_PICKER = "GLOBAL_TITLE_TIME_PICKER";
export const GLOBAL_TYPEAHEAD_ADD_NEW = "GLOBAL_TYPEAHEAD_ADD_NEW";
export const GLOBAL_VALUE_HIGHER_THAN = "GLOBAL_VALUE_HIGHER_THAN";
export const GP_BUILD_TAB_ENERGY_SIGN = "GP_BUILD_TAB_ENERGY_SIGN";
export const GRAPH_TICKETS_CLOSE_USER = "GRAPH_TICKETS_CLOSE_USER";
export const GRAPH_TICKETS_TIME_STATE = "GRAPH_TICKETS_TIME_STATE";
export const GRAPH_TICKETS_UNASSIGNED = "GRAPH_TICKETS_UNASSIGNED";
export const ID_CARD_PUBLIC_LINK_FAIL = "ID_CARD_PUBLIC_LINK_FAIL";
export const IMPORT_EQUIP_EQUIP_TITLE = "IMPORT_EQUIP_EQUIP_TITLE";
export const IMPORT_EQUIP_EXCEL_VALUE = "IMPORT_EQUIP_EXCEL_VALUE";
export const IMPORT_IGNORE_VALUES_VAL = "IMPORT_IGNORE_VALUES_VAL";
export const KPI_API_EQUIP_PROPORTION = "KPI_API_EQUIP_PROPORTION";
export const KPI_API_REM_LOW_DECREASE = "KPI_API_REM_LOW_DECREASE";
export const KPI_API_REM_LOW_INCREASE = "KPI_API_REM_LOW_INCREASE";
export const KPI_API_REM_MID_DECREASE = "KPI_API_REM_MID_DECREASE";
export const KPI_API_REM_MID_INCREASE = "KPI_API_REM_MID_INCREASE";
export const MISSION_CDM_DATA_COLUMNS = "MISSION_CDM_DATA_COLUMNS";
export const MISSION_INTERV_EXPLOITER = "MISSION_INTERV_EXPLOITER";
export const PANEL_ACTION_EVENT_TABLE = "PANEL_ACTION_EVENT_TABLE";
export const PLACEHOLDER_NOTE_SUBJECT = "PLACEHOLDER_NOTE_SUBJECT";
export const REG_ADD_ACTION_WITH_SITE = "REG_ADD_ACTION_WITH_SITE";
export const REG_ADD_FROM_ACTION_ELEM = "REG_ADD_FROM_ACTION_ELEM";
export const REG_ALREADY_HAVE_ACCOUNT = "REG_ALREADY_HAVE_ACCOUNT";
export const REG_CANCEL_EDIT_PASSWORD = "REG_CANCEL_EDIT_PASSWORD";
export const REG_CHECK_MAIL_LINK_SENT = "REG_CHECK_MAIL_LINK_SENT";
export const REG_SHOW_ONLY_OCCUPATION = "REG_SHOW_ONLY_OCCUPATION";
export const REM_CAT_COMFORT_AMBIANCE = "REM_CAT_COMFORT_AMBIANCE";
export const RIGHT_CREATE_EMPLACEMENT = "RIGHT_CREATE_EMPLACEMENT";
export const RIGHT_DELETE_EMPLACEMENT = "RIGHT_DELETE_EMPLACEMENT";
export const RIGHT_NRJ_WRITE_OWN_DATA = "RIGHT_NRJ_WRITE_OWN_DATA";
export const RIGHT_READ_OTHER_REG_DOC = "RIGHT_READ_OTHER_REG_DOC";
export const RIGHT_WRITE_NRJ_TAG_AUTO = "RIGHT_WRITE_NRJ_TAG_AUTO";
export const RIGHT_WRITE_OTHER_EVENTS = "RIGHT_WRITE_OTHER_EVENTS";
export const RIGHT_WRITE_OWN_CONTRACT = "RIGHT_WRITE_OWN_CONTRACT";
export const VD_THEORETICAL_SAN_DEBIT = "VD_THEORETICAL_SAN_DEBIT";
export const OPTION_CODE_REG_DOC_TYPES = "OPTION_CODE_REG_DOC_TYPES";
export const GAMME_FORM_OMNICLASS_USED = "GAMME_FORM_OMNICLASS_USED";
export const GAMME_FORM_TRANSLATE_NAME = "GAMME_FORM_TRANSLATE_NAME";
export const SUPERSET_ERROR_CONNECTING = "SUPERSET_ERROR_CONNECTING";
export const DATA_ENTRY_TABLE_SEEMS_OK = "DATA_ENTRY_TABLE_SEEMS_OK";
export const DATASET_PRESET_WATER_CONS = "DATASET_PRESET_WATER_CONS";
export const DATASET_PRESET_WORK_HOURS = "DATASET_PRESET_WORK_HOURS";
export const DATASET_PRESET_START_TEMP = "DATASET_PRESET_START_TEMP";
export const REG_WIZARD_REDUCE_CONTEXT = "REG_WIZARD_REDUCE_CONTEXT";
export const SUPERSET_DASH_WATER_TITLE = "SUPERSET_DASH_WATER_TITLE";
export const SUPERSET_DASH_WASTE_TITLE = "SUPERSET_DASH_WASTE_TITLE";
export const OPTION_CODE_OPENING_TYPES = "OPTION_CODE_OPENING_TYPES";
export const OPTION_CODE_COMMUNICATION = "OPTION_CODE_COMMUNICATION";
export const OPTION_CODE_REM_COST_TYPE = "OPTION_CODE_REM_COST_TYPE";
export const ACCESS_USER_LABEL_BLOCKED = "ACCESS_USER_LABEL_BLOCKED";
export const ACCESS_USER_LABEL_GRANTED = "ACCESS_USER_LABEL_GRANTED";
export const DATA_CORRECT_DATE_TO_KEEP = "DATA_CORRECT_DATE_TO_KEEP";
export const EPRA_DATASET_UNSELECT_ALL = "EPRA_DATASET_UNSELECT_ALL";
export const GLOBAL_CLICK_TO_SHOW_MORE = "GLOBAL_CLICK_TO_SHOW_MORE";
export const ALLOW_REG_MISSIONS_REPORT = "ALLOW_REG_MISSIONS_REPORT";
export const TAXO_MISSION_MIN_SG_1_TIP = "TAXO_MISSION_MIN_SG_1_TIP";
export const TAXO_MISSION_MIN_SG_2_TIP = "TAXO_MISSION_MIN_SG_2_TIP";
export const TAXO_MISSION_ADD_DOCUMENT = "TAXO_MISSION_ADD_DOCUMENT";
export const TAB_ADMIN_DATA_CORRECTION = "TAB_ADMIN_DATA_CORRECTION";
export const NRJ_REPORT_API_NRJ_VECTOR = "NRJ_REPORT_API_NRJ_VECTOR";
export const NRJ_REPORT_API_CONS_SPLIT = "NRJ_REPORT_API_CONS_SPLIT";
export const NRJ_REPORT_API_ELEC_EQUIP = "NRJ_REPORT_API_ELEC_EQUIP";
export const NRJ_REPORT_API_REG_ASPECT = "NRJ_REPORT_API_REG_ASPECT";
export const NRJ_REPORT_API_PER_REGION = "NRJ_REPORT_API_PER_REGION";
export const FORM_REG_PROP_SELECT_TYPE = "FORM_REG_PROP_SELECT_TYPE";
export const NRJ_REPORT_API_CONS_WATER = "NRJ_REPORT_API_CONS_WATER";
export const NRJ_REPORT_API_COST_WATER = "NRJ_REPORT_API_COST_WATER";
export const NRJ_REPORT_API_CONS_RATIO = "NRJ_REPORT_API_CONS_RATIO";
export const NRJ_REPORT_API_RATIO_COST = "NRJ_REPORT_API_RATIO_COST";
export const RED_FLAG_CAT_ARCHITECTURE = "RED_FLAG_CAT_ARCHITECTURE";
export const NRJ_DASH_SUB_MENU_HEATMAP = "NRJ_DASH_SUB_MENU_HEATMAP";
export const CERT_HISTORY_EDIT_HISTORY = "CERT_HISTORY_EDIT_HISTORY";
export const FORM_REG_PROP_STRING_TYPE = "FORM_REG_PROP_STRING_TYPE";
export const FORM_REG_PROP_NUMBER_TYPE = "FORM_REG_PROP_NUMBER_TYPE";
export const DATASET_TABLE_EXPORT_DATA = "DATASET_TABLE_EXPORT_DATA";
export const EDL_EXCEL_COL_RESPONSABLE = "EDL_EXCEL_COL_RESPONSABLE";
export const CAPEX_TABLE_CREATE_ACTION = "CAPEX_TABLE_CREATE_ACTION";
export const MATERIAL_COMPONENTS_LABEL = "MATERIAL_COMPONENTS_LABEL";
export const MATERIALS_SOLID_COMPOUNDS = "MATERIALS_SOLID_COMPOUNDS";
export const MATERIALS_PORTLAND_CEMENT = "MATERIALS_PORTLAND_CEMENT";
export const MATERIALS_SILICON_CARBIDE = "MATERIALS_SILICON_CARBIDE";
export const MATERIALS_METALLIC_ALLOYS = "MATERIALS_METALLIC_ALLOYS";
export const MATERIALS_STAINLESS_STEEL = "MATERIALS_STAINLESS_STEEL";
export const MATERIALS_ALUMINUM_ALLOYS = "MATERIALS_ALUMINUM_ALLOYS";
export const MATERIALS_PLANT_MATERIALS = "MATERIALS_PLANT_MATERIALS";
export const MATERIALS_SOFTWOOD_TIMBER = "MATERIALS_SOFTWOOD_TIMBER";
export const MATERIALS_SPRUCE_PINE_FIR = "MATERIALS_SPRUCE_PINE_FIR";
export const MATERIALS_HARDWOOD_TIMBER = "MATERIALS_HARDWOOD_TIMBER";
export const MATERIALS_DISTILLED_WATER = "MATERIALS_DISTILLED_WATER";
export const MATERIALS_DEIONIZED_WATER = "MATERIALS_DEIONIZED_WATER";
export const MATERIALS_NON_TOXIC_GASES = "MATERIALS_NON_TOXIC_GASES";
export const MATERIALS_FLAMMABLE_GASES = "MATERIALS_FLAMMABLE_GASES";
export const MATERIALS_CARBON_MONOXIDE = "MATERIALS_CARBON_MONOXIDE";
export const MATERIALS_METHYL_CHLORIDE = "MATERIALS_METHYL_CHLORIDE";
export const MATERIALS_MOLYBDENUM_DESC = "MATERIALS_MOLYBDENUM_DESC";
export const MATERIALS_METALLOIDS_DESC = "MATERIALS_METALLOIDS_DESC";
export const MATERIALS_NON_METALS_DESC = "MATERIALS_NON_METALS_DESC";
export const MATERIALS_PHOSPHORUS_DESC = "MATERIALS_PHOSPHORUS_DESC";
export const MATERIALS_TRAVERTINE_DESC = "MATERIALS_TRAVERTINE_DESC";
export const MATERIALS_TERRACOTTA_DESC = "MATERIALS_TERRACOTTA_DESC";
export const MATERIALS_RAIN_WATER_DESC = "MATERIALS_RAIN_WATER_DESC";
export const MATERIALS_LUBRICANTS_DESC = "MATERIALS_LUBRICANTS_DESC";
export const MATERIALS_HELIUM_GAS_DESC = "MATERIALS_HELIUM_GAS_DESC";
export const MATERIALS_OXYGEN_GAS_DESC = "MATERIALS_OXYGEN_GAS_DESC";
export const NRJ_MISSION_CONS_CONS_GAS = "NRJ_MISSION_CONS_CONS_GAS";
export const NRJ_MISSION_CONS_COST_GAS = "NRJ_MISSION_CONS_COST_GAS";
export const DATE_FORMATTER_LOCAL_TIME = "DATE_FORMATTER_LOCAL_TIME";
export const ACCESS_ROLE_SPECIAL_ROLES = "ACCESS_ROLE_SPECIAL_ROLES";
export const TAG_MAPPER_STATION_IMPORT = "TAG_MAPPER_STATION_IMPORT";
export const AFFECT_ENTERTAINMENT_CLUB = "AFFECT_ENTERTAINMENT_CLUB";
export const AFFECT_RETAIL_ELEC_RENTAL = "AFFECT_RETAIL_ELEC_RENTAL";
export const AFFECT_RETAIL_SUPERMARKET = "AFFECT_RETAIL_SUPERMARKET";
export const AFFECT_SPORT_WATER_CENTER = "AFFECT_SPORT_WATER_CENTER";
export const PRED_VALIDATE_AUTO_UPDATE = "PRED_VALIDATE_AUTO_UPDATE";
export const PRED_VALIDATE_UPDATE_FREQ = "PRED_VALIDATE_UPDATE_FREQ";
export const PRED_PRESET_NAME_BASELINE = "PRED_PRESET_NAME_BASELINE";
export const PRED_PRESET_DESC_BASELINE = "PRED_PRESET_DESC_BASELINE";
export const PRED_PRESET_NAME_ACTIVITY = "PRED_PRESET_NAME_ACTIVITY";
export const PRED_PRESET_DESC_ACTIVITY = "PRED_PRESET_DESC_ACTIVITY";
export const PRED_PRESET_NAME_OPENINGS = "PRED_PRESET_NAME_OPENINGS";
export const PRED_PRESET_DESC_OPENINGS = "PRED_PRESET_DESC_OPENINGS";
export const PASSWORD_STRENGTH_TOO_LOW = "PASSWORD_STRENGTH_TOO_LOW";
export const ALARMS_MAILING_UNSUB_TEXT = "ALARMS_MAILING_UNSUB_TEXT";
export const ALARMS_MAILING_SUBSCRIBED = "ALARMS_MAILING_SUBSCRIBED";
export const PRED_QUALITY_UPDATE_MODEL = "PRED_QUALITY_UPDATE_MODEL";
export const PRED_QUALITY_CHANGE_COLOR = "PRED_QUALITY_CHANGE_COLOR";
export const PRED_QUALITY_TYPE_OUTLIER = "PRED_QUALITY_TYPE_OUTLIER";
export const PRED_QUALITY_TYPE_SPECIAL = "PRED_QUALITY_TYPE_SPECIAL";
export const PRED_CONFIG_TH_USAGE_NONE = "PRED_CONFIG_TH_USAGE_NONE";
export const PRED_CONFIG_TH_USAGE_COLD = "PRED_CONFIG_TH_USAGE_COLD";
export const CDM_MAGIC_CREATE_DATASETS = "CDM_MAGIC_CREATE_DATASETS";
export const GLOBAL_STATUS_REG_MISSING = "GLOBAL_STATUS_REG_MISSING";
export const CDM_REPORT_EXT_OTHER_REMS = "CDM_REPORT_EXT_OTHER_REMS";
export const GLOBAL_SELECT_FILE_IMPORT = "GLOBAL_SELECT_FILE_IMPORT";
export const ACCESS_USER_NO_USER_GROUP = "ACCESS_USER_NO_USER_GROUP";
export const ACCESS_USER_PLEASE_SELECT = "ACCESS_USER_PLEASE_SELECT";
export const ACTION_VALUE_NOT_SPECIFIC = "ACTION_VALUE_NOT_SPECIFIC";
export const AUTH_SIGNIN_CREATE_INVITE = "AUTH_SIGNIN_CREATE_INVITE";
export const BAIL_MANAGER_RENTER_LABEL = "BAIL_MANAGER_RENTER_LABEL";
export const BR_B_NB_EQUIP_AUTO_CREATE = "BR_B_NB_EQUIP_AUTO_CREATE";
export const BR_FLOOR_FORMAT_INCORRECT = "BR_FLOOR_FORMAT_INCORRECT";
export const CDM_MISSION_SUMMARY_PANEL = "CDM_MISSION_SUMMARY_PANEL";
export const CFQ_TITLE_AUTO_SAVE_NOTIF = "CFQ_TITLE_AUTO_SAVE_NOTIF";
export const DATA_CHARTS_FAILED_DELETE = "DATA_CHARTS_FAILED_DELETE";
export const DATA_CHARTS_RESTORE_LABEL = "DATA_CHARTS_RESTORE_LABEL";
export const DATA_CHARTS_RESTORE_TITLE = "DATA_CHARTS_RESTORE_TITLE";
export const DATA_ENTRY_ASK_NOTE_TITLE = "DATA_ENTRY_ASK_NOTE_TITLE";
export const DATA_FORM_NO_WRITE_ACCESS = "DATA_FORM_NO_WRITE_ACCESS";
export const DATAGRID_PAGINATION_LABEL = "DATAGRID_PAGINATION_LABEL";
export const DATASET_CHART_MODAL_TITLE = "DATASET_CHART_MODAL_TITLE";
export const DATASET_CUMULATED_TOOLTIP = "DATASET_CUMULATED_TOOLTIP";
export const DATASET_LINK_ADD_SAME_OUT = "DATASET_LINK_ADD_SAME_OUT";
export const DATASET_WARNING_DUPLICATE = "DATASET_WARNING_DUPLICATE";
export const DIA_EDIT_MODE_UNAVAILABLE = "DIA_EDIT_MODE_UNAVAILABLE";
export const ENTERPRISES_PLURAL_SINGLE = "ENTERPRISES_PLURAL_SINGLE";
export const EQUIP_PANEL_SERVICE_LIGHT = "EQUIP_PANEL_SERVICE_LIGHT";
export const EQUIP_PANEL_SERVICE_VANNE = "EQUIP_PANEL_SERVICE_VANNE";
export const EQUIP_PANEL_SERVICE_WATER = "EQUIP_PANEL_SERVICE_WATER";
export const EQUIP_TAB_FORM_TIME_MONEY = "EQUIP_TAB_FORM_TIME_MONEY";
export const ER_DELETE_TICKETS_AS_WELL = "ER_DELETE_TICKETS_AS_WELL";
export const EVENT_DELETE_ALSO_ACTIONS = "EVENT_DELETE_ALSO_ACTIONS";
export const EXCEL_DATA_IMPORT_NO_UNIT = "EXCEL_DATA_IMPORT_NO_UNIT";
export const FE_DUPLICATE_FORMULA_NAME = "FE_DUPLICATE_FORMULA_NAME";
export const GLOBAL_AFFECTATION_DETAIL = "GLOBAL_AFFECTATION_DETAIL";
export const GLOBAL_SEARCH_PLACEHOLDER = "GLOBAL_SEARCH_PLACEHOLDER";
export const GLOBAL_SURFACE_CLIMATISED = "GLOBAL_SURFACE_CLIMATISED";
export const GRAPH_TICKETS_CLOSED_LATE = "GRAPH_TICKETS_CLOSED_LATE";
export const IMPORT_IGNORE_VALUES_PROP = "IMPORT_IGNORE_VALUES_PROP";
export const KPI_API_END_LIFE_FIVE_TEN = "KPI_API_END_LIFE_FIVE_TEN";
export const KPI_API_END_LIFE_OVER_TEN = "KPI_API_END_LIFE_OVER_TEN";
export const KPI_API_REM_HIGH_DECREASE = "KPI_API_REM_HIGH_DECREASE";
export const KPI_API_REM_HIGH_INCREASE = "KPI_API_REM_HIGH_INCREASE";
export const MATRIX_INVALID_URL_PARAMS = 'MATRIX_INVALID_URL_PARAMS';
export const MISSION_CDM_DATA_DATASETS = "MISSION_CDM_DATA_DATASETS";
export const MISSION_CDM_MISSING_PROPS = "MISSION_CDM_MISSING_PROPS";
export const MISSION_INTERV_CONSULTANT = "MISSION_INTERV_CONSULTANT";
export const NAV_TABLE_SUB_AFFECTATION = "NAV_TABLE_SUB_AFFECTATION";
export const P_NEW_TICKET_EQUIP_SELECT = "P_NEW_TICKET_EQUIP_SELECT";
export const PROFILE_UNACCESSIBLE_VIEW = "PROFILE_UNACCESSIBLE_VIEW";
export const REM_FORM_PANEL_DROP_TITLE = "REM_FORM_PANEL_DROP_TITLE";
export const RIGHT_NRJ_WRITE_ANON_DATA = "RIGHT_NRJ_WRITE_ANON_DATA";
export const RIGHT_READ_OTHER_CONTRACT = "RIGHT_READ_OTHER_CONTRACT";
export const RIGHT_WRITE_OTHER_REG_DOC = "RIGHT_WRITE_OTHER_REG_DOC";
export const RIGHT_WRITE_OWN_REMARQUES = "RIGHT_WRITE_OWN_REMARQUES";
export const TABLE_EMPLACEMENT_CREATED = "TABLE_EMPLACEMENT_CREATED";
export const MISSION_REPORT_REPORT_SEND = "MISSION_REPORT_REPORT_SEND";
export const FORM_REG_STATUS_TRANSLATOR = "FORM_REG_STATUS_TRANSLATOR";
export const DATA_ENTRY_TABLE_VALUE_SUM = "DATA_ENTRY_TABLE_VALUE_SUM";
export const LINK_MAP_AUTO_ASSIGN_ERROR = "LINK_MAP_AUTO_ASSIGN_ERROR";
export const SUPERSET_DASH_CONFIG_TITLE = "SUPERSET_DASH_CONFIG_TITLE";
export const OPTIONS_TABLE_VALUE_UNIQUE = "OPTIONS_TABLE_VALUE_UNIQUE";
export const OPTION_CODE_REM_CATEGORIES = "OPTION_CODE_REM_CATEGORIES";
export const REFRIGERANT_BASED_ON_TECO2 = "REFRIGERANT_BASED_ON_TECO2";
export const OPTIONS_TABLE_PROP_NO_EDIT = "OPTIONS_TABLE_PROP_NO_EDIT";
export const EPRA_EMPLACEMENT_SELECTION = "EPRA_EMPLACEMENT_SELECTION";
export const DATA_CORRECT_DATES_TO_KEEP = "DATA_CORRECT_DATES_TO_KEEP";
export const ALLOW_EPRA_MISSIONS_REPORT = "ALLOW_EPRA_MISSIONS_REPORT";
export const REPORT_MISSION_TABLE_ASSET = "REPORT_MISSION_TABLE_ASSET";
export const DATA_CORRECT_CANCEL_PERIOD = "DATA_CORRECT_CANCEL_PERIOD";
export const DATA_CORRECT_DATES_EXCLUDE = "DATA_CORRECT_DATES_EXCLUDE";
export const TAXO_MISSION_PED_THRESHOLD = "TAXO_MISSION_PED_THRESHOLD";
export const TAXO_MISSION_MIN_SAFEGUARD = "TAXO_MISSION_MIN_SAFEGUARD";
export const ALLOW_EU_TAXONOMY_MISSIONS = "ALLOW_EU_TAXONOMY_MISSIONS";
export const NRJ_REPORT_API_REPORT_DATE = "NRJ_REPORT_API_REPORT_DATE";
export const NRJ_REPORT_API_NATURAL_GAS = "NRJ_REPORT_API_NATURAL_GAS";
export const NRJ_REPORT_API_VAT_CHARGES = "NRJ_REPORT_API_VAT_CHARGES";
export const NRJ_REPORT_API_YEARLY_CONS = "NRJ_REPORT_API_YEARLY_CONS";
export const NRJ_REPORT_API_VENTILATION = "NRJ_REPORT_API_VENTILATION";
export const NRJ_REPORT_API_ELEC_SPREAD = "NRJ_REPORT_API_ELEC_SPREAD";
export const NRJ_REPORT_API_KEY_NUMBERS = "NRJ_REPORT_API_KEY_NUMBERS";
export const NRJ_REPORT_API_REPORT_AREA = "NRJ_REPORT_API_REPORT_AREA";
export const GAMME_DEACTIVATION_WARNING = "GAMME_DEACTIVATION_WARNING";
export const NRJ_REPORT_API_CONS_KWH_HS = "NRJ_REPORT_API_CONS_KWH_HS";
export const RED_FLAG_REMS_NOT_ASSIGNED = "RED_FLAG_REMS_NOT_ASSIGNED";
export const CERT_HISTORY_CERTIFY_VALUE = "CERT_HISTORY_CERTIFY_VALUE";
export const CERT_HISTORY_CERTIFICATION = "CERT_HISTORY_CERTIFICATION";
export const MATERIALS_QUANTITY_INVALID = "MATERIALS_QUANTITY_INVALID";
export const MATERIALS_OTHER_NON_METALS = "MATERIALS_OTHER_NON_METALS";
export const MATERIALS_LIMESTONE_MARBLE = "MATERIALS_LIMESTONE_MARBLE";
export const MATERIALS_CALCIUM_SILICATE = "MATERIALS_CALCIUM_SILICATE";
export const MATERIALS_EXPANDED_PERLITE = "MATERIALS_EXPANDED_PERLITE";
export const MATERIALS_PORCELAIN_ENAMEL = "MATERIALS_PORCELAIN_ENAMEL";
export const MATERIALS_ANIMAL_MATERIALS = "MATERIALS_ANIMAL_MATERIALS";
export const MATERIALS_POLYISOCYANURATE = "MATERIALS_POLYISOCYANURATE";
export const MATERIALS_NITROGEN_DIOXIDE = "MATERIALS_NITROGEN_DIOXIDE";
export const MATERIALS_LANTHANOIDS_DESC = "MATERIALS_LANTHANOIDS_DESC";
export const MATERIALS_NOBLE_GASES_DESC = "MATERIALS_NOBLE_GASES_DESC";
export const MATERIALS_SLAKED_LIME_DESC = "MATERIALS_SLAKED_LIME_DESC";
export const MATERIALS_VERMICULITE_DESC = "MATERIALS_VERMICULITE_DESC";
export const MATERIALS_EARTHENWARE_DESC = "MATERIALS_EARTHENWARE_DESC";
export const MATERIALS_FIRED_SHALE_DESC = "MATERIALS_FIRED_SHALE_DESC";
export const MATERIALS_ROCK_FIBERS_DESC = "MATERIALS_ROCK_FIBERS_DESC";
export const MATERIALS_ZINC_ALLOYS_DESC = "MATERIALS_ZINC_ALLOYS_DESC";
export const MATERIALS_LEAD_ALLOYS_DESC = "MATERIALS_LEAD_ALLOYS_DESC";
export const MATERIALS_POLYSTYRENE_DESC = "MATERIALS_POLYSTYRENE_DESC";
export const MATERIALS_POLYSULFIDE_DESC = "MATERIALS_POLYSULFIDE_DESC";
export const MATERIALS_WATER_VAPOR_DESC = "MATERIALS_WATER_VAPOR_DESC";
export const MATERIALS_TOXIC_GASES_DESC = "MATERIALS_TOXIC_GASES_DESC";
export const MATERIALS_REFRIGERANT_DESC = "MATERIALS_REFRIGERANT_DESC";
export const NRJ_MISSION_CONS_ELEC_CONS = "NRJ_MISSION_CONS_ELEC_CONS";
export const NRJ_MISSION_CONS_COST_ELEC = "NRJ_MISSION_CONS_COST_ELEC";
export const NRJ_MISSION_CONS_FUEL_CONS = "NRJ_MISSION_CONS_FUEL_CONS";
export const NRJ_MISSION_CONS_COST_FUEL = "NRJ_MISSION_CONS_COST_FUEL";
export const NRJ_MISSION_TAB_EQUIPMENTS = "NRJ_MISSION_TAB_EQUIPMENTS";
export const DIA_SOCKET_ERROR_NO_UNLOCK = "DIA_SOCKET_ERROR_NO_UNLOCK";
export const TABLE_STATE_INSERT_DEFAULT = "TABLE_STATE_INSERT_DEFAULT";
export const GRID_FILTER_OPERATOR_LABEL = "GRID_FILTER_OPERATOR_LABEL";
export const AFFECT_COMMUNITY_DAYCENTER = "AFFECT_COMMUNITY_DAYCENTER";
export const AFFECT_EDUCATION_SECONDARY = "AFFECT_EDUCATION_SECONDARY";
export const ALARMS_MAILING_UNSUBSCRIBE = "ALARMS_MAILING_UNSUBSCRIBE";
export const MAILING_ALARM_NONE_CREATED = "MAILING_ALARM_NONE_CREATED";
export const CDM_REPORT_EXT_METHODOLOGY = "CDM_REPORT_EXT_METHODOLOGY";
export const CDM_REPORT_EXT_REPORT_DATE = "CDM_REPORT_EXT_REPORT_DATE";
export const ALERT_TIME_LEFT_DISCONNECT = "ALERT_TIME_LEFT_DISCONNECT";
export const AUTH_NEW_USER_MAIL_SUCCESS = "AUTH_NEW_USER_MAIL_SUCCESS";
export const BAIL_MANAGER_IS_FRANCHISED = "BAIL_MANAGER_IS_FRANCHISED";
export const CDM_MISSION_CATEGORY_LABEL = "CDM_MISSION_CATEGORY_LABEL";
export const DATASET_EQUIVALENT_CONFIRM = "DATASET_EQUIVALENT_CONFIRM";
export const DATASETS_FILTERS_SELECTION = "DATASETS_FILTERS_SELECTION";
export const DIA_CONFIG_ORIENTATION_TOP = "DIA_CONFIG_ORIENTATION_TOP";
export const DIA_NOTIF_KICKED_OUT_TITLE = "DIA_NOTIF_KICKED_OUT_TITLE";
export const DIA_NOTIF_NO_LINK_SELECTED = "DIA_NOTIF_NO_LINK_SELECTED";
export const EQUIP_STORE_TABLE_SITE_NEW = "EQUIP_STORE_TABLE_SITE_NEW";
export const ER_NO_ASSOCIATED_EQUIPMENT = "ER_NO_ASSOCIATED_EQUIPMENT";
export const FAIL_DATA_IMPORT_DUPLICATE = "FAIL_DATA_IMPORT_DUPLICATE";
export const FORM_DATA_GRID_MAX_X_ENTRY = "FORM_DATA_GRID_MAX_X_ENTRY";
export const FORM_DATA_GRID_MIN_X_ENTRY = "FORM_DATA_GRID_MIN_X_ENTRY";
export const FORM_SUGGESTED_GAMME_EQUIP = "FORM_SUGGESTED_GAMME_EQUIP";
export const GLOBAL_FAILED_NAME_CONTEXT = "GLOBAL_FAILED_NAME_CONTEXT";
export const GLOBAL_TABLE_UNPIN_TOP_ROW = "GLOBAL_TABLE_UNPIN_TOP_ROW";
export const GLOBAL_TYPEAHEAD_SHOW_MORE = "GLOBAL_TYPEAHEAD_SHOW_MORE";
export const MISSION_CDM_MAGIC_CREATION = "MISSION_CDM_MAGIC_CREATION";
export const MISSION_CDM_NOT_CONTROLLED = "MISSION_CDM_NOT_CONTROLLED";
export const MISSION_REPORT_NEW_VERSION = "MISSION_REPORT_NEW_VERSION";
export const NEW_GEN_ACTION_MAINTENANCE = "NEW_GEN_ACTION_MAINTENANCE";
export const NOTIF_DIA_ERR_UPDATE_FETCH = "NOTIF_DIA_ERR_UPDATE_FETCH";
export const PWD_CHANGE_MAIL_NOT_EXISTS = "PWD_CHANGE_MAIL_NOT_EXISTS";
export const REG_INVALID_REGION_COUNTRY = "REG_INVALID_REGION_COUNTRY";
export const REG_TABLE_LOAD_ALL_ACTIONS = "REG_TABLE_LOAD_ALL_ACTIONS";
export const REM_FORM_PANEL_DROP_ACTION = "REM_FORM_PANEL_DROP_ACTION";
export const RIGHT_NRJ_READ_OTHER_ALARM = "RIGHT_NRJ_READ_OTHER_ALARM";
export const RIGHT_NRJ_WRITE_OTHER_DATA = "RIGHT_NRJ_WRITE_OTHER_DATA";
export const RIGHT_READ_OTHER_REMARQUES = "RIGHT_READ_OTHER_REMARQUES";
export const RIGHT_READ_SITE_REG_ACTION = "RIGHT_READ_SITE_REG_ACTION";
export const RIGHT_WRITE_OTHER_CONTRACT = "RIGHT_WRITE_OTHER_CONTRACT";
export const STATE_SAVER_CONFIRM_UPDATE = "STATE_SAVER_CONFIRM_UPDATE";
export const STATE_SAVER_NEW_STATE_TEXT = "STATE_SAVER_NEW_STATE_TEXT";
export const TREE_EDIT_MODE_GRANTED_MSG = "TREE_EDIT_MODE_GRANTED_MSG";
export const RIGHT_ACCESS_CREATE_MISSION = "RIGHT_ACCESS_CREATE_MISSION"
export const MISSION_REPORT_MAIL_FORM_CC = "MISSION_REPORT_MAIL_FORM_CC";
export const ERROR_DELETE_DATASETS_FIRST = "ERROR_DELETE_DATASETS_FIRST";
export const MISSION_CDM_COMMENT_INITIAL = "MISSION_CDM_COMMENT_INITIAL";
export const DATASET_PRESET_PRESSURE_BAR = "DATASET_PRESET_PRESSURE_BAR";
export const DATASET_PRESET_CONDUCTIVITY = "DATASET_PRESET_CONDUCTIVITY";
export const DATA_ORGANIZER_SANKEY_TITLE = "DATA_ORGANIZER_SANKEY_TITLE";
export const DASH_ENV_CONFIG_PANEL_WATER = "DASH_ENV_CONFIG_PANEL_WATER";
export const DASH_ENV_CONFIG_PANEL_WASTE = "DASH_ENV_CONFIG_PANEL_WASTE";
export const RIGHT_READ_GLOBAL_DOC_TYPES = "RIGHT_READ_GLOBAL_DOC_TYPES";
export const RENT_CREATE_BAIL_FROM_LINKS = "RENT_CREATE_BAIL_FROM_LINKS";
export const LINK_MAP_AUTO_ASSIGN_LOADER = "LINK_MAP_AUTO_ASSIGN_LOADER";
export const LINK_MAP_AUTO_ASSIGN_BUTTON = "LINK_MAP_AUTO_ASSIGN_BUTTON";
export const SUPERSET_DASH_CONFIG_BUTTON = "SUPERSET_DASH_CONFIG_BUTTON";
export const OPTION_CODE_REG_ACTION_DASH = "OPTION_CODE_REG_ACTION_DASH";
export const FEEDBACK_CONFIRMATION_TITLE = "FEEDBACK_CONFIRMATION_TITLE";
export const FEEDBACK_CONFIRMATION_PAR_1 = "FEEDBACK_CONFIRMATION_PAR_1";
export const FEEDBACK_CONFIRMATION_PAR_2 = "FEEDBACK_CONFIRMATION_PAR_2";
export const FEEDBACK_CONFIRMATION_PAR_3 = "FEEDBACK_CONFIRMATION_PAR_3";
export const ACCESS_USER_LABEL_INHERITED = "ACCESS_USER_LABEL_INHERITED";
export const PRED_CONFIG_TIME_GROUP_HOUR = "PRED_CONFIG_TIME_GROUP_HOUR";
export const ALLOW_RSC01_MISSIONS_REPORT = "ALLOW_RSC01_MISSIONS_REPORT";
export const REPORT_MISSION_TABLE_REPORT = "REPORT_MISSION_TABLE_REPORT";
export const INFO_BUBBLE_DONT_SHOW_AGAIN = "INFO_BUBBLE_DONT_SHOW_AGAIN";
export const AUTO_TRANSLATE_NO_FULL_BASE = "AUTO_TRANSLATE_NO_FULL_BASE";
export const TAXO_MISSION_HEAT_POWER_TIP = "TAXO_MISSION_HEAT_POWER_TIP";
export const TAXO_MISSION_CRVA_AVAILABLE = "TAXO_MISSION_CRVA_AVAILABLE";
export const RED_FLAG_CAPEX_SELECT_GROUP = "RED_FLAG_CAPEX_SELECT_GROUP";
export const NRJ_REPORT_API_CO2_EMISSION = "NRJ_REPORT_API_CO2_EMISSION";
export const NRJ_REPORT_API_PRESENTATION = "NRJ_REPORT_API_PRESENTATION";
export const NRJ_REPORT_API_BUILD_AFFECT = "NRJ_REPORT_API_BUILD_AFFECT";
export const NRJ_REPORT_API_LOCAL_AFFECT = "NRJ_REPORT_API_LOCAL_AFFECT";
export const NRJ_REPORT_API_HISTORY_CONS = "NRJ_REPORT_API_HISTORY_CONS";
export const GAMME_TABLE_EDIT_ONLY_EQUIP = "GAMME_TABLE_EDIT_ONLY_EQUIP";
export const EQUIP_PANEL_SERVICE_COUNTER = "EQUIP_PANEL_SERVICE_COUNTER";
export const NRJ_REPORT_API_CONS_MONTHLY = "NRJ_REPORT_API_CONS_MONTHLY";
export const NRJ_REPORT_API_COST_OFF_VAT = "NRJ_REPORT_API_COST_OFF_VAT";
export const JS_NRJ_GLOBAL_CONS_M3_TITLE = "JS_NRJ_GLOBAL_CONS_M3_TITLE";
export const FORM_REG_PROP_PICK_EXISTING = "FORM_REG_PROP_PICK_EXISTING";
export const DATA_EXPORT_FORMATTING_HELP = "DATA_EXPORT_FORMATTING_HELP";
export const MATERIALS_COMPONENT_INVALID = "MATERIALS_COMPONENT_INVALID";
export const MATERIALS_CHEMICAL_ELEMENTS = "MATERIALS_CHEMICAL_ELEMENTS";
export const MATERIALS_TRANSITION_METALS = "MATERIALS_TRANSITION_METALS";
export const MATERIALS_MINERAL_COMPOUNDS = "MATERIALS_MINERAL_COMPOUNDS";
export const MATERIALS_SEDIMENTARY_ROCKS = "MATERIALS_SEDIMENTARY_ROCKS";
export const MATERIALS_METAMORPHIC_ROCKS = "MATERIALS_METAMORPHIC_ROCKS";
export const MATERIALS_BINDING_COMPOUNDS = "MATERIALS_BINDING_COMPOUNDS";
export const MATERIALS_ORGANIC_COMPOUNDS = "MATERIALS_ORGANIC_COMPOUNDS";
export const MATERIALS_DOUGLAS_FIR_LARCH = "MATERIALS_DOUGLAS_FIR_LARCH";
export const MATERIALS_WESTERN_RED_CEDAR = "MATERIALS_WESTERN_RED_CEDAR";
export const MATERIALS_UREA_FORMALDEHYDE = "MATERIALS_UREA_FORMALDEHYDE";
export const MATERIALS_MARBLE_STONE_DESC = "MATERIALS_MARBLE_STONE_DESC";
export const MATERIALS_CARBON_FIBER_DESC = "MATERIALS_CARBON_FIBER_DESC";
export const MATERIALS_CARBON_STEEL_DESC = "MATERIALS_CARBON_STEEL_DESC";
export const MATERIALS_WROUGHT_IRON_DESC = "MATERIALS_WROUGHT_IRON_DESC";
export const MATERIALS_DUCTILE_IRON_DESC = "MATERIALS_DUCTILE_IRON_DESC";
export const MATERIALS_FOOD_OR_FUEL_DESC = "MATERIALS_FOOD_OR_FUEL_DESC";
export const MATERIALS_POLYBUTYLENE_DESC = "MATERIALS_POLYBUTYLENE_DESC";
export const MATERIALS_POLYURETHANE_DESC = "MATERIALS_POLYURETHANE_DESC";
export const MATERIALS_BUTYL_RUBBER_DESC = "MATERIALS_BUTYL_RUBBER_DESC";
export const MATERIALS_CARBON_BLACK_DESC = "MATERIALS_CARBON_BLACK_DESC";
export const MATERIALS_GROUND_WATER_DESC = "MATERIALS_GROUND_WATER_DESC";
export const MATERIALS_HYDROGEN_GAS_DESC = "MATERIALS_HYDROGEN_GAS_DESC";
export const NRJ_MISSION_CONS_TO_CORRECT = "NRJ_MISSION_CONS_TO_CORRECT";
export const NRJ_MISSION_CONS_PEAK_HOURS = "NRJ_MISSION_CONS_PEAK_HOURS";
export const NRJ_MISSION_CONS_COST_WATER = "NRJ_MISSION_CONS_COST_WATER";
export const NRJ_MISSION_CONS_TITLE_ELEC = "NRJ_MISSION_CONS_TITLE_ELEC";
export const NRJ_MISSION_CONS_TITLE_COMB = "NRJ_MISSION_CONS_TITLE_COMB";
export const AFFECT_ENTERTAINMENT_CINEMA = "AFFECT_ENTERTAINMENT_CINEMA";
export const PRED_QUALITY_PARAM_MIN_PLAT = "PRED_QUALITY_PARAM_MIN_PLAT";
export const MAILING_ALARM_LISTING_NUM_N = "MAILING_ALARM_LISTING_NUM_N";
export const PRED_CONFIG_QUALITY_CONTROL = "PRED_CONFIG_QUALITY_CONTROL";
export const KPI_DASHBOARD_HISTORY_TITLE = "KPI_DASHBOARD_HISTORY_TITLE";
export const AUTH_LINK_OVERSIGHT_REQUEST = "AUTH_LINK_OVERSIGHT_REQUEST";
export const AUTO_FILL_REG_HIDE_NO_GAMME = "AUTO_FILL_REG_HIDE_NO_GAMME";
export const AUTO_FILL_REG_SHOW_NO_GAMME = "AUTO_FILL_REG_SHOW_NO_GAMME";
export const BAIL_MANAGER_ENSEIGNE_LABEL = "BAIL_MANAGER_ENSEIGNE_LABEL";
export const BG_OCCUPATION_LABEL_TOOLTIP = "BG_OCCUPATION_LABEL_TOOLTIP";
export const BR_ERROR_UNSPECIFIED_HOPPER = "BR_ERROR_UNSPECIFIED_HOPPER";
export const CDM_DATA_STRUCTURE_EXISTING = "CDM_DATA_STRUCTURE_EXISTING";
export const CFQ_NOTIF_FORM_LOCKED_TITLE = "CFQ_NOTIF_FORM_LOCKED_TITLE";
export const CFS_TITLE_FORM_DISPLAY_TREE = "CFS_TITLE_FORM_DISPLAY_TREE";
export const DATASET_WARNING_ERROR_VALUE = "DATASET_WARNING_ERROR_VALUE";
export const DATASET_WARNING_OVERWRITTEN = "DATASET_WARNING_OVERWRITTEN";
export const DIA_CONFIG_ORIENTATION_LEFT = "DIA_CONFIG_ORIENTATION_LEFT";
export const DIA_NOTIF_ROOT_OUTPUT_TITLE = "DIA_NOTIF_ROOT_OUTPUT_TITLE";
export const DIA_NOTIF_TREE_LOCKED_TITLE = "DIA_NOTIF_TREE_LOCKED_TITLE";
export const ERR_DATE_TO_LOWER_DATE_FROM = "ERR_DATE_TO_LOWER_DATE_FROM";
export const EVENT_ERROR_END_LOWER_START = "EVENT_ERROR_END_LOWER_START";
export const EXCEL_DATA_IMPORT_WITH_UNIT = "EXCEL_DATA_IMPORT_WITH_UNIT";
export const FORM_NOT_ALL_FIELDS_CORRECT = "FORM_NOT_ALL_FIELDS_CORRECT";
export const GLOBAL_LABEL_ARRAY_REQUIRED = "GLOBAL_LABEL_ARRAY_REQUIRED";
export const GLOBAL_TYPEAHEAD_NO_OPTIONS = "GLOBAL_TYPEAHEAD_NO_OPTIONS";
export const IMPORT_IGNORE_VALUES_IGNORE = "IMPORT_IGNORE_VALUES_IGNORE";
export const KPI_API_END_LIFE_ESTIMATION = "KPI_API_END_LIFE_ESTIMATION";
export const KPI_API_END_LIFE_PROPORTION = "KPI_API_END_LIFE_PROPORTION";
export const KPI_API_END_LIFE_UNDER_FIVE = "KPI_API_END_LIFE_UNDER_FIVE";
export const KPI_API_EQUIP_SPREAD_NOT_OK = "KPI_API_EQUIP_SPREAD_NOT_OK";
export const KPI_API_LIFESPAN_MAIN_TITLE = "KPI_API_LIFESPAN_MAIN_TITLE";
export const KPI_API_MAIN_COMMENT_NOT_OK = "KPI_API_MAIN_COMMENT_NOT_OK";
export const LIGHT_TREE_ERR_TYPE_INVALID = "LIGHT_TREE_ERR_TYPE_INVALID";
export const LINK_ERROR_VALUE_NOT_OPTION = "LINK_ERROR_VALUE_NOT_OPTION";
export const MISSION_CDM_DATA_CATEGORIES = "MISSION_CDM_DATA_CATEGORIES";
export const MISSION_CDM_INCOMPLETE_ELEM = "MISSION_CDM_INCOMPLETE_ELEM";
export const PANEL_PORTFOLIO_ID_CARD_NRJ = "PANEL_PORTFOLIO_ID_CARD_NRJ";
export const REG_ADD_ACTION_WITHOUT_SITE = "REG_ADD_ACTION_WITHOUT_SITE";
export const REPORT_FINAL_REPORT_CONFIRM = "REPORT_FINAL_REPORT_CONFIRM";
export const RIGHT_NRJ_WRITE_OTHER_ALARM = "RIGHT_NRJ_WRITE_OTHER_ALARM";
export const RIGHT_READ_OTHER_REG_ACTION = "RIGHT_READ_OTHER_REG_ACTION";
export const RIGHT_WRITE_OTHER_REMARQUES = "RIGHT_WRITE_OTHER_REMARQUES";
export const RIGHT_WRITE_SITE_REG_ACTION = "RIGHT_WRITE_SITE_REG_ACTION";
export const STATE_SAVER_NEW_STATE_TITLE = "STATE_SAVER_NEW_STATE_TITLE";
export const SUSTAINABILITY_REPORT_MODAL = "SUSTAINABILITY_REPORT_MODAL";
export const FORM_REG_STATUS_CODE_INVALID = "FORM_REG_STATUS_CODE_INVALID";
export const FORM_REG_STATUS_NAME_INVALID = "FORM_REG_STATUS_NAME_INVALID";
export const TABLE_GAMME_REASSIGN_CURRENT = "TABLE_GAMME_REASSIGN_CURRENT";
export const DATA_ENTRY_TABLE_ALWAYS_ZERO = "DATA_ENTRY_TABLE_ALWAYS_ZERO";
export const DATASET_PRESET_PRESSURE_MBAR = "DATASET_PRESET_PRESSURE_MBAR";
export const DATA_ORGANIZER_CREATE_SANKEY = "DATA_ORGANIZER_CREATE_SANKEY";
export const SUPERSET_DASH_CONFIG_CONFIRM = "SUPERSET_DASH_CONFIG_CONFIRM";
export const DASH_ENV_CONFIG_PANEL_ENERGY = "DASH_ENV_CONFIG_PANEL_ENERGY";
export const LINK_MAP_AUTO_ASSIGN_SUCCESS = "LINK_MAP_AUTO_ASSIGN_SUCCESS";
export const RENT_CURRENT_SELECTED_RENTER = "RENT_CURRENT_SELECTED_RENTER";
export const FEEDBACK_CONFIRMATION_ENDING = "FEEDBACK_CONFIRMATION_ENDING";
export const ACCESS_USER_LABEL_IN_DESCEND = "ACCESS_USER_LABEL_IN_DESCEND";
export const ID_CARD_PANEL_MISSION_REPORT = "ID_CARD_PANEL_MISSION_REPORT";
export const TAXO_MISSION_FOSSIL_FUEL_TIP = "TAXO_MISSION_FOSSIL_FUEL_TIP";
export const DATA_CORRECT_DATE_TO_EXCLUDE = "DATA_CORRECT_DATE_TO_EXCLUDE";
export const EU_TAXONOMY_HEAT_POWER_PROOF = "EU_TAXONOMY_HEAT_POWER_PROOF";
export const EU_TAXONOMY_SAFEGUARDS_PROOF = "EU_TAXONOMY_SAFEGUARDS_PROOF";
export const TAXO_MISSION_ALIGNMENT_TITLE = "TAXO_MISSION_ALIGNMENT_TITLE";
export const TAXO_MISSION_MIN_SAFEGUARD_1 = "TAXO_MISSION_MIN_SAFEGUARD_1";
export const TAXO_MISSION_MIN_SAFEGUARD_2 = "TAXO_MISSION_MIN_SAFEGUARD_2";
export const NRJ_REPORT_API_ANALYSIS_YEAR = "NRJ_REPORT_API_ANALYSIS_YEAR";
export const NRJ_REPORT_API_NET_AREA_UNIT = "NRJ_REPORT_API_NET_AREA_UNIT";
export const NRJ_REPORT_API_TECH_FEATURES = "NRJ_REPORT_API_TECH_FEATURES";
export const NRJ_REPORT_API_SPECIFIC_CONS = "NRJ_REPORT_API_SPECIFIC_CONS";
export const NRJ_REPORT_API_SPECIFIC_COST = "NRJ_REPORT_API_SPECIFIC_COST";
export const NRJ_REPORT_API_AREA_EMISSION = "NRJ_REPORT_API_AREA_EMISSION";
export const NRJ_REPORT_API_CONS_ANALYSIS = "NRJ_REPORT_API_CONS_ANALYSIS";
export const NRJ_REPORT_API_ELEC_ANALYSIS = "NRJ_REPORT_API_ELEC_ANALYSIS";
export const NRJ_REPORT_API_CONS_ELEC_KWH = "NRJ_REPORT_API_CONS_ELEC_KWH";
export const RED_FLAG_COMMENT_PLACEHOLDER = "RED_FLAG_COMMENT_PLACEHOLDER";
export const RED_FLAG_AUTO_REMS_OVERWRITE = "RED_FLAG_AUTO_REMS_OVERWRITE";
export const EDL_EXCEL_COL_GOOD_CONDITION = "EDL_EXCEL_COL_GOOD_CONDITION";
export const CAPEX_TABLE_SUGGESTIONS_HIDE = "CAPEX_TABLE_SUGGESTIONS_HIDE";
export const CAPEX_TABLE_SUGGESTIONS_SHOW = "CAPEX_TABLE_SUGGESTIONS_SHOW";
export const MATERIALS_PETROLEUM_PRODUCTS = "MATERIALS_PETROLEUM_PRODUCTS";
export const MATERIALS_ALKALI_METALS_DESC = "MATERIALS_ALKALI_METALS_DESC";
export const MATERIALS_IGNEOUS_ROCKS_DESC = "MATERIALS_IGNEOUS_ROCKS_DESC";
export const MATERIALS_HYDRATED_LIME_DESC = "MATERIALS_HYDRATED_LIME_DESC";
export const MATERIALS_EXPANDED_CLAY_DESC = "MATERIALS_EXPANDED_CLAY_DESC";
export const MATERIALS_EXPANDED_MICA_DESC = "MATERIALS_EXPANDED_MICA_DESC";
export const MATERIALS_COPPER_ALLOYS_DESC = "MATERIALS_COPPER_ALLOYS_DESC";
export const MATERIALS_SOUTHERN_PINE_DESC = "MATERIALS_SOUTHERN_PINE_DESC";
export const MATERIALS_HAIR_AND_SKIN_DESC = "MATERIALS_HAIR_AND_SKIN_DESC";
export const MATERIALS_POLYCARBONATE_DESC = "MATERIALS_POLYCARBONATE_DESC";
export const MATERIALS_POLYPROPYLENE_DESC = "MATERIALS_POLYPROPYLENE_DESC";
export const MATERIALS_OTHER_LIQUIDS_DESC = "MATERIALS_OTHER_LIQUIDS_DESC";
export const NRJ_MISSION_CONS_TITLE_WATER = "NRJ_MISSION_CONS_TITLE_WATER";
export const NRJ_MISSION_DATA_CENTER_DESC = "NRJ_MISSION_DATA_CENTER_DESC";
export const CDM_DATA_DATASET_DELTA_INDEX = "CDM_DATA_DATASET_DELTA_INDEX";
export const AFFECT_ENTERTAINMENT_THEATER = "AFFECT_ENTERTAINMENT_THEATER";
export const CDM_REPORT_EXT_DATASET_CHECK = "CDM_REPORT_EXT_DATASET_CHECK";
export const CDM_REPORT_EXT_SUMMARY_TABLE = "CDM_REPORT_EXT_SUMMARY_TABLE";
export const ACTION_MAINTENANCE_LAST_DATE = "ACTION_MAINTENANCE_LAST_DATE";
export const AUTH_NEW_USER_MAIL_FORBIDDEN = "AUTH_NEW_USER_MAIL_FORBIDDEN";
export const BUILD_EDIT_FLOOR_MASS_DELETE = "BUILD_EDIT_FLOOR_MASS_DELETE";
export const BUILD_EDIT_FLOOR_REPLACEMENT = "BUILD_EDIT_FLOOR_REPLACEMENT";
export const CFQ_NO_AFFECTATION_BENCHMARK = "CFQ_NO_AFFECTATION_BENCHMARK";
export const DATA_CHARTS_IMPORT_GAS_TITLE = "DATA_CHARTS_IMPORT_GAS_TITLE";
export const DATASET_AGG_ANNUAL_THRESHOLD = "DATASET_AGG_ANNUAL_THRESHOLD";
export const DATASET_TABLE_OPEN_DASHBOARD = "DATASET_TABLE_OPEN_DASHBOARD";
export const DATASET_WARNING_EMPTY_IMPORT = "DATASET_WARNING_EMPTY_IMPORT";
export const DIA_CONFIG_ORIENTATION_RIGHT = "DIA_CONFIG_ORIENTATION_RIGHT";
export const DIA_NOTIF_CHANGES_MADE_TITLE = "DIA_NOTIF_CHANGES_MADE_TITLE";
export const DIA_NOTIF_NO_BROWSER_SUPPORT = "DIA_NOTIF_NO_BROWSER_SUPPORT";
export const DIA_NOTIF_NO_LINK_DESCENDANT = "DIA_NOTIF_NO_LINK_DESCENDANT";
export const EQUIP_IMPORT_CREATE_NEW_ITEM = "EQUIP_IMPORT_CREATE_NEW_ITEM";
export const EQUIP_IMPORT_CREATE_OLD_ITEM = "EQUIP_IMPORT_CREATE_OLD_ITEM";
export const EQUIP_PANEL_SERVICE_ELEVATOR = "EQUIP_PANEL_SERVICE_ELEVATOR";
export const EQUIP_TABLE_FILE_NAME_EXPORT = "EQUIP_TABLE_FILE_NAME_EXPORT";
export const ERR_DATE_FROM_HIGHER_DATE_TO = "ERR_DATE_FROM_HIGHER_DATE_TO";
export const EVENT_DELETE_TICKETS_WARNING = "EVENT_DELETE_TICKETS_WARNING";
export const EVENT_ERROR_START_HIGHER_END = "EVENT_ERROR_START_HIGHER_END";
export const GLOBAL_AFFECTATION_PRINCIPAL = "GLOBAL_AFFECTATION_PRINCIPAL";
export const GLOBAL_EXPORT_ERROR_OCCURRED = "GLOBAL_EXPORT_ERROR_OCCURRED";
export const GRAPH_TICKETS_CLOSED_IN_TIME = "GRAPH_TICKETS_CLOSED_IN_TIME";
export const GU_EMPTY_USER_TABS_SELECTION = "GU_EMPTY_USER_TABS_SELECTION";
export const KPI_API_COLD_HOT_REPLACE_TIP = "KPI_API_COLD_HOT_REPLACE_TIP";
export const KPI_API_END_LIFE_PER_CRIT_OK = "KPI_API_END_LIFE_PER_CRIT_OK";
export const KPI_API_HIGH_CRIT_MAIN_TITLE = "KPI_API_HIGH_CRIT_MAIN_TITLE";
export const KPI_API_REPLACE_GRAPH_ALL_OK = "KPI_API_REPLACE_GRAPH_ALL_OK";
export const LIGHT_TREE_ERR_LINK_NO_MATCH = "LIGHT_TREE_ERR_LINK_NO_MATCH";
export const LIGHT_TREE_ERR_NODE_REJECTED = "LIGHT_TREE_ERR_NODE_REJECTED";
export const MATRIX_DELETE_COMPARATOR_NUM = "MATRIX_DELETE_COMPARATOR_NUM";
export const MISSION_REPORT_FINAL_VERSION = "MISSION_REPORT_FINAL_VERSION";
export const NAV_CURRENT_CONTEXT_MISSIONS = "NAV_CURRENT_CONTEXT_MISSIONS";
export const NAVIGATOR_DEACTIVATE_MISSION = "NAVIGATOR_DEACTIVATE_MISSION";
export const NOTIF_DIA_ERR_UPDATE_MESSAGE = "NOTIF_DIA_ERR_UPDATE_MESSAGE";
export const NOTIF_NOTHING_IMPORT_MESSAGE = "NOTIF_NOTHING_IMPORT_MESSAGE";
export const REM_TABLE_ACTION_MODAL_TITLE = "REM_TABLE_ACTION_MODAL_TITLE";
export const RIGHT_WRITE_OTHER_REG_ACTION = "RIGHT_WRITE_OTHER_REG_ACTION";
export const TREE_EDIT_MODE_GRANTED_TITLE = "TREE_EDIT_MODE_GRANTED_TITLE";
export const VD_THEORETICAL_DEBIT_PULSION = "VD_THEORETICAL_DEBIT_PULSION";
export const MISSION_REPORT_MAIL_FORM_BODY = "MISSION_REPORT_MAIL_FORM_BODY";
export const MISSION_REPORT_MAIL_FORM_SEND = "MISSION_REPORT_MAIL_FORM_SEND";
export const MISSION_REPORT_REPORT_HISTORY = "MISSION_REPORT_REPORT_HISTORY";
export const FORM_REG_STATUS_COLOR_INVALID = "FORM_REG_STATUS_COLOR_INVALID";
export const GAMME_ASSIGNMENT_WILL_REPLACE = "GAMME_ASSIGNMENT_WILL_REPLACE";
export const MISSION_CDM_SCAN_QR_NOT_FOUND = "MISSION_CDM_SCAN_QR_NOT_FOUND";
export const REG_DOC_STATUS_REQUIRED_FIELD = "REG_DOC_STATUS_REQUIRED_FIELD";
export const BAIL_FORM_CREATION_CONDITIONS = "BAIL_FORM_CREATION_CONDITIONS";
export const ACCESS_USER_LABEL_UNAVAILABLE = "ACCESS_USER_LABEL_UNAVAILABLE";
export const PRED_CONFIG_TIME_GROUP_3_HOUR = "PRED_CONFIG_TIME_GROUP_3_HOUR";
export const DATA_CORRECT_DATE_TO_KEEP_TIP = "DATA_CORRECT_DATE_TO_KEEP_TIP";
export const EPRA_REPARTITION_VALUES_LABEL = "EPRA_REPARTITION_VALUES_LABEL";
export const EPRA_MISSION_PERIODS_REQUIRED = "EPRA_MISSION_PERIODS_REQUIRED";
export const EPRA_MISSION_PERIOD_1_INVALID = "EPRA_MISSION_PERIOD_1_INVALID";
export const EPRA_MISSION_PERIOD_2_INVALID = "EPRA_MISSION_PERIOD_2_INVALID";
export const EPRA_MISSION_PANEL_DATASOURCE = "EPRA_MISSION_PANEL_DATASOURCE";
export const TAXO_MISSION_PROOF_DATE_ERROR = "TAXO_MISSION_PROOF_DATE_ERROR";
export const TABLE_DATASET_LAST_VALUE_DATE = "TABLE_DATASET_LAST_VALUE_DATE";
export const REPORT_MISSION_TABLE_LOW_REMS = "REPORT_MISSION_TABLE_LOW_REMS";
export const EU_TAXONOMY_ADAPTATIONS_PROOF = "EU_TAXONOMY_ADAPTATIONS_PROOF";
export const NRJ_REPORT_API_HEAT_AREA_UNIT = "NRJ_REPORT_API_HEAT_AREA_UNIT";
export const NRJ_REPORT_API_COLD_AREA_UNIT = "NRJ_REPORT_API_COLD_AREA_UNIT";
export const NRJ_REPORT_API_REAL_ELEC_CONS = "NRJ_REPORT_API_REAL_ELEC_CONS";
export const NRJ_REPORT_API_WATER_ANALYSIS = "NRJ_REPORT_API_WATER_ANALYSIS";
export const GAMME_TABLE_FORCE_INHERITANCE = "GAMME_TABLE_FORCE_INHERITANCE";
export const NRJ_REPORT_API_CONS_YEAR_COMB = "NRJ_REPORT_API_CONS_YEAR_COMB";
export const NRJ_REPORT_API_CONS_YEAR_ELEC = "NRJ_REPORT_API_CONS_YEAR_ELEC";
export const JS_NRJ_ENERGY_SIGNATURE_XAXIS = "JS_NRJ_ENERGY_SIGNATURE_XAXIS";
export const CAPEX_ORIGIN_AUTO_MAINTENANCE = "CAPEX_ORIGIN_AUTO_MAINTENANCE";
export const KPI_API_END_LIFE_MISSING_DATA = "KPI_API_END_LIFE_MISSING_DATA";
export const MATERIALS_COMBUSTION_RESIDUES = "MATERIALS_COMBUSTION_RESIDUES";
export const MATERIALS_SYNTHETIC_COMPOUNDS = "MATERIALS_SYNTHETIC_COMPOUNDS";
export const MATERIALS_OTHER_HARMFUL_GASES = "MATERIALS_OTHER_HARMFUL_GASES";
export const MATERIALS_EXPANDED_SHALE_DESC = "MATERIALS_EXPANDED_SHALE_DESC";
export const MATERIALS_VITREOUS_CHINA_DESC = "MATERIALS_VITREOUS_CHINA_DESC";
export const MATERIALS_UNREFINED_SALT_DESC = "MATERIALS_UNREFINED_SALT_DESC";
export const MATERIALS_FERROUS_ALLOYS_DESC = "MATERIALS_FERROUS_ALLOYS_DESC";
export const MATERIALS_MALLEABLE_IRON_DESC = "MATERIALS_MALLEABLE_IRON_DESC";
export const MATERIALS_NATURAL_RUBBER_DESC = "MATERIALS_NATURAL_RUBBER_DESC";
export const MATERIALS_BARK_STEM_ROOT_DESC = "MATERIALS_BARK_STEM_ROOT_DESC";
export const MATERIALS_COAL_TAR_PITCH_DESC = "MATERIALS_COAL_TAR_PITCH_DESC";
export const MATERIALS_NORMAL_LIQUIDS_DESC = "MATERIALS_NORMAL_LIQUIDS_DESC";
export const MATERIALS_CARBON_DIOXIDE_DESC = "MATERIALS_CARBON_DIOXIDE_DESC";
export const MATERIALS_ETHYL_CHLORIDE_DESC = "MATERIALS_ETHYL_CHLORIDE_DESC";
export const NRJ_MISSION_DATA_CENTER_TITLE = "NRJ_MISSION_DATA_CENTER_TITLE";
export const CDM_DATA_DATASET_NOT_SELECTED = "CDM_DATA_DATASET_NOT_SELECTED";
export const DATASET_PANEL_USE_IN_FORMULAS = "DATASET_PANEL_USE_IN_FORMULAS";
export const MISSION_USER_ALREADY_SELECTED = "MISSION_USER_ALREADY_SELECTED";
export const CDM_REPORT_CONTROLLED_ELEMENT = "CDM_REPORT_CONTROLLED_ELEMENT";
export const PRED_QUALITY_PARAM_ALLOW_NULL = "PRED_QUALITY_PARAM_ALLOW_NULL";
export const PRED_CONFIG_TH_USAGE_HOT_COLD = "PRED_CONFIG_TH_USAGE_HOT_COLD";
export const CDM_MISSION_DATA_COL_TIME_END = "CDM_MISSION_DATA_COL_TIME_END";
export const KPI_DASHBOARD_HISTORY_CONFORM = "KPI_DASHBOARD_HISTORY_CONFORM";
export const ADD_EQUIP_GAMME_COPY_PROPERTY = "ADD_EQUIP_GAMME_COPY_PROPERTY";
export const BAIL_MANAGER_MUST_BE_RENTABLE = "BAIL_MANAGER_MUST_BE_RENTABLE";
export const CFQ_NOTIF_FORM_LOCKED_MESSAGE = "CFQ_NOTIF_FORM_LOCKED_MESSAGE";
export const DATASET_NON_CUMULATED_TOOLTIP = "DATASET_NON_CUMULATED_TOOLTIP";
export const DIA_CONFIG_ORIENTATION_BOTTOM = "DIA_CONFIG_ORIENTATION_BOTTOM";
export const DIA_NOTIF_EDIT_MODE_AVAILABLE = "DIA_NOTIF_EDIT_MODE_AVAILABLE";
export const DIA_NOTIF_NO_INFO_GRAPH_TITLE = "DIA_NOTIF_NO_INFO_GRAPH_TITLE";
export const DIA_NOTIF_ONE_LINK_DESCENDANT = "DIA_NOTIF_ONE_LINK_DESCENDANT";
export const EQUIP_PANEL_SERVICE_WATER_HOT = "EQUIP_PANEL_SERVICE_WATER_HOT";
export const ERROR_DELETE_DESCENDANT_FIRST = "ERROR_DELETE_DESCENDANT_FIRST";
export const KPI_API_END_LIFE_PER_CRIT_LOW = "KPI_API_END_LIFE_PER_CRIT_LOW";
export const KPI_API_END_LIFE_PER_CRIT_MID = "KPI_API_END_LIFE_PER_CRIT_MID";
export const KPI_API_REPLACE_GRAPH_ERR_NOW = "KPI_API_REPLACE_GRAPH_ERR_NOW";
export const LOC_REPORT_SHOW_ONLY_RENT_LOC = "LOC_REPORT_SHOW_ONLY_RENT_LOC";
export const REG_DELETE_REG_ACTION_WARNING = "REG_DELETE_REG_ACTION_WARNING";
export const REG_VALIDITY_OVER_THREE_MONTH = "REG_VALIDITY_OVER_THREE_MONTH";
export const MISSION_REPORT_MAIL_FORM_TITLE = "MISSION_REPORT_MAIL_FORM_TITLE";
export const FORM_REG_STATUS_CODE_DUPLICATE = "FORM_REG_STATUS_CODE_DUPLICATE";
export const DATA_ENTRY_TABLE_NEGATIVE_DATA = "DATA_ENTRY_TABLE_NEGATIVE_DATA";
export const OPTION_CODE_INTERVENANTS_ROLES = "OPTION_CODE_INTERVENANTS_ROLES";
export const FEEDBACK_CONFIRMATION_SUBTITLE = "FEEDBACK_CONFIRMATION_SUBTITLE";
export const EPRA_CONTEXT_ACTION_REMOVE_CAT = "EPRA_CONTEXT_ACTION_REMOVE_CAT";
export const EPRA_MISSION_TAB_BUTTON_PERIOD = "EPRA_MISSION_TAB_BUTTON_PERIOD";
export const EPRA_MISSION_PANEL_REPARTITION = "EPRA_MISSION_PANEL_REPARTITION";
export const TABLE_DATASET_LAST_VALUE_TITLE = "TABLE_DATASET_LAST_VALUE_TITLE";
export const REPORT_MISSION_TABLE_HIGH_REMS = "REPORT_MISSION_TABLE_HIGH_REMS";
export const ALLOW_RED_FLAG_MISSIONS_REPORT = "ALLOW_RED_FLAG_MISSIONS_REPORT";
export const TAXO_MISSION_MIN_SAFEGUARD_TIP = "TAXO_MISSION_MIN_SAFEGUARD_TIP";
export const TAXO_MISSION_BUILDING_CRITERIA = "TAXO_MISSION_BUILDING_CRITERIA";
export const TAXO_MISSION_ENERGY_MONITORING = "TAXO_MISSION_ENERGY_MONITORING";
export const BUBBLE_FORM_MANUAL_TRANSLATION = "BUBBLE_FORM_MANUAL_TRANSLATION";
export const NRJ_REPORT_API_WEATHER_STATION = "NRJ_REPORT_API_WEATHER_STATION";
export const NRJ_REPORT_API_HEAT_FLOOR_AREA = "NRJ_REPORT_API_HEAT_FLOOR_AREA";
export const NRJ_REPORT_API_EMISSION_FACTOR = "NRJ_REPORT_API_EMISSION_FACTOR";
export const NRJ_REPORT_API_AREA_CONSIDERED = "NRJ_REPORT_API_AREA_CONSIDERED";
export const NRJ_REPORT_API_REAL_WATER_CONS = "NRJ_REPORT_API_REAL_WATER_CONS";
export const REG_PROP_NO_OPTION_TRANSLATION = "REG_PROP_NO_OPTION_TRANSLATION";
export const NRJ_REPORT_API_CONS_PEAK_HOURS = "NRJ_REPORT_API_CONS_PEAK_HOURS";
export const NRJ_REPORT_API_CONS_DOWN_HOURS = "NRJ_REPORT_API_CONS_DOWN_HOURS";
export const NRJ_REPORT_API_CONS_TO_CORRECT = "NRJ_REPORT_API_CONS_TO_CORRECT";
export const MATERIALS_SOLID_COMPOUNDS_DESC = "MATERIALS_SOLID_COMPOUNDS_DESC";
export const MATERIALS_PORTLAND_CEMENT_DESC = "MATERIALS_PORTLAND_CEMENT_DESC";
export const MATERIALS_SILICON_CARBIDE_DESC = "MATERIALS_SILICON_CARBIDE_DESC";
export const MATERIALS_METALLIC_ALLOYS_DESC = "MATERIALS_METALLIC_ALLOYS_DESC";
export const MATERIALS_STAINLESS_STEEL_DESC = "MATERIALS_STAINLESS_STEEL_DESC";
export const MATERIALS_ALUMINUM_ALLOYS_DESC = "MATERIALS_ALUMINUM_ALLOYS_DESC";
export const MATERIALS_PLANT_MATERIALS_DESC = "MATERIALS_PLANT_MATERIALS_DESC";
export const MATERIALS_SOFTWOOD_TIMBER_DESC = "MATERIALS_SOFTWOOD_TIMBER_DESC";
export const MATERIALS_SPRUCE_PINE_FIR_DESC = "MATERIALS_SPRUCE_PINE_FIR_DESC";
export const MATERIALS_HARDWOOD_TIMBER_DESC = "MATERIALS_HARDWOOD_TIMBER_DESC";
export const MATERIALS_DISTILLED_WATER_DESC = "MATERIALS_DISTILLED_WATER_DESC";
export const MATERIALS_DEIONIZED_WATER_DESC = "MATERIALS_DEIONIZED_WATER_DESC";
export const MATERIALS_NON_TOXIC_GASES_DESC = "MATERIALS_NON_TOXIC_GASES_DESC";
export const MATERIALS_FLAMMABLE_GASES_DESC = "MATERIALS_FLAMMABLE_GASES_DESC";
export const MATERIALS_CARBON_MONOXIDE_DESC = "MATERIALS_CARBON_MONOXIDE_DESC";
export const MATERIALS_METHYL_CHLORIDE_DESC = "MATERIALS_METHYL_CHLORIDE_DESC";
export const PRED_CONFIG_FULL_MONTH_OF_DATA = "PRED_CONFIG_FULL_MONTH_OF_DATA";
export const ALARMS_MAILING_NO_SUBSCRIPTION = "ALARMS_MAILING_NO_SUBSCRIPTION";
export const PRED_CONFIG_TIME_CONSIDERATION = "PRED_CONFIG_TIME_CONSIDERATION";
export const CDM_REPORT_EXT_VISITATION_DATE = "CDM_REPORT_EXT_VISITATION_DATE";
export const KPI_DASHBOARD_HISTORY_PRESENCE = "KPI_DASHBOARD_HISTORY_PRESENCE";
export const AUTH_SIGNIN_FOLLOW_LINK_CREATE = "AUTH_SIGNIN_FOLLOW_LINK_CREATE";
export const AUTH_SIGNIN_LINK_VALIDITY_TIME = "AUTH_SIGNIN_LINK_VALIDITY_TIME";
export const DB_TICKETS_NO_TIME_RESTRICTION = "DB_TICKETS_NO_TIME_RESTRICTION";
export const DIA_CONFIG_ORIENTATION_DEFAULT = "DIA_CONFIG_ORIENTATION_DEFAULT";
export const DIA_NOTIF_ANNOT_NO_CREATE_LINK = "DIA_NOTIF_ANNOT_NO_CREATE_LINK";
export const DIA_NOTIF_FAKE_NODE_LINK_TITLE = "DIA_NOTIF_FAKE_NODE_LINK_TITLE";
export const DIA_NOTIF_NO_OTHER_INPUT_TITLE = "DIA_NOTIF_NO_OTHER_INPUT_TITLE";
export const DIA_NOTIF_OFFLINE_ENTITY_TITLE = "DIA_NOTIF_OFFLINE_ENTITY_TITLE";
export const EQUIP_PANEL_SERVICE_WATER_COLD = "EQUIP_PANEL_SERVICE_WATER_COLD";
export const GLOBAL_NUMBER_MUST_BE_POSITIVE = "GLOBAL_NUMBER_MUST_BE_POSITIVE";
export const GLOBAL_STATUS_REG_MISSING_LATE = "GLOBAL_STATUS_REG_MISSING_LATE";
export const KPI_API_END_LIFE_PER_CRIT_HIGH = "KPI_API_END_LIFE_PER_CRIT_HIGH";
export const KPI_API_REM_PER_CRIT_EVOLUTION = "KPI_API_REM_PER_CRIT_EVOLUTION";
export const KPI_API_TIP_EQUIP_LIGHT_PART_1 = "KPI_API_TIP_EQUIP_LIGHT_PART_1";
export const KPI_API_TIP_EQUIP_LIGHT_PART_2 = "KPI_API_TIP_EQUIP_LIGHT_PART_2";
export const MISSION_CDM_MISSING_PROP_TITLE = "MISSION_CDM_MISSING_PROP_TITLE";
export const NOTIF_EDIT_SUPER_ADMIN_MESSAGE = "NOTIF_EDIT_SUPER_ADMIN_MESSAGE";
export const REG_NOT_COMPATIBLE_GAMME_EQUIP = "REG_NOT_COMPATIBLE_GAMME_EQUIP";
export const RIGHT_WRITE_MAINTENANCE_ACTION = "RIGHT_WRITE_MAINTENANCE_ACTION";
export const MISSION_REPORT_MAIL_FORM_OBJECT = "MISSION_REPORT_MAIL_FORM_OBJECT";
export const OPTION_CODE_CONNECTION_DIAMETER = "OPTION_CODE_CONNECTION_DIAMETER";
export const TABLE_DATASET_LAST_VALUE_NUMBER = "TABLE_DATASET_LAST_VALUE_NUMBER";
export const EQUIP_QR_SCAN_NO_DATASET_ACCESS = "EQUIP_QR_SCAN_NO_DATASET_ACCESS";
export const RIGHT_ALLOW_CDM_MISSIONS_REPORT = "RIGHT_ALLOW_CDM_MISSIONS_REPORT";
export const RIGHT_ALLOW_NRJ_MISSIONS_REPORT = "RIGHT_ALLOW_NRJ_MISSIONS_REPORT";
export const RIGHT_ALLOW_EDL_MISSIONS_REPORT = "RIGHT_ALLOW_EDL_MISSIONS_REPORT";
export const TAXO_MISSION_CLIMATE_MITIGATION = "TAXO_MISSION_CLIMATE_MITIGATION";
export const TAXO_MISSION_CRVA_BEST_PRACTICE = "TAXO_MISSION_CRVA_BEST_PRACTICE";
export const TAXO_MISSION_ADAPTATION_MIN_REQ = "TAXO_MISSION_ADAPTATION_MIN_REQ";
export const NRJ_REPORT_API_SPECIFIC_CONS_KW = "NRJ_REPORT_API_SPECIFIC_CONS_KW";
export const CAPEX_TABLE_TOGGLE_TICKET_GROUP = "CAPEX_TABLE_TOGGLE_TICKET_GROUP";
export const GAMME_DEACTIVATION_WARNING_TEXT = "GAMME_DEACTIVATION_WARNING_TEXT";
export const NRJ_REPORT_API_CONS_WATER_SHORT = "NRJ_REPORT_API_CONS_WATER_SHORT";
export const NRJ_REPORT_API_CONS_REAL_KWH_HS = "NRJ_REPORT_API_CONS_REAL_KWH_HS";
export const NRJ_REPORT_API_CONS_REAL_KWH_HI = "NRJ_REPORT_API_CONS_REAL_KWH_HI";
export const NRJ_REPORT_API_CONS_COMBUSTIBLE = "NRJ_REPORT_API_CONS_COMBUSTIBLE";
export const NRJ_REPORT_API_CHARGE_COMB_OVAT = "NRJ_REPORT_API_CHARGE_COMB_OVAT";
export const NRJ_REPORT_API_CHARGE_ELEC_OVAT = "NRJ_REPORT_API_CHARGE_ELEC_OVAT";
export const PRED_VALIDATE_DATASET_TO_UPDATE = "PRED_VALIDATE_DATASET_TO_UPDATE";
export const CAPEX_ORIGIN_END_LIFE_ESTIMATED = "CAPEX_ORIGIN_END_LIFE_ESTIMATED";
export const MATERIALS_ALKALINE_EARTH_METALS = "MATERIALS_ALKALINE_EARTH_METALS";
export const MATERIALS_OTHER_NON_METALS_DESC = "MATERIALS_OTHER_NON_METALS_DESC";
export const MATERIALS_LIMESTONE_MARBLE_DESC = "MATERIALS_LIMESTONE_MARBLE_DESC";
export const MATERIALS_CALCIUM_SILICATE_DESC = "MATERIALS_CALCIUM_SILICATE_DESC";
export const MATERIALS_EXPANDED_PERLITE_DESC = "MATERIALS_EXPANDED_PERLITE_DESC";
export const MATERIALS_PORCELAIN_ENAMEL_DESC = "MATERIALS_PORCELAIN_ENAMEL_DESC";
export const MATERIALS_ANIMAL_MATERIALS_DESC = "MATERIALS_ANIMAL_MATERIALS_DESC";
export const MATERIALS_POLYISOCYANURATE_DESC = "MATERIALS_POLYISOCYANURATE_DESC";
export const MATERIALS_NITROGEN_DIOXIDE_DESC = "MATERIALS_NITROGEN_DIOXIDE_DESC";
export const NRJ_MISSION_CONS_OFF_PEAK_HOURS = "NRJ_MISSION_CONS_OFF_PEAK_HOURS";
export const AFFECT_ENTERTAINMENT_RESTAURANT = "AFFECT_ENTERTAINMENT_RESTAURANT";
export const PRED_VALIDATE_DATASET_TO_CREATE = "PRED_VALIDATE_DATASET_TO_CREATE";
export const CDM_MISSION_DATA_COL_TIME_START = "CDM_MISSION_DATA_COL_TIME_START";
export const CDM_REPORT_EXT_LOCAL_CONTROLLED = "CDM_REPORT_EXT_LOCAL_CONTROLLED";
export const MISSION_CDM_MAINTENANCE_OPINION = "MISSION_CDM_MAINTENANCE_OPINION";
export const BAIL_MANAGER_NO_LONGER_RENTABLE = "BAIL_MANAGER_NO_LONGER_RENTABLE";
export const EQUIP_PANEL_SERVICE_REFRIGERANT = "EQUIP_PANEL_SERVICE_REFRIGERANT";
export const IMPORT_EQUIP_EMP_NOT_ATTRIBUTES = "IMPORT_EQUIP_EMP_NOT_ATTRIBUTES";
export const KPI_API_END_LIFE_PER_VETUSTY_OK = "KPI_API_END_LIFE_PER_VETUSTY_OK";
export const KPI_API_GRAPH_TITLE_REPARTITION = "KPI_API_GRAPH_TITLE_REPARTITION";
export const REG_VALIDITY_UNDER_THREE_MONTHS = "REG_VALIDITY_UNDER_THREE_MONTHS";
export const GAMME_ASSIGNMENT_WILL_BE_DELETED = "GAMME_ASSIGNMENT_WILL_BE_DELETED";
export const EPRA_TABLE_SELECT_DISPLAYED_ROWS = "EPRA_TABLE_SELECT_DISPLAYED_ROWS";
export const ALERT_OUTDATED_APP_VERSION_TITLE = "ALERT_OUTDATED_APP_VERSION_TITLE";
export const DATA_CORRECT_DATE_TO_EXCLUDE_TIP = "DATA_CORRECT_DATE_TO_EXCLUDE_TIP";
export const EPRA_MISSION_TAB_BUTTON_FAVORITE = "EPRA_MISSION_TAB_BUTTON_FAVORITE";
export const LIGHT_TREE_ERR_CHILDREN_REJECTED = "LIGHT_TREE_ERR_CHILDREN_REJECTED";
export const REPORT_MISSION_TABLE_MEDIUM_REMS = "REPORT_MISSION_TABLE_MEDIUM_REMS";
export const REPORT_MISSION_TABLE_DOC_PRESENT = "REPORT_MISSION_TABLE_DOC_PRESENT";
export const REPORT_MISSION_TABLE_REPORT_DATE = "REPORT_MISSION_TABLE_REPORT_DATE";
export const TAXO_MISSION_MIN_SAFEGUARD_TITLE = "TAXO_MISSION_MIN_SAFEGUARD_TITLE";
export const NRJ_REPORT_API_PARKING_AREA_UNIT = "NRJ_REPORT_API_PARKING_AREA_UNIT";
export const NRJ_REPORT_API_CONVERSION_FACTOR = "NRJ_REPORT_API_CONVERSION_FACTOR";
export const NRJ_REPORT_API_COS_PHY_EVOLUTION = "NRJ_REPORT_API_COS_PHY_EVOLUTION";
export const NRJ_REPORT_API_CONS_KWH_BRACKETS = "NRJ_REPORT_API_CONS_KWH_BRACKETS";
export const NRJ_REPORT_API_EVOLUTION_COS_PHI = "NRJ_REPORT_API_EVOLUTION_COS_PHI";
export const MISSION_WIZARD_AUTO_SAVE_MISSION = "MISSION_WIZARD_AUTO_SAVE_MISSION";
export const EDL_EXCEL_COL_LIFESPAN_ESTIMATED = "EDL_EXCEL_COL_LIFESPAN_ESTIMATED";
export const MATERIALS_POST_TRANSITION_METALS = "MATERIALS_POST_TRANSITION_METALS";
export const MATERIALS_TOXIC_OR_HARMFUL_GASES = "MATERIALS_TOXIC_OR_HARMFUL_GASES";
export const MATERIALS_CHEMICAL_ELEMENTS_DESC = "MATERIALS_CHEMICAL_ELEMENTS_DESC";
export const MATERIALS_TRANSITION_METALS_DESC = "MATERIALS_TRANSITION_METALS_DESC";
export const MATERIALS_MINERAL_COMPOUNDS_DESC = "MATERIALS_MINERAL_COMPOUNDS_DESC";
export const MATERIALS_SEDIMENTARY_ROCKS_DESC = "MATERIALS_SEDIMENTARY_ROCKS_DESC";
export const MATERIALS_METAMORPHIC_ROCKS_DESC = "MATERIALS_METAMORPHIC_ROCKS_DESC";
export const MATERIALS_BINDING_COMPOUNDS_DESC = "MATERIALS_BINDING_COMPOUNDS_DESC";
export const MATERIALS_ORGANIC_COMPOUNDS_DESC = "MATERIALS_ORGANIC_COMPOUNDS_DESC";
export const MATERIALS_DOUGLAS_FIR_LARCH_DESC = "MATERIALS_DOUGLAS_FIR_LARCH_DESC";
export const MATERIALS_WESTERN_RED_CEDAR_DESC = "MATERIALS_WESTERN_RED_CEDAR_DESC";
export const MATERIALS_UREA_FORMALDEHYDE_DESC = "MATERIALS_UREA_FORMALDEHYDE_DESC";
export const NRJ_MISSION_DATA_CENTER_PRESENCE = "NRJ_MISSION_DATA_CENTER_PRESENCE";
export const PRED_QUALITY_PARAM_MIN_PLAT_ZERO = "PRED_QUALITY_PARAM_MIN_PLAT_ZERO";
export const ACCESS_TABLE_ERROR_EDIT_ADD_ROOT = "ACCESS_TABLE_ERROR_EDIT_ADD_ROOT";
export const ALERTS_DELETE_ALERTS_FROM_ALARMS = "ALERTS_DELETE_ALERTS_FROM_ALARMS";
export const DIA_NOTIF_NO_LINK_SELECTED_TITLE = "DIA_NOTIF_NO_LINK_SELECTED_TITLE";
export const MISSION_CDM_MISSING_PROP_CONFIRM = "MISSION_CDM_MISSING_PROP_CONFIRM";
export const EPRA_CHECK_EMP_MISSING_AREA_ERROR = "EPRA_CHECK_EMP_MISSING_AREA_ERROR";
export const EPRA_CHECK_EMP_MISSING_AREA_LABEL = "EPRA_CHECK_EMP_MISSING_AREA_LABEL";
export const GAMME_FORM_INVALID_OMNICLASS_MAIN = "GAMME_FORM_INVALID_OMNICLASS_MAIN";
export const EPRA_PERIOD_EDIT_FORCED_END_MONTH = "EPRA_PERIOD_EDIT_FORCED_END_MONTH";
export const MISSION_CDM_COMMENT_GENERAL_LABEL = "MISSION_CDM_COMMENT_GENERAL_LABEL";
export const DATA_ORGANISER_EDIT_DATASET_INPUT = "DATA_ORGANISER_EDIT_DATASET_INPUT";
export const EPRA_MISSION_TAB_BUTTON_ASSET_CAT = "EPRA_MISSION_TAB_BUTTON_ASSET_CAT";
export const ALLOW_EU_TAXONOMY_MISSIONS_REPORT = "ALLOW_EU_TAXONOMY_MISSIONS_REPORT";
export const TAXO_MISSION_ADAPTATION_SOLUTIONS = "TAXO_MISSION_ADAPTATION_SOLUTIONS";
export const NRJ_REPORT_API_CO2_EMISSION_CRREM = "NRJ_REPORT_API_CO2_EMISSION_CRREM";
export const NRJ_REPORT_API_CONS_PEAK_AND_DOWN = "NRJ_REPORT_API_CONS_PEAK_AND_DOWN";
export const NRJ_REPORT_API_TOTAL_COST_OFF_VAT = "NRJ_REPORT_API_TOTAL_COST_OFF_VAT";
export const RED_FLAG_AUTO_REMS_OVERWRITE_TEXT = "RED_FLAG_AUTO_REMS_OVERWRITE_TEXT";
export const PRED_CONFIG_THERMAL_PROPS_MISSING = "PRED_CONFIG_THERMAL_PROPS_MISSING";
export const MATERIALS_POLYVINYLIDENE_FLUORIDE = "MATERIALS_POLYVINYLIDENE_FLUORIDE";
export const MATERIALS_PETROCHEMICAL_COMPOUNDS = "MATERIALS_PETROCHEMICAL_COMPOUNDS";
export const MATERIALS_PETROLEUM_PRODUCTS_DESC = "MATERIALS_PETROLEUM_PRODUCTS_DESC";
export const EQUIP_IMPORT_CREATE_OR_AUTO_TITLE = "EQUIP_IMPORT_CREATE_OR_AUTO_TITLE";
export const FORM_EQUIP_STORE_INVALID_CATEGORY = "FORM_EQUIP_STORE_INVALID_CATEGORY";
export const KPI_API_EXPECTED_REPLACEMENT_DATE = "KPI_API_EXPECTED_REPLACEMENT_DATE";
export const KPI_API_REPLACE_GRAPH_ERR_OVER_10 = "KPI_API_REPLACE_GRAPH_ERR_OVER_10";
export const MISSION_REPORT_MAIL_FORM_DUPE_MAIL = "MISSION_REPORT_MAIL_FORM_DUPE_MAIL";
export const MISSION_REPORT_MAIL_FORM_RECIPIENT = "MISSION_REPORT_MAIL_FORM_RECIPIENT";
export const GAMME_ASSIGNMENT_ERROR_NOT_TO_SELF = "GAMME_ASSIGNMENT_ERROR_NOT_TO_SELF";
export const EPRA_TABLE_UNSELECT_DISPLAYED_ROWS = "EPRA_TABLE_UNSELECT_DISPLAYED_ROWS";
export const DATA_ORGANISER_EDIT_DATASET_OUTPUT = "DATA_ORGANISER_EDIT_DATASET_OUTPUT";
export const ALERT_OUTDATED_APP_VERSION_MESSAGE = "ALERT_OUTDATED_APP_VERSION_MESSAGE";
export const TAXO_MISSION_PROOF_DATE_ERROR_TEXT = "TAXO_MISSION_PROOF_DATE_ERROR_TEXT";
export const NRJ_REPORT_API_CO2_EMISSION_REGION = "NRJ_REPORT_API_CO2_EMISSION_REGION";
export const NRJ_REPORT_API_REAL_CORRECTED_CONS = "NRJ_REPORT_API_REAL_CORRECTED_CONS";
export const NRJ_REPORT_API_CONS_YEAR_ELEC_COMB = "NRJ_REPORT_API_CONS_YEAR_ELEC_COMB";
export const EDL_EXCEL_COL_END_LIFE_THEORETICAL = "EDL_EXCEL_COL_END_LIFE_THEORETICAL";
export const MATERIALS_POLYMER_MODIFIED_ASPHALT = "MATERIALS_POLYMER_MODIFIED_ASPHALT";
export const MATERIALS_COMBUSTION_RESIDUES_DESC = "MATERIALS_COMBUSTION_RESIDUES_DESC";
export const MATERIALS_SYNTHETIC_COMPOUNDS_DESC = "MATERIALS_SYNTHETIC_COMPOUNDS_DESC";
export const MATERIALS_OTHER_HARMFUL_GASES_DESC = "MATERIALS_OTHER_HARMFUL_GASES_DESC";
export const CFS_ERROR_LOAD_EXISTING_COVID_FORM = "CFS_ERROR_LOAD_EXISTING_COVID_FORM";
export const DIA_NOTIF_NO_BROWSER_SUPPORT_TITLE = "DIA_NOTIF_NO_BROWSER_SUPPORT_TITLE";
export const DIA_NOTIF_NO_LINK_DESCENDANT_TITLE = "DIA_NOTIF_NO_LINK_DESCENDANT_TITLE";
export const EQUIP_IMPORT_CREATE_OLD_ITEM_MULTI = "EQUIP_IMPORT_CREATE_OLD_ITEM_MULTI";
export const KPI_API_REPLACE_GRAPH_ERR_UNDER_10 = "KPI_API_REPLACE_GRAPH_ERR_UNDER_10";
export const FORM_REG_STATUS_MISSING_TRANSLATION = "FORM_REG_STATUS_MISSING_TRANSLATION";
export const GAMME_FORM_INVALID_OMNICLASS_FORMAT = "GAMME_FORM_INVALID_OMNICLASS_FORMAT";
export const GAMME_ASSIGNMENT_ERROR_HAS_CHILDREN = "GAMME_ASSIGNMENT_ERROR_HAS_CHILDREN";
export const EPRA_PERIOD_EDIT_FORCED_START_MONTH = "EPRA_PERIOD_EDIT_FORCED_START_MONTH";
export const DATA_ORGANISER_CREATE_DATASET_INPUT = "DATA_ORGANISER_CREATE_DATASET_INPUT";
export const PRED_CONFIG_TIME_GROUP_QUARTER_HOUR = "PRED_CONFIG_TIME_GROUP_QUARTER_HOUR";
export const EPRA_WARNING_ONLY_CAT_TO_BE_UPDATED = "EPRA_WARNING_ONLY_CAT_TO_BE_UPDATED";
export const REPORT_MISSION_TABLE_DOC_CONFORMITY = "REPORT_MISSION_TABLE_DOC_CONFORMITY";
export const EU_TAXONOMY_ENERGY_MONITORING_PROOF = "EU_TAXONOMY_ENERGY_MONITORING_PROOF";
export const TAXO_MISSION_CRVA_BEST_PRACTICE_TIP = "TAXO_MISSION_CRVA_BEST_PRACTICE_TIP";
export const NRJ_REPORT_API_EP_CONVERSION_FACTOR = "NRJ_REPORT_API_EP_CONVERSION_FACTOR";
export const NRJ_REPORT_API_YEAR_SPENDING_SPREAD = "NRJ_REPORT_API_YEAR_SPENDING_SPREAD";
export const NRJ_REPORT_API_EVOLUTION_COST_WATER = "NRJ_REPORT_API_EVOLUTION_COST_WATER";
export const MAILING_ALARM_USER_ALREADY_SELECTED = "MAILING_ALARM_USER_ALREADY_SELECTED";
export const ROLE_FORM_NO_CONTEXT_APPLICABLE_MSG = "ROLE_FORM_NO_CONTEXT_APPLICABLE_MSG";
export const ACCESS_TABLE_ERROR_EDIT_NOT_ALLOWED = "ACCESS_TABLE_ERROR_EDIT_NOT_ALLOWED";
export const DIA_NOTIF_EDIT_MODE_AVAILABLE_TITLE = "DIA_NOTIF_EDIT_MODE_AVAILABLE_TITLE";
export const DIA_NOTIF_ONE_LINK_DESCENDANT_TITLE = "DIA_NOTIF_ONE_LINK_DESCENDANT_TITLE";
export const EQUIP_IMPORT_CREATE_OLD_ITEM_SINGLE = "EQUIP_IMPORT_CREATE_OLD_ITEM_SINGLE";
export const MISSION_CDM_INCOMPLETE_ELEM_CONFIRM = "MISSION_CDM_INCOMPLETE_ELEM_CONFIRM";
export const RIGHT_READ_OTHER_MAINTENANCE_ACTION = "RIGHT_READ_OTHER_MAINTENANCE_ACTION";
export const TABLE_GAMME_CREATE_NEW_UNDER_CURRENT = "TABLE_GAMME_CREATE_NEW_UNDER_CURRENT";
export const DATA_ORGANISER_CREATE_DATASET_OUTPUT = "DATA_ORGANISER_CREATE_DATASET_OUTPUT";
export const NRJ_REPORT_API_CONS_WATER_METER_CUBE = "NRJ_REPORT_API_CONS_WATER_METER_CUBE";
export const NRJ_REPORT_API_CONS_CORRECTED_KWH_HS = "NRJ_REPORT_API_CONS_CORRECTED_KWH_HS";
export const NRJ_REPORT_API_CONS_CORRECTED_KWH_HI = "NRJ_REPORT_API_CONS_CORRECTED_KWH_HI";
export const NRJ_REPORT_API_DEGREE_DAY_EQUIVALENT = "NRJ_REPORT_API_DEGREE_DAY_EQUIVALENT";
export const MATERIALS_ALKALINE_EARTH_METALS_DESC = "MATERIALS_ALKALINE_EARTH_METALS_DESC";
export const BUILD_EDIT_FLOOR_DELETE_SELECT_LABEL = "BUILD_EDIT_FLOOR_DELETE_SELECT_LABEL";
export const DIA_NOTIF_ANNOT_NO_CREATE_LINK_TITLE = "DIA_NOTIF_ANNOT_NO_CREATE_LINK_TITLE";
export const KPI_API_REPLACE_GRAPH_ERR_UNDER_FIVE = "KPI_API_REPLACE_GRAPH_ERR_UNDER_FIVE";
export const RIGHT_WRITE_OTHER_MAINTENANCE_ACTION = "RIGHT_WRITE_OTHER_MAINTENANCE_ACTION";
export const MISSION_REPORT_MAIL_FORM_NEW_MAIL_TIP = "MISSION_REPORT_MAIL_FORM_NEW_MAIL_TIP";
export const EPRA_CHECK_SITE_MISSING_COUNTRY_ERROR = "EPRA_CHECK_SITE_MISSING_COUNTRY_ERROR";
export const EPRA_CHECK_SITE_MISSING_COUNTRY_LABEL = "EPRA_CHECK_SITE_MISSING_COUNTRY_LABEL";
export const TAXO_MISSION_CLIMATE_ADAPTATION_TITLE = "TAXO_MISSION_CLIMATE_ADAPTATION_TITLE";
export const NRJ_REPORT_API_TOTAL_CHARGE_ELEC_OVAT = "NRJ_REPORT_API_TOTAL_CHARGE_ELEC_OVAT";
export const NRJ_REPORT_API_DEGREE_DAY_COEFFICIENT = "NRJ_REPORT_API_DEGREE_DAY_COEFFICIENT";
export const MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT = "MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT";
export const MATERIALS_POST_TRANSITION_METALS_DESC = "MATERIALS_POST_TRANSITION_METALS_DESC";
export const MATERIALS_TOXIC_OR_HARMFUL_GASES_DESC = "MATERIALS_TOXIC_OR_HARMFUL_GASES_DESC";
export const GLOBAL_CHOICE_WILL_AFFECT_IMMEDIATELY = "GLOBAL_CHOICE_WILL_AFFECT_IMMEDIATELY";
export const MISSION_REPORT_MAIL_FORM_INVALID_EMAIL = "MISSION_REPORT_MAIL_FORM_INVALID_EMAIL";
export const IMPORT_DATA_MAPPER_DATASET_SRC_INVALID = "IMPORT_DATA_MAPPER_DATASET_SRC_INVALID";
export const NRJ_REPORT_API_BUILD_CONSTRUCTION_YEAR = "NRJ_REPORT_API_BUILD_CONSTRUCTION_YEAR";
export const NRJ_REPORT_API_DEGREE_DAY_MINUS_PERIOD = "NRJ_REPORT_API_DEGREE_DAY_MINUS_PERIOD";
export const PRED_CONFIG_THERMAL_PROPS_MISSING_EDIT = "PRED_CONFIG_THERMAL_PROPS_MISSING_EDIT";
export const MATERIALS_POLYVINYLIDENE_FLUORIDE_DESC = "MATERIALS_POLYVINYLIDENE_FLUORIDE_DESC";
export const MATERIALS_PETROCHEMICAL_COMPOUNDS_DESC = "MATERIALS_PETROCHEMICAL_COMPOUNDS_DESC";
export const KPI_API_EXPECTED_CRIT_REPLACEMENT_DATE = "KPI_API_EXPECTED_CRIT_REPLACEMENT_DATE";
export const GAMME_FORM_INVALID_OMNICLASS_DESCENDANT = "GAMME_FORM_INVALID_OMNICLASS_DESCENDANT";
export const MATERIALS_POLYMER_MODIFIED_ASPHALT_DESC = "MATERIALS_POLYMER_MODIFIED_ASPHALT_DESC";
export const MISSION_REPORT_MAIL_FORM_ERROR_NO_OBJECT = "MISSION_REPORT_MAIL_FORM_ERROR_NO_OBJECT";
export const TAXO_MISSION_ADAPT_SOLUTIONS_MIN_REQ_TIP = "TAXO_MISSION_ADAPT_SOLUTIONS_MIN_REQ_TIP";
export const MATERIALS_MANUFACTURED_MINERAL_COMPOUNDS = "MATERIALS_MANUFACTURED_MINERAL_COMPOUNDS";
export const GLOBAL_CRITICITY_FAIL_CRITICITY_TEMPLATE = "GLOBAL_CRITICITY_FAIL_CRITICITY_TEMPLATE";
export const NRJ_REPORT_API_DEGREE_DAY_EQUIVALENT_BASE = "NRJ_REPORT_API_DEGREE_DAY_EQUIVALENT_BASE";
export const NRJ_REPORT_API_CONS_WATER_TOTAL_METER_CUBE = "NRJ_REPORT_API_CONS_WATER_TOTAL_METER_CUBE";
export const PRED_CONFIG_THERMAL_PROPS_MISSING_CONTINUE = "PRED_CONFIG_THERMAL_PROPS_MISSING_CONTINUE";
export const MATERIALS_GLASSES_AND_GLASS_LIKE_MATERIALS = "MATERIALS_GLASSES_AND_GLASS_LIKE_MATERIALS";
export const MISSION_REPORT_MAIL_FORM_ERROR_NO_RECIPIENT = "MISSION_REPORT_MAIL_FORM_ERROR_NO_RECIPIENT";
export const NRJ_REPORT_API_DEGREE_DAY_NORMAL_EQUIVALENT = "NRJ_REPORT_API_DEGREE_DAY_NORMAL_EQUIVALENT";
export const GAMME_ASSIGNMENT_GAMME_ASSIGNMENT_TO_REASSIGN = "GAMME_ASSIGNMENT_GAMME_ASSIGNMENT_TO_REASSIGN";
export const MATERIALS_MANUFACTURED_MINERAL_COMPOUNDS_DESC = "MATERIALS_MANUFACTURED_MINERAL_COMPOUNDS_DESC";
export const MISSION_REPORT_MAIL_FORM_ERROR_INVALIDATE_MAILS = "MISSION_REPORT_MAIL_FORM_ERROR_INVALIDATE_MAILS";
export const MATERIALS_GLASSES_AND_GLASS_LIKE_MATERIALS_DESC = "MATERIALS_GLASSES_AND_GLASS_LIKE_MATERIALS_DESC";
export const NRJ_REPORT_API_ELECTRICITY_CONSUMPTION_EVOLUTION = "NRJ_REPORT_API_ELECTRICITY_CONSUMPTION_EVOLUTION";