import auth from "./Auth";
import socket from "./socket";
import crumbs from "./crumbs";
import alerts from "./alerts";
import portals from "./Portals";
import formIds from "./FormIds";
import language from "./Language";
import dataContext from "./context";
import rights from "./RightsContext";
import formContext from "./formContext";
import { combineReducers } from "redux";
import table_state from "./table_state";
import routes_states from "./routes_states";
import storedLanguages from "./languageLoad";

const combinedReducers = combineReducers({
  auth,
  alerts,
  crumbs,
  socket,
  rights,
  formIds,
  portals,
  language,
  table_state,
  dataContext,
  formContext,
  routes_states,
  storedLanguages,
});

export default combinedReducers;

export * from "./Auth";
export * from "./crumbs";
export * from "./alerts";
export * from "./Portals";
export * from "./context";
export * from "./Language";
export * from "./formContext";
export * from "./table_state";
export * from "./languageLoad";
export * from "./routes_states";