import _ from "lodash";
import React from 'react';
import moment from "moment";
import * as M from "../Modal";
import * as H from '../../hooks';
import * as C from '../../Common';
import * as S from "../../services"
import * as R from "../../reducers";
import * as DOM from "react-router-dom";
import * as PM from "../../PurposeModal";
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import * as US from "../../services/user.service";
import { FP, T, TB, TC, RIGHTS, MISSION } from '../../Constants';

const MAX_RECENT_NAV_ITEM = T.API.Utils.Navigator.MAX_RECENT_NAV_ITEM;
type GlobalResource = ReturnType<T.API.Utils.Navigator.NavigatorPortfoliosAndRecent>;
type ContextResource = ReturnType<T.API.Utils.Navigator.NavigatorSubContextMissions>;

const ICONS = {
    [FP.CLIENT_FORM]: "user",
    [FP.SITE_FORM]: "city",
    [FP.BUILDING_FORM]: "building",
    [FP.EMPLACEMENT_FORM]: "door-closed",
}

const NestedDropdown: React.FC = () => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const dispatch = useDispatch();
    const main = H.useBoolean(false);
    const navigate = DOM.useNavigate();
    const mission = H.useBoolean(false);
    const selection = H.useBoolean(false);
    const [roots, mission_id] = H.useRoots();
    const reload_context = React.useRef(false);
    const ref_main = React.useRef<HTMLDivElement>(null);
    const ref_mission = React.useRef<HTMLDivElement>(null);
    const ref_selection = React.useRef<HTMLDivElement>(null);
    const positions_mission = H.useElementPosition(ref_mission.current);
    const positions_selection = H.useElementPosition(ref_selection.current);
    const [ext_mission, set_ext_mission] = H.useAsyncState<ContextResource["missions"]>([]);
    const [global, set_global, global_status] = H.useAsyncState<GlobalResource>({ portfolios: [], recent: [], recent_missions: [] });
    const [context, set_context, context_status, set_context_status] = H.useAsyncState<ContextResource>({ sub_context: [], missions: [] });

    //#region Load data
    const context_content_ids = React.useMemo(() => {
        let context_ids = [];
        const recursive = (item: typeof context["sub_context"][number]) => {
            context_ids.push(item.id);
            for (let sub_item of item.sub_context) recursive(sub_item);
        }
        // Group every id present in the current context
        context.sub_context.forEach(recursive);
        return context_ids;
    }, [context.sub_context]);

    const reload_context_data = React.useMemo(() => {
        // Ref says the context must be reloaded
        if (reload_context.current) return true;
        // The current roots
        let vRoots = TB.findIds(roots.roots);
        // Are the current roots not in the former context, if roots are empty, then a portfolio was selected so reload is ok
        let need_reload = vRoots.length === 0 || vRoots.some(id => !context_content_ids.includes(id));
        return need_reload;
    }, [context_content_ids, roots.roots]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.navigatorPortfoliosAndRecent()
            .then(({ data }) => isSubscribed && set_global(data, "done"))
            .catch(() => isSubscribed && set_global({ portfolios: [], recent: [], recent_missions: [] }, "error"))
        return () => { isSubscribed = false };
    }, [set_global]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (reload_context_data) S.navigatorSubContextMissions(roots)
            .then(({ data }) => isSubscribed && set_context(data, "done"))
            .catch(() => isSubscribed && set_context({ sub_context: [], missions: [] }, "error"))
            // Reset the reload_context ref
            .finally(() => reload_context.current = false);
        return () => { isSubscribed = false };
    }, [set_context, reload_context_data, roots]);

    React.useEffect(() => {
        // Set the data as "loading"
        if (reload_context_data) set_context_status("load");
    }, [reload_context_data, set_context_status]);

    React.useEffect(() => {
        let isSubscribed = true;
        let fetch_ext_mission = mission_id && !context.missions.some(m => m.value === mission_id);
        if (fetch_ext_mission) S.usersMissions(mission_id)
            .then(({ data }) => isSubscribed && set_ext_mission(data, "done"))
            .catch(() => isSubscribed && set_ext_mission([], "error"));
        else set_ext_mission([], "done");
        return () => {
            isSubscribed = false;
            set_ext_mission([], "load");
        }
    }, [mission_id, context.missions, set_ext_mission]);
    //#endregion

    //#region Events listener
    const close_all = React.useCallback(() => {
        main.setFalse();
        mission.setFalse();
        selection.setFalse();
    }, [main, mission, selection]);

    H.useOnClickOutside(ref_main, close_all);
    H.useOnClickOutside(ref_mission, mission.setFalse);
    H.useOnClickOutside(ref_selection, selection.setFalse);
    //#endregion

    //#region Context
    const current_context = React.useMemo(() => {
        // Currently in a portfolio
        if (roots.portfolio) return context.current;
        // Currently in a mission
        else if (TB.mongoIdValidator(mission_id)) {
            let mission = context.missions.filter(m => m.value === mission_id)[0]
                || ext_mission.filter(m => m.value === mission_id)[0];
            if (mission) return { type: "mission" as "mission", ...mission };
            else return null;
        }
        // Currently in a root
        else {
            let context_current: typeof context.current;

            const recursive = (sub_context: typeof context.sub_context[number]) => {
                if (sub_context.id === roots.roots || sub_context.id === roots.roots?.[0]) context_current = {
                    type: "sub",
                    id: sub_context.id,
                    name: sub_context.name,
                    path: sub_context.path,
                }
                else sub_context.sub_context.forEach(recursive);
            }

            context.sub_context.forEach(recursive);

            return context_current;
        }
    }, [context, mission_id, ext_mission, roots]);

    const can_create_mission = React.useMemo(() => {
        if (current_context?.type !== "sub") return false;
        // For each mission type, check that the user has the right for that type, and that the type of context is accepted
        else return MISSION.MissionTypes.some(type => rights.isRightAllowed(type.right) && type.allowed_asset.includes(current_context.path));
    }, [current_context, rights]);

    const show_mission_sub_menu = React.useMemo(() => rights.isRightAllowed(RIGHTS.MISSION.ACCESS_MISSIONS), [rights]);
    //#endregion

    //#region Events
    const events = React.useMemo(() => ({
        unselect_mission: () => {
            // Close the menu
            close_all();
            // Update the redux
            dispatch(R.unselectContextMission());
            // If currently in a mission wizard, redirect to id card
            if (window.location.pathname.includes("/mission/")) navigate("/card_id");
        },
        select_portfolio: (id: string) => {
            // Update the redux
            dispatch(R.selectContextPortfolio(id));
            // Update the local list of recent items
            set_global(prev => {
                // Get the loaded portfolio data
                let portfolio = prev.portfolios.filter(p => p.id === id)[0];
                // Portfolio exists
                if (portfolio) {
                    // Clone the array and remove the portfolio it is appeared in it already
                    let new_recent = [...prev.recent].filter(p => p.id !== id);
                    // Add the selected portfolio in first place
                    new_recent.unshift({ type: "portfolio", ...portfolio });
                    // Keep the number of recent items to max 5
                    if (new_recent.length > MAX_RECENT_NAV_ITEM) new_recent = new_recent.splice(0, MAX_RECENT_NAV_ITEM);
                    return { ...prev, recent: new_recent };
                }
                else return prev;
            });
            // Close the menus
            close_all();
        },
        select_root: (id: string, path: string, name?: string, is_from_direct_context = false) => {
            // Should it be saved as a recent pick
            let save_as_recent = reload_context.current || !context_content_ids.includes(id);
            // Update the local list of recent items
            if (save_as_recent) set_global(prev => {
                // Find the selected item from the list of recent item
                let item = prev.recent.filter(r => r.id === id)[0];
                // Find the selected item from the list of sub-context
                if (!item) {
                    let found = context.sub_context.filter(s => s.id === id)[0];
                    if (found) item = { type: "sub", id: found.id, name: found.name, path: found.path };
                    else if (name) item = { type: "sub", id, path, name };
                }

                if (item) {
                    // Clone the array and remove a possible previous appearance from the root
                    let new_recent = [...prev.recent].filter(p => p.id !== id);
                    // Add the selected root in the first place
                    new_recent.unshift(item);
                    // Keep the number of recent items to max 5
                    if (new_recent.length > MAX_RECENT_NAV_ITEM) new_recent = new_recent.splice(0, MAX_RECENT_NAV_ITEM);
                    return { ...prev, recent: new_recent };
                }
                else return prev;
            });
            // Update the redux
            dispatch(R.selectContextItem({ item: id, path, save_recent: save_as_recent }));
            // If currently in a mission wizard, redirect to id card
            if (window.location.pathname.includes("/mission/") && !is_from_direct_context) navigate("/card_id");
            // Close the menus
            close_all();
        },
        select_recent: (id: string, type: GlobalResource["recent"][number]["type"], path?: string) => {
            if (type === "portfolio") events.select_portfolio(id);
            else {
                // Force the context to be reloaded
                reload_context.current = true;
                // Select the root
                events.select_root(id, path);
            }
        },
        add_portfolio: () => M.renderPortfolioForm().then(portfolio => {
            if (portfolio) set_global(prev => ({
                ...prev,
                portfolios: _.sortBy(prev.portfolios.concat({
                    id: portfolio._id,
                    name: portfolio.name,
                    picture: portfolio.image,
                }), "name")
            }));
        }),
        edit_portfolio: (id: string) => {
            US.getPortfolio(id).then(({ data }) => {
                M.renderPortfolioForm({ submission: data }).then(portfolio => {
                    if (portfolio) set_global(prev => ({
                        ...prev,
                        portfolios: _.sortBy(prev.portfolios.map(p => {
                            if (p.id === portfolio._id) return {
                                id: portfolio._id,
                                name: portfolio.name,
                                picture: portfolio.image,
                            };
                            else return p;
                        }), "name")
                    }));
                })
            }).catch(M.Alerts.loadError);
        },
        remove_portfolio: (id: string) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) US.removePortfolios({ _id: id }).then(() => {
                    set_global(p => ({
                        ...p,
                        recent: p.recent.filter(r => r.id !== id),
                        portfolios: p.portfolios.filter(po => po.id !== id),
                    }));
                }).catch(M.Alerts.deleteError);
            })
        },
        change_root: () => {
            M.renderSearchContext().then(results => {
                if (results) {
                    let path = "", name = results.name;
                    if (results.site) name += " (" + results.site + ")";
                    if (results.type === "building") path = FP.BUILDING_FORM;
                    else if (results.type === "client") path = FP.CLIENT_FORM;
                    else if (results.type === "site") path = FP.SITE_FORM;
                    // Force the context to be reloaded
                    reload_context.current = true;
                    // Select the root
                    events.select_root(results.id, path, name);
                }
            });
        },
        add_mission: () => {
            let asset = roots.roots?.[0];
            if (asset) PM.searchAssetMission({ asset }).then(existing => {
                if (existing !== "cancel") {
                    let copy: Partial<T.Mission>;
                    if (existing) {
                        copy = _.omit(existing, ["_id", "asset", "owner", "tasks", "controlled", "updatedAt", "createdAt", "extra", "deleted_by", "billed_date"]);
                        if (existing.type === "cdm") copy.extra = { cdm: { comment: existing.extra?.cdm?.comment, ignore_rems: existing.extra?.cdm?.ignore_rems } };
                    }

                    M.renderMissionForm({ asset, mission: copy, navigate }).then(mission => {
                        if (mission && typeof mission !== "string") S.usersMissions(mission._id)
                            .then(mission_data => {
                                let mission_infos = mission_data.data[0];
                                if (!mission_infos) throw new Error("Didn't find mission");
                                else set_context(p => ({
                                    ...p,
                                    missions: p.missions.concat({
                                        value: mission_infos.value,
                                        asset: mission_infos.asset,
                                        mission_type: mission_infos.mission_type,
                                        created: moment(mission.createdAt).format("DD/MM/YYYY"),
                                        path: current_context.type === "sub" ? current_context.path : "",
                                        label: `${mission_infos.label} - ${moment(mission.createdAt).format("DD/MM/YYYY")}`,
                                        final: mission.tasks.some(t => t.type === "report" && t.versions.some(v => v.final)),
                                    }),
                                }));
                            })
                            .catch(() => set_context(p => ({
                                ...p,
                                missions: p.missions.concat({
                                    value: mission._id,
                                    asset: mission.asset,
                                    mission_type: mission.type,
                                    created: moment(mission.createdAt).format("DD/MM/YYYY"),
                                    path: current_context.type === "sub" ? current_context.path : "",
                                    final: mission.tasks.some(t => t.type === "report" && t.versions.some(v => v.final)),
                                    label: `${mission.type.toUpperCase()} - ${mission.ref_provider} - ${moment(mission.createdAt).format("DD/MM/YYYY")}`,
                                }),
                            })));
                    });
                }
            });
        },
        select_mission: (mission: ContextResource["missions"][number]) => {
            // If the mission is selected from the local context, update the name of the mission, to make it contain the name of the context
            let mission_with_correct_name = mission;
            // Update the local recent mission
            set_global(prev => {
                // Clone the array and remove a possible previous appearance from the root
                let new_recent = [...prev.recent_missions].filter(p => p.value !== mission.value);
                // Add the selected root in the first place
                new_recent.unshift(mission_with_correct_name);
                // Keep the number of recent items to max 5
                if (new_recent.length > MAX_RECENT_NAV_ITEM) new_recent = new_recent.splice(0, MAX_RECENT_NAV_ITEM);
                return { ...prev, recent_missions: new_recent };
            });
            // Update redux
            dispatch(R.selectContextMission({ asset: mission.asset, mission: mission.value, path: mission.path }));
            // Close the menus
            close_all();
            // Navigate to the mission wizard
            let path = "/mission/";
            if (mission.mission_type === "nrj") path += "nrj_report";
            else if (mission.mission_type === "epra") path += "epra";
            else if (mission.mission_type === "edl_reg") path += "reg";
            else if (mission.mission_type === "rsc01") path += "rsc01";
            else if (mission.mission_type === "red_flag") path += "red_flag";
            else if (mission.mission_type === "eu_taxonomy") path += "eu_taxonomy";
            else if (mission.mission_type === "cdm" || mission.mission_type === "edl_tech") path += "cdm";
            else path = "";
            // Valid path and not currently on the target page
            if (path.length > 0 && !window.location.pathname.includes(path)) navigate(path);
        },
        edit_mission: (mission: ContextResource["missions"][number]) => {
            M.renderMissionForm({ mission_id: mission.value, asset: mission.asset, navigate }).then(edited_mission => {
                // Mission was deleted
                if (edited_mission === "deleted") {
                    // Remove the mission from the local list
                    set_context(p => ({ ...p, missions: p.missions.filter(m => m.value !== mission.value) }));
                    // Remove the mission from the recent list
                    set_global(p => ({ ...p, recent_missions: p.recent_missions.filter(m => m.value !== mission.value) }));
                }
                // Mission was edited
                else if (edited_mission) set_context(p => ({
                    ...p,
                    missions: p.missions.map(m => {
                        if (m.value !== edited_mission._id) return m;
                        return {
                            path: mission.path,
                            value: edited_mission._id,
                            asset: edited_mission.asset,
                            mission_type: edited_mission.type,
                            created: moment(edited_mission.createdAt).format("DD/MM/YYYY"),
                            label: `${edited_mission.type.toUpperCase()} - ${edited_mission.ref_provider}`,
                            final: edited_mission.tasks.some(t => t.type === "report" && t.versions.some(v => v.final)),
                        };
                    })
                }));
            });
        },
        search_mission: () => {
            PM.searchMission({ isRequired: true }).then(mission => {
                if (mission) events.select_mission(mission);
            });
        },
        remove_recent: (id: string) => {
            S.removeRecentList(id)
                .then(() => set_global(p => ({ ...p, recent: p.recent.filter(r => r.id !== id) })))
                .catch(M.Alerts.updateError);
        },
    }), [dispatch, set_global, close_all, navigate, set_context, context_content_ids, current_context, context.sub_context, roots.roots]);
    //#endregion

    //#region Styles
    const selection_menu_style = React.useMemo<React.CSSProperties>(() => ({
        marginTop: (-1 * positions_selection.height) + "px",
        marginLeft: (positions_selection.right - positions_selection.left) + "px",
    }), [positions_selection.left, positions_selection.right, positions_selection.height]);

    const mission_menu_style = React.useMemo<React.CSSProperties>(() => ({
        marginTop: (-1 * positions_mission.height) + "px",
        marginLeft: (positions_mission.right - positions_mission.left) + "px",
    }), [positions_mission.left, positions_mission.right, positions_mission.height]);
    //#endregion

    //#region Content
    const render_sub_context = React.useCallback((context: ContextResource["sub_context"][number], parent = "", level = 0) => {
        // Added parent to key, because the same element can appear twice, with the only difference being the parent
        return <React.Fragment key={context.id + parent}>
            <Dropdown.Item
                as="div"
                active={roots?.roots?.includes(context.id)}
                onClick={() => events.select_root(context.id, context.path, undefined, true)}
                className='d-flex justify-content-between align-items-center'
            >
                <span style={{ marginLeft: level + "rem" }}>
                    <i className={"me-2 fa fa-" + ICONS[context.path]}></i>
                    {context.name}
                </span>
            </Dropdown.Item>
            {context.sub_context.map(c => render_sub_context(c, context.id, level + 1))}
        </React.Fragment >
    }, [events, roots?.roots]);

    const portfolio_content = React.useMemo(() => <C.Spinner loader_className="my-1" size="sm" status={global_status}>
        {global.portfolios.length === 0
            ? <Dropdown.ItemText>
                {lg.getStaticText(TC.NAV_EMPTY_PORTFOLIOS)}
            </Dropdown.ItemText>
            : global.portfolios.map(p => <Dropdown.Item
                as="div"
                key={p.id}
                className='d-flex justify-content-between align-items-center'
            >
                <span className='flex-grow-1 pointer' onClick={() => events.select_portfolio(p.id)}>
                    {p.picture
                        ? <img alt="" width={20} height={20} src={p.picture} />
                        : <i className='fa fa-wallet'></i>}
                    <span className='ms-1'>{p.name}</span>
                </span>
                <C.IconButton
                    size="sm"
                    icon="pencil"
                    className="me-2"
                    variant="primary"
                    onClick={() => events.edit_portfolio(p.id)}
                />
                <C.IconButton
                    size="sm"
                    icon="times"
                    variant="danger"
                    onClick={() => events.remove_portfolio(p.id)}
                />
            </Dropdown.Item>)
        }
    </C.Spinner>, [global.portfolios, global_status, events, lg]);

    const recent_content = React.useMemo(() => <C.Spinner loader_className="my-1" size="sm" status={global_status}>
        {global.recent.length === 0
            ? <Dropdown.ItemText children={lg.getStaticText(TC.NAV_EMPTY_RECENT)} />
            : global.recent.map(r => <Dropdown.Item
                as="div"
                key={r.id}
                className='d-flex justify-content-between align-items-center'
            >
                {r.type === "portfolio"
                    ? <C.Flex className="w-100" justifyContent="between" alignItems="center">
                        <span className="flex-grow-1 me-1" onClick={() => events.select_recent(r.id, r.type, null)}>
                            {r.picture
                                ? <img alt="" width={20} height={20} src={r.picture} />
                                : <i className='fa fa-wallet'></i>}
                            <span className='ms-1'>{r.name}</span>
                        </span>
                        <C.IconButton icon="times" className="text-danger" onClick={() => events.remove_recent(r.id)} />
                    </C.Flex>
                    : <C.Flex className="w-100" justifyContent="between" alignItems="center">
                        <span className="flex-grow-1 me-1" onClick={() => events.select_recent(r.id, r.type, r.path)}>
                            <i className={`fa fa-${ICONS[r.path]} me-2`}></i>
                            {r.name}
                        </span>
                        <C.IconButton icon="times" className="text-danger" onClick={() => events.remove_recent(r.id)} />
                    </C.Flex>
                }
            </Dropdown.Item>)}
    </C.Spinner>, [global_status, global.recent, events, lg]);

    const sub_context_content = React.useMemo(() => <C.Spinner loader_className="my-1" size="sm" status={context_status}>
        {context.sub_context.length === 0
            ? <Dropdown.ItemText>
                {lg.getStaticText(TC.NAV_EMPTY_SUB_CONTEXT)}
            </Dropdown.ItemText>
            : context.sub_context.map(c => render_sub_context(c))}
    </C.Spinner>, [render_sub_context, context_status, context.sub_context, lg]);

    const current_content = React.useMemo(() => <C.Spinner no_flex_loader loader_className="d-inline-block me-2 my-1" size="sm" status={context_status}>
        {!current_context
            ? <>{lg.getStaticText(TC.NAV_NO_CURRENT_CONTEXT)}</>
            : <>
                {current_context.type === "portfolio" && <span>
                    {current_context.picture
                        ? <img alt="" width={20} height={20} src={current_context.picture} />
                        : <i className='fa fa-wallet'></i>}
                    <span className='ms-2'>{current_context.name}</span>
                </span>}
                {current_context.type === "mission" && <span>
                    <i className="fa fa-folder-open me-2"></i>
                    {current_context.label}
                </span>}
                {current_context.type === "sub" && <span>
                    <i className={`fa fa-${ICONS[current_context.path]} me-2`}></i>
                    {current_context.name}
                </span>}
            </>}
    </C.Spinner>, [current_context, context_status, lg]);

    const main_portfolio_content = React.useMemo(() => <C.Spinner no_flex_loader loader_className="d-inline-block me-2 my-1" size="sm" status={context_status}>
        {!context.current
            ? <>{lg.getStaticText(TC.NAV_NO_CURRENT_CONTEXT)}</>
            : <>
                {context.current.type === "portfolio"
                    ? <span>
                        {context.current.picture
                            ? <img alt="" width={20} height={20} src={context.current.picture} />
                            : <i className='fa fa-wallet'></i>}
                        <span className='ms-2'>{context.current.name}</span>
                    </span>
                    : <span>
                        <i className={`fa fa-${ICONS[context.current.path]} me-2`}></i>
                        {context.current.name}
                    </span>
                }
            </>}
    </C.Spinner>, [context, context_status, lg]);

    const render_mission_content = React.useCallback((missions: ContextResource["missions"], status: typeof context_status) => {
        return <C.Spinner loader_className="my-1" size="sm" status={status}>
            {missions.length === 0
                ? <Dropdown.ItemText children={lg.getStaticText(TC.NAV_EMPTY_RECENT)} />
                : missions.map(m => <Dropdown.Item as="div" key={m.value} className='d-flex justify-content-between align-items-center' >
                    <span className="flex-grow-1 pointer" onClick={() => events.select_mission(m)}>
                        <i className='fa fa-folder'></i>
                        <span className='ms-1'>
                            {m.final ? "[F] " : ""}
                            {m.label}
                        </span>
                    </span>
                    <span className="ms-2" onClick={() => events.edit_mission(m)}>
                        <i className="fa fa-pencil-alt"></i>
                    </span>
                </Dropdown.Item>)}
        </C.Spinner>;
    }, [events, lg]);

    const context_missions = React.useMemo(() => {
        let current_root = roots.roots?.[0];
        if (!TB.mongoIdValidator(current_root)) return context.missions;
        else {
            let current_active_sub_roots = [] as string[];

            const recursive = (item: typeof context.sub_context[number], active = false) => {
                // The current item is our root, start the active list
                if (item.id === current_root) active = true;
                // The current item is the root or a sub-root, add it to the active list
                if (active) current_active_sub_roots.push(item.id);
                // Recursive call for the children
                item.sub_context.forEach(c => recursive(c, active));
            }
            context.sub_context.forEach(c => recursive(c));
            return context.missions.filter(m => current_active_sub_roots.includes(m.asset));
        }
    }, [context, roots]);
    //#endregion

    return <Dropdown ref={ref_main} show={main.value}>
        <Dropdown.Toggle onClick={main.toggle} variant={current_context?.type === "mission" ? "sector" : "primary"} size="sm" id="navigator-dropdown">
            {current_content}
        </Dropdown.Toggle>

        <Dropdown.Menu>

            {context?.current?.type === "portfolio" && <Dropdown.Item
                as="div"
                onClick={() => events.select_portfolio(context.current.id)}
                className='d-flex justify-content-between align-items-center'
            >
                {main_portfolio_content}
            </Dropdown.Item>}

            <div className={context?.current?.type === "portfolio" ? "ms-2" : ""} style={{ maxHeight: "12rem", overflowY: "scroll" }}>
                {sub_context_content}
            </div>

            <Dropdown.Divider />

            {/* Portfolios */}
            <Dropdown ref={ref_selection} show={selection.value} className={show_mission_sub_menu ? "" : "mb-2"}>
                <Dropdown.Toggle className='fw-bold btn-none text-start w-100 px-3' as="button" onClick={selection.toggle} id="dropdown-selection">
                    {lg.getStaticText(TC.NAV_CHANGE_SELECTION)}
                </Dropdown.Toggle>

                <Dropdown.Menu style={selection_menu_style}>

                    <Dropdown.Header>
                        {lg.getStaticText(TC.NAV_SELECTION_SAVED)}
                    </Dropdown.Header>

                    <div style={{ maxHeight: "8rem", overflowY: "scroll" }}>
                        {portfolio_content}
                    </div>

                    <Dropdown.Item as="div" onClick={events.add_portfolio}>
                        <span className='false_link'>
                            <i className='fa fa-plus me-2'></i>
                            {lg.getStaticText(TC.NAV_CREATE_SELECTION)}
                        </span>
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Header>
                        <i className='fa fa-clock me-2'></i>
                        {lg.getStaticText(TC.NAV_RECENT_SELECTION)}
                    </Dropdown.Header>

                    <div style={{ maxHeight: "12rem", overflowY: "scroll" }}>
                        {recent_content}
                    </div>

                    <Dropdown.Item as="div" onClick={events.change_root}>
                        <span className='false_link'>
                            <i className='fa fa-search me-2'></i>
                            {lg.getStaticText(TC.NAV_SEARCH)}
                        </span>
                    </Dropdown.Item>

                </Dropdown.Menu>

            </Dropdown>

            {/* Missions */}
            {show_mission_sub_menu && <>
                <Dropdown.Divider />
                <Dropdown className="mb-2" ref={ref_mission} show={mission.value}>
                    <Dropdown.Toggle className='fw-bold btn-none text-start w-100 px-3' as="button" onClick={mission.toggle} id="dropdown-mission">
                        {lg.getStaticText(TC.NAV_MISSIONS)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2" style={mission_menu_style}>

                        {/* Current context's missions */}
                        <Dropdown.Header>
                            {lg.getStaticText(TC.NAV_CURRENT_CONTEXT_MISSIONS)}
                        </Dropdown.Header>

                        <div style={{ maxHeight: "8rem", overflowY: "scroll" }}>
                            {render_mission_content(context_missions, context_status)}
                        </div>

                        {/* Create a new Mission */}
                        {can_create_mission && !roots.portfolio && <Dropdown.Item as="div" onClick={events.add_mission}>
                            <span className='false_link'>
                                <i className='fa fa-plus me-2'></i>
                                {lg.getStaticElem(TC.NAVIGATOR_ADD_MISSION)}
                            </span>
                        </Dropdown.Item>}

                        <Dropdown.Divider />

                        {/* The recent missions */}
                        <Dropdown.Header>
                            {lg.getStaticText(TC.NAV_RECENT_MISSIONS)}
                        </Dropdown.Header>

                        <div style={{ maxHeight: "12rem", overflowY: "scroll" }}>
                            {render_mission_content(global.recent_missions, global_status)}
                        </div>

                        {/* Search a mission */}
                        <Dropdown.Item as="div" onClick={events.search_mission}>
                            <span className='false_link'>
                                <i className='fa fa-search me-2'></i>
                                {lg.getStaticElem(TC.NAV_SEARCH)}
                            </span>
                        </Dropdown.Item>

                        {mission_id && <Dropdown.Item as="div" onClick={events.unselect_mission}>
                            {/* Disable the current mission */}
                            <span className='false_link'>
                                <i className='fa fa-minus-square me-2'></i>
                                {lg.getStaticElem(TC.NAVIGATOR_DEACTIVATE_MISSION)}
                            </span>
                        </Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>
            </>}
        </Dropdown.Menu>
    </Dropdown >;
}

export default NestedDropdown;