import React from 'react';
import classNames from 'classnames';
import * as H from '../../../hooks';
import * as C from "../../../Common";
import * as BS from 'react-bootstrap';
import { T } from '../../../Constants';
import * as CT from '../../../Context';
import * as R from "../../../reducers";
import * as DOM from 'react-router-dom';

type CollapseItemsProps = Record<"route", T.RoutesType>;
type NavbarVerticalMenuProps = Record<"routes", T.RoutesType[]>;
type NavbarVerticalMenuItemProps = Record<"route", T.RoutesType>;
type StateItemsProps = Record<"route", T.RoutesType> & Record<"states", T.RoutesStatesRedux>;

const CollapseItems: React.FC<CollapseItemsProps> = props => {
  const location = DOM.useLocation();

  const openCollapse = React.useCallback((childrens: T.RoutesType["children"]) => {
    const checkLink = (c: T.RoutesType) => {
      if (c.to === location.pathname) return true;
      return Array.isArray(c?.children) && c.children.some(checkLink);
    };
    return Array.isArray(childrens) ? childrens.some(checkLink) : false;
  }, [location.pathname]);

  const [open, setOpen] = React.useState(openCollapse(props.route.children));
  const linkClass = React.useMemo(() => classNames('dropdown-indicator cursor-pointer'), []);

  return <BS.Nav.Item as="li">
    <BS.Nav.Link aria-expanded={open} className={linkClass} onClick={() => setOpen(!open)}>
      <NavbarVerticalMenuItem route={props.route} />
    </BS.Nav.Link>

    <BS.Collapse in={open}>
      <BS.Nav as="ul" className="flex-column nav">
        <NavbarVerticalMenu routes={props.route.children} />
      </BS.Nav>
    </BS.Collapse>
  </BS.Nav.Item>
};

const StateItems: React.FC<StateItemsProps> = props => {
  const lg = H.useLanguage();
  const navigate = DOM.useNavigate();

  const on_click = React.useCallback((state_id?: string) => {
    let nav_link = props.route.active ? "/" + props.route.to : "#";
    // Update the state
    if (state_id) R.set_table_state(state_id);
    else R.clear_table_state();
    // Navigate to the link
    navigate(nav_link);
  }, [navigate, props.route]);

  return <BS.Nav.Item as="li">
    <BS.Nav.Link aria-expanded className="cursor-pointer" onClick={() => on_click()}>
      <C.Flex alignItems="center">
        {props.route.icon && <span className="nav-link-icon" children={<i className={"fa fa-" + props.route.icon} />} />}
        <span className={"nav-link-text ps-1"} children={props.route.name} />
      </C.Flex>
    </BS.Nav.Link>

    <BS.Collapse in>
      <BS.Nav as="ul" className="flex-column nav" >

        {props.states.map(s => <BS.Nav.Item as="li" key={s.id}>
          <BS.Nav.Link onClick={() => on_click(s.id)} className="nav-link" >
            <C.Flex alignItems="center">
              <span className="nav-link-icon" children={<i className="fa fa-star" />} />
              <span className="nav-link-text ps-1" children={lg.getTextObj(s.id, "name", s.name)} />
            </C.Flex>
          </BS.Nav.Link>
        </BS.Nav.Item>)}

      </BS.Nav>
    </BS.Collapse>
  </BS.Nav.Item>;
};

const NavbarVerticalMenuItem: React.FC<NavbarVerticalMenuItemProps> = props => <C.Flex alignItems="center">
  {props.route.icon && <span className="nav-link-icon" children={<i className={"fa fa-" + props.route.icon} />} />}
  <span className={"nav-link-text ps-1"} children={props.route.name} />
</C.Flex>;

const NavbarVerticalMenu: React.FC<NavbarVerticalMenuProps> = props => {
  const hide = H.useHideUnavailable();
  const routes_states = H.useRoutesStates();
  const { config: { showBurgerMenu }, setConfig } = React.useContext(CT.AppContext);
  const handleNavItemClick = React.useCallback(() => showBurgerMenu && setConfig('showBurgerMenu', !showBurgerMenu), [showBurgerMenu, setConfig]);

  return <>
    {props.routes.map((route, i) => {
      let states = routes_states.filter(r => r.origin === route.state_origin);
      // Do not show the item
      if (hide && !route.active) return null;
      // Render a collapse item containing other tabs
      if (route.children.length > 0) return <CollapseItems key={i} route={route} />;
      // Render an item with state saver
      else if (states.length > 0 && route.active) return <StateItems key={i} route={route} states={states} />;
      // Render a simple item
      else return <BS.Nav.Item as="li" key={i} onClick={handleNavItemClick}>
        <DOM.NavLink end onClick={R.clear_table_state} to={route.active ? "/" + route.to : "#"} className={"nav-link " + (route.active ? "" : "text-muted fs-85 pointer-no")} >
          <NavbarVerticalMenuItem route={route} />
        </DOM.NavLink>
      </BS.Nav.Item>;
    })}
  </>;
};

export default NavbarVerticalMenu;
