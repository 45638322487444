import React from "react";
import * as Task from "./Tasks";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { Row, Col } from "react-bootstrap";
import { NavigateFunction } from "react-router-dom";
import { FP, LANG, MISSION, T, TB, TC } from "../../../Constants";

type FormResource = ReturnType<T.API.Utils.Missions.FormResource>;

export type MissionFormProps = {
    /** The asset the mission relates to, in case of the creation of a new mission */
    asset?: string;
    /** The id of the mission, in case of editing an existing mission */
    mission_id?: string;
    /** Should the form be in a popup ? */
    popup?: boolean;
    /** Pre-filled mission props */
    mission?: Partial<T.Mission>;
    /** Extra modal style params */
    modal?: M.StyleModalProps;
    /** Do not offer the option to delete the mission */
    no_delete?: boolean;
    /** Callback to inform that a change has been done */
    onChange?: () => void;
    /** Callback for quitting */
    onQuit?: () => void;
    /** Callback for saving */
    onSave?: (mission: "deleted" | "error" | T.Mission, close_popup?: boolean) => void;
    /** The DOM navigate function */
    navigate: NavigateFunction;
}

export type MissionFormRef = {
    /** Call the saving function */
    save: (mission?: T.Mission, quit?: boolean) => "errors" | Promise<boolean>;
    /** Get the current mission state */
    mission: T.Mission;
    /** Update the mission from a different component */
    update: (mission: Partial<T.Mission>, fire_change_callback?: boolean) => void;
}

//#region Constants
const DEFAULT_MISSION: T.Mission = {
    _id: "",
    asset: "",
    users: [],
    owner: "",
    tasks: [],
    type: null,
    deadline: "",
    frequency: "",
    lang: "fr_fr",
    users_read: [],
    ref_client: "",
    ref_provider: "",
    intervenants: [],
    reformulation: false,
};

const set_base_mission = (mission?: Partial<T.Mission>): T.Mission => {
    if (mission?._id) return mission as T.Mission;
    let new_mission = { ...DEFAULT_MISSION, ...mission };

    if (new_mission.type === "cdm") {
        let has_visit_task = new_mission.tasks.some(t => t.type === "visit");
        if (!has_visit_task) new_mission.tasks.push({ type: "visit", invitations: [], end_date: "", start_date: "" });
        let has_report_task = new_mission.tasks.some(t => t.type === "report");
        if (!has_report_task) new_mission.tasks.push({ type: "report", versions: [] });
    }
    return new_mission;
}

export const Options = {
    task: Task.TasksOptions,
    type: MISSION.MissionTypes,
    invite: Task.InviteStatusOptions,
    intervenant_role: MISSION.RoleIntervenantsOptions,
    lang: LANG.ALL_LANGUAGES.map(l => ({ label: l.lg, value: l.prop })),
}
//#endregion

const MissionForm = React.forwardRef<MissionFormRef, MissionFormProps>(({ onSave, onChange, ...props }, ref) => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const [{ userId, isAdmin }] = H.useAuth();
    const [errors, set_errors] = React.useState<any>({});
    const [tags, set_tags, tags_status] = H.useAsyncState<T.Option[]>([]);
    const [mission, set_mission, status] = H.useAsyncState<T.Mission>(set_base_mission(props.mission));
    const [resources, set_resources, resources_status] = H.useAsyncState<FormResource>({ asset_name: "", asset_type: "", users: [] });

    //#region Load mission
    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(userId)) {
            // Load existing mission
            if (TB.mongoIdValidator(props.mission_id)) S.getMission(props.mission_id)
                .then(({ data }) => isSubscribed && set_mission(data, "done"))
                .catch(() => isSubscribed && set_mission(DEFAULT_MISSION, "error"));
            // Create a new mission
            else set_mission(p => ({ ...p, owner: userId, lang: lg.prop, asset: props.asset }), "done");
        }
        return () => { isSubscribed = false };
    }, [userId, props.mission_id, props.asset, lg.prop, set_mission]);
    //#endregion

    //#region Load resources
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionFormResources({ asset: props.asset, mission: props.mission_id })
            .then(({ data }) => isSubscribed && set_resources(data, "done"))
            .catch(() => isSubscribed && set_resources({ asset_name: "", asset_type: "", users: [] }, "error"));
        return () => { isSubscribed = false };
    }, [set_resources, props.asset, props.mission_id]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (mission.asset) S.getNoteTags({ context: { roots: mission.asset }, user: userId, type: "mission" })
            .then(({ data }) => isSubscribed && set_tags(data, 'done'))
            .catch(() => isSubscribed && set_tags([], "error"));
        else set_tags([], "done");
        return () => {
            isSubscribed = false;
            set_tags([], "load");
        };
    }, [userId, mission.asset, set_tags]);

    const options = React.useMemo(() => ({
        read: resources.users.filter(u => !mission?.users?.includes?.(u.value)),
        edit: resources.users.filter(u => !mission?.users_read?.includes?.(u.value)),
        type: Options.type.filter(t => t.allowed_asset.includes(resources.asset_type) && rights.isRightAllowed(t.right, props.asset)),
    }), [resources.users, mission?.users, mission?.users_read, resources.asset_type, rights, props.asset]);
    //#endregion

    //#region On Save / Delete
    const events = React.useMemo(() => ({
        change: ((mission, fire_change_callback = true) => {
            if (fire_change_callback) onChange?.();
            // Reset the lang if reformulation is disabled
            if (typeof mission.reformulation === "boolean" && !mission.reformulation) mission.data_lang = undefined;
            set_mission(p => ({ ...p, ...mission }));
        }) as MissionFormRef["update"],
        change_and_save: (tasks: T.Mission["tasks"], quit?: boolean) => events.save({ ...mission, tasks }, quit),
        change_type: (type: T.Mission["type"]) => {
            let authorized_tasks_types = Options.task
                .filter(t => t.mission_types.includes(type))
                .map(t => t.value);
            set_mission(p => set_base_mission({ ...p, type, tasks: p.tasks.filter(t => authorized_tasks_types.includes(t.type)) }));
        },
        change_users: (users: string[], prop: keyof Pick<T.Mission, "users" | "users_read">) => {
            set_mission(p => {
                let previous_users = p[prop] || [];
                let users_cant_remove = previous_users.filter(u => !resources.users.find(ru => ru.value === u)?.can_use);
                // Keep the users that can't be removed by the current one
                users_cant_remove.forEach(u => !users.includes(u) && users.push(u));
                return { ...p, [prop]: users };
            });
        },
        get_errors: (set = false) => {
            let saved_mission = mission;
            let new_errors: Partial<Record<keyof T.Mission, any>> = {};

            let frequency = TB.splitFrequency(saved_mission.frequency);
            let has_frequency = TB.validString(saved_mission.frequency);

            if (!saved_mission.type) new_errors.type = TC.GLOBAL_REQUIRED_FIELD;
            if (!saved_mission.ref_provider) new_errors.ref_provider = TC.GLOBAL_REQUIRED_FIELD;
            if (has_frequency && !frequency) new_errors.frequency = TC.GLOBAL_REQUIRED_FIELD;
            if (saved_mission.reformulation && !saved_mission.data_lang) new_errors.data_lang = TC.GLOBAL_REQUIRED_FIELD;

            // Check intervenants
            for (let i = 0; i < saved_mission.intervenants.length; i++) {
                let intervenant = saved_mission.intervenants[i];
                let error: T.Errors<T.Mission["intervenants"][number]> = {};

                if (!intervenant.role) error.role = TC.GLOBAL_REQUIRED_FIELD;
                if (!intervenant.name) error.name = TC.GLOBAL_REQUIRED_FIELD;
                if (!intervenant.company) error.company = TC.GLOBAL_REQUIRED_FIELD;
                if (intervenant.mail && !TB.validateMail(intervenant.mail)) error.mail = TC.REG_MAIL_INVALID;
                if (intervenant.role === "other" && !intervenant.other) error.other = TC.GLOBAL_REQUIRED_FIELD;

                if (Object.entries(error).length > 0) {
                    if (!Array.isArray(new_errors.intervenants)) new_errors.intervenants = [];
                    new_errors.intervenants[i] = error;
                }
            }

            // Check tasks
            for (let i = 0; i < saved_mission.tasks.length; i++) {
                let error: any;
                let task = saved_mission.tasks[i];

                if (task.type === "visit") {
                    let visit_error: T.Errors<T.Mission_Task_Visit> = {};

                    let end_date = TB.getDate(task.end_date),
                        start_date = TB.getDate(task.start_date);

                    if (end_date && start_date && end_date.getTime() <= start_date.getTime()) {
                        visit_error.end_date = TC.ERR_DATE_TO_LOWER_DATE_FROM;
                        visit_error.start_date = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
                    }

                    error = visit_error;
                }

                if (error !== undefined && Object.keys(error).length > 0) {
                    if (!Array.isArray(new_errors.tasks)) new_errors.tasks = [];
                    new_errors.tasks[i] = error;
                }
            }

            if (Object.keys(new_errors).length === 0) return new_errors;
            if (set) set_errors(new_errors);
            return new_errors;
        },
        add_tag: (text: string) => new Promise((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "mission" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    set_tags(p => p.concat(new_option));
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
        save: ((save_mission?: typeof mission, quit?: boolean) => {
            let new_errors = events.get_errors();
            let saved_mission = save_mission || mission;

            if (Object.keys(new_errors).length > 0) {
                onSave?.("error");
                set_errors(new_errors);
                return "error";
            }
            else return new Promise((res, rej) => {
                S.updateMission(saved_mission)
                    .then(({ data }) => {
                        if (save_mission) set_mission(saved_mission);
                        onSave?.(data, quit);
                        res(true);
                    })
                    .catch(e => {
                        M.Alerts.updateError(e);
                        rej(false);
                    });
            });
        }) as MissionFormRef["save"],
        delete: () => M.askConfirm().then(confirmed => {
            if (confirmed) S.deleteMission(mission._id)
                .then(() => {
                    onSave?.("deleted");
                    set_mission(DEFAULT_MISSION);
                })
                .catch(M.Alerts.deleteError);
        }),
    }), [mission, resources.users, userId, set_tags, set_mission, onSave, onChange]);

    React.useImperativeHandle(ref, () => ({ save: events.save, mission, update: events.change }), [mission, events.save, events.change]);
    //#endregion

    //#region User Selection
    const add_user = React.useCallback((text: string, prop: keyof Pick<T.Mission, "users" | "users_read">) => {
        // Make sure the email is correct
        M.askPrompt({ mail: true, label: TC.GENERAL_USERNAME, title: TC.MISSION_USERS_INPUT_MAIL, defaultVal: text, isRequired: true }).then((mail: string) => {
            // Check the availability of this email address
            if (mail) {
                // Check if the email is not already loaded
                let loaded_user = resources.users.filter(u => u.mail === mail)[0];
                // User was available as an option
                if (loaded_user) {
                    let has_access = (mission.users || []).includes(loaded_user.value) || (mission.users_read || []).includes(loaded_user.value);
                    // The user already has access to the mission
                    if (has_access) M.renderAlert({ type: "info", message: TC.MISSION_USER_ALREADY_SELECTED });
                    // The user did not have access to the mission, so we give him
                    else events.change({ [prop]: (mission[prop] || []).concat(loaded_user.value) });
                }
                // User was not available, check if the email exists
                else S.mailExists({ mail, allow_admin: true }).then(mail_info => {
                    let user = mail_info.data as T.Submission<T.UserData>;
                    // Can't use this email address
                    if (mail_info.data === "duplicate" || mail_info.data === "forbidden") M.renderAlert({ type: "warning", message: TC.AUTH_NEW_USER_MAIL_FORBIDDEN });
                    // Tha email does not exist, do not allow the creation of a new user
                    else if (mail_info.data === "free") M.renderAlert({ type: "warning", message: TC.PWD_CHANGE_MAIL_NOT_EXISTS });

                    /* M.askNameMail({ value: { name: "", email: mail }, disable_mail: true, modal: { title: TC.MISSION_USER_CREATE_NEW } }).then(infos => {
                    if (infos) S.createShellUser(infos).then(({ data }) => {
                        // Add the user to the mission property
                        events.change({ [prop]: (mission[prop] || []).concat(data._id) });
                        // Add the user to the list of available users
                        set_resources(p => ({
                            ...p,
                            users: p.users.concat({
                                value: data._id,
                                label: data.data.name,
                                mail: data.data.email,
                            })
                        }));
                    }).catch(M.Alerts.updateError);
                }); */


                    // User already exists, add him to the list, he will receive his access on save
                    else {
                        // Add the user to the mission property
                        events.change({ [prop]: (mission[prop] || []).concat(user._id) });
                        // Add the user to the list of available users
                        set_resources(p => ({
                            ...p,
                            users: p.users.concat({
                                value: user._id,
                                label: user.data.name,
                                mail: user.data.email,
                            })
                        }));
                    }
                }).catch(M.Alerts.loadError);
            }
        });
    }, [mission, resources.users, events, set_resources]);
    //#endregion

    //#region Main Form
    const is_final = React.useMemo(() => {
        let report_task = mission.tasks.filter(t => t.type === "report")[0] as T.Mission_Task_Report;
        if (report_task) return report_task.versions?.some?.(v => v.final);
        return false;
    }, [mission.tasks]);

    const can_not_be_edited = React.useMemo(() => {
        if (is_final) return true;
        else if (isAdmin) return false;
        // Find the groups the user is a part of
        let user_groups = resources.users.filter(u => u.can_use && !u.mail).map(u => u.value);
        return mission.owner !== userId && !mission.users?.includes?.(userId) && !user_groups.some(g => mission.users.includes(g));
    }, [isAdmin, mission.owner, mission.users, resources.users, userId, is_final]);

    const update_intervenants = React.useCallback((intervenants: T.Mission["intervenants"]) => {
        // Reset errors
        set_errors(p => ({ ...p, intervenants: undefined }));
        // Update intervenants array
        let new_tasks = mission.tasks;
        let uuids = intervenants.map(i => i.uuid);

        new_tasks = new_tasks.map(t => {
            if (t.type === "visit") return {
                ...t,
                type: "visit",
                invitations: (t.invitations || []).filter(i => uuids.includes(i.uuid)),
            } as any;
            return t as any;
        });

        events.change({ intervenants, tasks: new_tasks });
    }, [events, mission.tasks]);

    const buttons = React.useMemo(() => !can_not_be_edited && <C.Flex justifyContent="end">
        <C.Button disabled={is_final} onClick={() => events.save()} className="me-2" text={TC.GLOBAL_SAVE} />
        {!props.no_delete && <C.Button disabled={!TB.mongoIdValidator(mission._id)} onClick={events.delete} variant="danger" icon="trash" />}
    </C.Flex>, [events, is_final, mission._id, can_not_be_edited, props.no_delete]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer: buttons,
            isFullScreen: true,
            onQuit: props.onQuit,
            title: TC.MISSION_TITLE,
        } as M.BlankModalProps : null,
        <C.Spinner status={status}>
            {!props.popup && buttons}
            <Row className="mt-3">
                <Col children={<h4>{lg.getStaticElem(TC.MISSION_GENERAL)}</h4>} />
            </Row>
            <Row className="g-2">
                <Col md={6}>
                    <C.Form.Select
                        required
                        no_clear_btn
                        labelPosition="left"
                        value={mission.type}
                        options={options.type}
                        label={TC.MISSION_TYPE}
                        error={{ code: errors.type }}
                        onChange={events.change_type}
                        // Can't edit if mission has been finalized or if existing mission, or mission from an existing one
                        disabled={can_not_be_edited || TB.mongoIdValidator(mission._id) || !!props.mission?.type}
                    />
                </Col>
                <Col md={6}>
                    <C.Form.TextField
                        required
                        labelPosition="left"
                        label={TC.MISSION_PROV_REF}
                        disabled={can_not_be_edited}
                        value={mission.ref_provider}
                        error={{ code: errors.ref_provider }}
                        onChange={ref_provider => events.change({ ref_provider })}
                    />
                </Col>
                <Col md={6}>
                    <C.Spinner status={resources_status}>
                        <C.Form.TextField
                            readonly
                            labelPosition="left"
                            label={TC.MISSION_ASSET}
                            disabled={can_not_be_edited}
                            value={resources.asset_name}
                        />
                    </C.Spinner>
                </Col>
                <Col md={6}>
                    <C.Form.TextField
                        labelPosition="left"
                        value={mission.ref_client}
                        disabled={can_not_be_edited}
                        label={TC.MISSION_CLIENT_REF}
                        error={{ code: errors.ref_client }}
                        onChange={ref_client => events.change({ ref_client })}
                    />
                </Col>
                <Col md={6}>
                    <C.Form.Select
                        required
                        no_clear_btn
                        labelPosition="left"
                        value={mission.lang}
                        options={Options.lang}
                        label={TC.MISSION_LANG}
                        disabled={can_not_be_edited}
                        onChange={lang => events.change({ lang })}
                    />
                </Col>

                <Col md={6}>
                    <C.Form.RadioBool
                        always_selected
                        name="reformulation"
                        value={mission.reformulation}
                        label={TC.MISSION_FORM_REFORMULATE}
                        error={{ code: errors.reformulation }}
                        onChange={reformulation => events.change({ reformulation })}
                    />
                </Col>
                <Col md={6}>
                    {mission.reformulation && <C.Form.Select
                        required
                        no_clear_btn
                        labelPosition="left"
                        options={Options.lang}
                        value={mission.data_lang}
                        disabled={can_not_be_edited}
                        label={TC.MISSION_FORM_DATA_LANG}
                        error={{ code: errors.data_lang }}
                        onChange={data_lang => events.change({ data_lang })}
                    />}
                </Col>

                <Col md={6} />

                <Col md={6}>
                    <C.Form.Select
                        multiple
                        labelPosition="left"
                        value={mission.users}
                        options={options.edit}
                        label={TC.MISSION_USERS}
                        disabled={can_not_be_edited}
                        error={{ code: errors.users }}
                        loading={resources_status === "load"}
                        addResourceLabel={TC.MISSION_USER_ADD_LABEL}
                        onChange={users => events.change_users(users, "users")}
                        typeahead={{
                            height: "sm",
                            allowNewOption: true,
                            hideClearButton: true,
                            extra_search_props: "mail",
                            onAddOption: text => add_user(text, "users"),
                        }}
                    />
                </Col>
                <Col md={6}>
                    <C.Form.Select
                        multiple
                        labelPosition="left"
                        options={options.read}
                        value={mission.users_read}
                        disabled={can_not_be_edited}
                        label={TC.MISSION_USERS_READ_ONLY}
                        error={{ code: errors.users_read }}
                        loading={resources_status === "load"}
                        addResourceLabel={TC.MISSION_USER_ADD_LABEL}
                        onChange={users_read => events.change_users(users_read, "users_read")}
                        typeahead={{
                            height: "sm",
                            allowNewOption: true,
                            hideClearButton: true,
                            extra_search_props: "mail",
                            onAddOption: text => add_user(text, "users_read"),
                        }}
                    />
                </Col>
                <Col md={6}>
                    <C.Form.DateTime
                        labelPosition="left"
                        value={mission.deadline}
                        label={TC.MISSION_DEADLINE}
                        disabled={can_not_be_edited}
                        error={{ code: errors.deadline }}
                        onChange={deadline => events.change({ deadline })}
                    />
                </Col>
                <Col md={6}>
                    <C.Form.Frequency
                        labelPosition="left"
                        value={mission.frequency}
                        disabled={can_not_be_edited}
                        label={TC.MISSION_FREQUENCY}
                        error={{ code: errors.frequency }}
                        onChange={frequency => events.change({ frequency })}
                    />
                </Col>
                <Col md={6}>
                    <C.Form.Select
                        multiple
                        options={tags}
                        value={mission.tags}
                        labelPosition="left"
                        label={TC.DATASET_TAGS}
                        disabled={can_not_be_edited}
                        loading={tags_status === "load"}
                        onChange={tags => events.change({ tags })}
                        typeahead={{ allowNewOption: true, onAddOption: events.add_tag }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Task.Intervenants
                        disabled={can_not_be_edited}
                        errors={errors.intervenants}
                        value={mission.intervenants}
                        onChange={update_intervenants}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Task.Tasks
                        lang={mission.lang}
                        asset={props.asset}
                        quit={props.onQuit}
                        tasks={mission.tasks}
                        errors={errors.tasks}
                        mission_id={mission._id}
                        navigate={props.navigate}
                        mission_type={mission.type}
                        disabled={can_not_be_edited}
                        check_errors={events.get_errors}
                        asset_path={resources.asset_type}
                        intervenants={mission.intervenants}
                        onChange_save={events.change_and_save}
                        onChange={tasks => events.change({ tasks })}
                    />
                </Col>
            </Row>
        </C.Spinner>
    );
});

export default MissionForm;