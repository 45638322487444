// import React from "react";
import * as B from "./Button";
// import * as I from "../types";
// import * as C from "../../../../Common";
// import { T, TC } from "../../../../Constants";

export const Cell = B.Cell;

export type FilterModel = ("true" | "false" | "null")[];
export type Props<A> = B.Props<A> & Partial<Record<"invert" | "use_is_selected_filters_label" | "distinctFalseFromEmpty", boolean>>;

// const Filters = [
//     { label: TC.GLOBAL_YES, value: "true" },
//     { label: TC.GLOBAL_NO, value: "false" },
// ] as T.Option<{}, FilterModel[number]>[];

// const SelectFilters = [
//     { label: TC.GRID_BOOL_FILTER_SELECTED, value: "true" },
//     { label: TC.GRID_BOOL_FILTER_NOT_SELECTED, value: "false" },
// ] as T.Option<{}, FilterModel[number]>[];

export const type = "agSelectButton";
// const CheckBoxStyle: React.CSSProperties = { height: "1rem", width: "1rem" };

export const sort = (a: boolean, b: boolean) => {
    // Both booleans are true
    if (a && b) return 0;
    // One is true but not the other
    if (a || b) return a ? 1 : -1;
    // Both are false
    return 0;
}

export const Filter = null;