import React from "react";
import { T, TB } from "../../Constants";
import TipContainer from "./TipContainer";

export type IconButtonProps = {
    /** The fafa icon */
    icon: string;
    /** Additional classnames */
    className?: string;
    /** Is the button disabled */
    disabled?: boolean;
    /** OnClick callback */
    onClick?: React.MouseEventHandler<HTMLElement>;
    /** Icon color */
    variant?: T.ColorTypes | "none";
    /** The size of the button */
    size?: "sm" | "md" | "lg";
    /** Stop the propagation of the onClick event */
    no_propagation?: boolean;
    /** A tooltip to display on hover */
    tip?: string | React.ReactNode;
}

const IconButton: React.FC<IconButtonProps> = props => {

    const size = React.useMemo(() => {
        if (props.size === "lg") return "fs-200";
        if (props.size === "md") return "fs-150";
        return "";
    }, [props.size]);

    const pointer = React.useMemo(() => typeof props.onClick === "function" ? "pointer" : "", [props.onClick]);
    const variant = React.useMemo(() => props.disabled ? "light" : props.variant, [props.variant, props.disabled]);

    const onClick = React.useCallback<IconButtonProps["onClick"]>(event => {
        if (!props.disabled) {
            if (props.no_propagation) event.stopPropagation();
            props.onClick?.(event);
        }
    }, [props]);

    const icon = React.useMemo(() => <i
        onClick={onClick}
        className={`fa fa-${props.icon} ${size} text-${variant} ${pointer} ${TB.getString(props.className)}`}
    />, [onClick, props.icon, size, variant, pointer, props.className]);

    if (props.tip) return <TipContainer use_focus_trigger tipContent={props.tip} children={icon} />;
    else return icon;
}

export default IconButton;