import { RegAction } from "../Types";

type RegProperty = RegAction["extra_properties"][number];
type Nested_Reg_Properties = (RegProperty & Record<"children", Nested_Reg_Properties>)[];

/** Take a flat reg properties array and turn it into a nested one */
export const nesting_reg_properties = (props: RegAction["extra_properties"] = []): Nested_Reg_Properties => {
    let nested_properties: Nested_Reg_Properties = [];
    let container_history: Nested_Reg_Properties[] = [nested_properties];

    if (!Array.isArray(props) || props.length === 0) return [];
    else {
        for (let prop of props) {
            let current_container = container_history[container_history.length - 1];
            // If the property is a container, create a new container and add it to the current container
            if (prop.data_type === "list_section" || prop.data_type === "simple_section" || prop.data_type === "repetitive_section") {
                let new_container = { ...prop, children: [] };
                current_container.push(new_container);
                container_history.push(new_container.children);
            }
            // Move back up to the last parent container
            else if (prop.data_type === "end_section") container_history.pop();
            else current_container.push({ ...prop, children: [] });
        }
        return nested_properties;
    }
}