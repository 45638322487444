import React from 'react';
import classNames from 'classnames';
import * as H from '../../../hooks';
import * as C from "../../../Common";
import * as BS from 'react-bootstrap';
import { T } from '../../../Constants';
import * as CT from '../../../Context';
import * as DOM from 'react-router-dom';

type StateItemsProps = Record<"route", T.RoutesType>;
type CollapseItemsProps = Record<"route", T.RoutesType>;
type NavbarVerticalMenuProps = Record<"routes", T.RoutesType[]>;
type NavbarVerticalMenuItemProps = Record<"route", T.RoutesType>;

const CollapseItems: React.FC<CollapseItemsProps> = props => {
  const location = DOM.useLocation();

  const openCollapse = React.useCallback((childrens: T.RoutesType["children"]) => {
    const checkLink = (c: T.RoutesType) => {
      if (c.to === location.pathname) return true;
      return Array.isArray(c?.children) && c.children.some(checkLink);
    };
    return Array.isArray(childrens) ? childrens.some(checkLink) : false;
  }, [location.pathname]);

  const [open, setOpen] = React.useState(openCollapse(props.route.children));
  const linkClass = React.useMemo(() => classNames('dropdown-indicator cursor-pointer'), []);

  return <BS.Nav.Item as="li">
    <BS.Nav.Link aria-expanded={open} className={linkClass} onClick={() => setOpen(!open)}>
      <NavbarVerticalMenuItem route={props.route} />
    </BS.Nav.Link>

    <BS.Collapse in={open}>
      <BS.Nav as="ul" className="flex-column nav">
        <NavbarVerticalMenu routes={props.route.children} />
      </BS.Nav>
    </BS.Collapse>
  </BS.Nav.Item>
};

const StateItems: React.FC<StateItemsProps> = props => {
  const open = H.useBoolean(false);
  const navigate = DOM.useNavigate();
  const linkClass = React.useMemo(() => classNames('dropdown-indicator cursor-pointer'), []);

  const onClick = React.useCallback<React.MouseEventHandler<HTMLSpanElement>>(event => {
    let target = event.target as Element;
    // Only consider it as an attempt to open if it comes from the link, and not the icon or text
    if (target.tagName === "A") open.toggle();
    // Navigate to the link
    else navigate(props.route.active ? "/" + props.route.to : "#");
  }, [navigate, open, props.route]);

  return <BS.Nav.Item as="li">
    <BS.Nav.Link aria-expanded={open.value} className={linkClass} onClick={onClick}>

      <C.Flex alignItems="center">
        {props.route.icon && <span className="nav-link-icon" children={<i className={"fa fa-" + props.route.icon} />} />}
        <span className={"nav-link-text ps-1"} children={props.route.name} />
      </C.Flex>

    </BS.Nav.Link>

    <BS.Collapse in={open.value} >
      <BS.Nav as="ul" className="flex-column nav" >

        {props.route.states.map(s => <BS.Nav.Item as="li" key={s.id}>
          <DOM.NavLink end to={props.route.active ? "/" + props.route.to + "?state=" + s.id : "#"} className="nav-link" >
            <C.Flex alignItems="center">
              <span className="nav-link-icon" children={<i className="fa fa-star" />} />
              <span className="nav-link-text ps-1" children={s.name} />
            </C.Flex>
          </DOM.NavLink>
        </BS.Nav.Item>)}

      </BS.Nav>
    </BS.Collapse>
  </BS.Nav.Item>
};

const NavbarVerticalMenuItem: React.FC<NavbarVerticalMenuItemProps> = props => <C.Flex alignItems="center">
  {props.route.icon && <span className="nav-link-icon" children={<i className={"fa fa-" + props.route.icon} />} />}
  <span className={"nav-link-text ps-1"} children={props.route.name} />
</C.Flex>;

const NavbarVerticalMenu: React.FC<NavbarVerticalMenuProps> = props => {
  const hide = H.useHideUnavailable();
  const { config: { showBurgerMenu }, setConfig } = React.useContext(CT.AppContext);
  const handleNavItemClick = React.useCallback(() => showBurgerMenu && setConfig('showBurgerMenu', !showBurgerMenu), [showBurgerMenu, setConfig]);

  return <>
    {props.routes.map((route, i) => {
      // Do not show the item
      if (hide && !route.active) return null;
      // Render a collapse item containing other tabs
      if (route.children.length > 0) return <CollapseItems key={i} route={route} />;
      // Render an item with state saver
      else if (route.states.length > 0) return <StateItems key={i} route={route} />;
      // Render a simple item
      else return <BS.Nav.Item as="li" key={i} onClick={handleNavItemClick}>
        <DOM.NavLink end to={route.active ? "/" + route.to : "#"} className={"nav-link " + (route.active ? "" : "text-muted fs-85 pointer-no")} >
          <NavbarVerticalMenuItem route={route} />
        </DOM.NavLink>
      </BS.Nav.Item>;
    })}
  </>;
};

export default NavbarVerticalMenu;
