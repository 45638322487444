import React from "react";
import { TB, TC } from "../../../Constants";
import { StyleModalProps, default as Modal } from "../BlankModal";
import { Button, Flex, Form, ReduxWrapper } from "../../../Common";

export type PromptProps = {
    /** Callback for closing the modal */
    onClose?: () => void;
    /** Callback after confirming the value */
    onConfirm?: (prompt: string | number) => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** The label of the input */
    label?: string;
    /** The title of the modal */
    title?: string;
    /** Is the input expected an email */
    mail?: boolean;
    /** Is the input expected a password */
    password?: boolean;
    /** Force the input to be in caps */
    isAllCap?: boolean;
    /** An additional element displayed under the input */
    description?: string | React.ReactElement;
    /** The text to be displayed in the confirm button */
    confirmText?: string;
    /** Is a value required for validation */
    isRequired?: boolean;
    /** Is the input a number field */
    isNumberPrompt?: boolean;
    /** Does not put focus automatically in the modal */
    disableAutoFocus?: boolean;
    /** The default text/number value */
    defaultVal?: string | number;
    /** A custom callback to check the entered value */
    valChecker?: (str: string) => boolean | { message?: string, isValid?: boolean };
    /** Props for a number input */
    numberProps?: { min?: number, max?: number, step?: number };
    /** Content to add at the end of the input */
    suffix?: (Form.NumFieldProps | Form.TextFieldProps)["suffix"];
}

const Prompt: React.FC<PromptProps> = ({ onConfirm, valChecker, ...props }) => {
    const [val, setVal] = React.useState(props.defaultVal);
    const [error, setError] = React.useState<Form.InputProps["error"]>();

    //#region Props
    const textCase = React.useMemo<Form.TextFieldProps["case"]>(() => {
        if (props.mail) return "lowercase";
        else if (typeof props.isAllCap !== "boolean") return;
        return props.isAllCap ? "uppercase" : "lowercase";
    }, [props.isAllCap, props.mail]);
    //#endregion

    //#region Confirm & Errors
    React.useEffect(() => setError(null), [val]);

    const validatePrompt = React.useCallback((): typeof error => {
        if (props.isNumberPrompt) {
            let value = TB.getNumber(val);
            let [min, max] = [props.numberProps?.max, props.numberProps?.max].map(num => TB.getNumber(num));

            // No number
            if (props.isRequired && isNaN(value)) return { code: TC.GLOBAL_REQUIRED_FIELD };
            // Under maximum
            else if (!isNaN(max) && value > max) return { code: TC.FORM_NUM_MAX, template: max };
            // Over minimum
            else if (!isNaN(min) && value < min) return { code: TC.FORM_NUM_MIN, template: min };
        }
        else if (props.isRequired && !TB.validString(val)) return { code: TC.GLOBAL_REQUIRED_FIELD };
        else if (props.mail && !TB.validateMail(val as string)) return { code: TC.REG_MAIL_INVALID };
        else if (typeof valChecker === "function") {
            let check = valChecker(val as string);
            if (typeof check === "boolean") {
                if (!check) return { code: TC.GLOBAL_VALUE_INVALID };
            }
            else if (typeof check?.message === "string") return { code: check.message };
        }
    }, [val, props.isNumberPrompt, props.isRequired, props.numberProps, props.mail, valChecker]);

    const confirm = React.useCallback(() => {
        let error = validatePrompt();

        if (error) setError(error);
        else if (props.isNumberPrompt) onConfirm?.(TB.getNumber(val));
        else onConfirm?.(val);
    }, [validatePrompt, onConfirm, props.isNumberPrompt, val]);
    //#endregion

    //#region Footer
    const footer = React.useMemo(() => <Flex justifyContent="end">
        <Button onClick={confirm} icon="save">
            {props.confirmText || TC.GLOBAL_CONFIRM}
        </Button>
    </Flex>, [props.confirmText, confirm]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={props.onClose}
        size={props.modal?.size || "sm"}
        title={props.title || props.modal?.title || TC.GLOBAL_RENAME}
    >
        {props.isNumberPrompt
            ? <Form.NumField
                error={error}
                noBottomMargin
                onChange={setVal}
                label={props.label}
                suffix={props.suffix}
                value={TB.getNumber(val)}
                description={props.description}
            />
            : <Form.TextField
                error={error}
                noBottomMargin
                case={textCase}
                onChange={setVal}
                label={props.label}
                isEmail={props.mail}
                suffix={props.suffix}
                password={props.password}
                value={TB.getString(val)}
                description={props.description}
            />}


    </Modal>;
}

const WrapperPrompt: React.FC<PromptProps> = props => <ReduxWrapper>
    <Prompt {...props} />
</ReduxWrapper>;

export default WrapperPrompt;