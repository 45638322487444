import { Flex } from "../Layout";
import { TB } from "../../Constants";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import React, { FC, useMemo, useEffect, useCallback, FocusEventHandler, useRef } from "react";

type ColorSelectProps = {
    disabled?: boolean;
    noAutoClose?: boolean;
    defaultColor?: string;
    selected?: string | string[];
    onSelect: (value?: string) => void;
}

const ImgSelect: FC<ColorSelectProps> = ({ selected, defaultColor, noAutoClose, onSelect, ...props }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const selectedColor = useMemo(() => TB.getColor(selected), [selected]);

    const onOpen = useCallback(() => {
        const focusInput = (attempt: number) => {
            if (inputRef.current !== null) {
                inputRef.current.focus();
                inputRef.current.click();
            }
            else if (attempt < 20) setTimeout(() => focusInput(attempt + 1), 100);
        }
        if (buttonRef.current && buttonRef.current.dataset.closed === "1") buttonRef.current.dataset.closed = "0";
        else if (!noAutoClose) focusInput(0);
    }, [noAutoClose]);

    const onChangeVal = useCallback<FocusEventHandler<HTMLInputElement>>(event => {
        onSelect?.(event.target.value);
        if (!noAutoClose && buttonRef.current) {
            buttonRef.current.dataset.closed = "1";
            buttonRef.current.click();
        }
    }, [onSelect, noAutoClose]);

    const inputChangeProps = useMemo(() => noAutoClose ? { onChange: onChangeVal } : { onBlur: onChangeVal }, [noAutoClose, onChangeVal]);

    const popOver = React.useMemo(() => <Popover style={{ maxWidth: "750px", zIndex: 10000 }}>
        <input ref={inputRef} className="form-control form-control-color" defaultValue={selectedColor ?? '#FFF'} autoFocus={!noAutoClose} type="color" {...inputChangeProps} />
    </Popover>, [inputChangeProps, selectedColor, noAutoClose]);

    useEffect(() => {
        if (TB.isColor(defaultColor) && selectedColor === null) onSelect?.(defaultColor);
    }, [defaultColor, selectedColor, onSelect]);

    return <Flex>
        <div className="border rounded me-1">
            <ColorCard color={selectedColor} />
        </div>
        {!props.disabled && <OverlayTrigger trigger="click" placement="right" overlay={popOver}>
            <Button ref={buttonRef} onClick={onOpen} size="sm" className="px-2">
                <i className="fa fa-chevron-down" style={{ fontSize: "0.6rem" }}></i>
            </Button>
        </OverlayTrigger>}
    </Flex>
}

export default ImgSelect;

type ColorCardProps = { color: string | null };
const ColorCardStyle = { height: "75px", width: "75px" };

const ColorCard: FC<ColorCardProps> = ({ color, ...props }) => <div className="p-1 bg-white" style={{ ...ColorCardStyle }}>
    <div className="h-100 w-100" style={{ backgroundColor: color ?? "#FFF" }} />
</div>