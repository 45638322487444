import axios from "axios";
import { getHeader } from "../Headers";
import { T, URL } from "../../Constants";

const { API_URL } = URL;

type GetDia = T.API.ToAxios<T.API.Utils.Tree.GetDia>;
type RemoveRoles = T.API.ToAxios<T.API.Access.RemoveRoles>;
type GetAllUserAccesses = T.API.ToAxios<T.API.Access.GetAllUserAccesses>;
type GetRoleDistribution = T.API.ToAxios<T.API.Access.GetRoleDistribution>;

//#region Types
export type GetEnergyGraphsResults = {
    building?: T.BuildingType;
    head?: { [key in T.EnergyType]?: string[] };
    datasets?: (Pick<T.DataSet, "_id" | "name" | "type" | "src"> & { parent: string, path_parent: string })[];
};

type GetUsersRoles = () => T.AxiosPromise<T.AccessRole[]>;
type GetSpecialRoles = T.API.ToAxios<T.API.Access.GetSpecialRoles>;
type GetDiaNodes = (ids: T.AllowArray<string>) => T.AxiosPromise<T.NodeDia[]>;
type GetEnergyGraphs = (id: string) => T.AxiosPromise<GetEnergyGraphsResults>;
type GetAccessResume = () => T.AxiosPromise<T.API.Access.AccessHomeTableItem[]>;
type RemoveGroup = (ids: T.AllowArray<string>) => T.AxiosPromise<T.DeleteResult>;
type RemoveNote = (ids: string | string[]) => T.AxiosPromise<{ deleted: number }>;
type GetAccessGroupsItems = () => T.AxiosPromise<T.API.Access.AccessUsersTableData>;
type GetAccessContext = (root: string) => T.AxiosPromise<T.API.Access.AccessPlaceTree>;
type SaveAccessRole = (role: T.AccessRole) => T.AxiosPromise<"no_context_left" | T.AccessRole>;
type GetUserAccess = (params: T.ContextParams) => T.AxiosPromise<Omit<T.RightsContext, "loading">>;
type GetNotesHistory = (params: T.API.Form.GetNotesHistoryParams) => T.AxiosPromise<T.API.Form.Notes[]>;
type GetAccessGroup = (ids: string | string[]) => T.AxiosPromise<T.API.Access.AccessUsersTableData["groups"]>;
type UpdateAccess = (params: Parameters<T.API.Access.UpdateAccess>[0]) => T.AxiosPromise<ReturnType<T.API.Access.UpdateAccess>>;
//#endregion

/** Load a user's accessible elements */
export const getRoleDistribution: GetRoleDistribution = user => axios.post(API_URL + "role/distribution", { user });
/** Load the list of "special" roles */
export const getSpecialRoles: GetSpecialRoles = () => axios.get(API_URL + "role/specials");
/** Deletes a role and removes the access it provided to users and groups */
export const removeRoles: RemoveRoles = (roles_ids) => axios.post(API_URL + "remove/role", { roles_ids });

export const getDia: GetDia = params => axios.post(API_URL + "getDia", params);
export const getUsersRoles: GetUsersRoles = () => axios.post(API_URL + "roles");
export const getAccessResume: GetAccessResume = () => axios.get(API_URL + "access/resume");
export const getDiaNodes: GetDiaNodes = ids => axios.post(API_URL + "getDiaNodes", { ids });
export const getNotesHistory: GetNotesHistory = p => axios.post(API_URL + "notes_history", p);
export const getAllUserAccesses: GetAllUserAccesses = () => axios.get(API_URL + "access/user/all");
export const getAccessGroup: GetAccessGroup = ids => axios.post(API_URL + "access/groups", { ids });
export const getUserAccess: GetUserAccess = context => axios.post(API_URL + "userAccesses", context);
export const getEnergyGraphs: GetEnergyGraphs = id => axios.post(API_URL + "getEnergyGraphs", { id });
export const getAccessContext: GetAccessContext = root => axios.post(API_URL + "access/context", { root });
export const getAccessGroupsItems: GetAccessGroupsItems = () => axios.post(API_URL + "access/groups_users");
export const removeNote: RemoveNote = ids => axios.post(API_URL + "remove_note", { ids }, getHeader("DELETE"));
export const removeGroup: RemoveGroup = ids => axios.post(API_URL + "group/remove", { ids }, getHeader("DELETE"));
export const saveAccessRole: SaveAccessRole = (role) => axios.post(API_URL + "role/save", role, getHeader("EDIT"));
export const updateAccess: UpdateAccess = params => axios.post(API_URL + "access/update", params, getHeader("EDIT"));