import axios from "axios";
import { getHeader } from "../Headers";
import { T, URL } from "../../Constants";

const { TABLES_API } = URL;

type GetNoteTags = T.API.ToAxios<T.API.Utils.Tables.GetNoteTags>;
type GetUsersRows = T.API.ToAxios<T.API.Utils.Tables.GetUsersRows>;
type GetNotesRows = T.API.ToAxios<T.API.Utils.Tables.GetNotesRows>;
type GetCapexRows = T.API.ToAxios<T.API.Utils.Tables.GetCapexRows>;
type EditNoteField = T.API.ToAxios<T.API.Utils.Tables.EditNoteField>;
type EditUserField = T.API.ToAxios<T.API.Utils.Tables.EditUserField>;
type EditSiteField = T.API.ToAxios<T.API.Utils.Tables.EditSiteField>;
type GetTicketRows = T.API.ToAxios<T.API.Utils.Tables.GetTicketRows>;
type EditGroupField = T.API.ToAxios<T.API.Utils.Tables.EditGroupField>;
type BulkEquipments = T.API.ToAxios<T.API.Utils.Tables.BulkEquipments>;
type EditGammeField = T.API.ToAxios<T.API.Utils.Tables.EditGammeField>;
type GetBuildingTags = T.API.ToAxios<T.API.Utils.Tables.GetBuildingTags>;
type EditTicketField = T.API.ToAxios<T.API.Utils.Tables.EditTicketField>;
type BulkEmplacements = T.API.ToAxios<T.API.Utils.Tables.BulkEmplacements>;
type EditMissionField = T.API.ToAxios<T.API.Utils.Tables.EditMissionField>;
type GetTicketRowsIds = T.API.ToAxios<T.API.Utils.Tables.GetTicketRowsIds>;
type GetEquipmentsRows = T.API.ToAxios<T.API.Utils.Tables.GetEquipmentRows>;
type GetNotesRowsByIds = T.API.ToAxios<T.API.Utils.Tables.GetNotesRowsByIds>;
type EditBuildingField = T.API.ToAxios<T.API.Utils.Tables.EditBuildingField>;
type GetCapexRowsByIds = T.API.ToAxios<T.API.Utils.Tables.GetCapexRowsByIds>;
type GetEmplacementRows = T.API.ToAxios<T.API.Utils.Tables.GetEmplacementRows>;
type EditEquipmentField = T.API.ToAxios<T.API.Utils.Tables.EditEquipmentField>;
type EditRegActionField = T.API.ToAxios<T.API.Utils.Tables.EditRegActionField>;
type GetEquipGammesRows = T.API.ToAxios<T.API.Utils.Tables.GetEquipGammesRows>;
type RegisterNoteTagsUse = T.API.ToAxios<T.API.Utils.Tables.RegisterNoteTagsUse>;
type GetEquipmentsColumns = T.API.ToAxios<T.API.Utils.Tables.GetEquipmentsColumns>;
type EditEmplacementField = T.API.ToAxios<T.API.Utils.Tables.EditEmplacementField>;
type GetEquipGammesColumns = T.API.ToAxios<T.API.Utils.Tables.GetEquipGammesColumns>;
type GetEquipmentsRowsByIds = T.API.ToAxios<T.API.Utils.Tables.GetEquipmentsRowsByIds>;
type ToggleUsersNavbarStates = T.API.ToAxios<T.API.Utils.Tables.ToggleUsersNavbarStates>;
type GetEmplacementRowsByIds = T.API.ToAxios<T.API.Utils.Tables.GetEmplacementRowsByIds>;
type ToggleEquipGammeProperty = T.API.ToAxios<T.API.Utils.Tables.ToggleEquipGammeProperty>;

/** Edit a mission value from the mission table */
export const edit_mission_field: EditMissionField = params => axios.post(TABLES_API + "edit/mission", params, getHeader("EDIT"));
/** Load the resources for the CAPEX table */
export const getCapexRows: GetCapexRows = context => axios.post(TABLES_API + "row/capex", context);
/** Load CAPEX rows for just the action ids given */
export const getCapexRowsByIds: GetCapexRowsByIds = ids => axios.post(TABLES_API + "items/capex", { ids });
/** Load the resources for the emplacement table */
export const getEmplacementRows: GetEmplacementRows = context => axios.post(TABLES_API + "row/emplacement", context);
/** Load rows for just the emplacement ids given */
export const getEmplacementRowsByIds: GetEmplacementRowsByIds = ids => axios.post(TABLES_API + "items/emplacement", { ids });
/** Load the resources for the users table */
export const getUsersRows: GetUsersRows = () => axios.post(TABLES_API + "row/user");
/** Edit a user's value from the user access table */
export const editUserField: EditUserField = params => axios.post(TABLES_API + "edit/user", params, getHeader("EDIT"));
/** Edit a group's value from the group access table */
export const editGroupField: EditGroupField = params => axios.post(TABLES_API + "edit/group", params, getHeader("EDIT"));
/** Load the rows for the equipment table */
export const getEquipmentRows: GetEquipmentsRows = params => axios.post(TABLES_API + "row/equipment", params);
/** Load rows for just the equipments ids given */
export const getEquipmentRowsByIds: GetEquipmentsRowsByIds = ids => axios.post(TABLES_API + "items/equipment", { ids });
/** Load equipment's columns based on the form */
export const getEquipmentsColumns: GetEquipmentsColumns = () => axios.get(TABLES_API + "columns/equipment");
/** The equipment gamme's columns based on the equipment form */
export const getEquipGammesColumns: GetEquipGammesColumns = () => axios.get(TABLES_API + "columns/equip_gammes");
/** Creates equipments in bulk */
export const bulk_equipments: BulkEquipments = items => axios.post(TABLES_API + "bulk/equipment", { items }, getHeader("CREATE"));
/** Creates emplacements in bulk */
export const bulk_emplacements: BulkEmplacements = items => axios.post(TABLES_API + "bulk/emplacement", [items], getHeader("CREATE"));
/** Edit an emplacement value from the emplacement table */
export const update_emplacement_field: EditEmplacementField = params => axios.post(TABLES_API + "edit/emplacement", params, getHeader("EDIT"));
/** Edit an equipment value from the emplacement table */
export const update_equipment_field: EditEquipmentField = params => axios.post(TABLES_API + "edit/equipment", params, getHeader("EDIT"));
/** Edit a ticket value from the capex table */
export const update_ticket_field: EditTicketField = params => axios.post(TABLES_API + "edit/ticket", params, getHeader("EDIT"));
/** Get the ticket rows for a context */
export const getTicketRows: GetTicketRows = context => axios.post(TABLES_API + "row/ticket", context);
/** Get the ticket rows by ids */
export const getTicketRowsIds: GetTicketRowsIds = ids => axios.post(TABLES_API + "items/ticket", { ids });
/** Load the rows for the note table */
export const getNotesRows: GetNotesRows = context => axios.post(TABLES_API + "row/note", context);
/** Edit a note value from the note table */
export const editNoteField: EditNoteField = params => axios.post(TABLES_API + "edit/note", params, getHeader("EDIT"));
/** Load rows for just the notes ids given */
export const getNotesRowsByIds: GetNotesRowsByIds = ids => axios.post(TABLES_API + "items/note", { ids });
/** Load the note tags available for a user and/or a site */
export const getNoteTags: GetNoteTags = params => axios.post(TABLES_API + "note/tags", params);
/** Add a site and a user to the list of note tags usage */
export const registerNoteTagsUse: RegisterNoteTagsUse = params => axios.post(TABLES_API + "note/register", params, getHeader("EDIT"));
/** Load the building tags available for a user */
export const getBuildingTags: GetBuildingTags = user => axios.get(TABLES_API + "building/tags", { params: { user } });
/** Edit a building value for a specific property */
export const editBuildingField: EditBuildingField = params => axios.post(TABLES_API + "edit/building", params, getHeader("EDIT"));
/** Edit a site value for a specific property */
export const editSiteField: EditSiteField = params => axios.post(TABLES_API + "edit/site", params, getHeader("EDIT"));
/** Edit a reg Action value from the emplacement table */
export const update_reg_action_field: EditRegActionField = params => axios.post(TABLES_API + "edit/reg_action", params, getHeader("EDIT"));
/** Get the equipment's gamme table items */
export const getEquipGammesRows: GetEquipGammesRows = ids => axios.post(TABLES_API + "items/equip_gammes", { ids });
/** Toggle a property for a gamme and it's descendants */
export const toggleEquipGammeProperty: ToggleEquipGammeProperty = params => axios.post(TABLES_API + "toggle/gamme", params, getHeader("EDIT"));
/** Edit an equipment value from the emplacement table */
export const edit_gamme_field: EditGammeField = params => axios.post(TABLES_API + "edit/gamme", params, getHeader("EDIT"));
/** Update the array of users who get to see the state in the navbar */
export const toggleUsersNavbarStates: ToggleUsersNavbarStates = params => axios.post(TABLES_API + "toggle/users_navbar", params, getHeader("EDIT"));