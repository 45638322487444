import React from "react";
import moment from "moment";
import * as uuid from "uuid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as R from "../../../reducers";
import { useDispatch } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import { MISSION, T, TB, TC, URL } from "../../../Constants";

//#region Intervenants
export type IntervenantsProps = {
    /** Is the table disabled */
    disabled?: boolean;
    /** Callback for changes */
    onChange: (array: T.Mission["intervenants"]) => void;
    /** Current value */
    value: T.Mission["intervenants"];
    /** The current list of errors */
    errors: T.Errors<T.Mission["intervenants"][number]>[];
}

export const Intervenants: React.FC<IntervenantsProps> = props => {
    const lg = H.useLanguage();

    const event = React.useMemo(() => ({
        remove: (index: number) => props.onChange?.(props.value.filter((v, i) => i !== index)),
        add: () => props.onChange?.(props.value.concat({
            tel: "",
            name: "",
            mail: "",
            company: "",
            present: false,
            role: "advisor",
            uuid: uuid.v4(),
        })),
        set: (value: any, index: number, key: keyof T.Mission_Intervenant) => {
            props.onChange?.(props.value.map((v, i) => {
                if (i !== index) return v;
                else if (key === "role" && value !== "other") return { ...v, [key]: value, other: undefined };
                else return { ...v, [key]: value };
            }));
        },
    }), [props]);

    return <>
        <BS.Row>
            <BS.Col>
                <C.Flex>
                    <h4>{lg.getStaticElem(TC.MISSION_INTERV_TITLE)}</h4>
                    {!props.disabled && <C.Button onClick={event.add} className="ms-2" icon="plus" size="sm" />}
                </C.Flex>
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Row className="text-center fs-85 align-items-center fw-bold mb-1">
                <BS.Col md={3}>{lg.getStaticElem(TC.MISSION_INTERV_ROLE)}<span children="*" className="text-danger ms-2" /></BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_COMPANY)}<span children="*" className="text-danger ms-2" /></BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_NAME)}<span children="*" className="text-danger ms-2" /></BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_MAIL)}</BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_TEL)}</BS.Col>
                <BS.Col md={1}>{lg.getStaticElem(TC.MISSION_INTERV_PRESENT)}</BS.Col>
                <BS.Col md={1}></BS.Col>
            </BS.Row>
            {props.value.length === 0
                ? <C.CenterMessage italics children={TC.GLOBAL_NONE} />
                : props.value.map((i, index) => <BS.Row className="g-1" key={index}>
                    <BS.Col md={3}>
                        <C.Flex>
                            <C.Form.Select
                                hideLabel
                                value={i.role}
                                disabled={props.disabled}
                                customClass="flex-grow-1"
                                typeahead={{ hideClearButton: true }}
                                options={MISSION.RoleIntervenantsOptions}
                                onChange={v => event.set(v, index, "role")}
                                error={{ code: props.errors?.[index]?.role }}
                            />
                            {i.role === "other" && <C.Form.TextField
                                hideLabel
                                value={i.other}
                                disabled={props.disabled}
                                onChange={v => event.set(v, index, "other")}
                                error={{ code: props.errors?.[index]?.other }}
                            />}
                        </C.Flex>
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            hideLabel
                            value={i.company}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "company")}
                            error={{ code: props.errors?.[index]?.company }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            hideLabel
                            value={i.name}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "name")}
                            error={{ code: props.errors?.[index]?.name }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            isEmail
                            hideLabel
                            value={i.mail}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "mail")}
                            error={{ code: props.errors?.[index]?.mail }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            hideLabel
                            value={i.tel}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "tel")}
                            error={{ code: props.errors?.[index]?.tel }}
                        />
                    </BS.Col>
                    <BS.Col md={1}>
                        <C.Flex className="w-100 h-100" justifyContent="center" alignItems="center">
                            <C.Form.CheckBox
                                hideLabel
                                value={i.present}
                                check_type="switch"
                                disabled={props.disabled}
                                onChange={v => event.set(v, index, "present")}
                            />
                        </C.Flex>
                    </BS.Col>
                    <BS.Col md={1}>
                        <C.Button disabled={props.disabled} onClick={() => event.remove(index)} variant="danger" icon="trash" size="sm" />
                    </BS.Col>
                </BS.Row>)}
        </BS.Row>
    </>;
}
//#endregion

//#region Visits
type VisitsProps = {
    /** A list of intervenants */
    intervenants: T.Mission["intervenants"];
    /** The visits */
    visit: T.Mission_Task_Visit;
    /** Callback for changes */
    onChange: (visit: T.Mission_Task_Visit) => void;
    /** A list of current errors */
    errors: T.Errors<T.Mission_Task_Visit>;
    /** Is the visit disabled */
    disabled?: boolean;
}

type InviteStatus = T.Mission_Task_Visit["invitations"][number]["status"];

export const InviteStatusOptions = [
    { label: TC.MISSION_INVITE_ACCEPTED, variant: "success", value: "accepted" },
    { label: TC.MISSION_INVITE_DENIED, variant: "danger", value: "denied" },
    { label: TC.MISSION_INVITE_SENT, variant: "info", value: "sent" },
    { label: TC.MISSION_INVITE_TO_SEND, variant: "primary", value: "to_send" },
] as T.Option<{ variant: T.ColorTypes }, InviteStatus>[];

const Visits: React.FC<VisitsProps> = ({ onChange, ...props }) => {
    /* const lg = H.useLanguage();

    const get_status = React.useCallback((uuid: string) => {
        return props.visit?.invitations?.find?.(i => i.uuid === uuid)?.status || "to_send";
    }, [props.visit?.invitations]);

    const change_status = React.useCallback((uuid: string, status: InviteStatus) => {
        let new_invitations = props.visit?.invitations || [];

        // Update an existing mail
        if (new_invitations.some(i => i.uuid === uuid)) new_invitations = new_invitations
            .map(i => i.uuid === uuid ? { uuid, status } : i);
        // Add a mail
        else new_invitations = new_invitations.concat({ uuid, status });

        onChange?.({ ...props.visit, invitations: new_invitations });
    }, [onChange, props.visit]); */

    return <BS.Row>
        {/* <BS.Col>
            <BS.Row> */}
        <BS.Col>
            <C.Form.DateTime
                enableTime
                disabled={props.disabled}
                label={TC.MISSION_VISIT_FROM}
                value={props.visit?.start_date}
                error={{ code: props.errors?.start_date }}
                onChange={start_date => onChange?.({ ...props.visit, start_date })}
            />
        </BS.Col>
        {/* </BS.Row>
            <BS.Row> */}
        <BS.Col>
            <C.Form.DateTime
                enableTime
                disabled={props.disabled}
                label={TC.MISSION_VISIT_TO}
                value={props.visit?.end_date}
                error={{ code: props.errors?.end_date }}
                onChange={end_date => onChange?.({ ...props.visit, end_date })}
            />
        </BS.Col>
        {/* </BS.Row>
        </BS.Col> */}
        {/* <BS.Col>
            <BS.Row className="fw-bold">
                <BS.Col>
                    {lg.getStaticElem(TC.MISSION_INTERV_TITLE)}
                </BS.Col>
                <BS.Col>
                    {lg.getStaticElem(TC.MISSION_INVITATION)}
                </BS.Col>
            </BS.Row>
            {props.intervenants.length === 0
                ? <C.CenterMessage>
                    {TC.GLOBAL_NONE}
                </C.CenterMessage>
                : props.intervenants.map((i, index) => <BS.Row className="align-items-center" key={i.uuid || index}>
                    <BS.Col>
                        {TB.validateMail(i.mail)
                            ? i.name || "N/A"
                            : <C.ErrorBanner no_margins type="danger" message={TC.REG_MAIL_INVALID} size="sm" />}
                    </BS.Col>
                    <BS.Col>
                        <C.ButtonSelect
                            size="sm"
                            default_value="to_send"
                            value={get_status(i.uuid)}
                            options={InviteStatusOptions}
                            disabled={props.disabled || !TB.validateMail(i.mail)}
                            onChange={status => change_status(i.uuid, status as any)}
                        />
                    </BS.Col>
                </BS.Row>)
            }
        </BS.Col> */}
    </BS.Row>;
}
//#endregion

//#region Report
type ReportProps = {
    /** The id of the mission */
    mission_id: string;
    /** The id of the asset under review */
    asset: string;
    /** The report data */
    report: T.Mission_Task_Report;
    /** The type of mission */
    mission_type: T.Mission["type"];
    /** The language of the mission's report */
    lang: T.Mission["lang"];
    /** A list of intervenants */
    intervenants: T.Mission["intervenants"];
    /** Callback for changes */
    onChange: (report: T.Mission_Task_Report) => void;
    /** Is the report task disabled */
    disabled?: boolean;
    /** Callback to the main mission form for checking the mistakes */
    check_errors: TasksProps["check_errors"];
    /** Callback to update and save the main mission form */
    save: (report?: T.Mission_Task_Report) => void;
    /** The DOM navigate Function */
    navigate: NavigateFunction;
    /** A callback to close the popup */
    quit?: () => void;
    /** The path of the mission's asset */
    asset_path: string;
}

const Report: React.FC<ReportProps> = ({ onChange, quit, navigate, check_errors, save, ...props }) => {
    const lg = H.useLanguage();
    const dispatch = useDispatch();
    const [{ userId }] = H.useAuth();
    const [users, set_users, status] = H.useAsyncState<T.Submission<T.UserData>[]>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getUsers({ includeAdmin: true, includeSelf: true })
            .then(({ data }) => isSubscribed && set_users(data, "done"))
            .catch(() => isSubscribed && set_users([], "error"));
        return () => {
            isSubscribed = false;
            set_users([], "load");
        }
    }, [set_users]);

    const check_and_save_mission = React.useCallback((): boolean => {
        // Check if mission form is OK to save
        let errors = check_errors(true);
        // Found errors
        if (Object.entries(errors).length > 0) {
            M.renderAlert({ type: "warning", message: TC.REPORT_ERRORS_STILL });
            return false;
        }
        // No errors, save the mission
        else {
            save();
            return true;
        }
    }, [check_errors, save]);

    const report = React.useMemo(() => ({
        make_final: (version_index: number) => {
            let has_no_errors = check_and_save_mission();
            if (!has_no_errors) return;
            // Ask confirmation to the user
            else M.askConfirm({ title: TC.REPORT_FINAL_VERSION, text: TC.REPORT_FINAL_REPORT_CONFIRM }).then(confirmed => {
                if (confirmed) {
                    // Perform some actions before turning the version into the final one
                    const final_promise = new Promise<void>((resolve, reject) => {
                        let promise: Promise<any> = null;
                        if (props.mission_type === "cdm" || props.mission_type === "edl_tech") promise = S.finalReportKPIs(props.mission_id);
                        else if (props.mission_type === "edl_reg") promise = S.finalReportRegKPIs(props.mission_id);
                        // Wait for the promise to end
                        if (promise) promise.then(() => resolve()).catch(reject);
                        else resolve();
                    });
                    // Turn the version into the final one
                    final_promise.then(() => S.makeReportFinal({ mission_id: props.mission_id, version_index }))
                        .then(new_report => onChange(new_report.data))
                        .catch(M.Alerts.loadError);
                }
            });
        },
        new_normal: () => {
            let has_no_errors = check_and_save_mission();
            if (!has_no_errors) return;
            else M.renderMissionReportModal({ mission_id: props.mission_id }).then(note => {
                // Update the local state
                if (note) save({
                    type: "report",
                    versions: props.report.versions.concat({
                        sent: [],
                        user: userId,
                        final: false,
                        notes: note.notes,
                        files: note.files,
                        manually_generated: true,
                        date: new Date().toISOString(),
                    })
                });
            });
        },
    }), [check_and_save_mission, onChange, save, userId, props.mission_id, props.mission_type, props.report.versions]);

    const get_user_name = React.useCallback((id: string) => {
        if (status === "load") return <C.IconTip spin spinIcon="spinner" icon="spinner" />;
        else if (status === "error") return <C.IconTip className="text-danger" icon="exclamation-triangle" />;
        else return users.find(u => u._id === id)?.data?.name || "N/A";
    }, [status, users]);

    const generate_report = React.useCallback(() => {
        let has_no_errors = check_and_save_mission();
        if (!has_no_errors) return;
        // Check the data of the mission and get the info needed for the generation of the report
        else S.missionCheck(props.mission_id).then(({ data }) => {
            // Mission doesn't exists, or wrong type, or inaccessible
            if (data.state === "non-existent") M.Alerts.haveNotRight();
            // There are missing data
            else if (data.state === "incomplete") {
                // Just a list of errors
                if (data.errors.length > 0) {
                    // Make a list of the errors found
                    let text = React.createElement("ul", null, data.errors.map((error, i) => {
                        let text = [lg.getStaticText(error.error, error.template)];
                        if (error.label) text.push("-", lg.getStaticText(error.label));
                        if (error.extra) text.push(`(${lg.getStaticText(error.extra)})`);
                        return <li key={i} children={text.join(" ")} />;
                    }));
                    // Show the errors to the user
                    M.askConfirm({ text, no_buttons: true, title: TC.NRJ_REPORT_FAILED_CHECK });
                }
                // Errors that require actions
                else if (data.errors_actions.type === "epra_surfaces") {
                    M.askConfirm({
                        text: TC.EPRA_SURFACES_MISSING_BODY,
                        title: TC.EPRA_SURFACES_MISSING_TITLE,
                        yesText: TC.EPRA_SURFACE_MISSING_FIX_NOW,
                        noText: TC.EPRA_SURFACE_MISSING_FIX_LATER,
                    }).then(confirmed => {
                        if (confirmed) {
                            // Close the popup
                            quit?.();
                            // Set the context and activate the mission
                            dispatch(R.selectContextMission({ asset: props.asset, mission: props.mission_id, path: props.asset_path }));
                            // Open the emplacement table, and give the emplacements to complete
                            navigate("/table/emplacements", { state: { ids_to_show: data.errors_actions.cells_ids, asset: props.asset } });
                        }
                    });
                }
            }
            // The data is complete, generate the report
            else {
                // Render the loader
                const unmount = M.renderLoader(TC.GLOBAL_GENERATING_FILE);
                // Create the report version
                S.createReportVersion({ mission_id: props.mission_id, template: { report: data.report, name: data.name } })
                    .then(r_versions => onChange(r_versions.data))
                    .catch(M.Alerts.file_generation_fail)
                    .finally(unmount);
            }
        }).catch(M.Alerts.loadError);
    }, [lg, navigate, check_and_save_mission, onChange, dispatch, quit, props.asset, props.asset_path, props.mission_id]);

    const send_report = React.useCallback((version: T.Mission_Task_Report["versions"][number], version_index: number) => {
        let intervenants = props.intervenants.map(i => i.mail);
        // Render the mail form
        M.renderReportMailForm({ intervenants, report_names: version.files.map(f => f.originalName), mission_id: props.mission_id }).then(mail => {
            if (mail) S.sendReportMail({ mail, mission: props.mission_id, version_index })
                .then(response => onChange(response.data.find(d => d.type === "report") as T.Mission_Task_Report))
                .catch(M.Alerts.loadError);
        });
    }, [props.intervenants, props.mission_id, onChange]);

    const open_sent = React.useCallback((sent: T.Mission_Task_Report["versions"][number]["sent"][number], version: T.Mission_Task_Report["versions"][number]) => {
        let intervenants = props.intervenants.map(i => i.mail);
        // Add the mails that may not exists in the intervenants list
        sent.to.concat(sent.cc).forEach(m => !intervenants.includes(m) && intervenants.push(m));
        // Render the mail form as read-only
        M.renderReportMailForm({ intervenants, report_names: version.files.map(f => f.originalName), mail: sent, mission_id: props.mission_id })
    }, [props.intervenants, props.mission_id]);

    if (!TB.mongoIdValidator(props.mission_id)) return <C.CenterMessage children={TC.REPORT_UNSAVED_MISSION} />;
    return <>
        <BS.Row className="mb-3">
            <BS.Col>
                <BS.Row className="mb-3">
                    <BS.Col>
                        <h3>{lg.getStaticElem(TC.MISSION_VERSION_TITLE)} ({props.report.versions.length})</h3>
                    </BS.Col>
                    <BS.Col md={6}>
                        <C.Flex className="w-100" justifyContent="end" alignItems="center">
                            <C.Button disabled={props.disabled} className="mx-1" size="sm" variant="outline-primary" icon="plus" onClick={generate_report}>
                                {TC.MISSION_REPORT_GENERATE}
                            </C.Button>
                            <C.Button disabled={props.disabled} className="mx-1" size="sm" icon="upload" onClick={() => report.new_normal()}>
                                {TC.MISSION_REPORT_NEW_VERSION}
                            </C.Button>
                        </C.Flex>
                    </BS.Col>
                </BS.Row>

                <BS.Row className="fs-105 fw-semibold g-2 mb-2">
                    <BS.Col className="text-start" children="#" md={1} />
                    <BS.Col className="text-start" children={lg.getStaticElem(TC.MISSION_REPORT_DATE)} md={2} />
                    <BS.Col className="text-start" children={lg.getStaticElem(TC.MISSION_REPORT_NAME)} />
                    <BS.Col className="text-center" children={lg.getStaticElem(TC.MISSION_REPORT_NOTE)} />
                    <BS.Col className="text-center" children={lg.getStaticElem(TC.MISSION_REPORT_FILE)} />
                    <BS.Col md={1} className="text-center" children={lg.getStaticElem(TC.MISSION_REPORT_FINAL_VERSION)} />
                    <BS.Col className="text-center" children={lg.getStaticText(TC.MISSION_REPORT_REPORT_HISTORY)} md={2} />
                    <BS.Col className="text-center" children={lg.getStaticText(TC.MISSION_REPORT_REPORT_SEND)} />
                </BS.Row>

                {props.report.versions.length === 0
                    ? <BS.Row children={<C.CenterMessage italics className="my-2" children={TC.GLOBAL_NONE} />} />
                    : TB.reverse_array(props.report.versions).map((v, j) => {
                        // Array is reversed, recalculate the real index
                        let i = props.report.versions.length - j - 1;

                        return <BS.Row key={i} className="g-2 mb-2">
                            <BS.Col className="text-start" children={i + 1} md={1} />
                            <BS.Col className="text-start" children={moment(v.date).format("DD/MM/YYYY HH:mm")} md={2} />
                            <BS.Col className="text-start" children={get_user_name(v.user)} />
                            <BS.Col className="text-center" children={v.notes} />

                            <BS.Col className="text-center">
                                {v.files.map(f => {
                                    let [file_name, file_extension] = f.originalName.split(".");
                                    let file_type = TB.get_file_simple_type_based_on_mime_type(f.type);
                                    let icon = "file";
                                    if (file_type === "excel") icon = "file-excel";
                                    else if (file_type === "pdf") icon = "file-pdf";
                                    else if (file_type === "word") icon = "file-word";
                                    return <C.IconButton
                                        icon={icon}
                                        key={f.name}
                                        className="mx-1"
                                        tip={f.originalName}
                                        onClick={() => TB.downloadFile(f.url, file_name, file_extension)}
                                    />
                                })}
                            </BS.Col>
                            <BS.Col md={1}>
                                {!props.disabled && i === props.report.versions.length - 1 && v.manually_generated && <C.Button
                                    size="sm"
                                    variant="danger"
                                    icon="check-circle"
                                    onClick={() => report.make_final(i)}
                                />}
                            </BS.Col>

                            <BS.Col className="text-center" md={2}>
                                <BS.Dropdown>
                                    <BS.Dropdown.Toggle
                                        size="sm"
                                        variant="sector"
                                        className="w-100"
                                        disabled={v.sent.length === 0}
                                        children={lg.getStaticText(TC.MISSION_REPORT_REPORT_HISTORY)}
                                    />
                                    <BS.Dropdown.Menu>
                                        {v.sent.map(s => <BS.Dropdown.Item
                                            key={s.date}
                                            onClick={() => open_sent(s, v)}
                                            children={moment(s.date).format("DD/MM/YYYY HH:mm")}
                                        />)}
                                    </BS.Dropdown.Menu>
                                </BS.Dropdown>
                            </BS.Col>

                            <BS.Col className="text-center">
                                <C.Button
                                    size="sm"
                                    variant="info"
                                    icon="envelope"
                                    className="w-100"
                                    onClick={() => send_report(v, i)}
                                />
                            </BS.Col>
                        </BS.Row>
                    })}
            </BS.Col>
        </BS.Row>
    </>;
}
//#endregion

//#region Report Form
export type ReportFormProps = {
    /** Extra Modal styles */
    modal?: M.StyleModalProps;
    /** Render in a popup ? */
    popup?: boolean;
    /** The _id of the mission */
    mission_id: string;
    /** Callback for confirmation */
    onConfirm?: (version?: Pick<T.Mission_Task_Report["versions"][number], "notes" | "files">) => void;
}

export const ReportForm: React.FC<ReportFormProps> = props => {
    const lg = H.useLanguage();
    const process_image = H.useBoolean(false);
    const [errors, set_errors] = React.useState<T.Errors<typeof note>>({});
    const [note, set_note] = React.useState<Parameters<ReportFormProps["onConfirm"]>[0]>({ notes: "", files: null });

    const save_note = React.useCallback(() => {
        if (note.files.length !== 1) set_errors({ files: TC.GLOBAL_REQUIRED_FIELD });
        else {
            process_image.setTrue();
            S.tempFileExists(note.files[0].name).then(({ data }) => {
                if (data) S.moveTempFile({ name: note.files[0].name, id: props.mission_id })
                    .then(() => {
                        let uploaded_files: T.File[] = note.files.map(f => ({
                            ...f,
                            storage: "local",
                            url: URL.CRAFT_FILE_URL_NO_ORIGIN(props.mission_id, f.name),
                        }));

                        props.onConfirm({ files: uploaded_files, notes: note.notes });
                    })
                    .catch(() => {
                        process_image.setFalse();
                        set_errors({ files: TC.FILE_FAILED_UPLOAD });
                    })
                else {
                    process_image.setFalse();
                    set_errors({ files: TC.FILE_FAILED_UPLOAD });
                }
            }).catch(() => {
                process_image.setFalse();
                set_errors({ files: TC.FILE_FAILED_UPLOAD })
            });
        }
    }, [note, process_image, props]);

    React.useEffect(() => {
        set_errors(p => ({ ...p, files: undefined }));
    }, [note.files]);

    const content = React.useMemo(() => <div>
        <C.Form.TextField
            textArea
            rows={4}
            autoExpand
            value={note.notes}
            error={{ code: errors.notes }}
            label={TC.MISSION_REPORT_NOTE}
            onChange={notes => set_note(p => ({ ...p, notes }))}
        />
        <C.Form.FileUploader
            filePattern="application/pdf"
            label={TC.MISSION_REPORT_FILE}
            error={{ code: errors.files }}
            onChange={f => set_note(p => ({ ...p, files: f }))}
        />
    </div>, [note, errors]);

    const footer = React.useMemo(() => <C.Flex>
        <C.Button
            onClick={save_note}
            text={TC.GLOBAL_SAVE}
            icon={{
                icon: "save",
                spinIcon: "spinner",
                spin: process_image.value,
            }}
        />
    </C.Flex>, [process_image.value, save_note]);

    if (!TB.mongoIdValidator(props.mission_id)) return <C.ErrorBanner
        type="danger"
        textCode="invalid mission id"
    />;
    else if (props.popup) return <M.BlankModal
        {...props.modal}
        footer={footer}
        size={props.modal?.size || "sm"}
        onQuit={() => props.onConfirm?.()}
        title={props.modal?.title || TC.MISSION_REPORT_NEW_VERSION}
    >
        {content}
    </M.BlankModal>
    return <div>
        <h5>{lg.getStaticElem(TC.MISSION_REPORT_NEW_VERSION)}</h5>
        {content}
        {footer}
    </div>;
}
//#endregion

//#region Tasks
export type TasksProps = {
    /** The id of the asset under review */
    asset: string;
    /** A list of intervenants */
    intervenants: T.Mission["intervenants"];
    /** The current tasks states */
    tasks: T.Mission["tasks"];
    /** The language of the mission's report */
    lang: T.Mission["lang"];
    /** The type of Mission */
    mission_type: T.Mission["type"];
    /** A callback to update the tasks */
    onChange: (tasks: T.Mission["tasks"]) => void;
    /** A list of current errors */
    errors: T.Errors<any>[];
    /** The id of the mission */
    mission_id?: string;
    /** Are the tasks disabled */
    disabled?: boolean;
    /** Callback after a change, also save the data */
    onChange_save: (tasks: T.Mission["tasks"], quit?: boolean) => void;
    /** Check the current form for errors */
    check_errors: (set?: boolean) => Partial<Record<keyof T.Mission, any>>;
    /** The DOM navigate Function */
    navigate: NavigateFunction;
    /** A callback to close the popup */
    quit?: () => void;
    /** The path of the mission's asset */
    asset_path: string;
}

export const TasksOptions = [
    { label: TC.MISSION_TASK_REG, value: "reg", unique: true, mission_types: ["cdm", "edl_tech"] },
    // { label: TC.MISSION_TASK_CRVA, value: "crva", unique: true, mission_types: ["eu_taxonomy"] },
    { label: TC.MISSION_TASK_VISIT, value: "visit", unique: true, mission_types: ["cdm", "edl_tech", "edl_reg", "eu_taxonomy"] },
    { label: TC.MISSION_TASK_REPORT, value: "report", unique: true, mission_types: ["cdm", "nrj", "edl_tech", "red_flag", "edl_reg", "eu_taxonomy", "rsc01", "epra"] },
] as T.Option<{ unique: boolean, mission_types: T.Mission["type"][] }, T.Mission["tasks"][number]["type"]>[];

export const Tasks: React.FC<TasksProps> = props => {
    const lg = H.useLanguage();

    const options = React.useMemo(() => {
        let already_selected = props.tasks.map(t => t.type);
        let available = TasksOptions.filter(o => o.mission_types.includes(props.mission_type) && (!o.unique || !already_selected.includes(o.value)));
        return available;
    }, [props.tasks, props.mission_type]);

    const event = React.useMemo(() => ({
        remove_task: (index: number) => props.onChange?.(props.tasks.filter((t, i) => i !== index)),
        add: () => {
            let type = options[0]?.value;
            if (type) props.onChange?.(props.tasks.concat({ type: type as any, versions: type === "report" ? [] : undefined }));
        },
        set_type: (type: T.Mission["tasks"][number]["type"], index: number) => {
            props.onChange?.(props.tasks.map((t, i) => {
                if (i !== index) return t;
                else if (type === "reg") return { type: "reg" };
                else if (type === "crva") return { type: "crva" };
                else if (type === "report") return { type: "report", versions: [] };
                else return { type: "visit", end_date: "", start_date: "", invitations: [] };
            }));
        },
        set_task: (task: T.Mission["tasks"][number], index: number) => {
            props.onChange?.(props.tasks.map((t, i) => {
                if (i !== index) return t;
                return task;
            }));
        },
        save_task: (task?: T.Mission["tasks"][number], index?: number) => {
            if (!task || typeof index !== "number") props.onChange_save(props.tasks, false);
            else props.onChange_save?.(props.tasks.map((t, i) => i !== index ? t : task), false);
        },
    }), [options, props]);

    const getOptions = React.useCallback((value?: T.Mission["tasks"][number]["type"]) => {
        let currentOption = TasksOptions.filter(o => o.value === value)[0];
        if (currentOption && !options.some(o => o.value === value)) return options.concat(currentOption);
        else return options;
    }, [options]);

    return <>
        <BS.Row className="mb-3">
            <BS.Col>
                <C.Flex>
                    <h4>{lg.getStaticElem(TC.MISSION_TASKS)}</h4>
                    <C.Button disabled={props.disabled || options.length === 0} onClick={event.add} className="ms-2" icon="plus" size="sm" />
                </C.Flex>
            </BS.Col>
        </BS.Row>
        {props.tasks.length === 0
            ? <BS.Row>
                <BS.Col>
                    <C.CenterMessage italics>
                        {TC.GLOBAL_NONE}
                    </C.CenterMessage>
                </BS.Col>
            </BS.Row>
            : props.tasks.map((t, i) => <BS.Row key={i} className="m-2 p-3 border">
                <BS.Col md={4}>
                    <C.Form.Select
                        hideLabel
                        value={t.type}
                        disabled={props.disabled}
                        options={getOptions(t.type)}
                        typeahead={{ hideClearButton: true }}
                        onChange={type => event.set_type(type, i)}
                    />
                </BS.Col>
                <BS.Col md={7}></BS.Col>
                <BS.Col className="text-end" md={1}>
                    <C.Button
                        size="sm"
                        icon="times"
                        variant="danger"
                        disabled={props.disabled}
                        onClick={() => event.remove_task(i)}
                    />
                </BS.Col>
                <BS.Col md={12}>
                    {t.type === "reg" && <></>}
                    {t.type === "crva" && <></>}

                    {t.type === "visit" && <Visits
                        visit={t}
                        disabled={props.disabled}
                        errors={props.errors?.[i]}
                        intervenants={props.intervenants}
                        onChange={value => event.set_task(value, i)}
                    />}

                    {t.type === "report" && <Report
                        report={t}
                        lang={props.lang}
                        quit={props.quit}
                        asset={props.asset}
                        disabled={props.disabled}
                        navigate={props.navigate}
                        mission_id={props.mission_id}
                        asset_path={props.asset_path}
                        mission_type={props.mission_type}
                        check_errors={props.check_errors}
                        intervenants={props.intervenants}
                        save={value => event.save_task(value, i)}
                        onChange={value => event.set_task(value, i)}
                    />}
                </BS.Col>
            </BS.Row>)}
    </>
}
//#endregion