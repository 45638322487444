import * as D from "./Data";
import { API, Schedule } from ".";
import { BulkMongoose } from "./mongo";
import { ColumnState, ChartModel } from "ag-grid-community";

//#region Collections types
export type LanguageMin = {
    _id: string;
    prop: string;
    label: string;
}

export type CovidForm = {
    _id: string;
    user: string;
    site: string;
    client: string;
    building: string;
    answer: AnyObject;
    emplacement?: string;
}

export type FormAction = {
    _id: string;
    name: string;
    form: string;
    title: string;
    priority: number;
    machineName: string;
    settings?: AnyObject;
    deleted: null | number;
    handler: ("before" | "after")[];
    method: ("create" | "update")[];
    condition?: {
        field?: string;
        eq?: string;
        value?: string;
        custom?: string;
    }
}

export type ItemOrder = {
    _id: string;
    item: string;
    relative: string;
}

export type AlarmsML = {
    _id: string;
    mail: string[];
    modele: string;
    number: string;
    compteur: string;
    min_threshold: number;
    max_threshold: number;
}

export type TrainingML = {
    _id: string;
    city: string;
    score: number;
    data?: string;
    number: string;
    country: string;
    compteur: string;
    regressor: string;
    end_score: number;
    end_training_time: string;
    start_training_time: string;
}

export type RegDoc = {
    _id: string;
    /** A list of file */
    files: File[];
    /** The user who created the document */
    user: string;
    /** A property to differentiate reg documents and other documents */
    type: "reg" | "other";
    notes?: string;
    regAction: string;
    controller: string;
    last_control: string;
    frequency: string | null;
    formerElement: RegFormElem[];
    currentElement: RegFormElem[];
    /** A list of extra properties, defined in the reg action */
    extra_properties?: Record<string, any>;
    /** A list of descriptive tags */
    tags: string[];
}

export type TempCollection = {
    done: boolean;
    user: UserData,
    note: NoteData,
    created: string;
    ticket: TicketData,
    linkQueries: { [key: string]: BulkMongoose };
}

export type TrainingList = {
    _id: string;
    city: string;
    country: string;
    compteur: string;
    models: string[];
    createdAt: string;
    updatedAt: string;
    first_day: string;
    pairs: TrainingPair[];
}
//#endregion

//#region Data per Form
export type NoteTag = {
    /** The name given to the tag */
    name: string;
    /** The list of sites it has been used on */
    sites: string[];
    /** The list of users it has been used by */
    users: string[];
    /** Should this tag always be present amongst the other tags */
    global?: boolean;
    /** Where is the tag used */
    type: "note"
    | "rems"
    | "site"
    | "equip"
    | "alarm"
    | "ticket"
    | "mission"
    | "dataset"
    | "regDoc"
    | "building"
    | "build_tag"
    | "reg_done_by"
    | "emplacement";
}
export type NoteData = {
    note?: string;
    audio?: any[];
    files?: File[];
    origin: string;
    subject?: string;
    responses?: string[];
    tags?: string[] | null;
}
export type ClientData = {
    /** Name of the company */
    name?: string;
    /** Pictures of the company */
    picture?: File[];
    /** An email contact of the company */
    email?: string;
    /** Is the company a franchise */
    isFranchise?: boolean;
    /** SIREN or TVA */
    numSiren?: string;
    /** The display color of the node */
    color?: string;
    /** The country of the client */
    country?: string;
}
export type GroupData = {
    /** The display name of the group */
    name: string;
    /** List of ids of the users that are allowed to edit */
    admin: string[];
    /** List of roles granted to the group */
    roles: AccessRoleZone[];
    /** List of members of the group */
    members: string[];
    /** Succinct description of the group */
    description?: string;
}
export type SiteData = {
    name: string;
    color?: string;
    refClient?: string;
    region?: string;
    country?: string;
    picture?: File[];
    requiresPE?: boolean;
    stations?: StationObj[];
    requiresEtudeSol?: boolean;
    requiresAvisIncendie?: boolean;
    reglementations?: ActionValueItem[],
    requiresGrosConsommateurRBC?: boolean;
    /** An id to the meteo station entry in the db */
    weather_station?: number;
    /** A list of tags attributed to the emplacement */
    tags?: string[];
    /** A list of schedules presets */
    schedules?: {
        /** Name of the schedule */
        name: string;
        /** The schedule */
        preset: Schedule[];
    }[];
}
export type ParkingData = {
    name: string;
    plan?: File[];
    area?: number;
    color?: string;
    capacity?: string;
    nbLevels?: number;
    type?: "indoor" | "outdoor" | "underground";
}
export type LanguageData = {
    code: string;
    icon?: File[];
    language: string;
}
export type UserData = {
    name: string;
    email: string;
    phone?: string;
    /** Whether the user wants to hide the items he does not have access to or not */
    hideUnavailable: boolean;
    /** The most recent selections, just as ids */
    last_used?: string[];
    /** The most recent missions, just as ids */
    last_missions?: string[];
    /**
     * @deprecated
     */
    tabs?: string[];
    apiKey?: string;
    password: string;
    preferences?: {};
    /**
     * @deprecated
     */
    rights?: string[];
    /**
     * @deprecated
     */
    loadTypes?: string[];
    roles?: AccessRoleZone[];
    /**
     * @deprecated
     */
    selectSite?: { _id: string, data: { name: string } }[];
    /**
     * @deprecated
     */
    selectClient?: { _id: string, data: { name: string } }[];
    /**
     * @deprecated
     */
    selectBuilding?: { _id: string, data: { name: string } }[];
};
export type TabMenuData = {
    fafa?: string;
    label: string;
    path?: string;
    order?: number;
    isMain: boolean;
    /**
     * @deprecated
     */
    roles: string[];
    reference: string;
    links: string[];
    /** Does this tab needs an option to be active ? */
    mission_dependant?: D.Mission["type"][];
    /** The name of the origin associated with this tab in the state savers */
    state_origin?: string;
};
export type ObjResourceData = {
    form: string;
    type: string;
}
export type ActionMaintenanceData = {
    name: string;
    site?: string;
    gammes: string[];
    duration?: string;
    resources: string[];
    description?: string;
    frequency: Frequency[];
}
export type RegAction = {
    name: string;
    doneBy?: string;
    paidBy?: string;
    sites?: string[];
    comment?: string;
    noFreq?: boolean;
    extLink?: string;
    gammes: string[];
    reference?: string;
    condition?: string;
    regions?: string[];
    countries: string[];
    resources: string[];
    frequency: Frequency;
    /** A list of custom status */
    status_list?: (Record<"code" | "color", string> & Record<"lang", Record<LanguageProps, string>>)[];
    /** A property to differentiate reg action and other actions */
    type: "reg" | "other";
    europeanDirective?: string;
    /** A number to determine the order to show the actions in */
    order: number;
    /** A code to retrieve specific document types, for specific usage */
    code?: "nzeb" | "performance_monitoring" | "hvac_power" | "crva" | "adaptation_solutions" | "min_safeguard" | "peb"
    /** Select in which dashboards this action needs to appear */
    dashboards?: ("id_card_build")[];
    /** Can a file auto-generate from a document of this type ?  */
    auto_file?: boolean;
    /** List of properties to ask when completing a Reg Doc for this action */
    extra_properties?: {
        /** Has an admin user disabled the update of this property ? */
        locked?: boolean;
        /** The names of this property in many languages */
        names: Record<LanguageProps, string>;
        /** The list of options translated, if data type is select */
        options?: Record<LanguageProps, string>[];
        /** The translation of the unit */
        unit_tr: Record<LanguageProps, string>;
        /** A unique name for the property */
        prop: string;
        /** The kind of data expected */
        data_type: "date"
        | "file"
        | "image"
        | "string"
        | "number"
        | "select"
        | "boolean"
        | "duration"
        | "end_section"
        | "list_section"
        | "simple_section"
        | "user_signature"
        | "simple_signature"
        | "repetitive_section"
        | "element_multi_select";
        /** A unit to measure the data */
        unit?: string;
        /** Is it required to enter a value for this property ? */
        required: boolean;
        /** Should the property be disclosed in a dashboard */
        dashboards?: RegAction["dashboards"];
    }[];
}
export type EquipStateData = {
    label: string;
    code: "M" | "A" | "HS";
}
export type RegionData = {
    name: string;
    country: string;
    parent?: string;
    reference: string;
}
export type RenovationActionData = {
    name: string;
    cost: string;
    gammes: string[];
    lifetime: string;
    emissions: string;
    periodSkip: number;
    energySavings: string;
    energyProduction: string;
    exclusions: { actions: string[], duration: number }[];
}
export type TicketData = {
    end?: string;
    title: string;
    start?: string;
    after?: File[];
    before?: File[];
    action?: string;
    signedBy?: string;
    duration?: string;
    /** A link to an image of a signature. Just because a ticket is closed, doesn't mean there is a signature */
    signature?: string;
    /** The _id of the local/equipment the ticket is linked to. Null for grouped tickets */
    equipment?: string;
    /** Was the ticket created by the AISET Bot */
    internal?: boolean;
    /** A list of tags attributed to the emplacement */
    tags?: string[];
    /** Id of the remarque this ticket is linked to */
    remarque?: string;
    /** Id of the regAction this ticket is linked to */
    regAction?: string;
    description?: string;
    dateSignature?: string;
    technicians?: string[];
    /** If ticket type is 'group', an estimation of the consumption after the ticket is done */
    estimated_cons?: number;
    /** If ticket type is 'group', the list of ticket ids this ticket regroups */
    sub_tickets?: string[];
    /** Does this action fits in a CAPEX plan */
    capex: boolean;
    /** The cost of the action (without VTA) */
    cost?: number;
    /** What kind of action does it represent */
    type: "reg" | "maintenance" | "doc_renewal" | "replacement" | "group";
}
export type EquipIndicatorData = {
    level?: string;
    color?: string;
    number?: number;
    emergency?: number;
    explication?: string;
    type: "criticityEquipment" | "criticityFailure" | "vetusty";
}
export type EventData = {
    url?: string;
    host?: string;
    name?: string;
    urls?: string;
    location?: string;
    shouldEnd?: string;
    shouldStart?: string;
    attendees?: string[];
    calendar: { end?: string, start?: string }[];
}
export type ContractData = {
    end?: string;
    file?: File[];
    title?: string;
    start?: string;
    receiver?: string;
    category?: string;
    internRef?: string;
    externRef?: string;
    obligations?: string;
    indexation?: boolean;
    facturation?: string;
    yearlyPrice?: number;
    noticePeriod?: number;
    reconduction?: boolean;
    indexationDate?: string;
    perimeter?: { loc?: string[], gammes?: string[] };
}
export type EntityData = AllTags & {
    /** The name of the entity */
    name: string;
    /** The color of the entity in the tree */
    color?: string;
    formula?: string;
    selectIcon?: string;
    CNL_DAC_UNIT?: "kWh";
    station?: StationObj;
    CNL_DAC_INDEX?: boolean;
    CNL_DAC_REPORT?: boolean;
    entityreference?: string;
    typedecompteur?: TypeCpt;
    typeEnergie?: EnergyType;
    CNL_DAC_IND_NEG?: boolean;
    calculatedformula?: string;
    stationsList?: StationObj[];
    MET_EXISTENCE_REAL?: boolean;
    CNL_ALA_MAX_1D_VALUE?: number;
    CNL_DAC_INTERVAL_STEP?: number;
    CNL_ALA_MAX_1D_ACTIVE?: boolean;
    CNL_ALA_3WEEKS_ACTIVE?: boolean;
    CNL_DAC_AGGREGATE_METHOD?: "Sum";
    CNL_ALA_3WEEKS_THRESHOLD?: number;
    dataType?: "INDEX" | "CONSUMPTION";
    functionality?: EntityFunctionality[];
    CNL_DAC_INTERVAL_TYPE?: "Minute" | "Hour";
    CNL_ALA_3WEEKS_KEEP_EXTREM_VALUES?: boolean;
    MET_IDE_FEEDING?: "COMMUN" | "INVOICE" | "PRIVATE";
}
export type LinkTypeData = {
    type: string;
    color?: string;
    parentSearch: boolean;
    pairs: { input: string, output: string, full: string }[];
}
export type ControlEntityData = {
    code: string;
    label: string;
}
export type EnseigneData = {
    /** The name of the enseigne */
    name: string;
    /** Display color in the diagram */
    color?: string;
    /** The list of related enterprises */
    enterprises?: string[];
}
export type LogoUrlData = {
    url: string;
    navLogo?: File[];
    tabLogo?: File[];
    browserTitle?: string;
}
export type RightData = {
    code: string;
    label: string;
    icon?: string;
    roles: string[];
    description: string;
}
export type IconData = {
    icon: File[];
    label: string;
    iconmenu: boolean;
}
export type BailData = {
    end?: string;
    link?: string;
    start?: string;
    break?: number;
    affectation?: string;
    loadDistribution?: string;
}

export type BailType = D.Submission<BailData>;
export type IconType = D.Submission<IconData>;
export type LogoUrlType = D.Submission<LogoUrlData>;
export type UserType = D.Submission<UserData>;
export type SiteType = D.Submission<SiteData>;
export type EventType = D.Submission<EventData>;
export type EntityType = D.Submission<EntityData>;
export type TicketType = D.Submission<TicketData>;
export type ClientType = D.Submission<ClientData>;
export type RegionType = D.Submission<RegionData>;
export type LinkType = D.Submission<LinkTypeData>;
export type TabMenuType = D.Submission<TabMenuData>;
export type ParkingType = D.Submission<ParkingData>;
export type EnseigneType = D.Submission<EnseigneData>;
export type LanguageType = D.Submission<LanguageData>;
export type EquipState = D.Submission<EquipStateData>;
export type ContractType = D.Submission<ContractData>;
export type EquipmentType = D.Submission<D.EquipmentData>;
export type EquipGammeType = D.Submission<D.EquipGammeData>;
export type ActionRegulationType = D.Submission<RegAction>;
export type ObjResourceType = D.Submission<ObjResourceData>;
export type EquipIndicator = D.Submission<EquipIndicatorData>;
export type RenovationActionType = D.Submission<RenovationActionData>;
export type ActionMaintenanceType = D.Submission<ActionMaintenanceData>;
//#endregion

//#region Misc
export type ModalSizes = "xs" | "sm" | "md" | "lg" | "xl";
export type Frequency = string | "SN";
export type RegStatusML = RegStatusM | "late";
export type RegStatusM = RegStatus | "missing";
export type AnyObject = { [key: string]: any; };
export type PromiseResolve = (value?: any) => void;
export type StringObject = { [key: string]: string };
export type CategoryTree = { [key: string]: string[] };
export type EntityFunctionality = "VENT" | "FR" | "CH";
export type TicketStatus = "closed" | "open" | "assigned";
export type RegStatus = "infraction" | "notes" | "conform";
export type Roots = { portfolio?: string, roots?: string[] };
export type StationObj = { id: number, station_name: string };
export type IDAList = "IDA1" | "IDA2" | "IDA3" | "IDA4" | "IDA";
export type Option<Extra = {}, Values = string> = { label: string, value: Values, prop?: string } & Extra;
export type TakeArrayElement<T extends ArrayLike<any>> = T[number];
export type TypeCpt = "CALCULATED" | "AUTOMATIC_READING" | "MANUAL";
export type AsyncStates<S extends string = ""> = "load" | "done" | "error" | S;
export type EnergyType = "ELEC" | "THFR" | "THCH" | "GAS" | "WATER" | "ELEC_PPV" | "FUEL";
export type LanguageProps = "nl" | "es" | "it" | "ar" | "fr_be" | "fr_fr" | "en_us" | "en_uk" | "de";
export interface FullOptionsLocationsTyped<A extends D.Submission> extends FullOptionsLocations { submission: A };
export type ColorTypes = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' | "sector" | "tiny-success";
export type RequestType = "READ" | "CREATE" | "DELETE" | "EDIT" | "OTHER" | "LOGIN" | "GRAFANA" | "FILE" | "ADMIN";
export type TagProp = "tag" | "th_tag" | "tag_ppv" | "gas_tag" | "fuel_tag" | "water_tag" | "th_tin_tag" | "th_tout_tag" | "th_cons_tag" | "th_power_tag" | "th_volume_tag";

export type TrainingPair = {
    number: string;
    index?: number;
    end_date: string;
    models: string[];
    frequency: string;
    columns: string[];
    start_date: string;
}

export type TagObj = {
    name: string;
    tag_id: number;
    station_id: number;
    equipment_name: string;
}

export type BaseFavorite<A = AnyObject> = {
    name: string;
    filters: A;
    isDefault: boolean;
}

export type RegFormElem = {
    id: string;
    elem: string;
    notes?: string;
    ticket?: string;
    status: RegStatus;
}

export interface FormExtraData extends AnyObject {
    site?: string;
    category?: string;
}

export type File = {
    url: string;
    name: string;
    size: number;
    type: string;
    originalName: string;
    storage: string; //"local" | "url" | "base64";
}

export type RenovationItem = {
    /** The year of the renovation */
    date?: number;
    description?: string;
    type?: "roof" | "wall" | "window" | "light" | "area" | "hvac";
}

export type HomeContextType = {
    sites?: string[];
    clients?: string[];
    portfolio?: string;
    buildings?: string[];
}

export type RoutesType = {
    /** The _id of the tab */
    _id: string;
    /** A link */
    to?: string;
    /** An icon */
    icon?: string;
    /** A label reference */
    name?: string;
    /** The order where this tab should appear */
    order?: number;
    /** Should the tab be active ? */
    active: boolean;
    reference?: string;
    labelProp?: string;
    /** A list of sub-content */
    children: RoutesType[];
    /** The list of state savers available for this page */
    states: Record<"name" | "id", string>[];
}

export type RoutesCollection = {
    label: string;
    labelDisable?: boolean;
    children: RoutesType[];
}

export type LocationType = {
    id: string;
    site?: SiteType[];
    client?: ClientType[];
    local?: D.EmplacementType[];
    building?: D.BuildingType[];
    parking?: D.EmplacementType[];
    emplacement?: D.EmplacementType[];
    orderedPlaces?: D.EmplacementType[];
    /** The last equipments found above the item */
    equipments?: D.Submission<D.EquipmentData>[];
    /** The complete list of equipments found above the item, in order */
    orderedEquipments?: D.Submission<D.EquipmentData>[];
}

export type AllTags = { [key in TagProp]?: TagObj };


export type FullOptionsLocations = {
    path: string;
    submission: D.Submission;
    location: LocationType;
};

export type TableState = {
    _id: string;
    name?: string;
    user?: string;
    origin: string;
    isGeneric: boolean;
    default_users?: string[];
    extraContext?: AnyObject;
    chartModels: ChartModel[];
    columnState: ColumnState[];
    filters?: AnyObject | string;
    adaptable?: AnyObject | string;
    open_row_nodes: string[];
    /** List of users who chose to have this state appears in the navbar */
    users_show_navbar?: string[];
    /** In case the state is a generic one, the users who chose to not have this state appear in the navbar */
    users_hide_navbar?: string[];
}

export type LanguageObj = { [key in LanguageProps]: string } & {
    id?: number;
    ref?: string;
    _id?: string;
    prop?: string;
    last_update: string;
    update_method: "A" | "M";
}

export type ReducedFileOptions = {
    url: string;
    label: string;
    value: string;
}

export type ActionValueItem = { action?: string, duration?: string, worker?: string[], dateLastMaintenance?: string };

export type ContextSites = ReturnType<API.Utils.Context.GetContextSites>[number];

export type AccessRoleZone = {
    /** The id of the zone the roles are active in */
    zone: string;
    /** The list of roles ids active in the zone */
    roles: string[];
}
//#endregion