import * as T from "../Types";
import { arrayWrapper } from "./array";
import { findIds, mongoIdValidator } from "./id";

/** Put a context in a valid format for a GET request */
export const toStringContext = (context: T.ContextParams): T.StringContextParams => ({ roots: arrayWrapper(context?.roots).join(), portfolio: context?.portfolio });

/** Transform a Context from a GET request to a regular context */
export const fromStringContext = (context: T.StringContextParams | T.ContextParams): T.ContextParams => {
    if (typeof context === "string") {
        try {
            let json_context = JSON.parse(context as any);
            if (json_context.roots || json_context.portfolio) return json_context;
        }
        catch (err) {
            console.error(err);
            return { roots: [] };
        }
    }
    // If the portfolio is a valid mongoId, return it
    if (mongoIdValidator(context?.portfolio)) return { portfolio: context.portfolio };
    // The roots are an array
    else if (Array.isArray(context?.roots)) return { roots: context.roots };
    // There is only one root, and it's a valid mongoId
    else if (mongoIdValidator(context?.roots)) return { roots: context.roots };
    // Try and find ids in the roots string
    else return { roots: findIds(context?.roots || "") };
}