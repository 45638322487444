import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { LANG, REGEX, T, TB, TC } from "../../../Constants";

export type FormProps = {
    /** When editing a gamme, the id of that gamme */
    gamme_id?: string;
    /** An option to pre-select the parent */
    parent?: string;
    /** Should the component be rendered in a popup ? */
    popup?: boolean;
    /** If the options were already loaded */
    options?: Option[];
    /** Extra Styling for the modal */
    modal?: M.StyleModalProps;
    /** Callback after validation, return the new gamme, or the edited gamme with it's updated children */
    on_validate?: (gammes: Row[]) => void;
}

type Data = ReturnType<T.API.Utils.Gammes.GetGammeBaseInfos>;
type Row = ReturnType<T.API.Utils.Tables.GetEquipGammesRows>[number];
type Option = ReturnType<T.API.Utils.Gammes.GetGammesOptions>[number];

const get_next_omniclass_example = (parent_omniclass: string) => {
    let clean_parent = TB.strip_omniclass(parent_omniclass);
    let new_omniclass = clean_parent + " ??";
    // If the parent has a double "00" at the end, add one pair to the new omniclass
    if (parent_omniclass.endsWith(" 00 00")) new_omniclass += " 00";
    // If the parent has a 'D' at the end, add it to the new omniclass
    if (parent_omniclass.endsWith(" D")) new_omniclass += " D";
    return new_omniclass;
}

const Form: React.FC<FormProps> = ({ on_validate, ...props }) => {
    const lg = H.useLanguage();
    const [errors, set_errors] = React.useState<T.Errors<Data>>({});
    const [gammes, set_gammes, status_gammes] = H.useAsyncState<Option[]>([]);
    const [data, set_data, status_data] = H.useAsyncState<Data>({ name: "", parent: "", omniclass: "", translations: {} });

    const statuses = React.useMemo(() => [status_data, status_gammes], [status_data, status_gammes]);

    React.useEffect(() => {
        if (props.parent) {
            let parent_omniclass = gammes.find(g => g.value === props.parent)?.omniclass;
            if (parent_omniclass) set_data(p => ({ ...p, parent: props.parent, omniclass: get_next_omniclass_example(parent_omniclass) }));
        }
    }, [props.parent, gammes, set_data]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.options) set_gammes(props.options, "done");
        else S.getGammesOptions({ lang: lg.prop, load_inactive: true })
            .then(({ data }) => isSubscribed && set_gammes(data, "done"))
            .catch(() => isSubscribed && set_gammes([], "error"));
        return () => {
            isSubscribed = false;
            set_gammes([], "load");
        }
    }, [lg.prop, props.options, set_gammes]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (!props.gamme_id) set_data({ name: "", parent: "", omniclass: "", translations: {} }, "done");
        else S.getGammeBaseInfos(props.gamme_id)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data({ name: "", parent: "", omniclass: "", translations: {} }, "error"));
        return () => {
            isSubscribed = false;
            set_data({ name: "", parent: "", omniclass: "", translations: {} }, "load");
        }
    }, [props.gamme_id, set_data]);

    const events = React.useMemo(() => ({
        name: (new_name: string) => set_data(p => ({ ...p, name: new_name, translations: { ...p.translations, [lg.prop]: new_name } })),
        parent: (parent_id: string) => {
            if (parent_id) {
                let parent_omniclass = gammes.find(g => g.value === parent_id)?.omniclass;
                set_data(p => ({ ...p, parent: parent_id, omniclass: get_next_omniclass_example(parent_omniclass) }));
            }
            else set_data(p => ({ ...p, parent: undefined }));
        },
        translate: () => {
            let rows = [data.translations];
            let modal_ref: C.QuickInputProps2<typeof rows[number]>["modal_ref"] = { current: null };
            let columns: C.QuickInputProps2<typeof rows[number]>["columns"] = LANG.ALL_LANGUAGES.map(l => ({ type: "text", prop: l.prop, label: l.lg }));

            const auto_translate = () => {
                let values = modal_ref.current?.get() || [];
                let complete_lang = LANG.ALL_PROPS.find(l => values.every(v => typeof v[l] === "string" && v[l].trim().length > 0));
                // No lang was had a translation for all rows, notify user
                if (!complete_lang) M.renderAlert({ type: "warning", message: TC.AUTO_TRANSLATE_NO_FULL_BASE });
                else {
                    const unmount = M.renderLoader();
                    let texts = values.map(v => v[complete_lang]);
                    S.translateManyText({ texts, lang: complete_lang }).then(({ data }) => {
                        let new_rows = values.map((value, index) => {
                            let result = data[index];
                            let clean_value = Object.fromEntries(
                                Object.entries(value).filter(([lg_prop, text]) => typeof text === "string" && text.trim().length > 0)
                            );

                            if (result === "failed") return value;
                            else return { ...result, ...clean_value };
                        });
                        modal_ref.current?.set(new_rows);
                    })
                        .catch(M.Alerts.loadError)
                        .finally(unmount);
                }
            };

            let footer = <C.Button variant="info" icon="robot" text={TC.AUTO_TRANSLATE} onClick={auto_translate} />
            M.renderQuickInput<Data["translations"]>({ gel_rows: true, footer, columns, rows, modal_ref, modal: { title: TC.GAMME_FORM_TRANSLATE_NAME, size: "lg" } }).then(labels => {
                if (labels) set_data(p => ({ ...p, translations: labels[0] }));
            });
        },
        validate: () => {
            let new_errors = {} as typeof errors;
            let check_promises: Promise<typeof new_errors>[] = [];
            // Check for missing values
            if (!data.name) new_errors.name = TC.GLOBAL_REQUIRED_FIELD;
            if (!data.omniclass) new_errors.omniclass = TC.GLOBAL_REQUIRED_FIELD;
            // Missing some translations
            if (LANG.ALL_PROPS.some(l => !data.translations[l])) new_errors.name = TC.GAMME_FORM_MISSING_TRANSLATIONS;
            // Check the validity of the omniclass
            if (!(data.omniclass || "").match(REGEX.OMNICLASS_REGEX)) new_errors.omniclass = TC.GAMME_FORM_INVALID_OMNICLASS_FORMAT;
            // Check if the omniclass is formatted to be a descendant of the parent
            else if (data.parent) {
                let parent_omniclass = gammes.find(g => g.value === data.parent)?.omniclass;
                if (!parent_omniclass) new_errors.parent = TC.GLOBAL_REQUIRED_FIELD;
                else {
                    // Remove the trailing 0 and D from the parent omniclass
                    let parent_omniclass_base = TB.strip_omniclass(parent_omniclass);
                    // Remove the trailing 0 and D from the new omniclass
                    let omniclass_base = TB.strip_omniclass(data.omniclass);
                    // Check that the new omniclass starts with the parent omniclass
                    let start_with_parent = omniclass_base.startsWith(parent_omniclass_base);
                    // Check that the new omniclass is only one level below the parent omniclass
                    let is_one_level_below = omniclass_base.length === parent_omniclass_base.length + 3;
                    // Check that the new omniclass is a direct descendant
                    if (!(start_with_parent && is_one_level_below)) new_errors.omniclass = TC.GAMME_FORM_INVALID_OMNICLASS_DESCENDANT;
                    // Check that the omniclass is not already used
                    else check_promises.push(new Promise((resolve, reject) => {
                        S.isOmniclassUsed({ omniclass: data.omniclass, id: props.gamme_id }).then(is_used => {
                            if (is_used.data === "unchanged") resolve({});
                            else if (is_used.data) resolve({ omniclass: TC.GAMME_FORM_OMNICLASS_USED });
                            else resolve({});
                        }).catch(reject);
                    }));
                }
            }
            // Gamme is a new 'Main' one, force it to follow the format '??-?? 00 00'
            else if (!data.omniclass.endsWith(" 00 00") || data.omniclass.length !== 11) new_errors.omniclass = TC.GAMME_FORM_INVALID_OMNICLASS_MAIN;

            // Render the loader if there are promises to check
            let unmount: ReturnType<typeof M.renderLoader>;
            if (check_promises.length > 0) unmount = M.renderLoader();
            // Wait for all promises to resolve
            Promise.all(check_promises).then(errors => {
                let final_errors = errors.reduce((acc, val) => ({ ...acc, ...val }), new_errors);
                if (Object.keys(final_errors).length > 0) set_errors(final_errors);
                else S.createEditGamme(data)
                    .then(results => on_validate?.(results.data))
                    .catch(M.Alerts.loadError);
            })
                .catch(M.Alerts.loadError)
                // Remove the loader when all promises are done
                .finally(() => unmount?.());
        },
    }), [on_validate, set_data, data, gammes, lg.prop, props.gamme_id]);

    const elements = React.useMemo(() => ({
        translate: { icon: "language", variant: "info", size: "sm", onClick: events.translate } as C.ButtonProps,
        footer: <C.Flex justifyContent="end" children={<C.Button icon="check" text={TC.GLOBAL_CONFIRM} onClick={events.validate} />} />,
    }), [events]);

    const render_option = React.useCallback((option: Option) => <C.Flex justifyContent="between" alignItems="end">
        <span children={option.label} />
        <span className="ms -2 text-muted fs-85" children={option.omniclass} />
    </C.Flex>, []);

    //#region Reset Errors
    React.useEffect(() => set_errors(p => ({ ...p, name: undefined })), [data.name]);
    React.useEffect(() => set_errors(p => ({ ...p, parent: undefined })), [data.parent]);
    React.useEffect(() => set_errors(p => ({ ...p, omniclass: undefined })), [data.omniclass]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer: elements.footer,
            size: props.modal?.size || "md",
            onQuit: () => on_validate?.(null),
            title: props.modal?.title || (TB.mongoIdValidator(props.gamme_id) ? TC.TABLE_GAMME_EDIT_CURRENT : TC.TABLE_GAMME_CREATE_NEW),
        } as M.BlankModalProps : null,
        <C.Spinner min_load_size="7.5rem" status={statuses}>

            <C.Form.Select
                options={gammes}
                value={data.parent}
                error={errors.parent}
                onChange={events.parent}
                label={TC.GAMME_FORM_PARENT}
                typeahead={{ renderItem: render_option, extra_search_props: "omniclass", dropdownFit: true }}
            />

            <C.Form.TextField
                required
                value={data.name}
                error={errors.name}
                onChange={events.name}
                label={TC.GAMME_FORM_NAME}
                suffix={elements.translate}
            />

            <C.Form.TextField
                required
                value={data.omniclass}
                error={errors.omniclass}
                label={TC.GAMME_FORM_OMNICLASS}
                onChange={omniclass => set_data(p => ({ ...p, omniclass }))}
            />

            {!props.popup && elements.footer}
        </C.Spinner>
    );
}

export default Form;