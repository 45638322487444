import _ from "lodash";
import React from "react";
import * as I from "../Input";
import * as IT from "../../Item";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { Modal as M } from "../../../Components";
import { T, TC, TB, LANG } from "../../../Constants";

export type RegPropertiesProps = I.InputProps;

type Row = T.RegAction["extra_properties"][number];
type WorkingRow = Row & Record<"name_default", string>;
type Option = T.Option<Record<"type", "dashboard" | "existing_prop">>;

const DATA_TYPES: T.Option<{}, Row["data_type"]>[] = [
    { label: TC.FORM_REG_PROP_DATE_TYPE, value: "date" },
    { label: TC.FORM_REG_PROP_STRING_TYPE, value: "string" },
    { label: TC.FORM_REG_PROP_NUMBER_TYPE, value: "number" },
    { label: TC.FORM_REG_PROP_BOOL_TYPE, value: "boolean" },
    { label: TC.FORM_REG_PROP_SELECT_TYPE, value: "select" },
    { label: TC.FORM_REG_PROP_SIMPLE_SECTION, value: "simple_section" },
    { label: TC.FORM_REG_PROP_LIST_SECTION, value: "list_section" },
    { label: TC.FORM_REG_PROP_ITERATIVE_SECTION, value: "repetitive_section" },
    { label: TC.FORM_REG_PROP_END_SECTION, value: "end_section" },
    { label: TC.FORM_REG_PROP_IMAGE, value: "image" },
    { label: TC.FORM_REG_PROP_FILE, value: "file" },
    { label: TC.FORM_REG_PROP_SIGNATURE, value: "simple_signature" },
    { label: TC.FORM_REG_PROP_USER_SIGNATURE, value: "user_signature" },
    { label: TC.FORM_REG_PROP_MULTI_SELECT, value: "element_multi_select" },
    { label: TC.FORM_REG_PROP_DURATION, value: "duration" },
];

const TYPES_WITH_UNITS: Row["data_type"][] = ["number", "select"];
const TYPES_WITH_DASHBOARD: Row["data_type"][] = ["select", "number", "date", "string", "boolean"];
const TYPES_WITH_REQUIRED: Row["data_type"][] = ["select", "number", "date", "string", "boolean", "list_section", "image", "file", "simple_signature", "user_signature", "element_multi_select", "duration"];

const RegProperties: React.FC<RegPropertiesProps> = ({ onChange, ...props }) => {
    const [auth] = H.useAuth();
    const lg = H.useLanguage();
    const template_attempts = React.useRef(0);
    const is_disabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const options = React.useMemo(() => {
        let available_dashboards: string[] = props.fullSubmission?.dashboards || [];
        let dashboard_options = (props.options as Option[] || []).filter(o => o.type === "dashboard" && available_dashboards.includes(o.value));
        let existing_prop_options = (props.options as Option[] || []).filter(o => o.type === "existing_prop");
        return { dashboard: dashboard_options, existing_prop: existing_prop_options };
    }, [props.options, props.fullSubmission?.dashboards]);

    const working_rows = React.useMemo(() => {
        let value_row: Row[] = props.value || [{}];
        return value_row.map(r => ({ ...r, name_default: r?.names?.[lg.prop] }));
    }, [props.value, lg.prop]);

    const add_options = React.useCallback(() => {
        M.askSelect({ options: options.existing_prop, isRequired: true, title: TC.FORM_REG_PROP_PICK_EXISTING }).then(selected => {
            let selected_prop = options.existing_prop.find(o => o.value === selected);
            if (selected_prop) {
                let new_rows = _.omit(selected_prop, "value", "labe", "type") as any as Row;
                onChange?.((props.value || []).concat(new_rows));
            }
        })
    }, [options.existing_prop, props.value, onChange]);

    const set_labels = React.useCallback(() => {
        let properties: Row[] = props.value || [];
        let rows = [] as (Row["names"] & Record<"data_type", Row["data_type"] | "unit" | "option"> & Partial<Record<"prop" | "self_prop", string>>)[];

        for (let property of properties) {
            // Push the row itself
            rows.push({ ...property.names, self_prop: property.prop, data_type: property.data_type });
            // Push the options
            if (property.data_type === "select") {
                let options = (property.unit || "").split("|").map(t => t.trim());
                options.forEach((o, i) => rows.push({ ...property.options?.[i], [lg.prop]: o, prop: property.prop, data_type: "option" }));
            }
            // Push the unit
            else if (property.unit) rows.push({ ...property.unit_tr, [lg.prop]: property.unit, prop: property.prop, data_type: "unit" });
        }

        let modal_ref: IT.QuickInputProps2<typeof rows[number]>["modal_ref"] = { current: null };
        let columns: IT.QuickInputProps2<typeof rows[number]>["columns"] = LANG.ALL_LANGUAGES.map(l => ({
            label: l.lg,
            type: "text",
            prop: l.prop,
            // Disable the field if the row is an end section
            is_disabled: r => r.data_type === "end_section",
        }));

        const auto_translate = () => {
            let values = modal_ref.current?.get() || [];
            let complete_lang = LANG.ALL_PROPS.find(l => values.every(v => v.data_type === "end_section" || (typeof v[l] === "string" && v[l].trim().length > 0)));
            // No lang was had a translation for all rows, notify user
            if (!complete_lang) M.renderAlert({ type: "warning", message: TC.AUTO_TRANSLATE_NO_FULL_BASE });
            else {
                const unmount = M.renderLoader();
                let texts = values.map(v => v[complete_lang]);
                S.translateManyText({ texts, lang: complete_lang }).then(({ data }) => {
                    let new_rows = values.map((value, index) => {
                        let result = data[index];
                        if (result === "failed") return value;
                        else return { ...value, ...result };
                    });
                    modal_ref.current?.set(new_rows);
                })
                    .catch(M.Alerts.loadError)
                    .finally(unmount);
            }
        };

        let footer = <IT.Button variant="info" icon="robot" text={TC.AUTO_TRANSLATE} onClick={auto_translate} />

        M.renderQuickInput<typeof rows[number]>({ columns, rows, modal_ref, footer, modal: { size: "lg", title: TC.FORM_REG_PROP_TRANSLATOR } }).then(labels => {
            if (labels) {
                let new_rows = properties.map(r => {
                    let names = labels.find(l => l.self_prop === r.prop);
                    let unit_tr = r.data_type === "select" ? undefined : labels.find(l => l.prop === r.prop);
                    let options_tr = r.data_type === "select" ? labels.filter(l => l.prop === r.prop) : undefined;
                    if (unit_tr) delete unit_tr.prop;
                    if (names) delete names.self_prop;
                    if (options_tr) options_tr.forEach(o => delete o.prop);
                    return { ...r, names, unit_tr, options: options_tr };
                });
                onChange?.(new_rows);
            }
        });
    }, [props.value, lg.prop, onChange]);

    const submit_rows = React.useCallback<IT.QuickInputProps2<WorkingRow>["onSubmit"]>(rows => {
        let new_rows = rows.map(r => ({
            ...r,
            name_default: undefined,
            names: { ...r.names, [lg.prop]: r.name_default },
            prop: TB.toSnakeCase(r.names?.[lg.prop] || "").toLowerCase(),
        }));

        onChange?.(new_rows);
    }, [onChange, lg.prop]);

    const is_row_disabled = React.useCallback((row: WorkingRow, prop: keyof WorkingRow) => {
        // Do not allow to edit one of the 'special' properties, if the row doesn't have a data_type
        if (!row.data_type && prop !== "data_type" && prop !== "name_default") return true;
        // Special Case: End section do not need a name
        else if (row.data_type === "end_section" && prop === "name_default") return true;
        // Do not allow to edit the data_type field, if the row is locked
        else if (!auth.isAdmin && row.locked) return false;
        // Do not allow to edit the unit field, if the data_type is not a number or select
        else if (prop === "unit") return !TYPES_WITH_UNITS.includes(row.data_type);
        // Do not allow to edit the required field, if the data_type does not require it
        else if (prop === "required") return !TYPES_WITH_REQUIRED.includes(row.data_type);
        // Do not allow to edit the dashboards field, if the data_type does not require it
        else if (prop === "dashboards") return !TYPES_WITH_DASHBOARD.includes(row.data_type);
        else return false;
    }, [auth.isAdmin]);

    const generate_template = React.useCallback(() => {
        template_attempts.current++;
        S.generateRegDocTemplate({ properties: working_rows, attempt: template_attempts.current }).then(res => {
            // Update the field in the form
            onChange?.([res.data], "custom_template" as keyof T.RegAction);
            // Download the file in the user's computer
            if (res.data.url) {
                const link = document.createElement('a');
                link.href = res.data.url;
                link.download = res.data.originalName; // Default name for downloaded file
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            // Alert the user that the file has been generated
            M.renderAlert({ type: "success", message: TC.FORM_REG_PROP_TEMPLATE_GENERATED });
        }).catch(M.Alerts.loadError);
    }, [working_rows, onChange]);

    const columns = React.useMemo<IT.QuickInputProps2<WorkingRow>["columns"]>(() => {
        let columns = [] as IT.QuickInputProps2<WorkingRow>["columns"];

        if (auth.isAdmin) columns.push({
            size: 1,
            prop: "locked",
            type: "boolean",
            label: TC.REG_CUSTOM_PROP_LOCKED,
            is_disabled: row => is_row_disabled(row, "locked"),
        });

        columns.push(
            {
                type: "text",
                prop: "name_default",
                label: TC.FORM_REG_PROP_NAME,
                is_disabled: row => is_row_disabled(row, "name_default")
            },
            {
                type: "select",
                prop: "data_type",
                no_clear_btn: true,
                options: DATA_TYPES,
                label: TC.FORM_REG_PROP_DATA_TYPE,
                is_disabled: row => is_row_disabled(row, "data_type")
            },
            {
                type: "text",
                prop: "unit",
                label: TC.FORM_REG_PROP_UNIT,
                is_disabled: row => is_row_disabled(row, "unit"),
                tip_props: { icon: "question-circle", children: TC.FORM_REG_PROP_UNIT_TIP },
            },
            {
                size: 1,
                type: "boolean",
                prop: "required",
                label: TC.FORM_REG_PROP_REQUIRED,
                is_disabled: row => is_row_disabled(row, "required")
            },
            {
                type: "select",
                multiple: true,
                prop: "dashboards",
                no_clear_btn: true,
                options: options.dashboard,
                label: TC.FORM_REG_PROP_DASHBOARD,
                is_disabled: row => is_row_disabled(row, "dashboards"),
            },
        );
        return columns;
    }, [options.dashboard, auth.isAdmin, is_row_disabled]);

    const footer = React.useMemo(() => {
        return <>
            {options.existing_prop.length > 0 && <IT.Button
                icon="plus"
                variant="secondary"
                onClick={add_options}
                text={TC.FORM_REG_PROP_PICK_EXISTING}
            />}

            <IT.Button
                variant="info"
                icon="language"
                onClick={set_labels}
                text={TC.FORM_REG_PROP_TRANSLATOR}
            />
            <IT.Button
                size="sm"
                icon="file-word"
                variant="sector"
                onClick={generate_template}
                text={TC.FORM_REG_PROP_GENERATE_TEMPLATE}
            />
        </>
    }, [options.existing_prop, add_options, set_labels, generate_template]);

    return <I.ComponentWrapper {...props} disabled={is_disabled}>
        <IT.QuickInput2<WorkingRow>
            controlled
            no_init_focus
            footer={footer}
            columns={columns}
            rows={working_rows}
            onSubmit={submit_rows}
        />
    </I.ComponentWrapper>;
};

export default RegProperties;