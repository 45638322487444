import _ from "lodash";
import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { FP, T, TB, TC } from "../../Constants";
import { Table, TableProps, CellsTypes as CT, ColDefParams } from "../Gestion";

type Row = ReturnType<T.API.Utils.Missions.GetMissionsReports>[number]["reports"][number]
    & Record<"type_label", ReturnType<T.API.Utils.Missions.GetMissionsReports>[number]["label"]>;

export type MissionReportProps = {
    /** The _id of the asset under review */
    asset: string;
}

const MissionReport: React.FC<MissionReportProps> = props => {
    const lg = H.useLanguage();
    const [mission_type, set_mission_type] = React.useState<T.Mission["type"] | "all">("all");
    const [data, set_data, status] = H.useAsyncState<ReturnType<T.API.Utils.Missions.GetMissionsReports>>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getMissionsReports(props.asset)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data([], "error"));
        return () => {
            isSubscribed = false;
            set_data([], "load");
        }
    }, [set_data, props.asset]);

    const rows = React.useMemo<Row[]>(() => {
        let rows = [] as Row[];

        if (mission_type === "all") data.forEach(d => rows = rows.concat(d.reports.map(r => ({ ...r, type_label: d.label }))));
        else {
            let mission = data.find(d => d.code === mission_type);
            rows = (mission?.reports || []).map(r => ({ ...r, type_label: mission?.label || "" }));
        }

        let typed_rows = rows.map(r => ({ ...r, tr_type: lg.getStaticText(r.type_label) }));
        return typed_rows;
    }, [data, mission_type, lg]);

    const option_types = React.useMemo(() => {
        // Check the total number of missions
        let total_missions = data.reduce((acc, d) => acc + d.reports.length, 0);
        // Create an option to show all missions
        let options: T.Option<{}, typeof mission_type>[] = [{ label: lg.getStaticText(TC.GLOBAL_ALL) + ` (${total_missions})`, value: "all" }];
        // Create an option for each mission type
        data.forEach(d => options.push({ label: lg.getStaticText(d.label) + ` (${d.reports.length})`, value: d.code }));
        return options;
    }, [data, lg]);

    const renders = React.useMemo<Record<"doughnut" | "report" | "color_number" | "asset_icon", ColDefParams<Row>["render"]>>(() => ({
        doughnut: (row, field) => {
            if (!row) return null;
            let value = _.get(row, field);
            if (typeof value !== "number" || isNaN(value)) return null;
            let color = TB.pickHex(value / 100);
            return <div className="fs-110 fw-bold text-center" style={{ color }} children={value + "%"} />;
        },
        color_number: (row, field) => {
            if (!row) return null;
            let value = _.get(row, field), color = "#748194";
            if (field === "extra.high_crit_rem") color = "#e63757";
            else if (field === "extra.med_crit_rem") color = "#f5803e";
            return <div className="fs-110 fw-bold text-center" style={{ color }} children={value} />;
        },
        asset_icon: row => {
            if (!row) return null;
            let icon = "building";
            if (row.asset_path === FP.SITE_FORM) icon = "city";
            return <div className="w-100 text-center" children={<i className={`fa fa-${icon}`} />} />;
        },
        report: row => {
            if (!row) return;
            return <div>
                {row.links.map(l => {
                    let icon = "file";
                    if (l.file_type === "pdf") icon = "file-pdf";
                    else if (l.file_type === "word") icon = "file-word";
                    else if (l.file_type === "excel") icon = "file-excel";
                    return <C.IconButton className="me-1" icon={icon} onClick={() => window.open(l.url, "_blank")} />
                })}
            </div>;
        },
    }), []);

    const columns = React.useMemo<TableProps<Row>["columns"]>(() => {
        let extra_columns = [] as TableProps<Row>["columns"];

        if (mission_type === "all" || mission_type === "cdm" || mission_type === "edl_tech") extra_columns.push(
            { field: "extra.high_crit_rem", headerName: TC.REPORT_MISSION_TABLE_HIGH_REMS, type: CT.TYPE_FREE_RENDER, params: { render: renders.color_number } },
            { field: "extra.med_crit_rem", headerName: TC.REPORT_MISSION_TABLE_MEDIUM_REMS, type: CT.TYPE_FREE_RENDER, params: { render: renders.color_number } },
            { field: "extra.low_crit_rem", headerName: TC.REPORT_MISSION_TABLE_LOW_REMS, type: CT.TYPE_FREE_RENDER, params: { render: renders.color_number } },
        );
        if (mission_type === "all" || mission_type === "edl_reg") extra_columns.push(
            { field: "extra.reg_doc_presence", headerName: TC.REPORT_MISSION_TABLE_DOC_PRESENT, type: CT.TYPE_FREE_RENDER, params: { render: renders.doughnut } },
            { field: "extra.reg_doc_conformity", headerName: TC.REPORT_MISSION_TABLE_DOC_CONFORMITY, type: CT.TYPE_FREE_RENDER, params: { render: renders.doughnut } },
        );

        return [
            { field: "link", headerName: TC.REPORT_MISSION_TABLE_REPORT, type: CT.TYPE_FREE_RENDER, params: { render: renders.report } },
            { field: "tr_type", headerName: TC.MISSION_TYPE, rowGroup: mission_type === "all" },
            { field: "date", headerName: TC.REPORT_MISSION_TABLE_REPORT_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
            { field: "asset_path", headerName: " ", type: CT.TYPE_FREE_RENDER, params: { render: renders.asset_icon } },
            { field: "asset_name", headerName: TC.REPORT_MISSION_TABLE_ASSET },
            ...extra_columns,
        ];
    }, [mission_type, renders]);

    return <C.Spinner status={status}>

        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    no_clear_btn
                    value={mission_type}
                    options={option_types}
                    label={TC.MISSION_TYPE}
                    onChange={type => set_mission_type(type)}
                />
            </BS.Col>
        </BS.Row>

        <div className="bg-white h-100 mb-3">
            <Table<Row>
                rows={rows}
                columns={columns}
                remove_unknown_columns
                sideBar="filters_columns"
                loading={status === "load"}
                columns_base="all_but_edit"
                adaptableId="mission_report_table"
                autoFit={React.useMemo(() => ["link", "asset_path"], [])}
            />
        </div>


    </C.Spinner>;
};

export default MissionReport;