import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as ES from "./ElemStyles"
import * as S from "../../services";
import blobStream from 'blob-stream';
import * as BS from "react-bootstrap";
import { DndProvider } from "react-dnd";
import * as DOM from "react-router-dom";
import * as PM from "../../PurposeModal";
import BP from "./PropsPrimitiveDiagrams.json";
import { FamDiagram } from 'basicprimitivesreact';
import { FamDiagramPdfkit } from "basicprimitives";
import PdfKit from "pdfkit/js/pdfkit.standalone.orig";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FP, LT, RIGHTS, T, TABS, TB, TC, URL } from "../../Constants";
import ConfigForm, { ConfigFormRef, ConfigType, mergeConfig } from "./ConfigForm";
import { BuildEditor } from "../BuildEditor";

//#region Types
export type TreeProps = { root?: string, nameContext?: string };
type LiveStateStringProps = "templateName" | "cursorItem" | "menu_item";
type Resource = ReturnType<T.API.Utils.Tree.GetDia> & Partial<Record<"error", any>>;
type LiveStateBoolProps = 'isLocked' | "enableDrag" | "showMenu" | "edit_wait" | "no_edit_unlock";
type LiveState = Record<LiveStateBoolProps, boolean> & Partial<Record<LiveStateStringProps, string>>;
//#endregion

//#region Constants
const MAX_SHOWN_NODES = 2000;
const DF_RESOURCES: Resource = { links: [], nodes: [], linkTypes: [], roots: [], fake_links: [], contextName: "" };

const RIGHT_DICTIONARY: Record<string, Record<"edit" | "create" | "delete" | "move", string>> = {
    [FP.BUILDING_FORM]: {
        edit: RIGHTS.TECH.EDIT_BUILDING,
        move: RIGHTS.TECH.MOVE_BUILDING,
        create: RIGHTS.TECH.CREATE_BUILDING,
        delete: RIGHTS.TECH.DELETE_BUILDING,
    },
    [FP.SITE_FORM]: {
        edit: RIGHTS.TECH.EDIT_SITE,
        move: RIGHTS.TECH.MOVE_SITE,
        create: RIGHTS.TECH.CREATE_SITE,
        delete: RIGHTS.TECH.DELETE_SITE,
    },
    [FP.EMPLACEMENT_FORM]: {
        edit: RIGHTS.TECH.EDIT_EMPLACEMENT,
        move: RIGHTS.TECH.MOVE_EMPLACEMENT,
        create: RIGHTS.TECH.CREATE_EMPLACEMENT,
        delete: RIGHTS.TECH.DELETE_EMPLACEMENT,
    },
    [FP.EQUIPEMENT_FORM]: {
        edit: RIGHTS.TECH.EDIT_EQUIPMENT,
        move: RIGHTS.TECH.MOVE_EQUIPMENT,
        create: RIGHTS.TECH.CREATE_EQUIPMENT,
        delete: RIGHTS.TECH.DELETE_EQUIPMENT,
    },
    other: {
        edit: RIGHTS.TECH.WRITE_OTHERS,
        move: RIGHTS.TECH.WRITE_OTHERS,
        create: RIGHTS.TECH.WRITE_OTHERS,
        delete: RIGHTS.TECH.WRITE_OTHERS,
    }
}

const DF_LIVE_STATE: LiveState = {
    isLocked: true,
    showMenu: false,
    edit_wait: false,
    enableDrag: false,
    no_edit_unlock: false,
    templateName: ES.TemplatesOptions.ContextMenu.NAME,
};
//#endregion

const Tree: React.FC<TreeProps> = ({ root, nameContext }) => {
    const lg = H.useLanguage();
    H.useCrumbs(TC.TREE_STRUCT);
    const rights = H.useRights();
    const [copyValue] = H.useClipBoard();
    const expanded_view = H.useBoolean(false);
    const [socket] = H.useSingleSocket(URL.DIA_SOCKET);
    const configRef = React.useRef<ConfigFormRef>(null);
    const { state } = DOM.useLocation() as { state?: TreeProps };
    const [hiddenNodes, setHiddenNodes] = React.useState<string[]>([]);
    const [context] = H.useRoots({ roots: state?.root || root });
    const [{ isAdmin, userId }] = H.useAuth({ tabName: TABS.DIA_TREE });
    const [config, setConfig] = React.useState<Partial<ConfigType>>(mergeConfig());
    const [{ links, nodes, roots, linkTypes, contextName, fake_links }, setResources, status] = H.useAsyncState<Resource>(DF_RESOURCES);
    const [{ isLocked, cursorItem, menu_item, enableDrag, templateName, showMenu, edit_wait, no_edit_unlock }, setLiveState] = React.useState<LiveState>(DF_LIVE_STATE);
    const realContextName = React.useMemo(() => state?.nameContext || nameContext || contextName, [state?.nameContext, nameContext, contextName]);

    //#region Fetch data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getDia(context).then(({ data }) => {
            if (isSubscribed) {
                let firstChildren = data.links.filter(l => data.roots.includes(l.input)).map(l => l.output);
                let firstChildrenWithChildren = firstChildren.filter(id => data.links.some(l => l.input === id));

                setHiddenNodes(firstChildrenWithChildren);
                setResources(p => ({ ...p, ...data }), "done");
                if (TB.mongoIdValidator(data.roots[0])) setLiveState(p => ({ ...p, cursorItem: data.roots[0] }));
            }
        }).catch(error => isSubscribed && setResources(p => ({ ...p, error }), "error"));
        return () => {
            setHiddenNodes([]);
            isSubscribed = false;
            setResources(p => ({ ...p, links: [], nodes: [], roots: [], contextName: "" }), "load");
            setLiveState(p => ({ isLocked: true, no_edit_unlock: false, enableDrag: false, showMenu: false, edit_wait: false, templateName: p.templateName }));
        };
    }, [context, setResources]);
    //#endregion

    //#region Socket
    const sendActivities = React.useCallback((updated?: boolean) => socket.emit("tree_activity", { updated }), [socket]);

    const askForEditMode = React.useCallback(() => {
        socket.emit("start_edit_tree");
        setLiveState(p => ({ ...p, edit_wait: true }));
    }, [socket]);

    const treeKickedOut = React.useCallback(() => {
        setLiveState(p => ({ ...p, isLocked: true }));
        M.renderAlert({ type: "error", message: TC.TREE_TIME_OUT_MSG, title: TC.TREE_TIME_OUT_TITLE, delay: -1 });
    }, []);

    const leaveEditMode = React.useCallback(() => {
        socket.emit("stop_edit_tree");
        setLiveState(p => ({ ...p, isLocked: true }));
    }, [socket]);

    const grantedEditMode = React.useCallback((params: T.Sockets.Dia.ToEditMode) => {
        const updateState = () => setLiveState(p => ({ ...p, edit_wait: false, isLocked: false }));

        if (params?.showAlert) M.renderAlert({ type: "info", title: TC.TREE_EDIT_MODE_GRANTED_TITLE, message: TC.TREE_EDIT_MODE_GRANTED_MSG });

        if (params?.shouldReload) S.getDia(context).then(({ data }) => {
            updateState();
            setResources(p => ({ ...p, ...data }), "done");
        }).catch(error => setResources(p => ({ ...p, error }), "error"));
        else updateState();
    }, [context, setResources]);

    const editModeInQueue = React.useCallback((queueLength: number) => {
        setLiveState(p => ({ ...p, edit_wait: false }));
        // No user waiting, but edit mode unavailable
        if (queueLength === 1) M.renderAlert({ type: "info", title: TC.DIA_EDIT_MODE_UNAVAILABLE, message: TC.DIA_EDIT_MODE_IN_USE });
        // Several users waiting
        else M.renderAlert({ type: "info", title: TC.DIA_EDIT_MODE_UNAVAILABLE, message: { ref: TC.DIA_EDIT_MODE_QUEUE, template: queueLength } });
    }, []);

    const socketErrorHandler = React.useCallback((error?: string) => {
        M.renderAlert({ type: "error", message: TB.getString(error, TC.GLOBAL_ERROR) });
        setLiveState(p => ({ ...p, isLocked: true, edit_wait: false }));
    }, []);

    React.useEffect(() => socket.addOneCallback("tree_time_out", treeKickedOut), [socket, treeKickedOut]);
    React.useEffect(() => socket.addOneCallback("edit_tree_allowed", grantedEditMode), [socket, grantedEditMode]);
    React.useEffect(() => socket.addOneCallback("edit_tree_in_queue", editModeInQueue), [socket, editModeInQueue]);
    React.useEffect(() => socket.addOneCallback("socket_tree_error", socketErrorHandler), [socket, socketErrorHandler]);

    React.useEffect(() => socket.addOneCallback("failed_ancestors_search", () => {
        /* Error occurred when loading data in the socket, do not allow to use the edit mode */
        setLiveState(p => ({ ...p, no_edit_unlock: true }));
        M.Alerts.loadError({ type: "warning", message: TC.DIA_SOCKET_ERROR_NO_UNLOCK });
    }), [socket]);

    React.useEffect(() => {
        if (roots.length > 0) socket.emit("connect_tree", { roots, user: userId } as T.Sockets.Dia.ConnectTree);
        return () => socket.emit("disconnect_tree");
    }, [roots, userId, socket]);
    //#endregion

    //#region Languages
    React.useEffect(() => {
        let nodesIds = nodes.map(n => n.id);
        let typesIds = linkTypes.map(lt => lt._id);
        let allIds = nodesIds.concat(typesIds).filter(TB.mongoIdValidator);
        if (allIds.length > 0) lg.fetchObjectTranslations(allIds);
    }, [lg, nodes, linkTypes]);
    //#endregion

    //#region Subs & Links Types
    const own_link_type = React.useMemo(() => linkTypes.filter(lt => lt.type === LT.LINK_TYPE_OWN)[0]?._id, [linkTypes]);
    const foundTypes = React.useMemo(() => ({ nodes: _.uniq(nodes.map(n => n.path)), links: _.uniq(links.map(l => l.type)) }), [nodes, links]);
    //#endregion

    //#region LiveState Changes
    const setLiveStateString = React.useCallback((prop: LiveStateStringProps, str?: string) => setLiveState(p => p[prop] === str ? p : { ...p, [prop]: str }), []);

    const setLiveStateBool = React.useCallback((prop: LiveStateBoolProps, bool?: boolean) => setLiveState(p => {
        // No boolean provided, default is toggle
        if (typeof bool !== "boolean") return { ...p, [prop]: !p[prop] };
        return p[prop] === bool ? p : { ...p, [prop]: bool }
    }), []);

    React.useEffect(() => {
        // Disable drag if tree is lock
        if (isLocked && enableDrag) setLiveStateBool("enableDrag");
    }, [isLocked, enableDrag, setLiveStateBool]);
    //#endregion

    //#region Link Search
    const linkSearch = React.useCallback((id: string) => {
        let lParents = links.filter(l => l.output === id);
        let lChildren = links.filter(l => l.input === id);
        let [lReverseSearchParents, lRegularParents] = _.partition(lParents, l => _.find(linkTypes, lt => lt._id === l.type)?.parentSearch);
        return { children: lChildren, parents: lRegularParents, triggeredSearch: lReverseSearchParents };
    }, [links, linkTypes]);

    const getFamily = React.useCallback((id: string, searchDown: boolean, direct = false) => {
        let idList: string[] = [], hasExecuted = false;

        const recursive = (ids: string | string[]) => {
            if (direct && hasExecuted) return;
            if (!hasExecuted) hasExecuted = true;
            let idsArray = TB.arrayWrapper(ids).filter(TB.mongoIdValidator);
            if (idsArray.length === 0) return;

            let familyIds = [];
            if (searchDown) familyIds = links.filter(l => ids.includes(l.input) && !idList.includes(l.output)).map(l => l.output);
            else familyIds = links.filter(l => ids.includes(l.output) && !idList.includes(l.input)).map(l => l.input);
            idList.push(...familyIds);
            if (familyIds.length === 0) return;
            return recursive(familyIds);
        }

        recursive(id);
        return nodes.filter(n => idList.includes(n.id));
    }, [nodes, links]);

    const getParents = React.useCallback((id: string, direct = false) => getFamily(id, false, direct), [getFamily]);
    const getChildren = React.useCallback((id: string, direct = false) => getFamily(id, true, direct), [getFamily]);
    const getSites = React.useCallback((id: string) => getFamily(id, false, false).filter(n => n.path === FP.SITE_FORM), [getFamily]);

    const getNodes = React.useCallback((ids: string[]) => {
        let vIds = TB.getArray(ids);
        return nodes.filter(n => vIds.includes(n.id));
    }, [nodes]);

    const getAvailableLinkTypesFromPaths = React.useCallback((input: string, output: string) => linkTypes.filter(lt => lt.pair.some(p => p.input === input && p.output === output)), [linkTypes]);

    const getAvailableLinkTypesFromIds = React.useCallback((input: string, output: string) => {
        let nodes = getNodes([output, input]);
        let nOutput = _.find(nodes, n => n.id === output), nInput = _.find(nodes, n => n.id === input);
        return getAvailableLinkTypesFromPaths(nInput.path, nOutput.path);
    }, [getNodes, getAvailableLinkTypesFromPaths]);
    //#endregion

    //#region Elements List
    const entitiesList = React.useMemo(() => {
        let options = [{ name: lg.getStaticText(TC.GLOBAL_NONE) as string, id: "" }];
        let entities = nodes.filter(n => n.path === FP.ENTITY_FORM)
            .map(n => ({ name: n.title, id: n.id }));

        return options.concat(entities);
    }, [lg, nodes]);
    //#endregion

    //#region Callbacks
    const allowDropSimpleCheck = React.useCallback<T.DiaElementProps["allowDrop"]>((nodeDragged, nodeReceiver) => {
        let draggedRight = (RIGHT_DICTIONARY[nodeDragged.path] || RIGHT_DICTIONARY.other).move;
        let receiverRight = (RIGHT_DICTIONARY[nodeReceiver.path] || RIGHT_DICTIONARY.other).move;

        if (rights.isRightAllowed(draggedRight) && rights.isRightAllowed(receiverRight)) {
            if (!TB.mongoIdValidator(nodeDragged.id) || !TB.mongoIdValidator(nodeReceiver.id)) return false;
            if (nodeDragged.id === nodeReceiver.id) return false;

            // Can't move to one of his children
            let draggedChildren = getChildren(nodeDragged.id).map(c => c.id);
            if (draggedChildren.includes(nodeReceiver.id)) return false;

            // Can't move to his own parent
            let parentDragged = getParents(nodeDragged.id, true).map(c => c.id);
            if (parentDragged.includes(nodeReceiver.id)) return false;

            // No LinkOptions
            let availableLinks = getAvailableLinkTypesFromIds(nodeReceiver.id, nodeDragged.id);
            if (availableLinks.length === 0) return false;

            return true;
        }
        else return false;
    }, [getChildren, getParents, getAvailableLinkTypesFromIds, rights]);

    const onDragDrop = React.useCallback((nodeDragged: string, nodeReceiver: string) => {
        let nodes = getNodes([nodeDragged, nodeReceiver]);
        let dragged = nodes.filter(n => n.id === nodeDragged)[0];
        let received = nodes.filter(n => n.id === nodeReceiver)[0];

        let links = getAvailableLinkTypesFromPaths(received?.path, dragged?.path);
        let linksOptions = links.map(l => ({ label: lg.getTextObj(l._id, "type", l.type), value: l._id }));

        let selectLinkPromise = new Promise<T.DiaReducedLinkTypes | null>((resolve, reject) => {
            if (links.length === 0) reject();
            else if (links.length === 1) resolve(links[0]);
            else M.askSelect({ title: FP.LINK_TYPE_FORM, isRequired: true, label: FP.LINK_TYPE_FORM, options: linksOptions }).then(link => {
                let type = links.filter(l => l._id === link);
                if (type.length > 0) resolve(type[0]);
                else resolve(null);
            })
        });

        selectLinkPromise.then(link => {
            if (link) S.moveNode({ type: link._id, parent: nodeReceiver, children: nodeDragged, removeOld: "sameType" }).then(replyLinks => {
                setResources(p => ({
                    ...p,
                    links: p.links
                        // Remove the previous link of the type selected
                        .filter(l => l.output !== nodeDragged || l.type !== link._id)
                        // Add the new links
                        .concat(replyLinks.data)
                }));
                // Disable the drag & drop
                sendActivities(true);
            }).catch(M.Alerts.updateError);
        }).catch(() => M.renderAlert({ type: "error", message: TC.DIA_WARN_NO_LINK_PAIR }));
    }, [getNodes, lg, getAvailableLinkTypesFromPaths, sendActivities, setResources]);

    const copyNodeId = React.useCallback((node: T.NodeDia) => {
        copyValue(node.id)
            .then(() => M.renderAlert({ type: "success", message: TC.GLOBAL_COPIED }))
            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR }));
    }, [copyValue]);

    const openGraphModal = React.useCallback((node: T.NodeDia) => PM.renderEnergyDashboard({ building: node.id, isFullScreen: true }), []);

    const openSortModal = React.useCallback((node: T.NodeDia) => {
        PM.renderSortModal<Resource["nodes"][number]>({
            popUp: { title: TC.DIA_SORT_TITLE, size: "md" },
            renderCard: item => <BS.Card body style={{ backgroundColor: item.color }} className="border my-1 text-center">
                {item.icon && <img className="me-2" width={30} height={30} src={"/api/file/icon/" + item.icon} alt="" />}
                <span className="flex-grow-1">{item.title}</span>
            </BS.Card>,
            listItem: getChildren(node.id, true).map(n => ({ ...n, relative: n.relativeItem })),
        }).then(sortItems => {
            if (sortItems) S.sortItem(sortItems).then(() => {
                let sortItemsIds = sortItems.map(i => i.id);
                let sortItemsObj = Object.fromEntries(sortItems.map(i => [i.id, i.relative]));

                setResources(p => ({
                    ...p,
                    nodes: p.nodes.map(n => sortItemsIds.includes(n.id) ? { ...n, relativeItem: sortItemsObj[n.id] } : n),
                }));
            }).catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
        });
    }, [getChildren, setResources]);

    const toggleHiddenNodes = React.useCallback((node: T.NodeDia, direct = true) => {
        setHiddenNodes(p => {
            // The current node has it's direct descendance hidden
            if (p.includes(node.id)) {
                // Reveal only the next level of node under the current one
                if (direct) {
                    // Find the nodes under this one, to hide them
                    let children = getChildren(node.id, true)
                        .map(c => c.id)
                        .filter(id => !p.includes(id) && getChildren(id, true).length > 0);
                    // Add the descendants as hidden and un-hide the current one
                    return p.filter(id => id !== node.id).concat(children);
                }
                // Mark the current node as not hidden
                else return p.filter(id => id !== node.id);
            }
            // The current node doesn't have it's descendance hidden
            else {
                // Find all it's descendants to remove them from the array of hidden nodes
                let children = getChildren(node.id, true).map(c => c.id);
                if (children.length === 0) return p;
                return p.filter(id => !children.includes(id)).concat(node.id);
            }
        });
    }, [getChildren]);

    const showFormModal = React.useCallback((node: T.NodeDia, readOnly = true) => {
        let updated_sub_equip: string[] = [];
        M.renderPopUpFormModal({ readOnly, submissionId: node.id, path: node.path, updateSubEquip: equip => updated_sub_equip.push(equip._id) }).then(sub => {
            if (sub) S.getDiaNodes(updated_sub_equip.concat(sub._id)).then(diaNode => {
                setResources(p => ({
                    ...p,
                    nodes: p.nodes.map(n => diaNode.data.find(dn => dn.id === n.id) || n)
                }));
                sendActivities(true);
            }).catch(M.Alerts.updateError);
        })
            // Restore the Y axis scrollbar
            .finally(() => {
                document.body.style.overflow = "auto";
                document.body.style.overflowY = "auto";
            });
    }, [setResources, sendActivities]);

    const removeModal = React.useCallback((node: T.NodeDia) => {
        M.askConfirm().then(confirmed => {
            if (confirmed) S.removeNodes(node.id).then(result => {
                if (result.data === "has_children") M.renderAlert({ type: "warning", message: TC.ERROR_DELETE_DESCENDANT_FIRST });
                else if (result.data === "has_datasets") M.renderAlert({ type: "warning", message: TC.ERROR_DELETE_DATASETS_FIRST });
                else {
                    setResources(p => ({
                        ...p,
                        nodes: p.nodes.filter(n => n.id !== node.id),
                        links: p.links.filter(l => l.input !== node.id && l.output !== node.id),
                    }));
                    sendActivities(true);
                }
            }).catch(M.Alerts.deleteError);
        });
    }, [setResources, sendActivities]);

    const createModal = React.useCallback((parent: T.NodeDia) => {
        M.askObjTypePicker({ parentForm: parent.form, entities: entitiesList, only_own: true }).then(results => {
            if (results) {
                if (results.path === FP.BUILDING_FORM) {
                    let created_link: T.Link = null;
                    let created_building: T.Submission<T.BuildingType> = null;

                    M.renderBlankModal({
                        isFullScreen: true,
                        title: TC.DIA_BUILDING_CREATION,
                        children: <BuildEditor
                            hideEquipTab
                            rootParent={parent.id}
                            onBuildingUpdate={(build, link) => {
                                created_link = link;
                                created_building = build;
                            }}
                        />,
                    }).then(() => {
                        if (created_building && created_link) S.getDiaNodes(created_building._id).then(diaNode => {
                            setResources(p => ({
                                ...p,
                                links: p.links.concat(created_link),
                                nodes: p.nodes.concat(diaNode.data),
                            }));
                            sendActivities(true);
                        }).catch(M.Alerts.updateError);
                    });
                }
                else {
                    type FormParams = Parameters<typeof M.renderFormModal>[0];
                    let parentId = parent.id;
                    let forcedSubmission: FormParams["forcedSubmission"], extraData: FormParams["extraData"];

                    if (TB.mongoIdValidator(results.entity)) parentId = results.entity;
                    if (results.path === FP.ENTITY_FORM) extraData = { site: getSites(parentId).map(n => n.id) };

                    M.renderFormModal({ path: results.path, extraData, forcedSubmission }).then(sub => {
                        if (sub) {
                            if (results.path === FP.ENTITY_FORM) M.askPrompt({ numberProps: { min: 1 }, defaultVal: 1, isNumberPrompt: true, title: TC.DIA_NB_COPIES }).then((nbCopy: number) => {
                                S.duplicateSubmissions(sub._id, nbCopy - 1).then(replyClones => {

                                    let params: Parameters<typeof S.attachNode>[0] = {
                                        parent: parentId,
                                        type: results.linkType,
                                        children: replyClones.data.map(c => c._id).concat(sub._id),
                                    };

                                    S.attachNode(params).then(replyLinks => {
                                        S.getDiaNodes(replyClones.data.map(n => n._id).concat(sub._id)).then(replyNodes => {
                                            setResources(p => ({
                                                ...p,
                                                links: p.links.concat(replyLinks.data),
                                                nodes: p.nodes.concat(replyNodes.data),
                                            }));
                                            sendActivities(true)
                                        }).catch(M.Alerts.updateError);
                                    }).catch(M.Alerts.updateError);
                                }).catch(M.Alerts.updateError);
                            });
                            else {
                                let params: Parameters<typeof S.attachNode>[0] = {
                                    parent: parentId,
                                    children: sub._id,
                                    type: results.linkType,
                                };

                                S.attachNode(params).then(replyLinks => {
                                    S.getDiaNodes(sub._id).then(replyNodes => {
                                        setResources(p => ({
                                            ...p,
                                            links: p.links.concat(replyLinks.data),
                                            nodes: p.nodes.concat(replyNodes.data),
                                        }));
                                        sendActivities(true);
                                    }).catch(M.Alerts.updateError);
                                }).catch(M.Alerts.updateError);
                            }
                        }
                    })
                        // Restore the Y axis scrollbar
                        .finally(() => {
                            document.body.style.overflow = "auto";
                            document.body.style.overflowY = "auto"
                        });
                }
            }
            else {
                document.body.style.overflow = "auto";
                document.body.style.overflowY = "auto"
            }
        });
    }, [sendActivities, setResources, getSites, entitiesList]);

    const unlinkModal = React.useCallback((node: T.NodeDia) => {
        // Find the links descending from the node selected
        let childrenLinks = links.filter(l => l.input === node.id)
            .filter(l => {
                // Find the linkage info of the children
                let child_up_links = links.filter(link => link.output === l.output);
                // Can't unlink a node that only has one link in his ascendance
                if (child_up_links.length === 0) return false;
                // Can't unlink if the child only has one appartenance link, and it's to the selected node and it's this link
                let own_links = child_up_links.filter(l => l.type === own_link_type);
                if (own_links.length === 0) return false;
                else if (own_links.length === 1 && own_links[0].input === node.id && own_links[0]._id === l._id) return false;
                // Other case are ok
                return true;
            });
        // Find the children's nodes 
        let nodes = getNodes(childrenLinks.map(l => l.output));
        // Gather more info on the links
        let linksInfos = childrenLinks.map(l => ({
            ...l,
            type_id: l.type,
            type: lg.getTextObj(l.type, "type", l.type),
            name: nodes.filter(n => n.id === l.output)[0]?.title || "N/A",
        }));

        // No descendant link for this node
        if (linksInfos.length === 0) M.renderAlert({ type: "warning", message: TC.DIA_NO_DESCENDANTS });
        else {
            let selectParams: Parameters<typeof M.askMultiSelect>[0] = {
                required: true,
                title: TC.DIA_DELETE_LINK,
                label: TC.DIA_CHOOSE_DELETE_LINK,
                options: linksInfos.map(l => ({ value: l._id, label: `${l.name} (${l.type})` })),
            };

            M.askMultiSelect(selectParams).then(links => {
                if (links && links.length > 0) S.removeLinks(links as string[]).then(() => {
                    setResources(p => ({ ...p, links: p.links.filter(l => !links.includes(l._id)) }));
                    sendActivities(true);
                }).catch(M.Alerts.deleteError);
            });
        }
    }, [links, own_link_type, lg, getNodes, setResources, sendActivities]);

    const linkModal = React.useCallback((node: T.NodeDia) => {
        M.askMultiLink({ input: node.id }).then(links => {
            if (Array.isArray(links)) {
                sendActivities(true);
                setResources(p => ({ ...p, links: p.links.concat(links) }));
            }
        });
    }, [sendActivities, setResources]);

    const [toggleIcon, toggleHide] = React.useMemo(() => {
        let all_has_sub_equip_ids = nodes.filter(n => n.has_sub_equip).map(n => n.id);
        let only_hidden_sub_equip_and_nothing_else = hiddenNodes.length === all_has_sub_equip_ids.length && hiddenNodes.every(h => all_has_sub_equip_ids.includes(h));

        if (!only_hidden_sub_equip_and_nothing_else) return ["eye", () => setHiddenNodes(all_has_sub_equip_ids)];
        else {
            let children = links.filter(l => roots.includes(l.input) && links.some(li => li.input === l.output)).map(l => l.output);
            return ["eye-slash", () => setHiddenNodes(children)];
        }
    }, [hiddenNodes, roots, links, nodes]);
    //#endregion

    //#region Rights
    const generateRight = React.useCallback((node: Resource["nodes"][number]): T.DiaRight[] => {
        let nRights: T.DiaRight[] = [];
        let linkStats = linkSearch(node.id);
        let rightObj = RIGHT_DICTIONARY[node.path] || RIGHT_DICTIONARY.other;

        let conditions = {
            read: true,
            create: true,
            copy_id: isAdmin,
            reveal: hiddenNodes.includes(node.id),
            graph: node.path === FP.BUILDING_FORM,
            edit: rights.isRightAllowed(rightObj.edit),
            link: rights.isRightAllowed(rightObj.move),
            sort: rights.isRightAllowed(rightObj.edit) && linkStats.children.length > 1,
            unlink: rights.isRightAllowed(rightObj.move) && linkStats.children.length > 0,
            hide: (linkStats.children.length > 0 || linkStats.triggeredSearch.length > 0) && !hiddenNodes.includes(node.id),
            delete: rights.isRightAllowed(rightObj.delete) && linkStats.children.length === 0 && linkStats.triggeredSearch.length === 0,
        };

        // Copy the element's id in the paste bin
        if (conditions.copy_id) nRights.push({ code: TC.TREE_COPY_ID, icon: "key" });
        // Display the element's form in read-only
        if (conditions.read) nRights.push({ code: RIGHTS.TREE_READ, icon: "search", shortcut: "Ctrl + I" });
        // If isLocked, other rights are out
        if (!isLocked) {
            // Edit this element
            if (conditions.edit) nRights.push({ code: RIGHTS.TREE_EDIT, icon: "edit", shortcut: "Ctrl + E" });
            // Sort the children of this element
            if (conditions.sort) nRights.push({ code: TC.TREE_SORT, icon: "sort-amount-up" });
            // Add a new child under this element
            if (conditions.create) nRights.push({ code: RIGHTS.TREE_CREATE, icon: "plus", shortcut: "Ctrl + D" });
            // Link a child to this element
            if (conditions.link) nRights.push({ code: RIGHTS.TREE_LINK, icon: "link", shortcut: "Ctrl + L" });
            // UnLink a children of this element
            if (conditions.unlink) nRights.push({ code: RIGHTS.TREE_UNLINK, icon: "chain-broken", shortcut: "Ctrl + M" });
            // Delete this element
            if (conditions.delete) nRights.push({ code: RIGHTS.TREE_DELETE, icon: "minus", shortcut: "Ctrl + S" });
        }
        // Show the dashboard of the building
        if (conditions.graph) nRights.push({ code: TC.TREE_GRAPH, icon: "pie-chart" });
        // Hide the descendance of this element
        if (conditions.hide) nRights.push({ code: TC.TREE_HIDE, icon: "eye-slash" });
        // Reveal the descendance of this element
        if (conditions.reveal) nRights.push({ code: TC.TREE_REVEAL, icon: "eye", shortcut: "Ctrl + Click" });

        return nRights;
    }, [rights, isAdmin, hiddenNodes, isLocked, linkSearch]);

    const onPickRight = React.useCallback((code: string, node: T.NodeDia, direct_hide_reveal = true) => {
        const getFunction = (): (node: T.NodeDia) => void => {
            switch (code) {
                case RIGHTS.TREE_LINK: return linkModal;
                case TC.TREE_SORT: return openSortModal;
                case TC.TREE_COPY_ID: return copyNodeId;
                case TC.TREE_GRAPH: return openGraphModal;
                case RIGHTS.TREE_UNLINK: return unlinkModal;
                case RIGHTS.TREE_DELETE: return removeModal;
                case RIGHTS.TREE_CREATE: return createModal;
                case RIGHTS.TREE_READ: return showFormModal;
                case TC.TREE_HIDE: return node => toggleHiddenNodes(node, direct_hide_reveal);
                case TC.TREE_REVEAL: return node => toggleHiddenNodes(node, direct_hide_reveal);
                case RIGHTS.TREE_EDIT: return node => showFormModal(node, false);
                default: return null;
            }
        }

        let fn = getFunction();
        // The user has been active, notify the socket
        sendActivities(false);
        if (typeof fn === "function") fn(node);
    }, [copyNodeId, toggleHiddenNodes, sendActivities, removeModal, linkModal, openGraphModal, unlinkModal, createModal, openSortModal, showFormModal]);
    //#endregion

    //#region Templates & Render
    const pickRight_ref = React.useRef(onPickRight);
    React.useImperativeHandle(pickRight_ref, () => onPickRight, [onPickRight]);
    const templateHasDrag = React.useMemo(() => _.find(ES.Templates, t => t.NAME === templateName)?.HAS_DRAG, [templateName]);
    const allowDrag = React.useMemo(() => templateHasDrag && enableDrag, [enableDrag, templateHasDrag]);

    const can_move_items = React.useMemo(() => {
        // Make a list of every rights that allows to move items
        let move_rights = Object.values(RIGHT_DICTIONARY).map(r => r.move);
        let rights_allowed = move_rights.some(r => rights.isRightAllowed(r));
        return rights_allowed;
    }, [rights]);

    const templates = React.useMemo(() => ES.Templates.map(t => ({
        name: t.NAME,
        realName: t.NAME,
        itemSize: t.ITEM_SIZE,
        minimizedItemLineType: BP.LineType.Dashed,
        onItemRender: (params: T.DiaRendererArg) => <t.Node
            {...params}
            onDrop={onDragDrop}
            focused={cursorItem}
            allowDrag={allowDrag}
            node={params.context}
            key={params.context.id}
            rights={params.context.rights}
            allowDrop={allowDropSimpleCheck}
            hidden={hiddenNodes.includes(params.context.id)}
            onPick={(code, node, is_direct) => pickRight_ref.current(code, node, is_direct)}
            toggle_menu={() => setLiveState(p => ({ ...p, cursorItem: params.context.id, menu_item: p.menu_item === params.context.id ? "" : params.context.id }))}
        />,
        onButtonsRender: ({ context, ...props }: T.DiaRendererArg) => t.Menu && <t.Menu
            {...props}
            node={context}
            key={context.id}
            onClose={() => setLiveState(p => ({ ...p, menu_item: "" }))}
            onPick={(code, node, is_direct) => pickRight_ref.current(code, node, is_direct)}
        />,
    })), [allowDropSimpleCheck, onDragDrop, allowDrag, hiddenNodes, cursorItem]);

    const buttonsPanelSize = React.useMemo(() => _.find(ES.Templates, t => t.NAME === templateName).BUTTON_PANEL_SIZE, [templateName]);
    //#endregion

    //#region Data Formatted & Visual Extra
    const linksColor = React.useMemo(() => Object.fromEntries(linkTypes.map(lt => [lt._id, lt.color])), [linkTypes]);
    const linksColorAnnot = React.useMemo(() => Object.fromEntries(linkTypes.map(lt => [lt._id, lt.color === "#00000000" ? '#000000' : lt.color])), [linkTypes]);
    const reversedLinks = React.useMemo(() => Object.fromEntries(linkTypes.map(lt => [lt._id, !!lt.parentSearch])), [linkTypes]);

    const hiddenLinksAndNodes = React.useMemo(() => {
        // The gammes that should not be showed
        let hide_gammes = configRef.current?.getGammes?.(config.gammes) || [];

        // Remove the nodes that have been set as hidden based on their types
        let not_hidden_nodes = nodes.filter(n => !config?.hiddenTypes?.includes(n.path));

        // Hide the emplacement node if specified
        if (typeof config?.hide_isRentable === "boolean") not_hidden_nodes = not_hidden_nodes
            .filter(n => n.path !== FP.EMPLACEMENT_FORM || n.data.isRentable === config?.hide_isRentable);

        // Hide the equipments based on gammes if necessary
        if (hide_gammes.length > 0) {
            let hidden_gammes = not_hidden_nodes
                .filter(n => {
                    if (n.path !== FP.EQUIPEMENT_FORM) return false;
                    else if (TB.mongoIdValidator(n.data.category)) return !hide_gammes.includes(n.data.category);
                    else return !hide_gammes.includes("none");
                });

            if (config?.hide_lineage) not_hidden_nodes = hidden_gammes;
            // Show the relatives of the nodes that match the search
            else {
                let ids = hidden_gammes.map(n => n.id);

                let all_parents = ids.map(id => getFamily(id, false, false));
                let all_children = ids.map(id => getFamily(id, true, false));

                let all = _.flatten(all_parents.concat(all_children)).map(n => n.id).concat(ids);
                not_hidden_nodes = not_hidden_nodes.filter(n => all.includes(n.id));
            }

        }

        // Remove the links based on type and the nodes that still appears
        let not_hidden_links = links.filter(l => !config?.hiddenLinks?.includes?.(l.type));

        // Apply the search
        if (TB.validString(config?.search)) {
            let str_match_nodes = not_hidden_nodes.filter(n => TB.areStringSimilar(config?.search, n.title, { inclusion: "reference" }));
            // Only show the nodes that match the search, hide every others
            if (config?.hide_lineage) not_hidden_nodes = str_match_nodes;
            // Show the relatives of the nodes that match the search
            else {
                let ids = str_match_nodes.map(n => n.id);

                let all_parents = ids.map(id => getFamily(id, false, false));
                let all_children = ids.map(id => getFamily(id, true, false));

                let all = _.flatten(all_parents.concat(all_children)).map(n => n.id).concat(ids);
                not_hidden_nodes = not_hidden_nodes.filter(n => all.includes(n.id));
            }
        }

        if (config.hide_lineage) not_hidden_links = [];

        // The ids of the nodes still in play
        let still_displayed = not_hidden_nodes.map(n => n.id);
        // Keep only the link if both end are still in play
        not_hidden_links = not_hidden_links.filter(l => still_displayed.includes(l.input) && still_displayed.includes(l.output));
        // Remove the nodes 'floating around' with no links
        if (config.hide_solo) {
            let not_hidden_nodes_no_float = not_hidden_nodes.filter(node => {
                let links = not_hidden_links.filter(l => l.input === node.id || l.output === node.id);
                return links.length > 0;
            });
            // There is no non-hidden nodes, so add the roots
            if (not_hidden_nodes_no_float.length === 0) not_hidden_nodes = not_hidden_nodes.filter(n => roots.includes(n.id));
            else not_hidden_nodes = not_hidden_nodes_no_float;
        }

        return { links: not_hidden_links, nodes: not_hidden_nodes };
    }, [links, nodes, roots, config?.hiddenLinks, config?.hide_solo, config?.hiddenTypes, config?.gammes, config?.hide_isRentable, config?.search, config?.hide_lineage, getFamily]);

    const appliedHiding = React.useMemo(() => {
        if (hiddenNodes.length === 0) return hiddenLinksAndNodes;

        let vLinks: T.Link[] = [], searchLinks: string[] = [];

        const recursive = (ids: string[]) => {
            // Keep Links that are children of the ids given, that output are not hidden & not searched already
            let childrenLinks = hiddenLinksAndNodes.links.filter(l => {
                if (reversedLinks[l.type]) return false;
                return ids.includes(l.input) && !hiddenNodes.includes(l.input) && !searchLinks.includes(l._id)
            });

            // Keep Links that are parents of the ids given, that are parentSearched, that input are not hidden & not searched already
            let reverseParentLinks = hiddenLinksAndNodes.links.filter(l => {
                if (!reversedLinks[l.type]) return false;
                return ids.includes(l.output) && !hiddenNodes.includes(l.output) && !searchLinks.includes(l._id);
            });

            let allLinks = childrenLinks.concat(reverseParentLinks);

            if (allLinks.length > 0) {
                vLinks.push(...allLinks);
                searchLinks.push(...allLinks.map(l => l._id));
                recursive(childrenLinks.map(l => l.output));
                recursive(reverseParentLinks.map(l => l.input));
            }
        }

        let temp_roots = [] as string[];

        // The og roots are not showed. The new temporary roots are the elements without parent
        if (!hiddenLinksAndNodes.nodes.some(n => roots.includes(n.id))) temp_roots = hiddenLinksAndNodes.nodes
            .filter(n => hiddenLinksAndNodes.links.filter(l => l.output === n.id).length === 0)
            .map(n => n.id);
        else temp_roots = roots;

        recursive(temp_roots);

        let nodesIds = vLinks.map(l => l.input).concat(vLinks.map(l => l.output));
        let vNodes = hiddenLinksAndNodes.nodes.filter(n => nodesIds.includes(n.id) || temp_roots.includes(n.id));

        return { links: vLinks, nodes: vNodes };
    }, [hiddenNodes, reversedLinks, hiddenLinksAndNodes, roots]);

    const links_to_take_into_account = React.useMemo<(Resource["fake_links"][number] | Resource["links"][number])[]>(() => {
        let to_render_links = appliedHiding.links;
        // Also add the 'fake links' that appears if some sub-equipments are hidden
        let fake_links_to_show = fake_links.filter(l => hiddenNodes.includes(l.if_hidden));
        // The full list of links to render
        let all_links_to_render = to_render_links.concat(fake_links_to_show);
        return all_links_to_render;
    }, [appliedHiding.links, fake_links, hiddenNodes]);

    const fLinks = React.useMemo(() => links_to_take_into_account.map(l => {
        let is_link_fake = "if_hidden" in l;
        if (config?.annotLinks?.includes(l.type)) return {
            lineWidth: 3,
            toItem: l.output,
            fromItem: l.input,
            lineType: BP.LineType.Dashed,
            color: linksColorAnnot[l.type] || "#000000",
            annotationType: BP.AnnotationType.Connector,
            connectorShapeType: BP.ConnectorShapeType.OneWay,
            connectorPlacementType: BP.ConnectorPlacementType.Offbeat,
        }
        return {
            id: l._id,
            lineWidth: 7,
            lineType: BP.LineType.Solid,
            opacity: is_link_fake ? 0.45 : 0.6,
            showArrows: is_link_fake, //false,
            items: [l.input, l.output],
            color: linksColor[l.type] || "#000000",
            annotationType: BP.AnnotationType.HighlightPath,
        }
    }), [linksColorAnnot, linksColor, config?.annotLinks, links_to_take_into_account]);

    const fNodes = React.useMemo(() => appliedHiding.nodes.map(n => ({
        ...n,
        templateName,
        rights: generateRight(n),
        image: TB.iconIdToUrl(n.icon),
        label: lg.getTextObj(n.id, "name", n.title),
        placementType: BP.AdviserPlacementType.Left,
        // Do not show the Highlighted items if there is a menu in display
        showCallout: menu_item ? BP.Enabled.False : BP.Enabled.Auto,
        // Show the button panel if there is a value in the menu_item variable
        hasButtons: menu_item === n.id ? BP.Enabled.True : BP.Enabled.False,
        parents: links_to_take_into_account
            .filter(l => l.output === n.id && !config?.annotLinks?.includes(l.type))
            .map(l => l.input),
    })), [appliedHiding.nodes, links_to_take_into_account, menu_item, templateName, config?.annotLinks, generateRight, lg]);
    //#endregion

    //#region ToolBelt
    const pdfFunction = React.useMemo(() => {
        let currentTemplateFn = _.find(ES.Templates, t => t.NAME === templateName)?.toPdf;
        if (!currentTemplateFn) return _.find(ES.Templates, t => typeof t.toPdf === "function")?.toPdf;
        return currentTemplateFn;
    }, [templateName]);

    const downloadPDF = React.useCallback(() => {
        try {
            let sample = FamDiagramPdfkit({
                ...config,
                items: fNodes,
                onItemRender: pdfFunction,
                templates: ES.Templates.map(t => ({ name: t.NAME, itemSize: t.ITEM_SIZE || { width: 100, height: 50 }, itemTemplate: "Use this stuff" })),
                annotations: fLinks.map(l => l.annotationType === BP.AnnotationType.HighlightPath ? { ...l, lineWidth: 5, opacity: 1 } : l),
            });

            let sampleSize = sample.getSize();

            let doc = new PdfKit({ size: [sampleSize.width + 100, sampleSize.height + 150] });
            let stream = doc.pipe(blobStream());
            doc.save();
            sample.draw(doc, 50, 100);
            doc.restore();
            doc.end();

            stream.on("finish", () => {
                let url = stream.toBlobURL("application/pdf");
                TB.downloadFile(url, TB.getString(realContextName, "Tree"), "pdf");
            })
        }
        catch (error) {
            console.log(error);
        }
    }, [config, fLinks, fNodes, realContextName, pdfFunction]);

    const zoom = React.useCallback((type: "in" | "out") => {
        setConfig(p => {
            let scale = p.scale + (type === "out" ? -0.1 : 0.1);
            if (scale < 0.1) scale = 0.1;
            else if (scale > 3) scale = 3;
            return { ...p, scale };
        });
    }, []);

    const toolBelt = React.useMemo(() => <C.Flex alignItems="center" justifyContent="between">
        <div>
            <C.IconTip className="pointer fs-130 mx-2" tipContent={TC.DIA_TIP_MENU} icon="bars" onClick={() => setLiveStateBool("showMenu", true)} />
            <C.IconTip className="pointer fs-130 mx-2" tipContent={TC.DIA_DOWNLOAD_PDF} icon="file-pdf" onClick={downloadPDF} />
            <C.IconTip className="pointer fs-130 mx-2" tipContent={TC.DIA_TOGGLE_HIDDEN} icon={toggleIcon} onClick={toggleHide} />
            <C.IconTip className="pointer fs-130 mx-2" tipContent={TC.DIA_EXPAND_NODES} icon={expanded_view.value ? "compress-alt" : "expand-alt"} onClick={expanded_view.toggle} />

            <C.IconTip className="pointer fs-130 mx-2" tipContent="Ctrl + Scroll ↑" icon="search-plus" onClick={() => zoom("in")} />
            <C.IconTip className="pointer fs-130 mx-2" tipContent="Ctrl + Scroll ↓" icon="search-minus" onClick={() => zoom("out")} />

            {!isLocked && can_move_items && <>
                {enableDrag ?
                    <C.IconTip
                        icon="hand-paper"
                        className="pointer fs-130 mx-2"
                        tipContent={TC.DIA_TOGGLE_DRAG}
                        onClick={() => setLiveStateBool("enableDrag")}
                    />
                    : <C.TipContainer tipContent={TC.DIA_TOGGLE_DRAG}>
                        <span className="fa-stack fa-2x mx-2 fs-75 pointer" style={{ marginTop: "-0.5rem" }} onClick={() => setLiveStateBool("enableDrag")}>
                            <i className="fa-solid fa-hand-paper fa-stack-1x"></i>
                            <i className="fa-solid fa-ban fa-stack-2x" style={{ color: "Tomato" }}></i>
                        </span>
                    </C.TipContainer>}
            </>}
        </div>
        <C.Flex>
            <C.Form.TextField
                uncontrolled
                noBottomMargin
                customClass="me-2"
                value={config.search}
                placeholder={TC.GLOBAL_SEARCH_PLACEHOLDER}
                onChange={search => setConfig(p => ({ ...p, search }))}
            />

            <BS.ButtonGroup>
                <C.Button
                    disabled={no_edit_unlock}
                    text={isLocked ? TC.DIA_UNLOCK : TC.DIA_UNLOCK}
                    onClick={isLocked ? askForEditMode : leaveEditMode}
                    icon={{ icon: (isLocked ? "lock" : "lock-open"), spin: edit_wait, spinIcon: "circle-notch" }}
                />
            </BS.ButtonGroup>
        </C.Flex>
    </C.Flex>, [isLocked, toggleIcon, edit_wait, config.search, no_edit_unlock, enableDrag, can_move_items, expanded_view, setLiveStateBool, zoom, downloadPDF, toggleHide, askForEditMode, leaveEditMode]);
    //#endregion

    //#region Event Listener
    const container = React.useRef<HTMLDivElement>(null);
    const backend = React.useMemo(() => HTML5Backend, []);

    H.useEventListener("wheel", event => {
        // Update the zoom on Ctrl + MouseWheel
        if (event.ctrlKey) {
            event.preventDefault();
            zoom(event.deltaY > 0 ? "out" : "in");
        }
    }, container);

    H.useEventListener("keydown", event => {
        // The Ctrl key needs to be active, and an item selected
        if (event.ctrlKey && event.key !== "Control" && cursorItem) {
            event.preventDefault();
            let item = fNodes.find(n => n.id === cursorItem);
            // No items found
            if (!item) M.renderAlert({ type: "warning", message: TC.GLOBAL_NO_SELECTION });
            else {
                // Find what actions are available for the current node
                let rights = generateRight(item);
                // Check which shortcut was activated
                let action = rights.find(r => {
                    // This action does not have a shortcut
                    if (!r.shortcut) return false;
                    // Retrieve the key that identifies the action's shortcut
                    let key = r.shortcut[r.shortcut.length - 1].toLowerCase();
                    // Check if the key is the one that was pressed
                    return key === event.key.toLowerCase();
                });
                // If an action was found, execute it
                if (action) onPickRight(action.code, item);
            }
        }
    }, container);
    //#endregion

    return <C.Flex ref={container} className="flex-grow-1" direction="column">
        <C.Spinner loading={status === "load"} error={status === "error"}>
            <>
                <ConfigForm
                    ref={configRef}
                    show={showMenu}
                    scale={config.scale}
                    gammes={config.gammes}
                    linkColors={linksColor}
                    onConfigChange={setConfig}
                    full_linkTypes={linkTypes}
                    subTypes={foundTypes.nodes}
                    linkTypes={foundTypes.links}
                    closeMenu={() => setLiveStateBool("showMenu", false)}
                />

                {toolBelt}
                <div className="flex-grow-1">
                    {/* @ts-ignore */}
                    <DndProvider backend={backend} key={1}>

                        {fNodes.length > MAX_SHOWN_NODES
                            ? <C.ErrorBanner type="info" textCode={TC.DIA_TOO_MANY_NODES} />
                            : <FamDiagram
                                centerOnCursor
                                onCursorChanged={(e, { context }) => setLiveStateString("cursorItem", context?.id)}
                                config={{
                                    templates,
                                    ...config,
                                    cursorItem,
                                    items: fNodes,
                                    buttonsPanelSize,
                                    annotations: fLinks,
                                    enablePanning: false,
                                    minimalVisibility: allowDrag || expanded_view.value ? BP.Visibility.Normal : BP.Visibility.Dot,
                                }} />}
                    </DndProvider>
                </div>
            </>
        </C.Spinner>
    </C.Flex>;
}

export default Tree;