import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import BP from "./PropsPrimitiveDiagrams.json";
import { TB, TC, T, FP, LT } from "../../Constants";

const STATIC_CONFIG = {
    linesWidth: 2,
    name: "Diagram",
    dotLevelShift: 20,
    lineLevelShift: 20,
    dotItemsInterval: 30,
    normalLevelShift: 35,
    labelFontSize: '13px',
    showExtraArrows: false,
    normalItemsInterval: 10,
    minimumVisibleLevels: 1,
    linesColor: BP.Colors.Black,
    hideGrandParentsConnectors: false,
    pageFitMode: BP.PageFitMode.FitToPage,
    hasSelectorCheckbox: BP.Enabled.False,
    arrowsDirection: BP.GroupByType.Children,
    selectionPathMode: BP.SelectionPathMode.FullStack,
};

const DYNAMIC_CONFIG = {
    scale: 0.9,
    search: "",
    hide_solo: true,
    hide_lineage: false,
    gammes: [] as string[],
    annotLinks: [] as string[],
    hiddenTypes: [] as string[],
    hiddenLinks: [] as string[],
    hide_isRentable: null as boolean | null,
    orientationType: BP.OrientationType.None,
    labelPlacement: BP.PlacementType.BottomLeft,
    labelOrientation: BP.TextOrientationType.RotateLeft,
};

export const mergeConfig = (config = DYNAMIC_CONFIG): ConfigType => ({ ...STATIC_CONFIG, ...config });

//#region Types
export type ConfigFormProps = {
    /** The scale's value, can be changed via Ctrl + Wheel */
    scale: number;
    /** Hide or show the config menu */
    show: boolean;
    /** The selected types of submissions to show */
    subTypes: string[];
    /** The current display of link types */
    linkTypes: string[];
    /** The full list of link types */
    full_linkTypes: T.DiaReducedLinkTypes[];
    /** The colors of the link types, as an object [_id, color] */
    linkColors: Record<string, string>;
    /** The currently hidden gammes */
    gammes: string[];
    /** Callback when the menu closes */
    closeMenu: () => void;
    /** Callback when the config changes */
    onConfigChange: (config: ConfigType) => void;
}

export type ConfigFormRef = {
    /** Get all the gammes to not show based on the gammes selected */
    getGammes: (ids: T.AllowArray<string>) => string[];
};

type Gammes = ReturnType<T.API.Utils.Gammes.TreeFiltersGammes>;
export type ConfigType = typeof STATIC_CONFIG & typeof DYNAMIC_CONFIG;
type RadioValue = C.Form.RadioButtonsProps["values"][number] & { value: "hide" | "annot" | "none" };
//#endregion

const TEXT_CODES = [
    TC.TREE_CONFIG_HIDE_SUBS, TC.TREE_CONFIG_HIDE_LINKS, TC.DIA_CONFIG_TITLE, TC.DIA_CONFIG_SCALE,
    TC.DIA_CONFIG_VISUAL, TC.GLOBAL_CONFIRM, TC.TAB_GAMME_EQUIP, TC.GLOBAL_PROPERTIES_LABEL,
];

const SUB_ORDER = {
    [FP.CLIENT_FORM]: 0,
    [FP.SITE_FORM]: 1,
    [FP.BUILDING_FORM]: 2,
    [FP.EMPLACEMENT_FORM]: 3,
    [FP.EQUIPEMENT_FORM]: 4,
}
const OTHER_SUB_ORDER = Object.keys(SUB_ORDER).length;

const ConfigForm = React.forwardRef<ConfigFormRef, ConfigFormProps>(({ onConfigChange, show, closeMenu, subTypes, linkTypes, ...props }, ref) => {
    const [forms] = H.useFormIds();
    const lg = H.useLanguage(TEXT_CODES);
    const textfield_ref = React.useRef<C.Form.TextFieldRef>(null);
    const [gammes, setGammes, status] = H.useAsyncState<Gammes>([]);
    const [preset_config, set_preset_config] = React.useState(null);

    //#region Ref
    React.useImperativeHandle(ref, () => ({
        getGammes: ids => {
            ids = TB.arrayWrapper(ids);
            let items = [] as string[];
            let gammes_db = gammes.filter(g => ids.includes(g._id));
            let non_mains = gammes_db.filter(g => !g.isMain);
            let non_mains_ids = non_mains.map(g => g._id);
            let shown_non_mains = gammes.filter(g => !g.isMain && !non_mains_ids.includes(g._id));
            if (ids.includes("none")) items.push("none");
            for (let g of gammes_db) items.push(g._id, ...g.includes);
            for (let shown of shown_non_mains) items = items.filter(i => !shown.includes.includes(i) && i !== shown._id);
            return items;
        },
    }), [gammes]);
    //#endregion

    //#region Load filters
    React.useEffect(() => {
        let isSubscribed = true;
        S.treeFiltersGammes()
            .then(({ data }) => isSubscribed && setGammes(data, "done"))
            .catch(() => isSubscribed && setGammes([], "error"));
        return () => { isSubscribed = false };
    }, [setGammes]);

    React.useEffect(() => {
        let ids = gammes.map(g => g._id);
        lg.fetchObjectTranslations(ids);
    }, [lg, gammes]);
    //#endregion

    //#region Update Config
    const applyConfig = React.useCallback((settings: typeof DYNAMIC_CONFIG, close = false) => {
        // Update the tree filters
        onConfigChange?.(mergeConfig(settings));
        // Close the menu
        if (close) closeMenu?.();
    }, [onConfigChange, closeMenu]);

    const [{ latestFilters, dropdown }, { updateFilters }] = H.useFavorite({ origin: "new_tree", variant: "primary", applyFav: applyConfig, initialState: DYNAMIC_CONFIG });

    const changeConfig = React.useCallback((val: Partial<typeof DYNAMIC_CONFIG>) => updateFilters(p => ({ ...p, ...val })), [updateFilters]);

    const updateHiddenTypes = React.useCallback((type: string) => {
        updateFilters(p => ({ ...p, hiddenTypes: p.hiddenTypes.includes(type) ? p.hiddenTypes.filter(t => t !== type) : p.hiddenTypes.concat(type) }));
    }, [updateFilters]);

    const selectAllTypes = React.useCallback(() => {
        updateFilters(p => ({ ...p, hiddenTypes: p.hiddenTypes.length === 0 ? subTypes : [] }));
    }, [subTypes, updateFilters]);

    const sorted_sub_type = React.useMemo(() => (subTypes || []).sort((path_a, path_b) => {
        let score_a = SUB_ORDER[path_a] || OTHER_SUB_ORDER,
            score_b = SUB_ORDER[path_b] || OTHER_SUB_ORDER;
        return score_a - score_b;
    }), [subTypes]);
    //#endregion

    //#region Language
    React.useEffect(() => {
        if (linkTypes.length > 0) lg.fetchObjectTranslations(linkTypes);
    }, [linkTypes, lg]);
    //#endregion

    //#region Visual
    const scaler = React.useMemo(() => <BS.Form.Group>
        <BS.Form.Label>{lg.getStaticText(TC.DIA_CONFIG_SCALE)}</BS.Form.Label>
        <C.Flex alignItems="center">
            <span className="me-2">{latestFilters.scale.toFixed(1)}x</span>
            <BS.Form.Range value={latestFilters.scale} step={0.1} onChange={e => changeConfig({ scale: parseFloat(e.target.value) || 0.9 })} min={0.1} max={3} />
        </C.Flex>
    </BS.Form.Group>, [lg, latestFilters.scale, changeConfig]);

    React.useEffect(() => {
        changeConfig({ scale: props.scale })
    }, [changeConfig, props.scale]);

    const onChangeOrientation = React.useCallback((orientation: string) => {
        let orientationType = parseInt(orientation);
        if ([BP.OrientationType.Left, BP.OrientationType.Right].includes(orientationType)) changeConfig({
            orientationType,
            labelPlacement: BP.PlacementType.TopRight,
            labelOrientation: BP.TextOrientationType.Horizontal
        });
        else changeConfig({
            orientationType,
            labelPlacement: BP.PlacementType.BottomLeft,
            labelOrientation: BP.TextOrientationType.RotateLeft,
        });
    }, [changeConfig]);

    const orientationOptions = React.useMemo(() => [
        { label: TC.DIA_CONFIG_ORIENTATION_LEFT, value: BP.OrientationType.Left.toString() },
        { label: TC.DIA_CONFIG_ORIENTATION_RIGHT, value: BP.OrientationType.Right.toString() },
        { label: TC.DIA_CONFIG_ORIENTATION_TOP, value: BP.OrientationType.Top.toString() },
        { label: TC.DIA_CONFIG_ORIENTATION_BOTTOM, value: BP.OrientationType.Bottom.toString() },
        { label: TC.DIA_CONFIG_ORIENTATION_DEFAULT, value: BP.OrientationType.None.toString() },
    ], []);

    const orientationSelect = React.useMemo(() => <C.Form.Select
        options={orientationOptions}
        onChange={onChangeOrientation}
        label={TC.DIA_CONFIG_ORIENTATION}
        value={latestFilters.orientationType.toString()}
    />, [onChangeOrientation, latestFilters.orientationType, orientationOptions]);

    const visualGroup = React.useMemo(() => <BS.Accordion.Item eventKey="visual">
        <BS.Accordion.Header>{lg.getStaticElem(TC.DIA_CONFIG_VISUAL)}</BS.Accordion.Header>
        <BS.Accordion.Body>
            {scaler}
            {orientationSelect}
        </BS.Accordion.Body>
    </BS.Accordion.Item>, [scaler, orientationSelect, lg])
    //#endregion

    //#region Hide Elements
    const link_cb = React.useMemo(() => ({
        value: (type: string): RadioValue["value"] => {
            if (latestFilters.annotLinks.includes(type)) return "annot";
            if (latestFilters.hiddenLinks.includes(type)) return "hide";
            return "none";
        },
        change: (type: string, newValue: RadioValue["value"]) => {
            updateFilters(p => {
                let annotLinks = p.annotLinks.filter(l => l !== type);
                let hiddenLinks = p.hiddenLinks.filter(l => l !== type);

                if (newValue === "annot") annotLinks = annotLinks.concat(type);
                else if (newValue === "hide") hiddenLinks = hiddenLinks.concat(type);
                return { ...p, hiddenLinks, annotLinks };
            });
        },
        change_all: (value: RadioValue["value"]) => {
            updateFilters(p => {
                let annotLinks = [], hiddenLinks = [];

                if (value === "annot") annotLinks = linkTypes;
                else if (value === "hide") hiddenLinks = linkTypes;
                return { ...p, hiddenLinks, annotLinks };
            });
        }
    }), [latestFilters.annotLinks, latestFilters.hiddenLinks, linkTypes, updateFilters]);

    const gammes_cb = React.useMemo(() => ({
        change_all: (active: boolean) => updateFilters(p => {
            if (active) return { ...p, gammes: [] };
            else return { ...p, gammes: gammes.map(g => g._id).concat("none") };
        }),
        change: (id: string) => updateFilters(p => {
            if (!Array.isArray(p.gammes)) return { ...p, gammes: [id] };
            if (p.gammes.includes(id)) return { ...p, gammes: p.gammes.filter(_id => _id !== id) };
            else return { ...p, gammes: p.gammes.concat(id) };
        }),
    }), [gammes, updateFilters]);

    const isAllSelected = React.useMemo(() => {
        let state = linkTypes.map(link_cb.value);
        return {
            nodes: latestFilters.hiddenTypes.length === 0,
            links: TB.allEqualArray(state) ? state[0] : "",
        }
    }, [latestFilters.hiddenTypes, link_cb.value, linkTypes]);

    const selectBoxesValues = React.useMemo<RadioValue[]>(() => [
        { label: TC.DIA_CONFIG_BASE_LINK, value: "none" },
        { label: TC.DIA_CONFIG_ANNOT_LINK, value: "annot" },
        { label: TC.DIA_CONFIG_HIDE_LINK, value: "hide" },
    ], []);
    //#endregion

    //#region Pre-defined configs
    const defined_configs = React.useMemo<T.Option[]>(() => {
        const TO_KEEP = [LT.LINK_TYPE_ELEC, LT.LINK_TYPE_GAS, LT.LINK_TYPE_WATER, LT.LINK_TYPE_TH_FUEL, LT.LINK_TYPE_TH_HOT, LT.LINK_TYPE_TH_COLD];
        return props.full_linkTypes.filter(lt => linkTypes.includes(lt._id) && TO_KEEP.includes(lt.type))
            .map(lt => ({ value: lt._id, label: lg.getTextObj(lt._id, "type", "") }));
    }, [linkTypes, lg, props.full_linkTypes]);

    const set_defined_config = React.useCallback((link_id?: string) => {
        let new_config = null;
        if (TB.mongoIdValidator(link_id)) updateFilters(p => {
            new_config = {
                ...p,
                annotLinks: [],
                hide_solo: true,
                hiddenLinks: linkTypes.filter(t => t !== link_id),
            };
            return new_config;
        });
        else updateFilters(p => {
            new_config = { ...p, annotLinks: [], hiddenLinks: [], hide_solo: false };
            return new_config;
        });

        set_preset_config(link_id || null);
        applyConfig(new_config);
    }, [linkTypes, updateFilters, applyConfig]);
    //#endregion

    //#region
    H.useEventListener("keypress", (event) => {
        let target = (event.target as HTMLInputElement | HTMLTextAreaElement);
        // Apply the new filters to the tree immediately
        if (event.key === "Enter" && target?.id === "name_search_input") applyConfig({ ...latestFilters, search: target.value }, true);
    }, textfield_ref.current?.single?.current?.input);
    //#endregion

    return <BS.Offcanvas show={show} onHide={closeMenu}>
        <BS.Offcanvas.Header closeButton>
            <BS.Offcanvas.Title>
                {lg.getStaticElem(TC.DIA_CONFIG_TITLE)}
            </BS.Offcanvas.Title>
        </BS.Offcanvas.Header>
        <BS.Offcanvas.Body>

            <C.Flex className="mb-2" alignItems="center" justifyContent="end">
                {dropdown}
            </C.Flex>

            <C.Form.TextField
                ref={textfield_ref}
                id="name_search_input"
                label={TC.DIA_NAME_SEARCH}
                value={latestFilters.search}
                onChange={search => changeConfig({ search: search || "" })}
            />

            {/* Pre-defined settings */}
            <C.Form.Select
                value={preset_config}
                options={defined_configs}
                label={TC.DIA_COUNTER_PLAN}
                onChange={set_defined_config}
            />

            <BS.Accordion>
                {/* Elem Type Panel */}
                <BS.Accordion.Item eventKey="hide_sub">
                    <BS.Accordion.Header>
                        {lg.getStaticText(TC.TREE_CONFIG_HIDE_SUBS)}
                    </BS.Accordion.Header>
                    <BS.Accordion.Body>

                        <C.Form.CheckBox
                            key="all"
                            labelPosition="left"
                            label={TC.GLOBAL_ALL}
                            onChange={selectAllTypes}
                            value={isAllSelected.nodes}
                        />

                        {sorted_sub_type.map(path => <React.Fragment key={path}>
                            <C.Form.CheckBox
                                label={path}
                                labelPosition="left"
                                onChange={() => updateHiddenTypes(path)}
                                value={!latestFilters.hiddenTypes.includes(path)}
                            />

                            {/* Gammes Sub-Panel */}
                            {path === FP.EQUIPEMENT_FORM && <div className="ms-2 mb-2">
                                <BS.Accordion>
                                    <BS.Accordion.Item eventKey="gammes">
                                        <BS.Accordion.Header>
                                            {lg.getStaticText(TC.TAB_GAMME_EQUIP)}
                                        </BS.Accordion.Header>
                                        <BS.Accordion.Body>
                                            <C.Spinner status={status}>
                                                <>
                                                    {/* All */}
                                                    <C.Form.CheckBox
                                                        labelFullWidth
                                                        labelPosition="left"
                                                        label={TC.GLOBAL_ALL}
                                                        onChange={gammes_cb.change_all}
                                                        value={latestFilters.gammes.length === 0}
                                                    />

                                                    {/* No Gammes */}
                                                    <C.Form.CheckBox
                                                        labelFullWidth
                                                        labelPosition="left"
                                                        label={TC.GLOBAL_GAMME_UNKNOWN}
                                                        onChange={() => gammes_cb.change("none")}
                                                        value={!latestFilters.gammes?.includes?.("none")}
                                                    />

                                                    {/* Normal */}
                                                    {gammes.map(g => <C.Form.CheckBox
                                                        key={g._id}
                                                        labelFullWidth
                                                        labelPosition="left"
                                                        onChange={() => gammes_cb.change(g._id)}
                                                        value={!latestFilters.gammes?.includes?.(g._id)}
                                                        label={{ _id: g._id, prop: "name", text: g.name }}
                                                    />)}
                                                </>
                                            </C.Spinner>
                                        </BS.Accordion.Body>
                                    </BS.Accordion.Item>
                                </BS.Accordion>
                            </div>}

                            {/* Emplacement sub-panel */}
                            {path === FP.EMPLACEMENT_FORM && <div className="ms-2 mb-2">
                                <BS.Accordion>
                                    <BS.Accordion.Item eventKey="prop">
                                        <BS.Accordion.Header>
                                            {lg.getStaticText(TC.GLOBAL_PROPERTIES_LABEL)}
                                        </BS.Accordion.Header>
                                        <BS.Accordion.Body>
                                            <C.Form.RadioBool
                                                name="rentable"
                                                variant="primary"
                                                value={latestFilters.hide_isRentable}
                                                label={{ _id: forms[FP.EMPLACEMENT_FORM], prop: "isRentable" }}
                                                onChange={hide_isRentable => updateFilters(p => ({ ...p, hide_isRentable }))}
                                            />
                                        </BS.Accordion.Body>
                                    </BS.Accordion.Item>
                                </BS.Accordion>
                            </div>}
                        </React.Fragment>)}
                    </BS.Accordion.Body>
                </BS.Accordion.Item>

                {/* Link Type Panel */}
                <BS.Accordion.Item eventKey="hide_link">
                    <BS.Accordion.Header>
                        {lg.getStaticText(TC.TREE_CONFIG_HIDE_LINKS)}
                    </BS.Accordion.Header>
                    <BS.Accordion.Body>
                        <BS.Row className="mb-3">
                            <BS.Col>
                                <C.Form.RadioButtons
                                    key="all"
                                    name="all"
                                    label={TC.GLOBAL_ALL}
                                    values={selectBoxesValues}
                                    value={isAllSelected.links}
                                    onChange={link_cb.change_all}
                                />
                            </BS.Col>
                        </BS.Row>

                        <BS.Row>
                            {linkTypes.map(type => <BS.Col md={6} key={type}>
                                <C.Form.RadioButtons
                                    name={type}
                                    values={selectBoxesValues}
                                    value={link_cb.value(type)}
                                    onChange={value => link_cb.change(type, value)}
                                    label={<C.Flex alignItems="center" className="mb-2">
                                        <C.ColorBox shape="circle" color={props.linkColors[type] || "#00000000"} />
                                        <span className="fs-85 ms-2 flex-grow-1">
                                            {lg.getTextObj(type, "type", "")}
                                        </span>
                                    </C.Flex>}
                                />
                            </BS.Col>)}
                        </BS.Row>
                    </BS.Accordion.Body>
                </BS.Accordion.Item>

                {visualGroup}
            </BS.Accordion>

            <div className="my-3">
                <C.Form.RadioBool
                    name="lineage"
                    label={TC.DIA_HIDE_LINEAGE}
                    value={!!latestFilters.hide_lineage}
                    onChange={v => updateFilters(p => ({ ...p, hide_lineage: typeof v === "boolean" ? v : p.hide_lineage }))}
                />

                <C.Form.RadioBool
                    name="hide_solo"
                    label={TC.DIA_HIDE_SOLO}
                    tooltip={TC.DIA_HIDE_SOLO_TIP}
                    value={!!latestFilters.hide_solo}
                    onChange={v => updateFilters(p => ({ ...p, hide_solo: typeof v === "boolean" ? v : p.hide_solo }))}
                />
            </div>

            <C.Flex className="my-3" justifyContent="end">
                <C.Button onClick={() => applyConfig(latestFilters, true)} text={TC.GLOBAL_CONFIRM} />
            </C.Flex>
        </BS.Offcanvas.Body>
    </BS.Offcanvas>;
});

export default ConfigForm;