import _ from "lodash";
import React from "react";
import moment from "moment";
import { Table, TableProps } from "../Grid";
import { TABS, TC } from "../../../Constants";
import { CellsTypes as CT } from "../AgGridDefs";
import { ActionPlan } from "../../Dashboard/ActionPlan/ActionPlanTypes";

type ActionPlanTableProps = {
    plan: ActionPlan;
    yearLaps: number;
}

const ActionPlanTable: React.FC<ActionPlanTableProps> = props => {

    const format = React.useMemo(() => ({
        action: (id: string) => props.plan.actions.filter(a => a._id === id)[0]?.data?.name || "",
        equip: (id: string) => props.plan.equipments.filter(e => e._id === id)[0]?.data?.name || "",
        site: (id: string) => (props.plan.locations.filter(l => l.id === id)[0]?.site || []).map(s => s.data.name).join(),
        building: (id: string) => (props.plan.locations.filter(l => l.id === id)[0]?.building || []).map(s => s.data.name).join(),
        position: (year: number, index: number) => {
            //Start a one, because 0 index array
            let length = 1;
            for (let i = 0; i < year; i++) {
                if (Array.isArray(props.plan.actionPlan[i])) length += props.plan.actionPlan[i].length;
            }
            return length + index;
        }
    }), [props.plan]);

    const columns = React.useMemo<TableProps<any>["columns"]>(() => [
        { field: "index", headerName: "#", colId: "_id", type: CT.TYPE_NUMBER, params: { maxDigit: 0 } },
        { field: "year", headerName: TC.GP_GROUP_YEAR, type: CT.TYPE_NUMBER, params: { maxDigit: 0 } },
        { field: "site", headerName: TC.GLOBAL_LABEL_SITE },
        { field: "building", headerName: TC.GLOBAL_LABEL_BUILD },
        { field: "equipment", headerName: TC.GLOBAL_LABEL_EQUIPMENT },
        { field: "action", headerName: TC.G_ACTION },
        { field: "yearsLeft", headerName: TC.APS_BAIL_YEAR_LEFT, type: CT.TYPE_NUMBER, params: { maxDigit: 0 } },
        { field: "lifeLeft", headerName: TC.APS_EQUIP_YEAR_LEFT, type: CT.TYPE_NUMBER, params: { maxDigit: 0 } },
        { field: "cost", headerName: TC.GP_BUILD_TAB_COST, enableValue: true, type: CT.TYPE_NUMBER, params: { unit: '€', maxDigit: 2, formatted: "money" } },
        { field: "roi", headerName: TC.APS_ROI, type: CT.TYPE_NUMBER, params: { unit: '%', maxDigit: 2, formatted: true } },
        { field: "energySavings", headerName: TC.APS_ENERGY_GAINED, type: CT.TYPE_NUMBER, params: { unit: 'MWh', maxDigit: 3, formatted: true } },
        { field: "energyProduction", headerName: TC.APS_ENERGY_PRODUCED, type: CT.TYPE_NUMBER, params: { unit: 'MWh', maxDigit: 3, formatted: true } },
        { field: "coImpact", headerName: TC.APS_IMPACT, type: CT.TYPE_NUMBER, params: { unit: 'KgCO₂/€', maxDigit: 3, formatted: true } },
    ], []);

    const rows = React.useMemo(() => _.flatten(
        (props.plan?.actionPlan || []).map((yearlyPlan, y) => {
            return yearlyPlan.map((a, i) => ({
                ...a,
                index: format.position(y, i),
                site: format.site(a.equipment),
                action: format.action(a.action),
                equipment: format.equip(a.equipment),
                building: format.building(a.equipment),
                year: moment().year() + y + props.yearLaps,
                energySavings: a.energySavings / 1000 || null,
                energyProduction: a.energyProduction / 1000 || null,
            }));
        })
    ), [format, props.yearLaps, props.plan.actionPlan]);

    return <Table<any>
        sideBar
        rows={rows}
        enableCharts
        noBottomPadding
        columns={columns}
        autoFit={["index", "year"]}
        origin={TABS.ACTION_SIMULATOR}
        adaptableId={TABS.ACTION_SIMULATOR}
        columns_base={["filterable", "grouped", "sortable"]}
    />;
}

export default ActionPlanTable;