import React from 'react';
import * as H from "../../hooks";
import * as C from "../../Common";
import * as CT from "../../Context";
import * as S from "../../services";
import * as R from "../../reducers";
import * as EL from "../ErrorsLayout";
import * as CP from "../../Components";
import * as DOM from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TB, T } from "../../Constants";
// import * as M from "../../Components/Modal";

const NAV_BAR_CONST = { top: "top", both: "combo", vertical: "vertical" };

type MainLayoutProps = {
    /** Should the content take the whole width of the page ? */
    fluid?: boolean;
};

const MainLayout: React.FC<MainLayoutProps> = props => {
    H.useInfosBubbles();
    const lg = H.useLanguage();
    const socket = H.useSocket();
    const dispatch = useDispatch();
    const [context] = H.useRoots();
    H.useAuth({ forcedLog: false });
    const { pathname } = DOM.useLocation();
    const [searchParams] = DOM.useSearchParams({});
    const [routes, setRoutes, routesStatus, setRoutesStatus] = H.useAsyncState<T.RoutesType[]>([]);
    const { config: { isFluid, navbarPosition, isDark }, setConfig } = React.useContext(CT.AppContext);

    // Fetch the Browser Tab Title
    React.useEffect(() => {
        let isSubscribed = true;
        let title = "AISET";
        S.getAppTitle()
            .then(r => title = r.data)
            .finally(() => {
                if (isSubscribed) {
                    let titleTag = document.querySelector("head title");
                    if (!titleTag) {
                        titleTag = document.createElement("title");
                        document.getElementsByTagName("title")[0].appendChild(titleTag);
                    }
                    titleTag.textContent = title;
                }
            });
        return () => { isSubscribed = false };
    }, []);

    // Store the current version of the backend
    React.useEffect(() => {
        S.getVersion()
            .then(results => sessionStorage.setItem("app_release", results.data))
            .catch(console.error);
    }, []);

    // Disconnect the socket when the component unmounts
    React.useEffect(() => {
        return () => {
            socket.disconnect();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps -- Can't put just the socket in dependency array, cause it causes infinite re-renders */
    }, [socket.disconnect]);

    //#region isDark url param
    React.useEffect(() => {
        let darkModeParam = searchParams.get("isDark");
        if (TB.validString(darkModeParam) && ["true", "false"].includes(darkModeParam)) {
            let forceDarkMode = searchParams.get("isDark") === "true";
            if (forceDarkMode !== isDark) setConfig('isDark', forceDarkMode)
        }
    }, [setConfig, searchParams, isDark]);
    //#endregion

    //#region User
    React.useEffect(() => {
        if (pathname === "/logout") {
            localStorage.clear();
            dispatch(R.logout());
        }
    }, [pathname, dispatch]);
    //#endregion

    //#region Tabs & Flags
    React.useEffect(() => setRoutesStatus("load"), [setRoutesStatus, context]);

    React.useEffect(() => {
        let isSubscribed = true;
        // Load the user's routes
        S.getNavMenu({ context })
            .then(({ data }) => isSubscribed && setRoutes(data, "done"))
            .catch(() => isSubscribed && setRoutes([], "error"));
        return () => { isSubscribed = false };
    }, [context, setRoutes]);
    //#endregion

    //#region Misc.
    React.useEffect(() => window.scrollTo(0, 0), [pathname]);
    //#endregion

    //#region Container
    const containerClass = React.useMemo(() => props.fluid || isFluid ? "container-fluid" : "container", [isFluid, props.fluid]);
    //#endregion

    //#region Routes Array
    const routeSorting = React.useCallback((a: T.RoutesType, b: T.RoutesType) => {
        let getLabel = (obj: T.RoutesType) => lg.getStaticText(obj.labelProp);
        let getOrder = (obj: T.RoutesType) => typeof obj.order === "number" ? obj.order : 10000;
        let hasChildren = (obj: T.RoutesType) => Array.isArray(obj.children) && obj.children.length > 0;

        let [labelA, labelB] = [a, b].map(getLabel);
        let [orderA, orderB] = [a, b].map(getOrder);
        let [childA, childB] = [a, b].map(hasChildren);

        if (childA && childB) return orderA > orderB ? 1 : -1;
        if (childA || childB) return childA ? 1 : -1;
        if (orderA === orderB) {
            if (labelA > labelB) return 1;
            if (labelA < labelB) return -1;
            return 0;
        }
        return orderA > orderB ? 1 : -1;
    }, [lg]);

    const routeFormatRecursive = React.useCallback((route: T.RoutesType) => {
        let prop = TB.validObject(route) && TB.validString(route.labelProp) ? route.labelProp : null;
        let name = TB.validString(prop) ? lg.getStaticText(prop) : "";
        if (!TB.validString(name)) name = "undefined";
        let icon = TB.validString(route.icon) ? route.icon : null;

        if (icon === null) {
            let str = TB.normalizedString(name, lg.language, true);
            if (TB.validString(str)) icon = str.toLowerCase()[0];
            else icon = "";
        }

        let newChildren;
        if (Array.isArray(route.children)) newChildren = route.children.filter(TB.validObject).map(routeFormatRecursive).sort(routeSorting);
        return { ...route, icon, name, children: newChildren };
    }, [lg, routeSorting]);

    const routesArray = React.useMemo(() => {
        if (!Array.isArray(routes)) return;
        return { label: "APP", children: routes.filter(TB.validObject).map(routeFormatRecursive).sort(routeSorting) };
    }, [routes, routeFormatRecursive, routeSorting]);

    const allRoutes = React.useMemo(() => [routesArray].filter(TB.validObject), [routesArray]);
    //#endregion

    //#region Nav Bars
    const topNavBar = React.useMemo(() => <CP.NavBar.TOP.NavbarTop routes={allRoutes} loading={routesStatus === "load"} />, [allRoutes, routesStatus]);
    const verticalNavBar = React.useMemo(() => <CP.NavBar.VERTICAL.NavbarVertical routes={allRoutes} logoSrc="/api/file/main" loading={routesStatus === "load"} />, [allRoutes, routesStatus]);
    const sideNavBarDisplay = React.useMemo(() => [NAV_BAR_CONST.both, NAV_BAR_CONST.vertical].includes(navbarPosition) ? verticalNavBar : undefined, [verticalNavBar, navbarPosition]);
    //#endregion

    return <EL.ErrorBoundary>
        <C.AlertsContainer />
        <div className={containerClass}>
            {sideNavBarDisplay}
            <C.Settings.Panel />

            <div className='content pb-0 d-flex flex-column'>
                {topNavBar}
                <div className="d-flex flex-grow-1 h-100">
                    <DOM.Outlet />
                </div>
            </div>
        </div>
    </EL.ErrorBoundary>;
};

export default MainLayout;
