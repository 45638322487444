import { AxiosResponse } from "axios";

export type AdminUpdates =
    "entityUnit"
    | "empAffect"
    | "freqUpdate"
    | "regDocFiles"
    | "entityToEquip"
    | "list_dead_links"
    | "update_note_tags"
    | "aggregateDataset"
    | "gammes_regulated"
    | "update_enseignes"
    | "convert_parkings"
    | "find_gas_datasets"
    | "find_dataset_mess"
    | "buildConstructDate"
    | "table_state_update"
    | "equip_gamme_colors"
    | "redistribute_access"
    | "reg_doc_empty_files"
    | "enseigneRenterLinks"
    | "brand_model_listing"
    | "build_tags_creation"
    | "brand_model_cleaning"
    | "manual_datasets_tags"
    | "fill_entry_edits_type"
    | "update_cdm_structures"
    | "force_lifetime_setter"
    | "rems_dates_description"
    | "update_equip_life_span"
    | "add_dates_to_remarques"
    | "set_default_dataset_flow"
    | "fix_the_sql_station_mess"
    | "linked_dataset_calculated"
    | "create_reg_doc_from_permit"
    | "initialize_tags_last_update"
    | "add_file_info_mission_versions"
    | "update_gammes_lifespan_datasets"
    | "update_users_nrj_dashboard_favorites";

export type DeleteOneResults = { ok?: number, n?: number, deletedCount?: number };
export type { UpdateWriteOpResult as MongoUpdateResults, AnyBulkWriteOperation } from "mongoose";

//#region Axios
export type { AxiosError } from "axios";
export type AxiosPromise<A = unknown> = Promise<AxiosResponse<A>>;
//#endregion

//#region Bulk
type BulkUpdateSingle = {
    updateOne: {
        upsert?: boolean;
        filter: Record<string, any>;
        update: Record<string, any>;
    }
}

type BulkUpdateMany = {
    updateMany: {
        upsert?: boolean;
        filter: Record<string, any>;
        update: Record<string, any>;
    }
}

export interface BulkResults {
    result?: any;
    matchedCount?: number | undefined;
    deletedCount?: number | undefined;
    insertedCount?: number | undefined;
    modifiedCount?: number | undefined;
    upsertedCount?: number | undefined;
    insertedIds?: { [index: number]: any } | undefined;
    upsertedIds?: { [index: number]: any } | undefined;
};

type BulkDeleteMany = { deleteMany: { filter: Record<string, any> } };
type BulkInsertSingle<M = {}> = { insertOne: { document: M } };

export type BulkMongoose<M = Record<string, any>> = BulkUpdateSingle | BulkUpdateMany | BulkDeleteMany | BulkInsertSingle<M>;
//#endregion

export type DeleteResult = {
    /** Indicates whether this write result was acknowledged. If not, then all other members of this result will be undefined. */
    acknowledged: boolean;
    /** The number of documents that were deleted */
    deletedCount: number;
}