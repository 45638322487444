import { TB, TC } from "../../Constants";
import { useBoolean } from "usehooks-ts";
import { useLanguage } from "../../hooks";
import { Row, Col, Button } from "react-bootstrap";
import React, { FC, useCallback, useMemo } from "react";

//#region Types
type Option = { label: string, value: string };

type TileSelectorProps = {
    selected?: string;
    options: Option[];
    langProp?: string;
    className?: string;
    onChange?: (value: Option) => void;
    sort?: boolean | ((a: Option, b: Option) => number);
}
//#endregion

const TileSelector: FC<TileSelectorProps> = ({ options, className, langProp, selected, sort, onChange }) => {
    const hideOptions = useBoolean(false);
    const { getTextObj, getStaticText } = useLanguage();

    //#region Options
    const nameSort = useCallback((a: Option, b: Option) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
    }, []);

    const sortedOptions = useMemo(() => {
        let vOptions = TB.getArray(options).filter(o => TB.validObject(o) && TB.validString(o.value) && TB.validString(o.label));
        if (typeof sort === 'boolean') return vOptions.sort(nameSort);
        if (typeof sort === "function") return vOptions.sort(sort);
        return vOptions;
    }, [options, sort, nameSort]);
    //#endregion

    //#region Languages
    const useLangProp = useMemo(() => TB.validString(langProp), [langProp]);

    const getLabel = React.useCallback((o: Option) => {
        if (useLangProp && TB.mongoIdValidator(o.value)) return getTextObj(o.value, langProp, o.label);
        else return getStaticText(o.label);
    }, [langProp, useLangProp, getStaticText, getTextObj]);
    //#endregion

    const hideShowButton = useMemo(() => <Col md={3} sm={6} xl={2}>
        <Button style={{ textOverflow: "ellipsis" }} variant="secondary" onClick={hideOptions.toggle} className="w-100 h-100">
            {getStaticText(hideOptions.value ? TC.ID_CARD_SHOW_OPTIONS : TC.ID_CARD_HIDE_OPTIONS)}
        </Button>
    </Col>, [hideOptions, getStaticText]);

    return sortedOptions.length > 1 &&
        <Row className={"g-1 " + TB.getString(className)}>
            {hideShowButton}
            {!hideOptions.value && sortedOptions.map(o => <Col key={o.value} md={3} sm={6} xl={2}>
                <Button style={{ textOverflow: "ellipsis" }} className={`w-100 h-100 ${o.value === selected ? "active" : ""}`} onClick={() => onChange?.(o)}>
                    {getLabel(o)}
                </Button>
            </Col>)}
        </Row>
}

export default TileSelector;