import React from "react";
import * as I from "../types";
import * as C from "../../../../Common";

type FunctionGetter<A = any, R = any> = (data: A, node: I.CellProps<Props>["node"], all_props: I.CellProps<Props>) => R;

export type Props<A = Record<string, any>> = {
    /** Callback when the button is pressed */
    action?: FunctionGetter<A, void>;
    /** Should the button be disabled ? */
    isDisabled?: boolean | FunctionGetter<A, boolean>;
    /** Should the button be shown ? */
    isHidden?: boolean | FunctionGetter<A, boolean>;
    /** Props for the button component */
    buttonProps?: C.ButtonProps | FunctionGetter<A, C.ButtonProps>;
    /** The content of the button */
    content?: C.ButtonProps["children"] | FunctionGetter<A, C.ButtonProps["children"]>;
}

export const type = "agCustomActionButton";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    const elements = React.useMemo(() => {
        let disabled: boolean;
        let content: Props["content"];
        let hidden: Props["isHidden"];
        let button_props: Props["buttonProps"];
        let params = props.colDef?.params || {};

        if (typeof params.content === "function") content = params.content(props.data, props.node, props);
        else content = params.content;
        if (typeof params.isDisabled === "function") disabled = params.isDisabled(props.data, props.node, props);
        else disabled = params.isDisabled || false;
        if (typeof params.isHidden === "function") hidden = params.isHidden(props.data, props.node, props);
        else hidden = params.isHidden;
        if (typeof params.buttonProps === "function") button_props = params.buttonProps(props.data, props.node, props);
        else button_props = params.buttonProps;
        // Don't show the button if it has no content and no props
        if (!hidden && !content && (typeof button_props !== "object" || Object.keys(button_props).length === 0)) hidden = true;
        return { content, disabled, hidden, button_props };
    }, [props]);

    const action = React.useCallback<C.ButtonProps["onClick"]>(event => {
        const action_prop = props.colDef?.params?.action;
        if (typeof action_prop === "function") action_prop(props.data, props.node, props);
        else elements.button_props?.onClick?.(event);
    }, [props, elements.button_props]);

    if (elements.hidden || props.node.group) return null;
    return <div className="w-100 text-center m-0">
        <C.Button
            {...elements.button_props}
            children={elements.content}
            disabled={elements.disabled}
            onClick={action}
        />
    </div>;
});