import _ from "lodash";
import React from "react";
import * as C from "../..";
import * as H from "../../../hooks";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as M from "../../../Components/Modal";
import { TC, T, TB, FP, LT, RIGHTS, ICONS } from "../../../Constants";

//#region Types
const AMOUNT_PER_PAGE = 6;

export type EmplacementMapperProps = {
    /** Should it be displayed in a modal ? */
    popup?: boolean;
    /** Extra modal style params */
    modal?: M.StyleModalProps;
    /** Callback for the closure of the modal */
    onQuit?: () => void;
    /** The list of available emplacements */
    emplacements: ReturnType<T.API.Utils.Import.GetEquipImportResources>["emplacements"];
    /** The list of links that are connecting the emplacements */
    links: T.Link[];
    /** The list of emplacements name found in the import */
    to_import: Mapping[];
    /** The building root id, to link the new emplacements to */
    building: string;
    /** Callback for the submission of the entries */
    onSave?: (results: {
        /** The newly created links and emplacements */
        created: Created;
        /** The results of the mapping */
        mapping: Mapping[];
    }) => void;
}

type Created = Pick<EmplacementMapperProps, "emplacements" | "links">;
type Mapping = ReturnType<T.API.Utils.Sub.AutoCreateEmplacements>["mapping"][number];
//#endregion

const EmplacementMapper: React.FC<EmplacementMapperProps> = props => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const show = H.useBoolean(false);
    const [page, set_page] = React.useState(0);
    const [local_icon, set_icon, status] = H.useAsyncState<string>(undefined);
    const [mapping, set_mapping] = React.useState<Mapping[]>(props.to_import);
    const [created, set_created] = React.useState<Created>({ emplacements: [], links: [] });
    const can_create = React.useMemo(() => rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT), [rights]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getIcon(ICONS.DEFAULT_LOCAL)
            .then(res => isSubscribed && set_icon(res.data, "done"))
            .catch(() => isSubscribed && set_icon(undefined, "error"));
        return () => {
            isSubscribed = false;
            set_icon(undefined, "load");
        }
    }, [set_icon]);

    //#region Options
    const SORT_LIKENESS = React.useCallback((options: T.Option[], label: string) => [...options].sort((o1, o2) => {
        let precision1 = TB.checkStringSimilarity(o1.label, label),
            precision2 = TB.checkStringSimilarity(o2.label, label);
        // Both are quite similar to the string given, order by their likeness
        if (precision1 > 0.85 && precision2 > 0.85) return precision2 - precision1;
        // Only one is quite similar
        if (precision1 > 0.85) return -1;
        if (precision2 > 0.85) return 1;
        // Order alphabetically
        if (o1.label === o2.label) return 0;
        return o1.label > o2.label ? 1 : -1;
    }), []);

    const { emplacements, links } = React.useMemo<Created>(() => ({
        links: props.links.concat(created.links),
        emplacements: props.emplacements.concat(created.emplacements),
    }), [created, props.links, props.emplacements]);

    const [floor, locals] = React.useMemo(() => _.partition(emplacements, e => e.type !== "local"), [emplacements]);

    const get_locals = React.useCallback((floor?: string) => {
        let children = [] as string[];
        // Find locals linked to the building
        if (!TB.mongoIdValidator(floor)) children = locals
            // Check for locals that are either not linked to anyone, or linked directly to the building
            .filter(loc => links.filter(link => link.output === loc.value && link.input !== props.building).length === 0)
            .map(l => l.value);
        else children = links.filter(l => l.input === floor).map(l => l.output);
        return locals.filter(l => children.includes(l.value));
    }, [locals, links, props.building]);
    //#endregion

    //#region Updates
    React.useEffect(() => set_mapping(props.to_import), [props.to_import]);

    const do_create_promise = React.useCallback((options: T.Option[], mapper_name: string) => new Promise<"create" | "cancel" | Record<"id", string>>(resolve => {
        // No options that could be deemed similar
        if (options.length === 0) resolve("create");
        // Only one similar option, ask user to confirm
        else if (options.length === 1) M.askConfirm({
            noText: TC.EQUIP_IMPORT_CREATE_NEW_ITEM,
            yesText: TC.EQUIP_IMPORT_CREATE_OLD_ITEM,
            title: lg.getStaticText(TC.EQUIP_IMPORT_CREATE_OR_AUTO_TITLE, mapper_name),
            text: lg.getStaticText(TC.EQUIP_IMPORT_CREATE_OLD_ITEM_SINGLE, options[0].label),
        })
            .then(confirmed => {
                // Canceled the select
                if (typeof confirmed !== "boolean") resolve("cancel");
                // Chose the existing item
                else if (confirmed) resolve({ id: options[0].value })
                // Chose to create a new item
                else resolve("create");
            })
        // More than one option found, ask user to pick
        else M.askSelect({
            options: options,
            defaultVal: options[0].value,
            selectProps: { hideClearButton: true },
            confirmText: TC.EQUIP_IMPORT_CREATE_OLD_ITEM,
            label: TC.EQUIP_IMPORT_CREATE_OLD_ITEM_MULTI,
            noSelectionText: TC.EQUIP_IMPORT_CREATE_NEW_ITEM,
            title: lg.getStaticText(TC.EQUIP_IMPORT_CREATE_OR_AUTO_TITLE, mapper_name),
        })
            .then(selection => {
                // Canceled the select
                if (selection === null) resolve("cancel");
                // Chose an existing item
                else if (TB.mongoIdValidator(selection)) resolve({ id: selection });
                // Chose to create a new item
                else resolve("create");
            });
    }), [lg]);

    const auto_all = React.useCallback(() => {
        let confirm_params = {
            noText: TC.EMP_AUTO_CREATE_CONFIRM_NO,
            title: TC.EMP_AUTO_CREATE_CONFIRM_TITLE,
            yesText: TC.EMP_AUTO_CREATE_CONFIRM_YES,
            text: TC.EMP_AUTO_CREATE_CONFIRM_MESSAGE,
        } as Parameters<typeof M.askConfirm>[0];

        M.askConfirm(confirm_params).then(confirmed => {
            if (confirmed) S.autoCreateEmplacements({ mapping, building: props.building }).then(({ data }) => {
                let emp_as_options: EmplacementMapperProps["emplacements"] = data.created
                    .map(e => ({ label: e.data.name, type: e.data.type, value: e._id }));
                // Update the created emplacements
                set_created(p => ({ emplacements: p.emplacements.concat(emp_as_options), links: p.links.concat(data.links) }));
                // Update the mapping
                set_mapping(data.mapping);
                // Inform the user of the success
                M.renderAlert({ type: "success", message: { ref: TC.EMP_AUTO_CREATE_MAPPER_SUCCESS, template: data.created.length.toString() } });
            }).catch(M.Alerts.updateError);
        });
    }, [mapping, props.building]);

    const updates = React.useMemo(() => ({
        set_floor: (label: string, id?: string) => set_mapping(p => p.map(m => {
            if (label !== m.floor) return m;
            else return { ...m, floor_id: id, local_id: undefined };
        })),
        set_local: (label: string, label_floor: string, id?: string) => set_mapping(p => p.map(m => {
            if (label_floor !== m.floor || label !== m.local) return m;
            else return { ...m, local_id: id };
        })),
        create_floor: (text: string, label: string) => {
            let submission = TB.submissionToArrayUpdate({ name: text, type: "floor" } as Partial<T.EmplacementData>);
            // Ask the user to create the new floor
            M.renderFormModal<T.EmplacementData>({ forcedSubmission: submission, path: FP.EMPLACEMENT_FORM }).then(emplacement => {
                // Link the floor to the building
                if (emplacement) S.attachNode({ children: emplacement._id, parent: props.building, type: LT.LINK_TYPE_OWN })
                    .then(links => {
                        // Add the floor and it's links to the list of created emplacement
                        set_created(p => ({
                            links: p.links.concat(links.data),
                            emplacements: p.emplacements.concat({
                                value: emplacement._id,
                                type: emplacement.data.type,
                                label: emplacement.data.name,
                            })
                        }));
                        // Update the floors in the mapping array
                        updates.set_floor(label, emplacement._id);
                    }).catch(M.Alerts.updateError);
            });
        },
        create_local: (text: string, label: string, label_floor?: string, floor_id?: string) => {
            let submission = TB.submissionToArrayUpdate({ name: text, type: "local" } as Partial<T.EmplacementData>);
            // Ask the user to create the new local
            M.renderFormModal<T.EmplacementData>({ forcedSubmission: submission, path: FP.EMPLACEMENT_FORM }).then(emplacement => {
                // Link the local to the floor or building
                if (emplacement) S.attachNode({ children: emplacement._id, parent: floor_id || props.building, type: LT.LINK_TYPE_OWN })
                    .then(links => {
                        // Add the floor and it's links to the list of created emplacement
                        set_created(p => ({
                            links: p.links.concat(links.data),
                            emplacements: p.emplacements.concat({
                                value: emplacement._id,
                                type: emplacement.data.type,
                                label: emplacement.data.name,
                            })
                        }));
                        // Update the floors in the mapping array
                        updates.set_local(label, label_floor, emplacement._id);
                    }).catch(M.Alerts.updateError);
            });
        },
        auto_local: (text: string, label: string, label_floor?: string, floor_id?: string) => {
            let submission = { name: text, type: "local", selectIcon: local_icon } as T.EmplacementData;
            let similar_options = get_locals(floor_id).filter(option => TB.checkStringSimilarity(option.label, text) > 0.9);

            do_create_promise(similar_options, text).then(action => {
                if (action === "create") S.createSubmission({ submission, path: FP.EMPLACEMENT_FORM, attach: { parent: floor_id || props.building, type: LT.LINK_TYPE_OWN } }).then(({ data }) => {
                    let emplacement = data.submissions[0];
                    if (emplacement) {
                        // Add the floor and it's links to the list of created emplacement
                        set_created(p => ({
                            links: p.links.concat(data.links),
                            emplacements: p.emplacements.concat({
                                value: emplacement._id,
                                type: emplacement.data.type,
                                label: emplacement.data.name,
                            })
                        }));
                        // Update the floors in the mapping array
                        updates.set_local(label, label_floor, emplacement._id);
                    }
                }).catch(M.Alerts.updateError);
                else if (action !== "cancel") updates.set_local(label, label_floor, action.id);
            })
        }
    }), [props.building, get_locals, do_create_promise, local_icon]);
    //#endregion

    //#region Renders
    // To allow a lighter version to appear through the popup more quickly
    React.useEffect(() => {
        if (!show.value) setTimeout(show.setTrue, 1000);
    }, [show]);

    const max_page = React.useMemo(() => Math.ceil(mapping.length / AMOUNT_PER_PAGE), [mapping.length]);

    const current_mapping = React.useMemo(() => {
        let start_index = page * AMOUNT_PER_PAGE,
            end_index = start_index + AMOUNT_PER_PAGE;
        return _.slice(mapping, start_index, end_index);
    }, [page, mapping]);

    const render_floor = React.useCallback((option: typeof floor[number]) => <div>
        <i className={"fa fa-" + (option.type === "floor" ? "stairs" : "square")}></i> {option.label}
    </div>, []);
    //#endregion

    //#region Validation
    const validate = React.useCallback(() => props.onSave?.({ created, mapping }), [mapping, created, props]);
    //#endregion

    //#region Content
    const content = React.useMemo(() => <C.Spinner loading={!show.value} min_load_size="250px">
        <C.Flex direction="column" className="h-100 w-100">
            <div className="mb-3">
                <C.ErrorBanner type="info" message={TC.IMPORT_EQUIP_EMP_INFO} />
            </div>
            <div className="flex-grow-1">
                {current_mapping.map((v, i) => <BS.Row key={i}>
                    <BS.Col>
                        <C.Form.Select
                            value={v.floor_id}
                            disabled={v.floor === undefined}
                            options={SORT_LIKENESS(floor, v.floor)}
                            onChange={id => updates.set_floor(v.floor, id)}
                            label={v.floor || TC.IMPORT_EQUIP_EMP_NOT_ATTRIBUTES}
                            typeahead={{
                                renderItem: render_floor,
                                allowNewOption: can_create,
                                onAddOption: text => updates.create_floor(text, v.floor),
                            }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Flex justifyContent="between" alignItems="center">
                            <div className="mb-0">{v.local || lg.getStaticText(TC.IMPORT_EQUIP_EMP_NOT_ATTRIBUTES)}</div>
                            <div>
                                {v.local !== undefined && can_create && <C.Button
                                    size="sm"
                                    variant="link"
                                    text={TC.IMPORT_AUTO_CREATE}
                                    onClick={() => updates.auto_local(v.local, v.local, v.floor, v.floor_id)}
                                />}
                            </div>
                        </C.Flex>
                        <C.Form.Select
                            value={v.local_id}
                            disabled={v.local === undefined}
                            options={SORT_LIKENESS(get_locals(v.floor_id), v.local)}
                            onChange={id => updates.set_local(v.local, v.floor, id)}
                            typeahead={{
                                allowNewOption: can_create,
                                onAddOption: text => updates.create_local(text, v.local, v.floor, v.floor_id),
                            }}
                        />
                    </BS.Col>
                </BS.Row>)}
            </div>
            <C.Flex justifyContent="center" alignItems="center">
                <C.IconButton
                    icon="arrow-left"
                    disabled={page <= 0}
                    onClick={() => set_page(p => p - 1)}
                />
                <div className="mx-3">{page + 1} / {max_page}</div>
                <C.IconButton
                    icon="arrow-right"
                    disabled={(page + 1) >= max_page}
                    onClick={() => set_page(p => p + 1)}
                />
            </C.Flex>
        </C.Flex>
    </C.Spinner>, [current_mapping, floor, can_create, updates, page, max_page, lg, show.value, SORT_LIKENESS, render_floor, get_locals]);

    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button icon="hat-wizard" variant="sector" text={TC.EMP_AUTO_CREATE_MAPPER_BUTTON} onClick={auto_all} />
        <C.Button icon="save" className="ms-2" text={TC.GLOBAL_CONFIRM} onClick={validate} />
    </C.Flex>, [validate, auto_all]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : "div",
        props.popup ? {
            ...props.modal,
            height: "75vh",
            footer: footer,
            onQuit: props.onQuit,
            size: props.modal?.size || "lg",
            title: props.modal?.title || TC.IMPORT_EQUIP_EMP_TITLE,
        } as M.BlankModalProps : null,
        <C.Spinner status={status} min_load_size="250px">
            {content}
            {!props.popup && <div className="mt-2" children={footer} />}
        </C.Spinner>
    );
}

export default EmplacementMapper;