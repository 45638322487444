import React from "react";
import { Flex } from "../../Layout";
import { TB } from "../../../Constants";
import { ButtonProps, Button } from "../../Item";
import { InputProps, ComponentWrapper } from "../Input";
import { useFormConsumer, useLanguage } from "../../../hooks";
import { InputGroup, FormControl, ListGroup } from "react-bootstrap";

//#region Types
export type NumFieldProps<A = (SingleProps | MultiProps)> = InputProps & CommonProps & A;

type SingleNumFieldRef = {
    /** Ref to the input */
    input: React.MutableRefObject<HTMLInputElement>;
}

export type NumFieldRef = {
    /** The ref to the single input */
    single?: React.MutableRefObject<SingleNumFieldRef>;
}

type CommonProps = {
    /** Text that precede the input */
    prefix?: string;
    /** Text that follows the input */
    suffix?: string | ButtonProps;
    /** Expects one or multiple value */
    multiple?: boolean;
    /** Text of the input when empty */
    placeholder?: string;
    /** The max number of decimals expected */
    decimalLimit?: number;
    autocomplete?: string;
}

type SingleProps = {
    value?: number;
    useCalculatedError?: boolean;
    onChange?: (number: number) => void;
    onChangeUncontrolled?: (number: number) => void;
}

type MultiProps = {
    value?: number[];
    onChange?: (numbers: number[]) => void;
}
//#endregion

//#region Single
const NumFieldSingle = React.forwardRef<SingleNumFieldRef, NumFieldProps<SingleProps>>(({ disabled, noOverride, onChange, onChangeUncontrolled, ...props }, ref) => {
    const lg = useLanguage();
    const [controlled, setControlled] = React.useState("");
    const textInputRef = React.useRef<HTMLInputElement>(null);
    const { error } = useFormConsumer(props.prop, props.contextKey);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);

    React.useImperativeHandle(ref, () => ({ input: textInputRef }), []);
    React.useEffect(() => setControlled(TB.getString(props.value)), [props.value]);
    const onChangeInput = React.useCallback((str: string) => setControlled(str), []);

    const onLostFocus = React.useCallback(() => {
        if (isDisabled) return;
        let num = TB.getNumber(controlled);
        if (typeof props.decimalLimit === "number") num = parseFloat(num.toFixed(props.decimalLimit));
        onChange?.(num);
    }, [props.decimalLimit, controlled, isDisabled, onChange]);

    React.useEffect(() => {
        if (isDisabled) return;
        let num = TB.getNumber(controlled);
        if (typeof props.decimalLimit === "number") num = parseFloat(num.toFixed(props.decimalLimit));
        onChangeUncontrolled?.(num);
    }, [props.decimalLimit, controlled, isDisabled, onChangeUncontrolled]);

    const vPrefix = React.useMemo(() => props.prefix && lg.getStaticText(props.prefix), [props.prefix, lg]);
    
    const vSuffix = React.useMemo(() => {
        if (TB.validString(props.suffix)) return <InputGroup.Text children={lg.getStaticText(props.suffix)} />;
        else if (props.suffix) return <Button {...props.suffix} />;
        else return null;
    }, [props.suffix, lg]);

    const vPlaceHolder = React.useMemo(() => props.placeholder && lg.getStaticText(props.placeholder), [props.placeholder, lg]);

    const inputProps = React.useMemo(() => ({
        type: "number",
        placeholder: vPlaceHolder,
        autoComplete: props.autocomplete,
    }), [vPlaceHolder, props.autocomplete]);

    return <ComponentWrapper {...props} error={props.useCalculatedError ? error : props.error} disabled={isDisabled}>
        <InputGroup>
            {vPrefix && <InputGroup.Text>{vPrefix}</InputGroup.Text>}
            <FormControl
                {...inputProps}
                ref={textInputRef}
                value={controlled}
                onBlur={onLostFocus}
                disabled={isDisabled}
                readOnly={props.readonly}
                onWheel={e => e?.currentTarget?.blur?.()}
                onScroll={e => e?.currentTarget?.blur?.()}
                onChange={e => onChangeInput(e.target.value)}
            />
            {vSuffix}
        </InputGroup>
    </ComponentWrapper>
});
//#endregion

const NumField = React.forwardRef<NumFieldRef, NumFieldProps>(({ multiple, disabled, noOverride, ...props }, ref) => {
    const singleRef = React.useRef<SingleNumFieldRef>(null);

    React.useImperativeHandle(ref, () => ({ single: singleRef }), []);

    const onChange = React.useMemo(() => props.onChange, [props.onChange]);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    const values = React.useMemo(() => TB.arrayWrapper(props.value), [props.value]);

    const editEntry = React.useCallback((num: number, index: number) => {
        if (values.length === 0 && index === 0) onChange?.([num]);
        else onChange?.(values.map((v, i) => i === index ? num : v));
    }, [onChange, values]);

    const addEntry = React.useCallback(() => onChange?.(values.concat(NaN)), [onChange, values]);
    const removeEntry = React.useCallback((index: number) => onChange?.(values.filter((v, i) => i !== index)), [onChange, values]);

    if (!multiple) return <NumFieldSingle {...props} disabled={isDisabled} ref={singleRef} />;
    return <ListGroup className="mb-3">
        {values.map((v, i) => <ListGroup.Item key={i}>
            <Flex alignItems="center">
                <NumFieldSingle
                    {...props}
                    value={v}
                    useCalculatedError
                    disabled={isDisabled}
                    prop={props.prop + "." + i}
                    onChange={(n: number) => editEntry(n, i)}
                    customClass={TB.getString(props.customClass) + " flex-grow-1"}
                />
                <div className="ms-3">
                    <Button variant="danger" disabled={isDisabled} size="sm" onClick={() => removeEntry(i)}>
                        <i className="fa fa-times"></i>
                    </Button>
                </div>
            </Flex>
        </ListGroup.Item>)}

        {values.length === 0 && <ListGroup.Item>
            <NumFieldSingle {...props} disabled={isDisabled} value={NaN} onChange={(n: number) => editEntry(n, 0)} />
        </ListGroup.Item>}

        <ListGroup.Item className="text-end">
            <Button size="sm" onClick={addEntry} disabled={isDisabled}>
                <i className="fa fa-plus"></i>
            </Button>
        </ListGroup.Item>
    </ListGroup>;
});

export default NumField;