import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes } from "../AgGridDefs";
import { Table, TableProps } from "../Grid";
import { FP, RIGHTS, T, TABS, TB, TC } from "../../../Constants";

//#region Constants
type Row = T.API.Reg.ActionsRegTableData["items"][number];
const TEXT_CODES = [TC.REG_DELETE_REG_ACTION_WARNING, TC.REG_ADD_ACTION_WITHOUT_SITE, TC.REG_ADD_ACTION_WITH_SITE, TC.UPDATE, TC.GLOBAL_DELETE];
const INIT_STATE: T.API.Reg.ActionsRegTableData = { sites: [], items: [], controllers: [], contextName: "" };
//#endregion

const RegActionTable: React.FC = () => {
    const [roots] = H.useRoots();
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    H.useCrumbs(FP.ACTION_REG_FORM);
    const loading = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    H.useAuth({ tabName: TABS.REG_ACTIONS_TABLE });
    const [resources, setResources, status] = H.useAsyncState(INIT_STATE, "load");

    //#region Fetch Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getRegActionsTableData(roots)
            .then(({ data }) => isSubscribed && setResources(data, "done"))
            .catch(() => setResources(INIT_STATE, "error"));

        return () => { isSubscribed = false };
    }, [roots, setResources]);
    //#endregion

    const regActionForm = React.useMemo(() => forms[FP.ACTION_REG_FORM], [forms]);

    //#region Columns
    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        { field: "ids._id", headerName: " ", params: { header_id: regActionForm }, hide: true },
        { field: "name", headerName: "name", params: { header_id: regActionForm } },
        { field: "tr_type", headerName: "type", params: { header_id: regActionForm } },
        { field: "doneBy", headerName: "doneBy", params: { header_id: regActionForm } },
        { field: "auto_file", headerName: "auto_file", type: CellsTypes.TYPE_CHECKBOX, params: { header_id: regActionForm } },
        { field: "order", headerName: "order", editable: true, type: CellsTypes.TYPE_NUMBER, params: { header_id: regActionForm } },
        { field: "paidBy", headerName: "paidBy", params: { header_id: regActionForm } },
        { field: "comment", headerName: "comment", params: { header_id: regActionForm } },
        { field: "extLink", headerName: "extLink", params: { header_id: regActionForm } },
        { field: "gammes", headerName: "gammes", params: { header_id: regActionForm } },
        { field: "reference", headerName: "reference", params: { header_id: regActionForm } },
        { field: "condition", headerName: "condition", params: { header_id: regActionForm } },
        {
            headerName: TC.GLOBAL_REGIONS,
            children: [
                { field: "countries", headerName: "countries", params: { header_id: regActionForm } },
                { field: "tr_regions", headerName: "regions", params: { header_id: regActionForm } },
                { field: "sites", headerName: "sites", params: { header_id: regActionForm } },
            ]
        },
        { field: "resources", headerName: "resources", params: { header_id: regActionForm } },
        { field: "freq", headerName: "freq", params: { header_id: regActionForm } },
        { field: "europeanDirective", headerName: "europeanDirective", params: { header_id: regActionForm } },
    ], [regActionForm]);
    //#endregion

    //#region Callbacks
    const selectSite = React.useCallback((actionId?: string, withSite?: boolean) => new Promise<string[] | null | void>((resolve, reject) => {
        if (TB.mongoIdValidator(actionId) || !withSite) resolve();
        else if (resources.sites.length === 0) {
            M.Alerts.missingContextSites();
            resolve(null);
        }
        else if (resources.sites.length === 1) resolve([resources.sites[0].value]);
        else M.askMultiSelect({ options: resources.sites, required: true, title: FP.SITE_FORM })
            .then(sites => resolve(sites as string[]));
    }), [resources.sites]);

    const openForm = React.useCallback((withSite?: boolean, id?: string) => {
        selectSite(id, withSite).then(sites => {
            let forcedSubmission = [{ prop: "sites", value: sites }];
            if (sites !== null) M.renderFormModal<T.RegAction>({ submissionId: id, path: FP.ACTION_REG_FORM, forcedSubmission, title: TC.REG_FORM_REGLEMENTATION })
                .then(action => {
                    if (action) S.getRegActionItems([action._id]).then(({ data }) => setResources(p => {
                        let loaded_items_ids = data.map(i => i.ids._id);
                        let existing_items_ids = p.items.map(i => i.ids._id);
                        let [existing_items, new_items] = _.partition(data, d => existing_items_ids.includes(d.ids._id));

                        return {
                            ...p,
                            items: p.items
                                .map(i => loaded_items_ids.includes(i.ids._id) ? existing_items.find(it => it.ids._id === i.ids._id) : i)
                                .concat(new_items)
                        };
                    })).catch(() => M.Alerts.loadError())
                })
        });
    }, [setResources, selectSite]);

    const deleteAction = React.useCallback((actionId: string) => {
        M.askConfirm({ text: TC.REG_DELETE_REG_ACTION_WARNING }).then(confirmed => {
            if (confirmed) S.deleteRegAction(actionId).then(() => {
                setResources(p => ({ ...p, items: p.items.filter(i => actionId !== i.ids._id) }));
            }).catch(() => M.Alerts.deleteError());
        })
    }, [setResources]);

    const on_inline_change = React.useCallback<TableProps<Row>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof items[number],
            is_site_specific = Array.isArray(row.sites) && row.sites.length > 0;

        let can_edit = is_site_specific
            ? rights.isRightAllowed(RIGHTS.REG.WRITE_SITE_REG_ACTIONS)
            : rights.isRightAllowed(RIGHTS.REG.WRITE_REG_DOC_TYPES);

        // This field can't be edited
        if (field !== "order") M.Alerts.updateError();
        // Can the user edit this action
        else if (can_edit) {
            let prop: keyof T.RegAction = field;

            const updatePromise = new Promise<Row>((resolve, reject) => {
                let api_params = {
                    field: prop,
                    _id: row._id,
                    old_value: old_value,
                    new_value: params.newValue,
                } as Parameters<typeof S.update_reg_action_field>[0];

                loading.setTrue();

                S.update_reg_action_field(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.update_reg_action_field({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve({ ...row, [prop]: params.newValue });
                        }).catch(reject);
                    });
                    else resolve({ ...row, [prop]: params.newValue });
                }).catch(reject);
            });

            updatePromise
                .then(row => setResources(p => ({ ...p, items: p.items.map(r => r._id === row._id ? row : r) })))
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        }
        else M.Alerts.haveNotRight();
    }, [loading, rights, setResources]);
    //#endregion

    //#region Context Menu
    const getContextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>((params) => {
        let customItems = [];
        let item: T.API.Reg.ActionsRegTableItems = params.node?.data;
        let is_site_specific = Array.isArray(item?.sites) && item.sites.length > 0;

        let can_add_normal = rights.isRightAllowed(RIGHTS.REG.WRITE_REG_DOC_TYPES),
            can_add_site = rights.isRightAllowed(RIGHTS.REG.WRITE_SITE_REG_ACTIONS),
            can_edit = is_site_specific
                ? rights.isRightAllowed(RIGHTS.REG.WRITE_SITE_REG_ACTIONS)
                : rights.isRightAllowed(RIGHTS.REG.WRITE_REG_DOC_TYPES);

        // Add a site specific reg action
        if (can_add_site) customItems.push({
            action: () => openForm(true),
            icon: "<i class='fa fa-plus'></i>",
            name: lg.getStaticText(TC.REG_ADD_ACTION_WITH_SITE),
        });

        // Add global reg action
        if (can_add_normal) customItems.push({
            action: () => openForm(),
            icon: "<i class='fa fa-plus'></i>",
            name: lg.getStaticText(TC.REG_ADD_ACTION_WITHOUT_SITE),
        });

        if (customItems.length > 0) customItems.push("separator");
        let itemsLength = customItems.length;

        if (item && can_edit) customItems.push(
            { name: lg.getStaticText(TC.UPDATE), action: () => openForm(false, item.ids._id), icon: "<i class='fa fa-pencil-alt'></i>" },
            { name: lg.getStaticText(TC.GLOBAL_DELETE), action: () => deleteAction(item.ids._id), icon: "<i class='fa fa-trash-alt'></i>" },
        );

        // Add a new Separator
        if (customItems.length > itemsLength) customItems.push("separator");
        return customItems.concat(params.defaultItems);
    }, [openForm, deleteAction, rights, lg]);
    //#endregion

    //#region Items
    const items = React.useMemo(() => resources.items.map(i => ({
        ...i,
        resources: i.resources.map(r => lg.getStaticText(r)),
        freq: i.noFreq ? "∞" : (i.frequency ? i.frequency : "X"),
        tr_type: lg.getStaticText(i.type === "reg" ? TC.ACTION_REG : TC.GP_OTHER),
        tr_regions: (i.ids.regions || []).map((r, j) => lg.getTextObj(r, "name", i.regions?.[j])),
    })), [lg, resources.items]);
    //#endregion

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <Table<Row>
                rows={items}
                status={status}
                columns={columns}
                loading={loading.value}
                sideBar="filters_columns"
                columns_base="all_but_edit"
                onValueChange={on_inline_change}
                adaptableId={TABS.REG_ACTIONS_TABLE}
                getContextMenuItems={getContextMenu}
            />
        </C.Spinner>
    </div>;
};

export default RegActionTable;