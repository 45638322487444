import axios from "axios";
import { T, URL } from "../../Constants";
import { getHeader } from "../Headers";

const { NAV_API } = URL;

type NavTableSites = T.API.ToAxios<T.API.Utils.Navigator.NavTableSites>;
type UsersMissions = T.API.ToAxios<T.API.Utils.Navigator.UsersMissions>;
type SubEquipContext = T.API.ToAxios<T.API.Utils.Navigator.SubEquipContext>;
type NavTableClients = T.API.ToAxios<T.API.Utils.Navigator.NavTableClients>;
type UpdateRecentList = T.API.ToAxios<T.API.Utils.Navigator.UpdateRecentList>;
type RemoveRecentList = T.API.ToAxios<T.API.Utils.Navigator.RemoveRecentList>;
type NavTableBuildings = T.API.ToAxios<T.API.Utils.Navigator.NavTableBuildings>;
type UsersAssetMissions = T.API.ToAxios<T.API.Utils.Navigator.UsersAssetMissions>;
type UpdateRecentMissionList = T.API.ToAxios<T.API.Utils.Navigator.UpdateRecentMissionList>;
type NavigatorSubContextMissions = T.API.ToAxios<T.API.Utils.Navigator.NavigatorSubContextMissions>;
type NavigatorPortfoliosAndRecent = T.API.ToAxios<T.API.Utils.Navigator.NavigatorPortfoliosAndRecent>;

/** Get the portfolios and recent selections used in the navigator */
export const navigatorPortfoliosAndRecent: NavigatorPortfoliosAndRecent = () => axios.post(NAV_API + "context");
/** Get the sub-context and the missions used in the navigator */
export const navigatorSubContextMissions: NavigatorSubContextMissions = context => axios.post(NAV_API + "portfolios", context);
/** A new context has been selected, update the recent list */
export const updateRecentList: UpdateRecentList = item => axios.post(NAV_API + "update", { item }, getHeader("EDIT"));
/** Remove a context from the list of recent items */
export const removeRecentList: RemoveRecentList = item => axios.post(NAV_API + "remove", { item }, getHeader("EDIT"));
/** Get the table data for a user's available clients */
export const navTableClients: NavTableClients = light => axios.get(NAV_API + "table/" + (light ? "light/" : "") + "clients");
/** Get the table data for a user's available sites */
export const navTableSites: NavTableSites = light => axios.get(NAV_API + "table/" + (light ? "light/" : "") + "sites");
/** Get the table data for a user's available buildings */
export const navTableBuildings: NavTableBuildings = light => axios.get(NAV_API + "table/" + (light ? "light/" : "") + "buildings");
/** A new mission has been selected, update the recent mission list */
export const updateRecentMissionList: UpdateRecentMissionList = mission => axios.post(NAV_API + "mission/update", { mission }, getHeader("EDIT"));
/** Get the missions a user has access to */
export const usersMissions: UsersMissions = ids => axios.post(NAV_API + "missions", { ids });
/** Get the user's missions on a specified context */
export const usersAssetMissions: UsersAssetMissions = asset => axios.post(NAV_API + "asset/missions", { asset });
/** Get the the equipment-specific context of another equipment */
export const subEquipContext: SubEquipContext = equipment => axios.post(NAV_API + "equipment", { equipment });