import React from "react";
import moment from 'moment';
import * as M from "../Modal";
import * as H from '../../hooks';
import * as C from "../../Common";
import * as S from "../../services";
import * as HP from "../../helpers";
import * as BS from 'react-bootstrap';
import * as PM from '../../PurposeModal';
import * as US from '../../services/user.service';
import { FP, T, TB, TC, URL } from '../../Constants';

const getDateFileName = () => moment().format("DD_MM_YY__HH_mm");

const BoardAdmin: React.FC = () => {
    H.useCrumbs(TC.TAB_ADMIN);
    const [roots] = H.useRoots();
    const [copy] = H.useClipBoard();
    const { adminCallback } = H.useSocket();
    const admin_status_checked = H.useBoolean(false);

    React.useEffect(() => {
        if (!admin_status_checked.value) S.adminCheck().then(r => {
            if (!r.data) window.location.replace("/unavailable");
            else admin_status_checked.setTrue();
        })
            .catch(() => window.location.replace("/unavailable"));
    }, [admin_status_checked]);

    const functions = React.useMemo(() => ({
        translate_table: () => S.fill_lang_table().then(({ data }) => console.log(data)).catch(M.Alerts.loadError),
        xml: () => {
            M.renderSearchContext().then(root => {
                if (root) {
                    let unmount = M.renderLoader();
                    S.generateXML({ root: root.id, fileName: "export", exportFiles: true })
                        .catch(() => M.Alerts.loadError({ message: TC.GLOBAL_EXPORT_ERROR_OCCURRED }))
                        .finally(unmount);
                }
            });
        },
        encrypt: () => {
            M.askPrompt({ label: TC.PASSWORD, password: true }).then(pwd => {
                if (typeof pwd === "string") TB.formioEncrypt(pwd).then(crypted => {
                    copy(crypted || "");
                    M.Alerts.clipboard_copy_success();
                });
            });
        },
        export_db_forms: () => {
            US.exportForms().then(({ data }) => {
                if (Array.isArray(data)) TB.downloadObjectAsJson(data, getDateFileName() + "forms");
                else M.Alerts.file_generation_fail();
            }).catch(M.Alerts.file_generation_fail);
        },
        export_db_data: () => {
            US.exportConstantData().then(({ data }) => {
                if (Array.isArray(data)) TB.downloadObjectAsJson(data, getDateFileName() + "forms");
                else M.Alerts.file_generation_fail();
            }).catch(M.Alerts.file_generation_fail);
        },
        import_db_forms: () => {
            M.askLargePrompt({ required: true, title: "Forms", label: "Forms (JSON)" }).then(strJson => {
                if (TB.validString(strJson)) try {
                    let forms = JSON.parse(strJson);
                    let unmount = M.renderLoader();
                    US.importForms(forms)
                        .then(r => console.log(r.data))
                        .catch(M.Alerts.updateError)
                        .finally(unmount)
                }
                    catch (error) {
                        M.renderAlert({ type: "error", message: "JSON format invalid" });
                    }
            })
        },
        import_db_data: () => {
            M.askLargePrompt({ required: true, title: "Data", label: "Data (JSON)" }).then(strJson => {
                if (TB.validString(strJson)) try {
                    let forms = JSON.parse(strJson);
                    let unmount = M.renderLoader();
                    US.importConstantData(forms)
                        .then(r => console.log(r.data))
                        .catch(M.Alerts.updateError)
                        .finally(unmount)
                }
                    catch (error) {
                        M.renderAlert({ type: "error", message: "JSON format invalid" });
                    }
            })
        },
        gen_api_key: () => {
            PM.renderUserSelect({ includeSelf: true }).then(id => {
                if (TB.mongoIdValidator(id)) US.setUserApiKey(id)
                    .then(({ data }) => {
                        copy(data)
                            .then(() => M.renderAlert({ type: "success", message: "Copied!" }))
                            .catch(() => M.renderAlert({ type: "error", message: "Not Copied!" }))
                    }).catch(error => console.log({ error }));
            });
        },
        get_ancestry: () => {
            M.askPrompt({ isRequired: true, label: "_id", title: "Chercher les parents" }).then(_id => {
                if (TB.mongoIdValidator(_id)) US.getLocationOptionsFull(_id)
                    .then(({ data }) => console.log(data?.[0] || null))
                    .catch(M.Alerts.loadError);
                else if (TB.validString(_id)) M.Alerts.loadError({ message: "Le texte n'est pas un _id" });
            });
        },
        listing_dead_links: () => {
            const treat_issues = ["has_old_props", "is_wrong_domain", "id_not_in_url", "is_not_on_disk"].map(value => ({ label: value, value })) as T.Option[];
            M.askMultiSelect({ options: treat_issues }).then(issues => {
                if (issues) S.adminUpdates("list_dead_links", { treat_issues: issues })
                    .then(({ data }) => console.log(data || "empty"))
                    .catch(M.Alerts.loadError);
            });
        },
        brand_model_listing: () => {
            S.adminUpdates("brand_model_listing").then(({ data }) => {
                let keys = ["type", "brand_id", "model_id", "brand", "model"];
                let aoa = [keys.concat("replace_brand_by", "replace_model_by", "delete_no_replace"), ...data.map(d => keys.map(k => d[k]))];
                TB.aoaToExcel(aoa, "brands_models", "Sheet 1");
            }).catch(M.Alerts.loadError);
        },
        brand_model_cleaning: () => {
            M.renderExcelMapper({
                save: "brand_model_cleaning",
                title: "Fichier de nettoyage",
                columnFormat: {
                    type: { label: "Type", type: "string", required: true },
                    brand_id: { label: "Id Brand", type: "string", required: true },
                    brand: { label: "Brand", type: "string", required: true },
                    model: { label: "Model", type: "string", required: true },
                    model_id: { label: "Id Model", type: "string", required: true },
                    replace_brand_by: { label: "Replacement Brand Id", type: "string", required: true },
                    replace_model_by: { label: "Replace Model Id", type: "string", required: true },
                    delete_no_replace: { label: "Delete Without Replace", type: "boolean", required: true },
                },
            }).then(values => {
                if (values) S.adminUpdates("brand_model_cleaning", values)
                    .then(x => console.log(x))
                    .catch(M.Alerts.updateError);
            });
        },
        linked_dataset_calculated: () => {
            S.adminUpdates("linked_dataset_calculated")
                .then(({ data }) => console.log(data))
                .catch(M.Alerts.loadError);
        },
        fill_entry_edits_type: () => {
            S.adminUpdates("fill_entry_edits_type")
                .then(({ data }) => console.log(data))
                .catch(M.Alerts.loadError);
        },
        find_dataset_mess: () => {
            S.adminUpdates("find_dataset_mess")
                .then(({ data }) => console.log(data))
                .catch(M.Alerts.loadError);
        },
        initialize_tags_last_update: () => {
            S.adminUpdates("initialize_tags_last_update")
                .then(r => console.log(r.data))
                .catch(console.error);
        },
        turn_report_file_into_files_array: () => {
            S.adminUpdates("turn_report_file_into_files_array")
                .then(r => console.log(r.data))
                .catch(console.error);
        },
        add_table_states_translations: () => {
            S.adminUpdates("add_table_states_translations")
                .then(r => console.log(r.data))
                .catch(console.error);
        }
    }), [copy]);

    //#region Buttons
    const buttons = React.useMemo(() => [
        { label: "Users en ligne", fn: () => adminCallback("show_users"), variant: "info", icon: "users" },
        { label: "Kicker les users", fn: () => adminCallback("disconnect_users"), variant: "danger", icon: "skull-crossbones" },
        { label: "Générer une clé API", fn: functions.gen_api_key, icon: "key" },
        { label: "Générer un XML", fn: functions.xml, icon: "file-alt" },
        { label: "Créer un client", fn: () => M.renderFormModal({ path: FP.CLIENT_FORM }), variant: "success" },
        { label: "Crypter un MDP", fn: functions.encrypt, variant: "secondary", icon: "lock" },
        { label: "Log Ancêtres", fn: functions.get_ancestry, icon: "tree", variant: "dark" },
        { label: "Translate Table", fn: functions.translate_table, icon: "language", variant: "info" },
        { label: "add_table_states_translations", fn: functions.add_table_states_translations },
    ], [functions, adminCallback]);

    const updates = React.useMemo(() => [
        { label: "Exporter les forms", variant: "secondary", fn: functions.export_db_forms, icon: "file-export" },
        { label: "Exporter les constantes", variant: "secondary", fn: functions.export_db_data, icon: "file-export" },
        { label: "Importer les forms", disabled: URL.IP_DIGITS === "75", variant: "secondary", fn: functions.import_db_forms, icon: "file-import" },
        { label: "Importer les constantes", disabled: URL.IP_DIGITS === "75", variant: "secondary", fn: functions.import_db_data, icon: "file-import" },
        { label: "Eq. Import (Sans context)", fn: () => HP.equip_import() },
        { label: "Eq. Import (Avec context)", fn: () => HP.equip_import({ context: roots }) },
        { label: "Listing Marques/Modèles", fn: functions.brand_model_listing, variant: "warning", icon: "list" },
        { label: "Cleaning Marques/Modèles", fn: functions.brand_model_cleaning, variant: "warning", icon: "recycle" },
        { label: "Listing liens mort", fn: functions.listing_dead_links, icon: "link" },
        { label: "Import Action/Tickets", fn: () => HP.ticket_remarque_import({ context: roots }) },
        { label: "linked_dataset_calculated", fn: functions.linked_dataset_calculated },
        { label: "fill_entry_edits_type", fn: functions.fill_entry_edits_type },
        { label: "find_dataset_mess", fn: functions.find_dataset_mess },
        { label: "initialize_tags_last_update", fn: functions.initialize_tags_last_update },
        { label: "turn_report_file_into_files_array", fn: functions.turn_report_file_into_files_array },
    ], [roots, functions]);
    //#endregion

    return <C.Spinner loading={!admin_status_checked.value}>
        <div className="container h-100 position-relative">
            <C.ErrorBanner type="success">
                <ul className="mb-0">
                    <li>Run 'add_table_states_translations' update</li>
                    <li>Run 'turn_report_file_into_files_array' update</li>
                </ul>
            </C.ErrorBanner>

            <C.Title level={3} text={TC.ADMIN_BOARD} />

            <h4>Outils</h4>
            <BS.Row className='mb-2 g-1'>
                {buttons.map((b, i) => <BS.Col key={i} sm={4} md={3}>
                    <C.Button onClick={b.fn} text={b.label} className="w-100 h-100" variant={b.variant} icon={b.icon} />
                </BS.Col>)}
            </BS.Row>

            <h4>Pour updates & autres tests</h4>
            <BS.Row className="g-1 mb-2">
                {updates.map((b, i) => <BS.Col key={i} sm={4} md={3}>
                    <C.Button onClick={b.fn} disabled={b.disabled} text={b.label} className="w-100 h-100" variant={b.variant} icon={b.icon} />
                </BS.Col>)}
            </BS.Row>
        </div>
    </C.Spinner>
};

export default BoardAdmin;