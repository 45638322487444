import "./Slide.scss";
import React from "react";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TC } from "../../../../Constants";

const Menu: React.FC<T.DiaMenuProps> = ({ onClose, onPick, ...props }) => {
    const lg = H.useLanguage();
    const list_group = React.useRef<HTMLDivElement>(null);
    H.useOnClickOutside(list_group, () => onClose?.(false));

    const on_right_action = React.useCallback((event: Parameters<React.MouseEventHandler<Element>>[0], right: T.DiaRight) => {
        event.stopPropagation();
        onClose?.(false);
        onPick?.(right.code, props.node, right.code !== TC.TREE_REVEAL);
    }, [props.node, onClose, onPick]);

    React.useEffect(() => {
        let div = list_group.current;
        let rect = div.getBoundingClientRect();

        let overflow_right = window.innerWidth - rect.right;
        let overflow_bottom = window.innerHeight - rect.bottom;

        if (overflow_right < 0) div.parentElement.style.left = (parseInt(div.parentElement.style.left) - (-1 * overflow_right) - 10) + "px";
        if (overflow_bottom < 0) div.parentElement.style.top = (parseInt(div.parentElement.style.top) - (-1 * overflow_bottom) - 10) + "px";
    }, []);

    return <BS.ListGroup key={props.node.id} ref={list_group} style={{ width: "max-content" }}>
        {props.node.rights.map(right => <BS.ListGroup.Item action key={right.code} onClick={e => on_right_action(e, right)}>
            <C.Flex justifyContent="between" alignItems="center" style={{ minWidth: "max-content", width: "100%" }}>
                <div>
                    <i className={"me-2 fa fa-" + right.icon} />
                    {lg.getStaticText(right.code)}
                </div>
                {right.shortcut && <div className="ms-3" children={right.shortcut} />}
            </C.Flex>
        </BS.ListGroup.Item>)}
    </BS.ListGroup>;
}

export default Menu;