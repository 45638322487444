import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../../Gestion/AgGridDefs";
import DataOrganizer, { TEXT_CODE_DIC } from "./DataOrganizer";
import { DS, FP, RIGHTS, T, TABS, TB, TC } from "../../../Constants";

//#region Types
export type DataPanelProps<A = {}> = {
    /** The origin to find datasets, takes priority over context */
    origin?: string;
    /** The context to find datasets, priority goes to origin */
    context?: T.ContextParams;
    /** Read-only */
    readOnly?: boolean;
    /** Do not allow to switch between the data and the links panel */
    no_link_panel?: boolean;
    /** Use the current time as the default date when adding a new manual value */
    add_data_now_default?: boolean;
    /** Callback to notify of the creation of new datasets */
    has_new_datasets?: (datasets: string[]) => void;
    /** Callback to notify of the deletion of datasets */
    has_removed_datasets?: (datasets: string[]) => void;
    /** Extra columns */
    extra_columns?: G.TableProps<Row<A>>["columns"];
    /** Add extra data to the rows */
    process_rows?: (rows: Row[]) => Row<A>[];
    /** Define the version of the table, that might change the data loaded, or the data displayed */
    version?: "epra";
    /** Force a restriction on the types of dataset to show */
    types?: T.AllowArray<T.DataSet["type"]>;
    /** Buttons to show in the toolbar */
    buttons?: G.TableProps<Row<A>>["extra_buttons"];
};

export type DataPanelRef<A = {}> = {
    /** Force a reload the content of table */
    reload: () => void;
    /** The Table Grid Ref */
    table: React.MutableRefObject<G.TableRef<Row<A>>>;
    /** The current rows */
    rows: Row<A>[];
    /** Callback to update the rows */
    setRows: (rows: Row<A>[]) => void;
}

type Row<A = {}> = T.API.Utils.Datasets.DatasetTableItem & A;
//#endregion

const TEXT_CODES = [TC.GLOBAL_EDIT, TC.GLOBAL_DELETE, TC.GLOBAL_NEW, TC.DATASET_PANEL_TAB_DATA, TC.DATASET_PANEL_TAB_LINKS, TC.CPT_ALARM_LIST];

const RenderDataPanel = <A extends {},>({ has_new_datasets, has_removed_datasets, process_rows, ...props }: DataPanelProps<A>, ref: React.ForwardedRef<DataPanelRef<A>>) => {
    const [auth] = H.useAuth();
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    const [copy] = H.useClipBoard();
    const isMounted = H.useIsMounted();
    const loading = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const grid = React.useRef<G.TableRef<Row<A>>>(null);
    const import_data_ref = React.useRef<() => void>(null);
    const [items, setItems, itemStatus] = H.useAsyncState<Row[]>([]);
    const [activeKey, setActiveKey] = React.useState<"table" | "grid">('table');

    //#region Load Items
    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(props.origin)) S.getDatasetOriginTable(props.origin)
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems([], "error"));
        else if (props.context) S.getDatasetContextTable({ context: props.context, version: props.version })
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems([], "error"));
        else setItems([], "done");

        return () => { isSubscribed = false };
    }, [props.context, props.origin, props.version, setItems]);

    React.useImperativeHandle(ref, () => ({
        table: grid,
        setRows: setItems,
        rows: items as Row<A>[],
        reload: () => {
            setItems([], "load");
            if (TB.mongoIdValidator(props.origin)) S.getDatasetOriginTable(props.origin)
                .then(({ data }) => setItems(data, "done"))
                .catch(() => setItems([], "error"));
            else if (props.context) S.getDatasetContextTable({ context: props.context, version: props.version })
                .then(({ data }) => setItems(data, "done"))
                .catch(() => setItems([], "error"));
            else setItems([], "done");
        },
    }), [props.origin, props.context, props.version, items, setItems]);
    //#endregion

    //#region Translated items
    const translatedItems = React.useMemo(() => {
        let translated = items.map(i => ({
            ...i,
            tPath: lg.getStaticText(i.path),
            tSrc: lg.getStaticText(TEXT_CODE_DIC.src[i.src]),
            tType: lg.getStaticText(TEXT_CODE_DIC.type[i.type]),
            tAgg: lg.getStaticText(DS.DATASETS.aggregate.find(a => a.value === i.aggregate)?.label),
        }) as Row<A & any>);

        if (typeof process_rows === "function") translated = process_rows(translated);
        if (Array.isArray(props.types)) translated = translated.filter(t => props.types.includes(t.type));
        else if (typeof props.types === "string") translated = translated.filter(t => t.type === props.types);
        return translated;
    }, [lg, items, process_rows, props.types]);
    //#endregion

    //#region Context Menu
    const getOrigin = React.useCallback((dataset?: typeof items[number]) => new Promise<string>(resolve => {
        // If edit dataset, return dataset's origin
        if (TB.mongoIdValidator(dataset?.origin)) resolve(dataset.origin);
        // If origin was provided, return origin
        else if (TB.mongoIdValidator(props.origin)) resolve(props.origin);
        // User pick origin from context
        else PM.renderOriginContextPickModal({ context: props.context, isRequired: true }).then(resolve);
    }), [props.origin, props.context]);

    const events = React.useMemo(() => ({
        update: (dataset?: Row, default_name?: string) => new Promise<Row>(resolve => {
            getOrigin(dataset).then(origin => {
                let default_dataset = default_name ? { name: default_name } : undefined;
                if (TB.mongoIdValidator(origin)) M.renderDataFormModal({ origin, dataset, default_values: default_dataset }).then(d => {
                    if (d) S.getDatasetItemTable(d._id).then(({ data }) => {
                        if (data.length > 0) {
                            setItems(p => {
                                const alreadyExists = p.map(d => d._id);
                                const newItems = data.filter(d => !alreadyExists.includes(d._id));
                                // Notify parent that there are new items
                                if (newItems.length > 0) has_new_datasets?.(newItems.map(i => i._id));
                                return p.map(d => data.filter(nd => nd._id === d._id)[0] || d)
                                    .concat(newItems);
                            });
                            resolve(data[0]);
                        }
                        else resolve(null);
                    })
                        .catch(error => {
                            resolve(null);
                            M.Alerts.updateError(error);
                        });
                    else resolve(null);
                });
                else resolve(null);
            });
        }),
        remove: (dataset?: Row) => {
            // Search if this dataset is used in a formula somewhere
            if (dataset) S.findDatasetsReferences(dataset._id).then(references => {
                let text = React.createElement("div", null, <>
                    <C.Title level={6} text={TC.DATASET_PANEL_USE_IN_FORMULAS} />
                    {React.createElement("ul", null, references.data.map((r, i) => <li key={i} children={r} />))}
                </>);
                if (references.data.length === 0) text = null;

                M.askConfirm({ text }).then(confirmed => {
                    if (confirmed) S.removeDataset(dataset._id).then(({ data }) => {
                        // Notify parent that datasets were removed
                        has_removed_datasets?.([dataset._id]);
                        // Update local state
                        setItems(p => p
                            // Remove the deleted dataset
                            .filter(i => i._id !== dataset._id && !data.includes(i.prediction))
                            // Remove possible reference as manual_equivalent
                            .map(i => i.manual_equivalent === dataset._id ? { ...i, manual_equivalent: undefined } : i)
                        );
                    }).catch(M.Alerts.deleteError);

                });
            }).catch(M.Alerts.loadError);
        },
        render_alarms: (dataset: Row) => M.renderBlankModal({
            isFullScreen: true,
            title: dataset.name || TC.CPT_ALARMS,
            children: <G.Alarm className="h-100" dataset={dataset._id} />,
        }),
        create_equivalent: (dataset: Row) => {
            M.askConfirm({ text: TC.DATASET_EQUIVALENT_CONFIRM, title: TC.DATASET_EQUIVALENT_TITLE }).then(confirmed => {
                if (confirmed) S.createManualEquivalent(dataset._id).then(({ data }) => {
                    let new_equivalent = data[0];
                    has_new_datasets?.([new_equivalent._id]);
                    setItems(p => p
                        // Update the automatic reading dataset
                        .map(d => d._id === dataset._id ? { ...d, manual_equivalent: new_equivalent._id } : d)
                        // Add the manual dataset
                        .concat(new_equivalent)
                    );
                }).catch(M.Alerts.updateError);
            });
        },
        add_entry: (dataset: Row) => {
            M.renderEntryFormModal({ dataset: dataset._id, askDate: true, value: props.add_data_now_default ? { time: new Date().toISOString() } : undefined }).then(entry => {
                if (entry) S.addManualEntry(entry as any)
                    .then(({ data }) => {
                        if (data === "duplicate") M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_DUPLICATE });
                        else M.Alerts.success_alert();
                    })
                    .catch(M.Alerts.updateError);
            });
        },
        create_pred: (dataset?: Row) => {
            M.renderPredForm({ dataset: dataset?._id, context: props.context }).then(new_datasets => {
                if (new_datasets && new_datasets.length > 0) S.getDatasetItemTable(new_datasets.map(d => d._id)).then(({ data }) => {
                    if (data.length > 0) setItems(p => {
                        const alreadyExists = p.map(d => d._id);
                        const newItems = data.filter(d => !alreadyExists.includes(d._id));
                        has_new_datasets?.(data.map(d => d._id));
                        return p.map(d => data.filter(nd => nd._id === d._id)[0] || d)
                            .concat(newItems);
                    });
                }).catch(M.Alerts.updateError);
            })
        },
        pred_report: (dataset: Row) => M.renderPredForm({
            is_report: true,
            modal: { size: "md" },
            context: props.context,
            prediction: dataset.prediction,
        }),
        update_pred: (dataset: Row) => {
            M.renderPredForm({ dataset: dataset.pred_dataset, context: props.context, prediction: dataset.prediction }).then(new_datasets => {
                if (new_datasets && new_datasets.length > 0) S.getDatasetItemTable(new_datasets.map(d => d._id)).then(({ data }) => {
                    if (data.length > 0) setItems(p => p
                        // Remove the datasets that were of the previous prediction, in case some are no longer applicable
                        .filter(d => d.prediction !== dataset.prediction)
                        // Add the updated dataset
                        .concat(data)
                    );
                }).catch(M.Alerts.updateError);
            })
        },
        move: (dataset: Row) => {
            // Search if this dataset is used in a formula somewhere
            if (dataset) S.findDatasetsReferences(dataset._id).then(references => {
                let text = React.createElement("div", null, <>
                    <C.Title level={6} text={TC.DATASET_PANEL_USE_IN_FORMULAS} />
                    {React.createElement("ul", null, references.data.map((r, i) => <li key={i} children={r} />))}
                </>);

                const confirm_promise = new Promise<boolean>(resolve => {
                    if (references.data.length === 0) resolve(true);
                    else M.askConfirm({ text, title: TC.TABLE_DATASET_MOVE_ELEM }).then(confirmed => resolve(confirmed))
                });

                confirm_promise.then(confirmed => {
                    if (confirmed) {
                        const location_promise = new Promise<string>(resolve => {
                            PM.renderSiteSelect({ context: props.context || { roots: props.origin }, isRequired: true }).then(root => {
                                if (root) M.renderLightTree({
                                    root,
                                    restrictOwnLinks: true,
                                    selection: dataset.origin,
                                    style: { size: "lg", title: TC.GLOBAL_PICK_PARENT },
                                    linkRestriction: {
                                        excludeIds: dataset.origin,
                                        objForm: FP.GROUP_DATASETS.map(p => forms[p]),
                                    }
                                })
                                    .then(parent => resolve(parent))
                                else resolve(null);
                            });
                        });

                        location_promise.then(new_origin => {
                            if (new_origin) S.updateDatasetOrigin({ id: dataset._id, origin: new_origin }).then(() => {
                                // If datasets are showed based on an origin, remove the old row
                                if (props.origin) setItems(p => p.filter(d => d._id !== dataset._id));
                                // Based on context, so load the updated row
                                else S.getDatasetItemTable(dataset._id).then(({ data }) => {
                                    if (data.length > 0) setItems(p => {

                                        const alreadyExists = p.map(d => d._id);
                                        const newItems = data.filter(d => !alreadyExists.includes(d._id));
                                        // Notify parent that there are new items
                                        if (newItems.length > 0) has_new_datasets?.(newItems.map(i => i._id));

                                        return p.map(d => data.filter(nd => nd._id === d._id)[0] || d)
                                            .concat(newItems);
                                    });
                                })
                            });
                        });
                    }
                });
            }).catch(M.Alerts.loadError);
        },
        export_data: (rows: Row[]) => {
            let ids = rows.map(r => r._id);
            M.askDataExport().then(params => {
                if (params) {
                    let dismount = M.renderLoader();
                    S.entityDataCsv({ entities: ids, ...params })
                        .then(({ data }) => TB.aoaToExcel(data, moment().format("YYYYMMDD_HHmmss"), "Data"))
                        .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_FAIL_GEN_FILE }))
                        .finally(dismount);
                }
            });
        },
        on_inline_change: (params => {
            let row = params.data,
                old_value = params.oldValue,
                field = params.colDef.field as keyof typeof translatedItems[number];
            // This field can't be edited
            if (field !== "tags_names") M.Alerts.updateError();
            // All seems ok
            else {
                let prop: keyof T.DataSet;
                if (field === "tags_names") {
                    prop = "tags";
                    old_value = row.tags;
                }
                else prop = field as typeof prop;

                if (!_.isEqual(old_value, params.newValue)) {

                    const updatePromise = new Promise<"cancel" | Row>((resolve, reject) => {
                        let api_params = {
                            field: prop,
                            _id: row._id,
                            old_value: old_value,
                            new_value: params.newValue,
                        } as Parameters<typeof S.update_dataset_field>[0];

                        loading.setTrue();

                        S.update_dataset_field(api_params).then(({ data }) => {
                            if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                                if (confirmed) S.update_dataset_field({ ...api_params, force_update: true }).then(({ data }) => {
                                    if (data === "changed") reject("Error");
                                    else resolve(data);
                                })
                                else resolve("cancel");
                            });
                            else resolve(data);
                        }).catch(reject);
                    });

                    updatePromise.then(new_row => {
                        if (new_row !== "cancel") setItems(p => p.map(t => t._id === new_row._id ? new_row : t));
                    })
                        .catch(M.Alerts.updateError)
                        .finally(loading.setFalse);
                }
            }
        }) as G.TableProps<Row<A>>["onValueChange"],
        update_origin: (data: Row) => {
            // Find the right to edit the element
            let write_right = null;
            if (data.path === FP.SITE_FORM) write_right = RIGHTS.TECH.EDIT_SITE;
            else if (data.path === FP.BUILDING_FORM) write_right = RIGHTS.TECH.EDIT_BUILDING;
            else if (data.path === FP.EQUIPEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EQUIPMENT;
            else if (data.path === FP.EMPLACEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EMPLACEMENT;
            else write_right = RIGHTS.TECH.WRITE_OTHERS;
            // Check if the user has the right to edit the element
            let readOnly = !rights.isRightAllowed(write_right);
            let updated_sub_equip = [] as string[];

            M.renderPopUpFormModal({ path: data.path, submissionId: data.origin, readOnly }).then(submission => {
                let to_reload = [submission._id, ...updated_sub_equip].filter(TB.mongoIdValidator);
                if (to_reload.length > 0) {
                    loading.setTrue();
                    S.getDatasetItemTable(to_reload).then(({ data }) => {
                        if (data.length > 0) setItems(p => {
                            const alreadyExists = p.map(d => d._id);
                            const newItems = data.filter(d => !alreadyExists.includes(d._id));
                            // Notify parent that there are new items
                            if (newItems.length > 0) has_new_datasets?.(newItems.map(i => i._id));
                            return p.map(d => data.filter(nd => nd._id === d._id)[0] || d)
                                .concat(newItems);
                        });
                    })
                        .catch(M.Alerts.updateError)
                        .finally(loading.setFalse);
                }
            })
        },
        dashboard: (rows: Row[]) => M.renderAlertDashboards({ datasets: rows.map(r => r._id) }),
    }), [forms, loading, rights, getOrigin, has_new_datasets, setItems, has_removed_datasets, props.add_data_now_default, props.context, props.origin]);

    const getContextMenu = React.useCallback<G.TableProps<Row<A>>["getContextMenuItems"]>(event => {
        const dataset = event.node?.data as Row<A & any>;
        const selection = event.api.getSelectedRows() || [] as Row<A>[];
        const actions: ReturnType<G.TableProps<Row<A>>["getContextMenuItems"]> = [];

        // If there is a dataset, and it is not yet in the selection, add it to the selection
        if (dataset && !selection.some(s => s._id === dataset._id)) selection.push(dataset);

        if (!props.readOnly) {
            // Create a new dataset
            actions.push({ name: TC.GLOBAL_NEW, action: () => events.update(), icon: "<i class='fa fa-plus'></i>" });
            // Other actions for a single element that is not a prediction
            if (dataset && dataset.src !== "prediction") actions.push(
                // Edit
                { name: TC.GLOBAL_EDIT, action: () => events.update(dataset), icon: '<i class="fa fa-pencil-alt"></i>' },
                // Move
                { name: TC.TABLE_DATASET_MOVE_ELEM, action: () => events.move(dataset), icon: '<i class="fa fa-arrows-alt"></i>' },
                // Delete
                { name: TC.GLOBAL_DELETE, action: () => events.remove(dataset), icon: '<i class="fa fa-times"></i>' },
                // Show alarms
                { name: TC.CPT_ALARM_LIST, action: () => events.render_alarms(dataset), icon: "<i class='fa fa-bell text-danger'></i>" },
                // Create a manual equivalent for an automatic reading
                {
                    name: TC.DATASET_EQUIVALENT_TITLE,
                    icon: "<i class='fa fa-copy'></i>",
                    action: () => events.create_equivalent(dataset),
                    disabled: dataset.src !== "automatic" || TB.mongoIdValidator(dataset.manual_equivalent),
                },
                // Create a prediction model for an automatic reading
                {
                    name: TC.PRED_NEW_FROM_DATASET,
                    action: () => events.create_pred(dataset),
                    icon: "<i class='fa fa-project-diagram'></i>",
                },
            );
            else if (dataset && dataset.src === "prediction") actions.push(
                { name: TC.GLOBAL_DELETE, action: () => events.remove(dataset), icon: '<i class="fa fa-times"></i>' },
                { name: TC.PRED_UPDATE_MODEL, action: () => events.update_pred(dataset), icon: "<i class='fa fa-project-diagram'></i>" },
                { name: TC.PRED_MODEL_REPORT, action: () => events.pred_report(dataset), icon: "<i class='fa fa-project-diagram'></i>" },
            );
            // Add a separation
            if (actions.length > 0 && selection.length > 0) actions.push("separator");
            // Open dashboard for multiple elements
            if (selection.length > 0) actions.push(
                {
                    icon: "<i class='fa fa-chart-pie'></i>",
                    action: () => events.dashboard(selection),
                    name: lg.getStaticText(TC.DATASET_TABLE_OPEN_DASHBOARD, selection.length),
                },
                {
                    icon: "<i class='fa fa-file-excel'></i>",
                    action: () => events.export_data(selection),
                    name: lg.getStaticText(TC.DATASET_TABLE_EXPORT_DATA, selection.length),
                },
            );
            // Add another separation
            if (actions.length > 0 && event.defaultItems) actions.push("separator");
        }
        else if (selection.length > 0) actions.push(
            {
                icon: "<i class='fa fa-chart-pie'></i>",
                action: () => events.dashboard(selection),
                name: lg.getStaticText(TC.DATASET_TABLE_OPEN_DASHBOARD, selection.length),
            },
            "separator"
        );

        // Add default items
        const defaultItems = TB.getArray(event.defaultItems).filter(TB.validString);
        return actions.concat(defaultItems)
            .map(a => typeof a === "string" ? a : { ...a, name: lg.getStaticText(a.name) });
    }, [events, lg, props.readOnly]);
    //#endregion

    //#region Columns
    const get_options = React.useMemo(() => ({
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            if (row) S.getNoteTags({ context: { roots: row.origin }, type: "dataset" })
                .then(r => resolve(r.data))
                .catch(reject);
            else resolve([]);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [auth.userId], sites: [], type: "dataset" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [auth.userId]);

    const columns = React.useMemo<G.TableProps<Row<A>>["columns"]>(() => {
        let col_defs = [
            {
                field: 'edit',
                headerName: TC.UPDATE,
                type: CT.TYPE_ACTION_BUTTON,
                params: {
                    isDisabled: props.readOnly,
                    action: row => row && events.update(row),
                    isHidden: row => !row || row.src === "prediction",
                    buttonProps: { variant: "primary", size: "sm", icon: "pencil-alt" },
                }
            },
            {
                field: 'data',
                headerName: TC.DATASET_DATA,
                type: CT.TYPE_ACTION_BUTTON,
                params: {
                    buttonProps: { variant: "secondary", size: "sm", icon: "chart-line" },
                    action: (row?: Row) => row && M.renderDataChartModal({ dataset: row, modalProps: { isFullScreen: true } }),
                }
            },
            {
                field: "add_manual",
                headerName: TC.DATASET_ADD_ENTRY_QUICK,
                type: CT.TYPE_ACTION_BUTTON,
                params: {
                    isDisabled: props.readOnly,
                    isHidden: (row: Row) => row?.src !== "manual",
                    action: (row: Row) => row && events.add_entry(row),
                    buttonProps: { variant: "primary", size: "sm", icon: "plus" },
                }
            },
            { field: 'name', headerName: TC.DATASET_NAME },
            { field: 'tSrc', headerName: TC.DATASET_SRC },
            { field: 'tAgg', headerName: TC.DATASET_AGGREGATION },
            { field: 'tType', headerName: TC.DATASET_TYPE },
            {
                headerName: TC.TABLE_DATASET_LAST_VALUE_TITLE,
                children: [
                    { field: "last_value", headerName: TC.TABLE_DATASET_LAST_VALUE_NUMBER, type: CT.TYPE_NUMBER },
                    { field: "last_value_time", headerName: TC.TABLE_DATASET_LAST_VALUE_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
                ]
            },
            {
                field: "tags_names",
                type: CT.TYPE_SELECT,
                editable: !props.readOnly,
                headerName: TC.DATASET_TAGS,
                params: {
                    multiple: true,
                    field_value: "tags",
                    getValues: get_options.tags,
                    typeahead: { allowNewOption: true, onAddOption: get_options.create_tag },
                }
            },
            { field: 'tFlow', headerName: TC.DATASET_FLOW },
            { field: 'unit', headerName: TC.DATASET_UNIT },
            { field: 'cumulated', headerName: TC.DATASET_CUMULATED, type: CT.TYPE_CHECKBOX },
            { field: 'global', headerName: TC.DATASET_IS_HEAD, type: CT.TYPE_CHECKBOX },
            { field: "renter", headerName: TC.GLOBAL_LABEL_RENTER },
            {
                field: "last_formula",
                type: CT.TYPE_FREE_RENDER,
                headerName: TC.DATASET_LATEST_FORMULA,
                params: { render: row => row && <C.HtmlText html={row.last_formula_html} /> },
            },
        ] as G.TableProps<Row<A>>["columns"];

        // Datasets are from a whole context, so show extra location infos
        if (!TB.mongoIdValidator(props.origin)) {
            col_defs.push({
                headerName: TC.DATASET_ORIGIN,
                children: [
                    { headerName: TC.DATASET_ELEMENT, field: "element", type: CT.TYPE_LINKED_ELEM, params: { render_form: events.update_origin } },
                    { headerName: TC.DATASET_PATH, field: "tPath" },
                    { headerName: FP.SITE_FORM, field: "site.name", hide: true },
                    { headerName: FP.BUILDING_FORM, field: "building.name", hide: true },
                    { headerName: FP.EMPLACEMENT_FORM, field: "emplacement.name", hide: true },
                    { headerName: TC.GLOBAL_LOCAL, field: "local.name", hide: true },
                    { headerName: TC.GLOBAL_FULL_LOC, field: "full_path" },
                ]
            });
            // If we are using the EPRA version, add the affectation and country
            if (props.version === "epra") (col_defs[col_defs.length - 1] as G.ColGroupDef<Row<A>>).children.push(
                { headerName: TC.DATASET_AFFECTATION, field: "affectation" },
                { headerName: TC.DATASET_COUNTRY, field: "country" }
            );
        };

        if (auth.isAdmin) col_defs.push({ field: "_id", headerName: "_id", editable: false });

        if (Array.isArray(props.extra_columns)) col_defs.push(...props.extra_columns);
        else if (props.extra_columns) col_defs.push(props.extra_columns);

        return col_defs;
    }, [props.origin, props.extra_columns, events, auth.isAdmin, props.readOnly, props.version, get_options]);
    //#endregion

    //#region Tabs
    const changeTab = React.useCallback((key: typeof activeKey) => {
        if (key === "table") {
            setItems([], "load");
            // Reload context
            if (TB.mongoIdValidator(props.origin)) S.getDatasetOriginTable(props.origin)
                .then(({ data }) => isMounted() && setItems(data, "done"))
                .catch(() => isMounted() && setItems([], "error"));
            else if (props.context) S.getDatasetContextTable({ context: props.context, version: props.version })
                .then(({ data }) => isMounted() && setItems(data, "done"))
                .catch(() => isMounted() && setItems([], "error"));
            else setItems([], "done");
        }
        setActiveKey(key);
    }, [props.origin, props.context, props.version, setItems, isMounted]);
    //#endregion

    //#region Table Misc.
    const manual_import = React.useCallback(() => {
        let map_params: Parameters<typeof M.renderExcelMapper>[0] = {
            raw: true,
            invert: true,
            allowIgnoring: true,
            allowSwapping: false,
            onAddColumns: text => new Promise(resolve => {
                events.update(null, text).then(row => {
                    if (row && (row.src === "automatic" || row.src === "manual")) {
                        let name = `${row.name} ${row.unit ? `[${row.unit}]` : ""} / ${row.element} (${row.site?.name || "N/A"})`;
                        resolve({ [row._id]: { type: "number", label: name } });
                    }
                    else if (row) M.renderAlert({ type: "info", message: TC.IMPORT_DATA_MAPPER_DATASET_SRC_INVALID })
                    else resolve(null);
                })
            }),
            columnFormat: {
                time: {
                    type: "date",
                    required: true,
                    no_convert_date: true,
                    label: lg.getStaticText(TC.DATASET_COL_TIME),
                }
            },
        };

        for (let dataset of items) {
            if (dataset.src === "automatic" || dataset.src === "manual") {
                let name = `${dataset.name} ${dataset.unit ? `[${dataset.unit}]` : ""} / ${dataset.element} (${dataset.full_path || "N/A"})`;
                map_params.columnFormat[dataset._id] = { type: "number", label: name };
            }
        }

        M.renderExcelMapper(map_params).then(results => {
            if (Array.isArray(results)) {
                let times = results.map(r => r.time);
                let datasets = [] as Parameters<typeof S.importMultipleRecords>[0];

                for (let result of results) {
                    let time = result.time;
                    for (let [key, value] of Object.entries(result)) {
                        if (key !== "time") {
                            let dataset_existing = datasets.filter(d => d.dataset === key)[0];
                            if (dataset_existing) dataset_existing.entries.push({ time, value });
                            else datasets.push({ dataset: key, entries: [{ time, value }] });
                        }
                    }
                }

                if (datasets.length === 0) M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_EMPTY_IMPORT });
                else {
                    let format_promise = new Promise<"cancel" | typeof datasets>(resolve => {
                        if (times.some((t: any) => !(t instanceof Date))) M.askTimeFormat({ examples: times }).then(format => {
                            if (format) {
                                let parsed_datasets = datasets.map(d => ({
                                    ...d,
                                    entries: d.entries.map(e => ({
                                        value: TB.getNumber(e.value),
                                        time: TB.parseDate(e.time, format.format, format.timezone, format.timezone_data)?.toISOString?.(),
                                    }))
                                }));
                                resolve(parsed_datasets);
                            }
                            else resolve("cancel");
                        });
                        else resolve(
                            datasets.map(d => ({
                                ...d,
                                entries: d.entries.map(e => ({
                                    value: TB.getNumber(e.value),
                                    time: (e.time as any)?.toISOString?.(),
                                }))
                            }))
                        );
                    });

                    format_promise.then(parsed_datasets => {
                        if (parsed_datasets !== "cancel") S.importMultipleRecords(parsed_datasets).then(({ data }) => {
                            // Tell the user some errors occurred
                            if (data.failed.length > 0) {
                                let message = TC.FAIL_DATA_IMPORT;
                                // Show Error message
                                M.renderAlert({ type: "error", message });
                                // User download a file of the values that were not imported
                                TB.jsonToExcel(data.failed, "IMPORT_FAIL", "sheet");
                            }
                            // Tell the users data was inserted, but there were some duplicates
                            if (data.duplicates.length > 0) {
                                copy(data.duplicates.join("\n"));
                                M.renderAlert({ type: "warning", message: TC.FAIL_DATA_IMPORT_DUPLICATE });
                                M.renderBlankModal({
                                    size: "md",
                                    title: TC.DATA_INSERT_DUPLICATES,
                                    children: <>{data.duplicates.map((d, i) => <div key={i}>{d}</div>)}</>,
                                })
                            }
                            // Notify the user all went well
                            if (data.failed.length === 0 && data.duplicates.length === 0) M.Alerts.success_alert();
                        }).catch(M.Alerts.updateError);
                    });
                }
            }
        });
    }, [items, lg, events, copy]);

    const import_data = React.useCallback(() => {
        S.getStations(props.context).then(stations => {
            let type_params = {
                isRequired: true,
                title: TC.TAG_MAPPER_IMPORT_TYPE,
                options: [{ label: TC.TAG_MAPPER_MANUAL_IMPORT, value: "manual" }],
            } as Parameters<typeof M.askSelect>[0];
            // Add the stations directly to the pick
            stations.data.forEach(s => type_params.options.push({ ...s, label: lg.getStaticText(TC.TAG_MAPPER_STATION_IMPORT, s.label) }));
            // No Stations were found, default to manual import
            if (stations.data.length === 0) manual_import();
            else M.askSelect(type_params).then(type => {
                let station_name = stations.data.filter(s => s.value === type)[0]?.label;
                if (type) {
                    if (type === "manual") manual_import();
                    else M.renderTagMapper({ context: props.context, station: type, modal: { title: station_name } }).then(datasets => {
                        if (datasets) S.getDatasetItemTable(datasets.map(d => d._id)).then(({ data }) => {
                            if (data.length > 0) setItems(p => {
                                const alreadyExists = p.map(d => d._id);
                                const newItems = data.filter(d => !alreadyExists.includes(d._id));
                                has_new_datasets?.(newItems.map(d => d._id));
                                return p.map(d => data.filter(nd => nd._id === d._id)[0] || d).concat(newItems);
                            });
                        }).catch(M.Alerts.loadError);
                    });
                }
            });
        }).catch(M.Alerts.loadError);
    }, [props.context, setItems, manual_import, has_new_datasets, lg]);

    React.useImperativeHandle(import_data_ref, () => import_data, [import_data]);

    const table_props = React.useMemo(() => {
        // The columns to resize themselves
        let auto_fit: G.TableProps<Row>["autoFit"] = ["data", "edit", "add_manual"];
        // Extra buttons
        let extra_buttons: G.TableProps<Row>["extra_buttons"] = [];
        // Add the extra buttons
        if (Array.isArray(props.buttons)) extra_buttons.push(...props.buttons);
        else if (props.buttons) extra_buttons.push(props.buttons);
        // If the user is allowed to import data, add the button
        if (!props.readOnly) extra_buttons.push(
            {
                onClick: () => import_data_ref.current(),
                label: lg.getStaticText(TC.DATASET_IMPORT_DATA),
                icon: { element: "<i class='fa fa-file-import me-2'></i>" },
                hidden: () => !rights.isRightAllowed(RIGHTS.NRJ.WRITE_OWN_DATA),
            },
            {
                label: lg.getStaticText(TC.PRED_NEW),
                onClick: () => events.create_pred(),
                icon: { element: "<i class='fa fa-project-diagram me-2'></i>" },
            }
        );

        return { auto_fit, extra_buttons };
    }, [lg, rights, events, props.readOnly, props.buttons]);
    //#endregion

    return <C.Flex direction="column" className="h-100">
        {itemStatus === "error" && <C.ErrorBanner type='danger' textCode={TC.GLOBAL_FAILED_LOAD} />}
        {!props.readOnly && !props.no_link_panel && <div className="mb-2">
            <BS.Nav variant="pills" activeKey={activeKey} onSelect={changeTab}>
                <BS.Nav.Item>
                    <BS.Nav.Link eventKey="table">
                        {lg.getStaticElem(TC.DATASET_PANEL_TAB_DATA)}
                    </BS.Nav.Link>
                </BS.Nav.Item>
                <BS.Nav.Item>
                    <BS.Nav.Link eventKey="grid">
                        {lg.getStaticElem(TC.DATASET_PANEL_TAB_LINKS)}
                    </BS.Nav.Link>
                </BS.Nav.Item>
            </BS.Nav>
        </div>}

        {activeKey === "table"
            ? <G.Table<Row<A>>
                sideBar
                ref={grid}
                columns={columns}
                status={itemStatus}
                rows={translatedItems}
                loading={loading.value}
                rowSelection="multiple"
                getRowId={r => r.data._id}
                columns_base="all_but_edit"
                autoFit={table_props.auto_fit}
                getContextMenuItems={getContextMenu}
                onValueChange={events.on_inline_change}
                extra_buttons={table_props.extra_buttons}
                adaptableId={props.origin || TABS.NRJ_TAG_TABLE}
            />
            : <DataOrganizer origin={props.origin} context={props.context} />
        }
    </C.Flex>;
};

const DataPanel = React.forwardRef(RenderDataPanel) as <A>(props: DataPanelProps<A> & Partial<Record<"ref", React.ForwardedRef<DataPanelRef<A>>>>) => React.ReactElement;

export const DataPanelContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.GP_BUILD_TAB_ENTITY);
    H.useAuth({ tabName: TABS.NRJ_TAG_TABLE });

    return <C.Flex className="w-100" direction="column" children={<DataPanel context={roots} />} />;
}

export default DataPanel;