import { T } from "../Constants";
import { store } from "../Redux";

type State = T.TableStateRedux;
type Actions = T.TableStateActions;

const table_state_reducer: T.ReduxFn<State, Actions> = (state = "", payload) => {
    if (payload.type === "SET_TABLE_STATE") return payload.action;
    else if (payload.type === "CLEAR_TABLE_STATE") return "";
    else return state;
}

export default table_state_reducer;

/** Set a table state */
export const set_table_state = (id: State) => store.then(s => s.dispatch({ type: "SET_TABLE_STATE", action: id }));
/** Clear the table state */
export const clear_table_state = () => store.then(s => s.dispatch({ type: "CLEAR_TABLE_STATE" }));