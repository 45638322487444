import _ from "lodash";
import { REGEX } from "../Data";
import { validString, stringSimplifier } from "./string";

/**
 * Transform a blob to as base64 string
 */
export const getB64FromFileObject = (blob: Blob) => new Promise<string>((resolve, reject) => {
    let reader = new FileReader();
    reader.onerror = reject;
    reader.onload = e => validString(e.target?.result) ? resolve(e.target.result) : reject(null);
    reader.readAsDataURL(blob);
});

/**
 * Converts a human readable file size to a number of bytes
 */
export const humanSizeFormatToBytes = (size?: string) => {
    if (typeof size !== "string" || !size.match(REGEX.FILE_SIZE_REGEX)) return null;
    let sizes = ['BYTES', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let [sizeFormat, num] = _.partition(size.split(''), x => isNaN(parseInt(x)));
    let number = parseInt(num.join(""));
    let format = sizeFormat.join("").toUpperCase();

    if (isNaN(number) || !sizes.includes(format)) return null;
    return number * (1024 ** sizes.indexOf(format));
}

/**
 * Transform a number of bytes to a human readable string
 */
export const bytesToHumanSizeFormat = (bytes: number, decimals = 2) => {
    if (typeof bytes !== "number") return "Invalid Bytes Format";
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Get a simpler type for a file based on its mime type
 */
export const get_file_simple_type_based_on_mime_type = (mime_type: string): "word" | "picture" | "excel" | "pdf" | "video" | "other" => {
    const typeMapping: Record<string, ReturnType<typeof get_file_simple_type_based_on_mime_type>> = {
        'video/.*': 'video',
        'image/.*': 'picture',
        'application/pdf': 'pdf',
        'application/(vnd.openxmlformats-officedocument.wordprocessingml.document|msword)': 'word',
        'application/(vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.ms-excel)': 'excel',
    };

    for (const [regex, type] of Object.entries(typeMapping)) {
        if (new RegExp(regex).test(mime_type)) return type;
    }
    return 'other';
}

/**
 * Transform an array Buffer to a Base 64 string
 */
export const asyncArrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => getB64FromFileObject(new Blob([arrayBuffer]));

/** Replace any character not allowed on a file name with some other character */
export const sanitizeFileName = (input: string, replace = " ") => stringSimplifier(input).replace(/[/\\:*?"<>|]/g, replace || '_');