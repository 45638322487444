import * as R from "../Redux";
import * as S from "../services";
import { T, TB } from "../Constants";

type State = T.RoutesStatesRedux;
type Actions = T.RoutesStatesActions;

type RemoveStateAction = string;
type AddStateAction = T.AllowArray<T.RoutesStatesRedux[number]>;

const routes_reducer: T.ReduxFn<State, Actions> = (state = [], payload) => {
    let { type = null, action = null, asyncDispatch } = payload || {};

    // Add a new state to a route
    if (type === "ADD_TABLE_STATE_ROUTE") {
        let new_states = TB.arrayWrapper(action) as AddStateAction;
        return state.concat(new_states);
    }
    // Remove a state from a route
    else if (type === "DELETE_TABLE_STATE_ROUTE") {
        let state_id = action as RemoveStateAction;
        return state.filter(s => s.id !== state_id);
    }
    else if (type === "LOGIN_SUCCESS") {
        // Load the routes states for the user that has just logged in
        S.getRoutesTableStates()
            .then(({ data }) => asyncDispatch({ type: "LOADED_ROUTES_STATES", action: data }))
            .catch(() => asyncDispatch({ type: "LOADED_ROUTES_STATES", action: [] }));
        // Return a default state while waiting for the states to be loaded
        return [];
    }
    else if (type === "LOADED_ROUTES_STATES") return action as State;
    else return state;
}

/** Add a new state to a route */
export const add_route_state = (state: AddStateAction) => R.store.then(s => s.dispatch({ type: "ADD_TABLE_STATE_ROUTE", action: state }));
/** Remove a state from a route */
export const remove_route_state = (state_id: RemoveStateAction) => R.store.then(s => s.dispatch({ type: "DELETE_TABLE_STATE_ROUTE", action: state_id }));

export default routes_reducer;